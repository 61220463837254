import SVGIcon from './SVGIcon'

export default function LearningIcon({className, color, onClick})
{
  return <SVGIcon
          name='learning'
          className={className}
          size='84.62 101.27'
          color={color}
          onClick={onClick}
          paths={[
            'm48.88,101.27c15.82,0,25.2-5.57,25.2-14.99v-20.95c6.64-5.81,10.55-15.14,10.55-25.44C84.62,15.92,69.09,0,45.85,0S7.28,15.58,7.28,39.01c0,1.37.05,2.49.15,3.56l-5.32,9.47c-1.42,2.59-2.1,5.03-2.1,7.28,0,4.35,2.69,7.81,7.23,8.94v8.64c0,7.62,5.37,11.23,12.21,10.5l7.81-.83-3.12-3.17v2.88c0,9.42,9.18,14.99,24.76,14.99Zm0-7.03c-11.43,0-18.16-3.52-18.16-9.38v-4.98c0-.59-.24-.78-.78-.73l-11.43,1.27c-2.73.29-4.25-.78-4.25-3.96v-13.38c0-.39-.29-.68-.78-.68h-2.39c-2.59,0-4-1.22-4-3.17,0-1.07.34-2.3,1.17-3.71l6.45-11.52c-.29-1.71-.44-3.42-.44-5.08,0-19.14,12.6-31.88,31.59-31.88s31.74,13.13,31.74,32.86c0,9.52-4,17.82-10.6,21.92l.05,23.05c0,5.86-6.79,9.38-18.16,9.38Z',
            'm36.28,21.88c-.05,1.32.83,2.3,2.2,2.3,1.27,0,2.1-.83,2.15-2.15.15-3.37,2.54-5.71,5.91-5.71,1.95,0,3.76,1.03,4.49,2.64.34.59.98.54,1.95.29.34-.05.73-.05,1.17-.05,3.42,0,6.15,2.69,6.15,6.15,0,8.79-12.7,7.13-12.7,16.6,0,4.35,3.47,7.52,8.35,7.52h1.07c.68,3.81,3.27,6.4,6.4,6.4,4.59,0,7.91-5.08,7.91-11.91,0-3.03-.2-4.93-.39-6.74l-4.44,1.22c.2,1.71.44,3.32.44,5.42,0,4.1-1.66,7.62-3.52,7.62-1.66,0-2.78-2.15-2.78-5.18,0-.54-.39-.78-.88-.78h-1.71c-4.1,0-6.01-1.17-6.01-3.61,0-6.54,12.7-4.49,12.7-16.55,0-5.96-4.49-10.6-10.16-10.6-.29,0-.68.05-1.07.1-1.76-1.86-4.3-2.93-6.98-2.93-5.81,0-10.06,4.1-10.25,9.96Zm-15.77,10.6c0,7.42,5.18,12.5,12.84,12.5s12.65-4.54,12.65-11.28c0-2.83-.93-5.13-2.73-6.59-.88-.73-1.46-1.03-2.29-1.03-1.22,0-2.15.83-2.15,2,0,1.37.93,1.81,1.81,2.64.68.63,1.03,1.61,1.03,2.88,0,4.2-3.27,6.98-8.25,6.98s-8.5-3.22-8.5-8.11c0-3.03,1.56-5.71,4.2-7.08l-2.29-3.86c-3.91,2.25-6.3,6.45-6.3,10.94Zm38.33,8.45c0,1.17.93,2.1,2.15,2.25,7.03.49,11.87-3.71,11.87-10.45,0-5.57-4.05-9.86-10.06-10.5l-.78,4.44c3.86-.24,6.45,2.2,6.45,6.05s-2.93,6.45-7.37,6.01c-1.17-.05-2.25.98-2.25,2.2ZM25.88,22.31c0,4.83,3.37,8.06,8.35,8.06,1.17,0,2.25-1.03,2.25-2.2s-1.03-2.2-2.25-2.2c-2.39,0-3.91-1.46-3.91-3.66s1.95-3.96,4.59-3.96c1.56,0,3.12.59,4.39,1.66l2.64-3.61c-2.05-1.56-4.44-2.44-6.98-2.44-5.18,0-9.08,3.56-9.08,8.35Zm23.97,20.36c-1.17.49-2.64.73-4.05.73-3.52,0-6.88-1.51-7.67-3.56l-3.71,3.03c2.2,3.08,6.4,4.98,11.04,4.98,1.95,0,4-.34,5.91-1.03l-1.51-4.15Zm-6.98-13.77l1.42,4.25c5.27-.88,8.01-4.05,8.01-9.23,0-1.17-1.03-2.2-2.2-2.2s-2.2.98-2.2,2.2c0,3.22-1.46,4.64-5.03,4.98Z'
            ]}
          />
}
