import { useState } from 'react'
import joinedClassName from 'ssi-react-lib/joinedClassName'
import DropDownIconBox from '../Widgets/DropDownIconBox'
import List from 'ssi-react-lib/List'
import './DropsList.css'

export default function DropsList({className, items, getLabel, getKey, getMeta, itemLayout, itemDropLayout})
{
  const [selected, setSelected] = useState(null)

  const onClick = (uuid) =>
  {
    if (uuid === selected)
    {
      setSelected(null)
    } else {
      setSelected(uuid)
    }
  }

  const litems = items.map(item =>
  {
    const isSelected = selected && item.uuid === selected
    const dropDownLayout = (fullyExpanded) =>
    {
      return itemDropLayout(item, fullyExpanded)
    }
    const controls =
      <DropDownIconBox active={isSelected} dropDownLayout={dropDownLayout} />
    const contents =
      <div className='list-item-row-box'>
        <div className='list-item-row'>
          {itemLayout(item)}
        </div>
        {controls}
      </div>
    return { label: getLabel(item), key: getKey(item), meta: getMeta(item), className: isSelected ? 'list-item-selected' : null, contents: contents }
  })

  const list = litems.length > 0 ?
    <List className={joinedClassName('drops-list', className)} onClick={onClick}>
      {litems}
    </List> : null
  return list
}
