import SVGIcon from './SVGIcon'

export default function PaperAirplaneIcon({className, color, onClick}) {
  return <SVGIcon
          name='paper-airplane'
          className={className}
          size='84.88 75.06'
          color={color}
          onClick={onClick}
          paths={['m39.27,74.99c2.98.53,5.51-1.71,7.73-5.33L82.69,10.15c1.12-1.84,1.86-3.49,2.11-4.93.47-2.64-.92-4.62-3.56-5.09-1.44-.25-3.25-.13-5.31.3L7.34,13.73c-3.59.7-6.71,2.34-7.23,5.32-.68,3.85,2.09,5.57,5.69,7.4l25.35,12.6,3.15,27.68c.44,4.24,1.14,7.59,4.99,8.27Zm-5.06-42.14l-23.91-12.3c-.32-.16-.39-.32-.36-.46.03-.19.11-.33.52-.4l51.03-9.64c3.64-.7,7.21-1.8,10.8-2.76-3.3,1.85-7.19,3.94-9.96,5.88l-28.11,19.68Zm7.48,32.65c-.19-.03-.25-.24-.34-.61l-3.38-26.68,28.1-19.64c2.83-1.98,6.18-4.96,9-7.49-2.12,3.05-4.44,6.06-6.36,9.34l-26.51,44.61c-.21.36-.33.49-.53.45Z']}
        />
}
