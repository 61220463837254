import { useNavigate } from 'react-router-dom'
import joinedClassName from 'ssi-react-lib/joinedClassName'
import './NavPanel.scss'

export default function NavPanel({className, backLabel, backUrl, onBack, extraButtons, children})
{
  const navigate = useNavigate()

  const goBack = () =>
  {
    if (onBack) onBack();
    navigate(backUrl ?? -1)
  }

  const nav =
    <div className='nav-panel-outer'>
      <div className='nav-back-button-row'>
        <span className='nav-back-button' onClick={goBack}>◀︎ {backLabel}</span>
        {extraButtons}
      </div>
      <div className={joinedClassName('navpanel-content', className)}>
        {children}
      </div>
    </div>
  return nav
}
