import SVGIcon from './SVGIcon'

export default function GroupIcon({className, color, onClick})
{
  return <SVGIcon
          name='group'
          className={className}
          size='152.69 72.12'
          color={color}
          onClick={onClick}
          paths={[
            'M10.06,72.12H57.32c6.84,0,10.06-2,10.06-6.4,0-10.06-12.7-24.76-33.69-24.76S0,55.66,0,65.72c0,4.39,3.27,6.4,10.06,6.4Zm23.63-36.28c9.18,0,16.8-8.25,16.8-18.16S43.07,0,33.69,0,16.89,7.91,16.89,17.77s7.62,18.07,16.8,18.07ZM7.86,65.53c-.54,0-.83-.15-.83-.68,0-5.71,9.38-17.29,26.66-17.29s26.66,11.57,26.66,17.29c0,.54-.29,.68-.83,.68H7.86ZM33.69,29.25c-5.18,0-9.77-5.08-9.77-11.47s4.44-11.18,9.77-11.18,9.77,4.69,9.77,11.08-4.49,11.57-9.77,11.57Z',
            'M76.37,35.84c9.18,0,16.8-8.25,16.8-18.16S85.74,0,76.37,0s-16.85,7.91-16.8,17.77c0,9.86,7.57,18.07,16.8,18.07Zm0,5.13c-6.15,0-11.57,1.27-16.21,3.32,2,1.51,3.91,3.17,5.47,4.93,3.12-1.03,6.74-1.66,10.74-1.66,17.24,0,26.66,11.57,26.66,17.29,0,.54-.29,.68-.83,.68h-29.59c0,2.34-.39,4.59-1.81,6.59h29.15c6.84,0,10.11-2,10.11-6.4,0-10.06-12.74-24.76-33.69-24.76Zm0-11.72c-5.22,0-9.77-5.08-9.77-11.47s4.39-11.18,9.77-11.18,9.77,4.69,9.77,11.08-4.54,11.57-9.77,11.57Z',
            'M118.99,35.84c9.23,0,16.8-8.25,16.8-18.16S128.37,0,118.99,0s-16.8,7.91-16.8,17.77,7.62,18.07,16.8,18.07Zm0,5.13c-6.1,0-11.57,1.27-16.21,3.32,2.05,1.51,3.91,3.17,5.52,4.98,3.12-1.07,6.74-1.71,10.69-1.71,17.29,0,26.66,11.57,26.66,17.29,0,.54-.29,.68-.83,.68h-29.54c-.05,2.34-.39,4.59-1.81,6.59h29.15c6.84,0,10.06-2,10.06-6.4,0-10.06-12.7-24.76-33.69-24.76Zm0-11.72c-5.18,0-9.77-5.08-9.77-11.47s4.44-11.18,9.77-11.18,9.76,4.69,9.76,11.08-4.49,11.57-9.76,11.57Z'
            ]}
          />
}
