export default function PlayPauseIcon(props) {
  const color = props.color != null ? props.color : "currentColor"
  const playPause =
    <svg className="icon playPause-icon" viewBox="0 0 64 65.04">
      <path className="playIcon" fill={color} transform='translate(15 11.5)'
        d="M3.11,40.65c1.07,0,1.95-.4,2.99-1.01l27.47-15.81c2.23-1.31,2.96-2.14,2.96-3.51s-.73-2.2-2.96-3.51L6.1,1.01c-1.04-.61-1.92-1.01-2.99-1.01C1.19,0,0,1.5,0,3.75V36.9c0,2.26,1.19,3.75,3.11,3.75Z" />
      <path className="pauseIcon" fill={color} transform='translate(15 11.5)'
        d="M3.42,39.46h5.52c2.2,0,3.39-1.19,3.39-3.42V3.39c0-2.32-1.19-3.39-3.39-3.39H3.42C1.19,0,0,1.19,0,3.39V36.04c0,2.23,1.19,3.42,3.42,3.42Zm17.85,0h5.52c2.2,0,3.39-1.19,3.39-3.42V3.39c0-2.32-1.19-3.39-3.39-3.39h-5.52c-2.23,0-3.42,1.19-3.42,3.39V36.04c0,2.23,1.19,3.42,3.42,3.42Z" />
    </svg>
  return playPause
}
