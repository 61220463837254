import joinedClassName from 'ssi-react-lib/joinedClassName'
import LearnerIcon from 'ssi-react-lib/Icons/Learner'
import './LearnerId.css'

export default
function LearnerId({className, learner})
{
  const learnerId = learner ?
    <div className={joinedClassName('learner-id', className)}>
      <LearnerIcon color='black' />
      <span className='learner-id-name'>{learner.name}</span>
    </div> : null
  return learnerId
}
