import SVGIcon from './SVGIcon'

export default function LearnerSettingsIcon({className, color, onClick})
{
  return <SVGIcon
          name='learner-settings'
          className={className}
          size='87 84.37'
          color={color}
          onClick={onClick}
          paths={[
            'M33.74,35.84c9.18,0,16.8-8.2,16.8-18.07S42.97,0,33.74,0,16.89,7.76,16.89,17.68s7.62,18.16,16.85,18.16Zm8.3,35.64c-.63-2.1-1.03-4.3-1.07-6.59H7.86c-.54,0-.78-.15-.78-.68,0-5.71,9.38-17.29,26.66-17.29,4.64,0,8.79,.88,12.3,2.29,1.42-1.95,3.03-3.61,4.88-5.08-4.83-2.34-10.6-3.81-17.19-3.81C12.74,40.33,0,55.03,0,65.09c0,4.39,3.27,6.4,10.11,6.4h31.93Zm-8.3-42.19c-5.27,0-9.77-5.22-9.77-11.62s4.35-11.08,9.77-11.08,9.77,4.79,9.72,11.18c0,6.4-4.54,11.52-9.72,11.52Z',
            'M64.75,84.37h3.32c1.22,0,2.15-.73,2.44-1.95l.93-3.81c.54-.15,1.17-.39,1.71-.68l3.32,2.05c1.03,.63,2.15,.63,3.08-.29l2.29-2.34c.83-.88,.93-2,.24-3.12l-2-3.32c.34-.54,.49-1.03,.68-1.56l3.81-.93c1.22-.29,2-1.22,2-2.44v-3.32c0-1.12-.83-2-2-2.29l-3.76-.93c-.25-.68-.49-1.17-.68-1.61l2.1-3.47c.63-1.03,.54-2.25-.29-3.03l-2.39-2.29c-.88-.88-2.05-1.07-3.03-.44l-3.37,2.15c-.63-.34-1.17-.49-1.71-.68l-.93-3.91c-.29-1.17-1.22-1.95-2.44-1.95h-3.32c-1.22,0-2.2,.83-2.44,1.95l-.88,3.86c-.68,.24-1.22,.39-1.81,.73l-3.37-2.15c-.93-.59-2.1-.44-2.98,.44l-2.29,2.29c-.83,.83-1.07,2-.44,3.03l2.15,3.47c-.2,.44-.44,1.03-.68,1.61l-3.81,.93c-1.17,.29-1.95,1.22-1.95,2.29v3.32c0,1.22,.78,2.15,1.95,2.44l3.86,.93c.24,.54,.44,1.03,.68,1.56l-2,3.37c-.63,1.07-.54,2.25,.29,3.08l2.25,2.34c.88,.93,2,.93,3.03,.29l3.42-2.05c.64,.34,1.17,.54,1.71,.68l.88,3.81c.24,1.17,1.22,1.95,2.44,1.95Zm1.66-13.57c-3.61,0-6.44-2.88-6.44-6.49s2.83-6.45,6.44-6.49c3.52-.05,6.49,2.98,6.49,6.49s-2.98,6.49-6.49,6.49Z'
            ]}
          />
}
