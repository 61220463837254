import './FormField.css';

function FormField(props) {
  function onChange(e) {
    props.onChange(e.target.value)
  }
  const cls = props.class ? `form-field ${props.class}` : 'form-field';
  const extraAttrs = props.direction ? {...props.extraAttrs, dir: props.direction} : {...props.extraAttrs};
  const field =
    <input className={cls} id={props.id} name={props.name} value={props.value} {...extraAttrs} onChange={onChange} />
  return field
}

export default FormField;
