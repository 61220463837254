import PaperAirplaneIcon from 'ssi-react-lib/Icons/PaperAirplane'

export default function ResendInviteAction({groupId, roles, api, popNFade})
{
  const onResendInvitiation = async (e) =>
  {
    e.preventDefault()
    e.stopPropagation()
    const row = e.target.closest('.list-elem')
    const emailDiv = row.querySelector('.invite-person-email')
    const email = emailDiv?.innerText
    if (email)
    {
      const result = await api.sendInvitation(groupId, roles, email)
      if (result)
      {
        popNFade('Invitiation re-sent!')
      }
    }
  }

  const icon = <PaperAirplaneIcon className='person-invite-resend' onClick={onResendInvitiation} />
  return icon
}
