import joinedClassName from './joinedClassName'
import './List.css';

export default
function List({className, header, style, addtlEltAttrs, onClick, children}) {
  const listElements = children.map(elt =>
  {
    const listElemClass = elt.className ? `list-elem ${elt.className}` : 'list-elem'
    const draggy = elt.draggable ?
    {
      'draggable': true,
      'onDragStart' : e =>
      {
        e.target.classList.add('dragging')
        const rslt = e.dataTransfer.setData('text', elt.meta) // eslint-disable-line no-unused-vars
      },
      'onDragEnd' : e =>
      {
        e.target.classList.remove('dragging')
      },
    } : null
    const element =
      <div className={listElemClass} key={elt.key} data-meta={elt.meta} {...draggy} {...addtlEltAttrs}>
        {elt.contents ? elt.contents : elt.label}
      </div>
    if (elt.detail)
    {
      const detail = elt.detail.map(dt => <div className='list-elem-detail'>{dt.name}</div>)
      const detailed =
        <div className='list-elem-detailed'>
          {element}
          <div className='list-elem-detail-box'>
            {detail}
          </div>
        </div>
      return detailed
    } else {
      return element
    }
  })

  const onRowClick = (e) => {
    const row = e.target.closest('.list-elem')
    if (!row) return;
    const meta = row.dataset.meta
    if (onClick) {
      onClick(meta, e)
    }
  }

  const hdr = header ? <div className='list-header'>{header}</div> : null;
  const list =
    <div className={joinedClassName('list', className)} style={style}>
      {hdr}
      <div className='list-body' onClick={onRowClick}>
        {listElements}
      </div>
    </div>
  return list
}
