import { useEffect, useState } from 'react'
import './Toggle.scss'

export default
function Toggle(props) {
  const [isOn, setIsOn] = useState(props.value)
  useEffect(() =>
  {
    setIsOn(props.value)
  }, [props.value])
  const color = props.color != null ? props.color : "currentColor"
  const onClick = () => {
    const newState = !isOn
    setIsOn(newState)
    props.action(newState)
  }
  const toggleClass = `toggle${isOn ? ' on' : ''}`
  const toggle =
    <div className={toggleClass} onClick={onClick}>
      <div className="toggle-rel">
        <svg className="toggle-slot" viewBox="0 0 36 20">
          <path d="M4 9 c0 8 8 8 8 8 l12 0 c8 0 8 -8 8 -8 0 -8 -8 -8 -8 -8 l-12 0 c-8 0 -8 8 -8 8" fill="none" stroke={color} strokeWidth="2"></path>
        </svg>
        <svg className="toggle-bob" viewBox="0 0 36 20">
          <path d="M6 9 c0 6 6 6 6 6 6 0 6 -6 6 -6 0 -6 -6 -6 -6 -6 -6 0 -6 6 -6 6" stroke="none" fill={color}></path>
        </svg>
      </div>
    </div>
  return toggle
}
