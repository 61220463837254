import SSiFullLogo from 'ssi-react-lib/SSiFullLogo'
import './SSiBannerLogo.scss'

export default function SSiBannerLogo({withCatchPhrase})
{
  // const color = props.color != null ? props.color : "currentColor"
  const catchPhrase = 'The natural way to learn a language'
  // #d32c2c
  let justLogo = <SSiFullLogo />
  let logoStack =
    <div className='ssi-logo-container'>
      {justLogo}
      <div className='ssi-catch-phrase'>{catchPhrase}</div>
    </div>
  return withCatchPhrase ? logoStack : justLogo
}
