import AudioPlayer from '../Core/Audio/AudioPlayer'
import './InlineVideo.css'

export default function InlineVideo({
    setReady,
    presenterState,
    coHostState,
    onPresenterDuration,
    onCoHostDuration,
    onPresenterTimeUpdate,
    onCoHostTimeUpate,
    onPresenterEnded,
    onCoHostEnded,
    onError,
    onStalled,
    onPresenterStatusChange,
    onCoHostStatusChange,playbackRate})
{
  const inliner =
    <div className='inline-video-container'>
      <div className='inline-video-box'>
        <div className='inline-video-overlay-rel'>
          <AudioPlayer classNm='inline-video'
            setReady={setReady}
            presenterState={presenterState}
            coHostState={coHostState}
            onPresenterDuration={onPresenterDuration}
            onCoHostDuration={onCoHostDuration}
            onPresenterTimeUpdate={onPresenterTimeUpdate}
            onCoHostTimeUpdate={onCoHostTimeUpate}
            onPresenterEnded={onPresenterEnded}
            onCoHostEnded={onCoHostEnded}
            onError={onError}
            onStalled={onStalled}
            onPresenterStatusChange={onPresenterStatusChange}
            onCoHostStatusChange={onCoHostStatusChange}
            playbackRate={playbackRate} />
        </div>
      </div>
    </div>
  return inliner
}
