import joinedClassName from './joinedClassName'
import './Box.css'

export default function Box({className, addtlAttrs, children})
{
  const box =
    <div className={joinedClassName('box', className)} {...addtlAttrs}>
      {children}
    </div>
  return box
}
