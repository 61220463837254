import SVGIcon from './SVGIcon'

export default function TimeForwardIcon({className, color, onClick})
{
  return <SVGIcon
          name='time-forward'
          className={className}
          size='86.82 79.31'
          color={color}
          onClick={onClick}
          paths={[
            'm71.15,63.24c1.46-1.9,1.03-4.35-.78-5.42-1.81-1.12-3.91-.59-5.22,1.22-5.76,7.67-14.99,12.55-25.44,12.6-17.77.1-31.98-14.21-31.98-31.98S21.93,7.72,39.7,7.72s31.93,14.11,31.93,31.88h7.47c0-21.53-17.87-39.36-39.4-39.36S.25,18.12.25,39.65s17.92,39.4,39.45,39.4c12.7,0,24.07-6.3,31.45-15.82Zm-5.37-31.2c-3.03,0-3.81,2.1-2.2,4.39l8.69,12.4c1.32,1.9,3.37,1.9,4.74,0l8.69-12.35c1.66-2.34.93-4.44-2.15-4.44h-17.77Z',
            'm37.36,20.42v27.59s4.25-13.09,4.25-13.09l-13.28,18.02c-1.37,1.86-1.22,4.05.49,5.22,1.86,1.27,3.96.93,5.47-1.07l9.28-12.21c.73-.98,1.03-1.86,1.03-2.78v-21.68c0-2-1.61-3.61-3.61-3.61s-3.61,1.61-3.61,3.61Z'
            ]}
          />
}
