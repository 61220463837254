import './TabButtonGroup.css'

export default function TabButtonGroup({tabs, selected, onClick})
{
  const buttons = tabs.map((tb, i) =>
  {
    const pos = i === 0 ? ' left' : (i === tabs.length - 1) ? ' right' : ' middle';
    const seld = tb.identifier === selected ? ' selected' : '';
    const button = <div className={`button inset-button tab-button${pos}${seld}`} key={i} data-idx={i}>{tb.label}</div>
    return button
  })

  const localClick = (e) =>
  {
    const tab = e.target.closest('.button')
    if (!tab) return
    const idx = tab.dataset.idx
    if (idx)
    {
      const i = parseInt(idx)
      const seld = tabs[i]
      if (seld)
      {
        if (onClick) onClick(seld)
      }
    }
  }

  const buttonGroup =
    <div className='tab-button-group' onClick={localClick}>
      {buttons}
    </div>
  return buttonGroup
}

