import { useEffect, useState } from 'react'
import Button from 'ssi-react-lib/Button'
import EmailFormField from 'ssi-react-lib/EmailFormField'
import './EmailSignup.css'

export default function EmailSignup(props)
{
  const direction = props.direction
  const icon = props.icon

  const [buttonEnabled, setButtonEnabled] = useState(false)
  const [email, setEmail] = useState('')

  function emailChange(newEmail) {
    setEmail(newEmail)
  }

  useEffect(() => {
    function emailValid(email)
    {
      return /^\w+([.+-]\w+)*@\w+([.-]\w+)*(\.\w{2,6})+$/.test(email);
    }
    setButtonEnabled(email.length > 0 && emailValid(email))
  }, [email])

  const formSubmit = (e) => {
    e.preventDefault()
    onSubmit()
  }
  const onSubmit = () =>
  {
    props.onSignup(email)
  }

  const disabled = buttonEnabled ? '' : ' disabled'
  const signup =
    <div className='email-form-box'>
      <form className='email-field-form' onSubmit={formSubmit}>
        <EmailFormField value={email} direction={direction} onChange={emailChange} />
      </form>
      <Button icon={icon} class={`inset-button inline-button${disabled}`} action={onSubmit} />
    </div>
  return signup
}
