import './Content.scss'

export default function Content(props)
{
  const content =
    <div className='content automagic'>
      {props.children}
    </div>
  return content
}

/*
export default function Content(props) {
  const contentRef = useRef();
  const [height, setHeight] = useState({});
  const updateHeight = useCallback(() => {
    if (contentRef.current) {
      const contentHeight = document.documentElement.clientHeight - contentRef.current.offsetTop;
      const chPx = contentHeight + 'px';
      if (chPx !== height.height) { // why is this necessary... ????
        setHeight({ height: contentHeight + 'px' })
      }
    }
  }, [contentRef, height])
  useLayoutEffect(() => updateHeight())
  useEffect(() => {
    const onResize = () => updateHeight()
    window.addEventListener('resize', onResize, { passive: true});
    return () => window.removeEventListener('resize', onResize)
  }, [updateHeight])
  const content =
    <div ref={contentRef} className='content automagic' style={height}>
      {props.children}
    </div>
  return content
}
*/
