import PencilIcon from 'ssi-react-lib/Icons/PencilIcon'

export default function EditInviteAction({editInvite})
{
  const onEditInvite = (e) =>
  {
    e.preventDefault()
    e.stopPropagation()
    const row = e.target.closest('.list-elem')
    const guid = row.dataset.meta
    const emailDiv = row.querySelector('.invite-person-email')
    const email = emailDiv?.innerText
    editInvite(email, guid)
  }

  const icon = <PencilIcon className='person-invite-edit' onClick={onEditInvite} />
  return icon
}
