import joinedClassName from './joinedClassName'
import './Overlay.css';

export default function Overlay(props) {
    let overlay =
        <div className={`loading-overlay ${props.display ? 'show' : ''}`}>
          <div className={joinedClassName('overlay-inner', props.className)}>
            <div className='loading-spinner'></div>
            <div className='overlay-text'>{props.msg}</div>
          </div>
        </div>;
    return overlay
}
