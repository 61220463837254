import React from 'react'
import { useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import { useDispatch, useSelector, useStore } from 'react-redux'
import { automagickEndpoint } from './Core/app/API'
import { autoMagicSelector } from './Core/app/store'
import { setUser, loginHappened } from './Core/app/slices/autoMagicSlice'

let AuthContext = React.createContext(null)

export function AuthProvider(props) {
  const dispatch = useDispatch()
  const store = useStore()

  // TODO: probably need to deprecate this...
  const getUser = () => {
    const autoMagicSettings = autoMagicSelector(store.getState())
    return autoMagicSettings.user
  }

  // TODO: is this right .. ? it doesn't propagate upstream...
  const setLanguage = (info) => {
    const autoMagicSettings = autoMagicSelector(store.getState())
    let upd = autoMagicSettings.user
    upd.language = info
    dispatch(setUser(upd))
  }

  const hasAttribute = (attr) =>
  {
    const autoMagicSettings = autoMagicSelector(store.getState())
    const user = autoMagicSettings.user
    if (!user) return false;
    const has = user.attributes.find(a => a.attribute === attr)
    return has !== undefined
  }

  const check = async (requiredAttrs, fail, success) => {
    const autoMagicSettings = autoMagicSelector(store.getState())
    if (autoMagicSettings.user) return;
    const response = await fetch(automagickEndpoint('user'), { credentials: 'include' })
    if (!response.ok) {
      if (fail) fail()
      return null
    }
    const usr = await response.json()
    for (const reqd of requiredAttrs) {
      let rslt
      if (typeof reqd === 'object' && reqd !== null) {
        // TODO: This looks like a deprecated/temporary fix thing that I forgot to remove ...
        // Needs some more investigation before just pulling the plug, though
        rslt = usr.attributes.find(ga => ga.attribute === reqd.attribute && ga.uuid === '5C13B638-F21A-4690-88CD-D624B23EE5EA')
        console.log('gas', rslt)
        console.log('WHY?')
      } else {
        rslt = usr.attributes.find(ga => ga.attribute === reqd)
      }
      if (!rslt) {
        fail()
        return null
      }
    }
    dispatch(setUser(usr))
    success(usr)
  }

  const login = async (email, passwd, success, failure) => {
    const url = automagickEndpoint('login');
    const json = { email: email, passwd: passwd };
    const response = await fetch(url, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify(json),
      headers: { 'Content-Type': 'application/json' }
    })
    if (!response.ok) {
      if (failure) {
        failure()
      } else {
        throw(response)
      }
    } else {
      const usr = await response.json()
      dispatch(setUser(usr))
      success(usr)
    }
  }

  const value = { user: getUser, setLanguage: setLanguage, hasAttribute: hasAttribute, check: check, login: login }
  const prov =
    <AuthContext.Provider value={value}>
      {props.children}
    </AuthContext.Provider>
  return prov
}

export function useAuth() {
  return React.useContext(AuthContext)
}

export function Guarded(props) {
  const dispatch = useDispatch()
  const auth = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const context = props.context ? `/${props.context}` : ''
  const requiredAttrs = props.requiredAttrs ?? []
  useEffect(() => {
    auth.check(requiredAttrs, () => {
      navigate(`/login${context}`, { state: { from: location }, replace: true})
    }, (user) => {
      dispatch(loginHappened(user))
    })
  }, [requiredAttrs]) // eslint-disable-line react-hooks/exhaustive-deps -- 'auth', 'dispatch', 'location', 'navigate'
  const autoMagicSettings = useSelector(autoMagicSelector)
  const user = autoMagicSettings.user
  if (!user) return null
  return props.children
}
