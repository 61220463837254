import { useState } from 'react'
import EmailFormField from 'ssi-react-lib/EmailFormField'
import FormField from 'ssi-react-lib/FormField'
import PasswordFormField from 'ssi-react-lib/PasswordFormField'
import './FormFields.css'

export function useFormField({className, label, direction, initialValue})
{
  const [value, setValue] = useState(initialValue ?? '')

  function onChange(newValue)
  {
    setValue(newValue)
  }

  const field =
    <div>
      <div className='field-label' dir={direction}>{label}</div>
      <FormField class={className} value={value} direction={direction} onChange={onChange} />
    </div>
  return [value, field]
}

export function useEmailFormField({className, label, direction, initialValue})
{
  const [email, setEmail] = useState(initialValue ?? '')

  function onEmailChange(newEmail)
  {
    setEmail(newEmail)
  }

  const field =
    <div>
      <div className='field-label' dir={direction}>{label}</div>
      <EmailFormField class={className} value={email} direction={direction} onChange={onEmailChange} />
    </div>
  return [email, field]
}

export function usePasswordFormField({className, label, direction})
{
  const [passwd, setPasswd] = useState('')

  function onPasswdChange(newPasswd)
  {
    setPasswd(newPasswd)
  }

  const field =
    <div>
      <div className='field-label' dir={direction}>{label}</div>
      <PasswordFormField class={className} direction={direction} onChange={onPasswdChange} />
    </div>
  return [passwd, field]
}
