import { useRef, useState, useCallback, useLayoutEffect, useEffect } from 'react'
import joinedClassName from 'ssi-react-lib/joinedClassName'
import './Text.css'

export default function Text({className, text, style})
{
  const [length, setLength] = useState(text.length);
  useEffect(() =>
  {
    setLength(text.length)
  }, [text]);
  const initialFontSize = 28;
  const boxDivRef = useRef();
  const textDivRef = useRef();
  if (textDivRef.current)
  {
    if (length < text.length)
    {
      textDivRef.current.innerHTML = text.substring(0, length) + '…'
    } else
    {
      textDivRef.current.innerHTML = text;
    }
  }
  const [fontSize, setFontSize] = useState(initialFontSize);
  const updateSize = useCallback(() =>
  {
    if (textDivRef.current)
    {
      const textDiv = textDivRef.current;
      const height = textDiv.offsetHeight;
      if (height <= boxDivRef.current.offsetHeight) return;
      const newSize = fontSize * 0.9;
      if (newSize > 20)
      {
        setFontSize(newSize)
      }
    }
  }, [textDivRef, boxDivRef, fontSize]);
  useEffect(() =>
  {
    setFontSize(initialFontSize)
  }, [text]);
  useLayoutEffect(() => updateSize());
  useEffect(() =>
  {
    const onResize = () => updateSize()
    window.addEventListener('resize', onResize, { passive: true});
    return () => window.removeEventListener('resize', onResize)
  }, [updateSize]);

  const styl = { fontSize: fontSize + 'px', ...style };

  const content =
    <div ref={boxDivRef} className={joinedClassName('text-box', className)}>
      <div ref={textDivRef} className='text' style={styl}>
      </div>
    </div>
  return content
}
