export const speed = {
    slow: "slow",
    medium: "medium",
    fast: "fast",
    ultra: "ultra"
};

function descriptor(r1Factor, r2Factor, delayFactor, delayConstant) {
    return {
        r1Factor,
        r2Factor,
        delayFactor,
        delayConstant
    };
}

export const defaultDescriptor = descriptor(0.75, 0.25, 1.75, 1.25);

export function speedDescriptor(speed, velocities) {

    if (!velocities || velocities.length === 0) return defaultDescriptor;
    const params = velocities[speed].audioParameters
    const descr = descriptor(params.r1Factor, params.r2Factor, params.delayFactor, params.delayConstant)
    return descr

}
