import { useCallback, useState } from 'react'
import Modal from '../Widgets/Modal'

export function useShowAsModal(body)
{
  const [display, setDisplay] = useState(false)

  const onDisplay = useCallback(() => {
    setDisplay(true)
  }, [])

  const onDismiss = useCallback(() =>
  {
    setDisplay(false)
  }, [])

  let dialog =
    <Modal display={display} onDismiss={onDismiss}>
      {body}
    </Modal>
  return [onDisplay, onDismiss, dialog]
}
