import { useStore } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAPI } from '../Core/app/API'
import { useAuth } from '../Auth'
import { findGroupInTree } from '../Hooks/GroupTreeInfo'
import { PersonType } from '../Core/PersonType'
import ClassList from './ClassList'

export function useClassListComponent(className, groupTree, label, emptyMessage, refreshGroups, showAlert, showConfirm)
{
  const store = useStore()
  const navigate = useNavigate()
  const api = useAPI()
  const auth = useAuth()

  const onDragAndDrop = async (uuid) =>
  {
    const error = await api.addToGroup(auth.user().uuid, [PersonType.manager], uuid, true)
    if (error)
    {
      showAlert(error.reason)
      return
    }
    refreshGroups()
  }

  // TODO: shit, now we have these redundantly in both ClassListComponent and AllClassListComponent...
  const onPlay = async (learner, uuid) =>
  {
    const error = await api.addToGroup(auth.user().uuid, [PersonType.manager], uuid, true)
    if (error)
    {
      showAlert(error.reason)
      return
    }
    // select playing-as...
    await api.switchToLearner(store, learner)
    const link = '/app'
    navigate(link)
  }

  const onTrash = async (uuid) =>
  {
    const grp = findGroupInTree(uuid, groupTree)
    // const grp = group.groups.find(g => g.uuid === uuid)
    const confirmMessage = `Are you sure you want to permanently delete “${grp.name}”?`
    const onConfirm = async (cancelled) =>
    {
      if (cancelled) return;
      const error = await api.deleteGroup(uuid)
      if (error)
      {
        showAlert(error.reason)
        return
      }
      refreshGroups()
    }
    showConfirm(confirmMessage, onConfirm)
  }

  const onRemove = async (uuid) =>
  {
    const error = await api.removeFromGroup(auth.user().uuid, [PersonType.manager], uuid)
    if (error)
    {
      showAlert(error.reason)
      return
    }
    refreshGroups()
  }

  const groupId = groupTree ? groupTree.uuid : null

  const classList = <ClassList className={className} groupTree={groupTree} groupId={groupId} label={label} emptyMessage={emptyMessage} refreshGroups={refreshGroups} onDragAndDrop={onDragAndDrop} onPlay={onPlay} onTrash={onTrash} onRemove={onRemove} />
  return classList
}
