import SVGIcon from './SVGIcon'

export default function FeedbackIcon({className, color, onClick})
{
  return <SVGIcon
          name='feedback'
          className={className}
          size='116.94 75.93'
          color={color}
          onClick={onClick}
          paths={[
            'm11.82,75.93h75.88c7.67,0,11.82-3.96,11.82-11.62v-31.49l-7.03,7.08v24.8c0,2.98-1.71,4.64-4.69,4.64H11.72c-2.98,0-4.69-1.66-4.69-4.64V31.93c0-2.98,1.71-4.69,4.69-4.69h62.84l6.59-6.54H11.82c-7.67,0-11.82,3.96-11.82,11.62v31.98c0,7.67,4.15,11.62,11.82,11.62Z',
            'm67.77,50.39l8.11-3.47L110.01,12.79l-5.81-5.71-34.08,34.13-3.71,7.81c-.34.68.54,1.71,1.37,1.37ZM112.99,9.81l2.93-3.03c1.37-1.42,1.37-3.47,0-4.83l-1.07-1.07c-1.22-1.27-3.32-1.12-4.59.2l-3.03,2.93,5.76,5.81Z',
            'm20.85,54.1c3.17,0,5.76-2.64,5.76-5.81s-2.59-5.76-5.76-5.76-5.81,2.59-5.81,5.76,2.64,5.81,5.81,5.81Z',
            'm37.7,54.1c3.17,0,5.76-2.64,5.76-5.81s-2.59-5.76-5.76-5.76-5.76,2.59-5.76,5.76,2.59,5.81,5.76,5.81Z',
            'm54.59,54.1c3.17,0,5.76-2.64,5.76-5.81s-2.59-5.76-5.76-5.76-5.81,2.59-5.81,5.76,2.64,5.81,5.81,5.81Z'
            ]}
          />
}
