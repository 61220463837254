import { useCallback } from 'react'

export default function HTML({className, body})
{
  const handle = useCallback((div) =>
  {
    if (div) div.innerHTML = body;
  }, [body])

  return <div ref={handle} className={className}></div>
}
