import { useEffect, useState } from 'react'
import { useAPI } from '../Core/app/API'

export function useGroupTreeInfo(groupId, inviteGroupId, setSpinning)
{
  const api = useAPI()

  const [path, setPath] = useState(null)
  const [groupTree, setGroupTree] = useState(null)
  const [managersDict, setManagersDict] = useState(null)
  const [invited, setInvited] = useState(null)
  const [managersDictStale, setManagersDictStale] = useState(true)

  const updateManagersDict = async () =>
  {
    if (!managersDictStale) return;
    setSpinning(true)
    const groupTreeWithAncestors = await api.groupTree(groupId)
    const groupTree = groupTreeWithAncestors.groupInfo

    setGroupTree(groupTree)

    // find root
    const root = groupTree.attributes.find(attr => attr.attribute === 'role:canolfan')?.uuid ?? groupId
    let path = []
    for (const ne of groupTreeWithAncestors.ancestors)
    {
      path.push(ne)
      if (ne.uuid === root) break;
    }
    setPath(path)

    function annotateContext(context, tree)
    {
      if (!tree) return;
      tree.context = context
      for (const grp of tree.groups)
      {
        annotateContext(context ? [...context, tree] : [], grp)
      }
    }
    annotateContext(path, groupTree)

    function collectManagerAttributes(context, group)
    {
      if (!group) return [];
      // group.context = context
      const mgrs = group.attributes.filter(attr => attr.attribute === 'manager' /* || attr.attribute === 'staff' */)
      for (const mgr of mgrs)
      {
        mgr.group = group
      }
      const subs = group.groups.map(grp => collectManagerAttributes([...context, group], grp))
      const rslt = mgrs.concat(subs.flat())
      return rslt
    }
    const mgrAttrs = collectManagerAttributes([], groupTree)
    let mgrs = {}
    // eslint-disable-next-line no-unused-vars -- uniqd is only used for side-effect
    const uniqd = mgrAttrs.reduce((dict, attr) =>
    {
      const alrdy = dict[attr.uuid]
      dict[attr.uuid] = alrdy ? [...alrdy, attr.group] : [attr.group]
      mgrs[attr.uuid] = attr
      return dict
    }, {})
    setManagersDict(mgrs)
    setManagersDictStale(false)

    const invited = inviteGroupId ? await api.getInvites(inviteGroupId) : []
    setInvited(invited)

    setSpinning(false)
  }
  useEffect(() =>
  {
    updateManagersDict()
  }, [managersDictStale]) // eslint-disable-line react-hooks/exhaustive-deps -- updateManagersDict

  const refresh = () =>
  {
    setManagersDictStale(true)
  }
  return [path, groupTree, managersDict, invited, refresh]
}

export function findGroupInTree(uuid, tree)
{
  if (!tree) return null;
  function findInTree(tree)
  {
    if (tree.uuid === uuid) return tree;
    for (const grp of tree.groups)
    {
      const rslt = findInTree(grp)
      if (rslt) return rslt;
    }
    return null
  }
  return findInTree(tree)
}

export function findGroupInForest(uuid, tree)
{
  if (!tree) return null;
  function findInTree(tree)
  {
    if (tree.uuid === uuid) return tree;
    for (const grp of tree.groups)
    {
      const rslt = findInTree(grp)
      if (rslt) return rslt;
    }
    return null
  }
  let found = null
  for (const grp of tree.groups)
  {
    const rslt = findInTree(grp)
    if (rslt)
    {
      found = rslt
      break
    }
  }
  return found
}
