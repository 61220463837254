export const notDetermined = "notDetermined";
export const eventEnum = {
    abort: "abort",
    canplay: "canplay",
    canplaythrough: "canplaythrough",
    stalled: "stalled",
    suspend: "suspend",
    waiting: "waiting",
    playing: "playing",
    pause: "pause"
};

export default function statusFor(eventType) {
    const key = `${eventType}`.toLowerCase();
    return eventEnum[key] || notDetermined;
}