import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAPI } from '../Core/app/API'
import { automagickEndpoint } from '../Core/app/API'
import { useAlertDialog } from '../Hooks/AlertDialog'
import { useSpinner } from '../Hooks/Spinner'
import EmailSignup from '../Widgets/EmailSignup'
import LandingStandout from '../Widgets/LandingStandout'
import './SignupASR.scss'

export default function SignupASR(props)
{
  const params = useParams()
  const locale = params.locale
  const api = useAPI()

  const [dictionary, setDictionary] = useState({})
  useEffect(() => {
    (async () =>
     {
      const response = await fetch(automagickEndpoint(`translations/${locale}`))
      if (!response.ok) {
        setDictionary({}) // this will at least render the keys
      } else {
        const dict = await response.json()
        setDictionary(dict)
      }
    })()
  }, [locale])

  const translate = key => {
    const translation = dictionary[key] ?? key
    // console.log('translating', key)
    // console.log('to         ', translation)
    return translation
  }

  const [startSpinning, stopSpinning, spinOverlay] = useSpinner(translate('delivering'))
  const [showAlert, alertDialog] = useAlertDialog(translate('ok'))

  const onSignup = async (email) =>
  {
    startSpinning()
    const asrGroupIDs = { ar: 'D262CA54-3619-48C2-B3A4-F89A882787D6', prs: 'E828E31C-45C6-438C-B880-E86EC10FAAE4', pus: 'D6C031FA-E408-4D25-AD90-E3D48DADFD21' }
    const groupId = asrGroupIDs[locale]
    const resp = api.sendPublicInvitation(groupId, email, locale)
    stopSpinning()
    if (resp.alreadyExists)
    {
      showAlert(translate('userExists'))
    } else {
      showAlert(translate('invitationSent'))
    }
  }

  const page =
    <>
      <LandingStandout>
        <div className='asr-signup'>
          <div className='welcome-top' dir='rtl'>
            {translate('asrSignupWelcome')}
          </div>
          <div className='welcome-body' dir='rtl'>
            {translate('asrSignupBody')}
          </div>
          <EmailSignup icon={<div>{translate('signup')}</div>} onSignup={onSignup} />
        </div>
      </LandingStandout>
      {spinOverlay}
      {alertDialog}
    </>
  return page
}
