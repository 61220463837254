import { useState } from 'react'
import CenteredThings from 'ssi-react-lib/CenteredThings'
import CloseButton from 'ssi-react-lib/CloseButton'
import Modal from '../Widgets/Modal'
import OneLineTextEntryField from '../Widgets/OneLineTextEntryField'
import './AddOneDialog.css'

export default
function AddOneDialog(title, description, placeholder, icon, onAdd)
{
  const [display, setDisplay] = useState(false)
  const [context, setContext] = useState(null)

  const onDisplay = (context) => {
    setDisplay(true)
    setContext(context)
  }

  const onSubmit = (name) =>
  {
    if (onAdd) onAdd(name, context)
    setDisplay(false)
  }

  const onDismiss = () =>
  {
    setDisplay(false)
  }

  const dialog =
    <Modal display={display} onDismiss={onDismiss}>
      <CloseButton onClick={onDismiss} />
      <CenteredThings><div className='add-one-dialog-title'>{title}</div></CenteredThings>
      <OneLineTextEntryField className='add-one-text-entry' description={description} placeholder={placeholder} icon={icon} onSubmit={onSubmit} />
    </Modal>
  return [onDisplay, dialog]
}
