import { createAction, createSlice } from '@reduxjs/toolkit'
import { logoutHappened } from '../autoMagicSlice'
import statusFor, { notDetermined } from './mediaElementStatus'

import { PlayingStatesCount } from '../../PuppetEngine/PuppetEngine'
import urlToUUID from '../../PuppetEngine/url-to-uuid'

export const role = {
    presenter: "presenterState",
    coHost: "coHostState"
};

function makePresenterState(url,
    isPlaying = false,
    timestamp = Date.now()) {

    return {
        url: url,
        isPlaying: isPlaying,
        progress: 0,
        timeStamp: timestamp,
        currentDuration: undefined,
        status: notDetermined
    };

}

export function presenterIdle(presenterState) {
    const { url, progress } = presenterState;
    // TODO: what's up with the progress < 100?
    return url === null // || (url === Assets.coHostIdleURL && progress < 100);
}

export function currentDuration(refersToURL, presenterState, duration) {
    return presenterState.url === null || urlToUUID(refersToURL) === urlToUUID(presenterState.url)
        ? duration
        : presenterState.currentDuration;
}

export const ManifestKind = {
    infoVideo: "infoVideo",
    section: "section",
    summary: "summary",
    encouragement: "encouragement",
    randomWalk: "randomWalk",
    announcement: "announcement",
    expiration: "expiration"
}

export const initialState = {
    itemQueue: [],
    playingStateIndex : 0,
    isPlaying: false,
    noSkip: true,
    text: "",
    opacity: 0,
    gapDuration: 0,
    gapProgress: 0,
    [role.presenter]: makePresenterState(null, false),
    [role.coHost]: makePresenterState(null, false)
}

function _setText(state, text) {
    if (text === '') {
        state.opacity = 0
    } else {
        state.text = text
        state.opacity = 1
    }
}
function _resetGapProgress(state) {
    state.gapDuration = 0
    state.gapProgress = 0
}
function _setPresenterIdle(state, action, isPlaying) {
    if (!presenterIdle(state[role.presenter])) {
        state[role.presenter] = makePresenterState(null, isPlaying, action.payload);
    }
}
function _setCoHostIdle(state, action, isPlaying) {
    if (!presenterIdle(state[role.coHost])) {
        state[role.coHost] = makePresenterState(null, isPlaying, action.payload);
    }
}
export const sessionSlice = createSlice({
    name: "session",
    initialState,
    reducers: {
        resetSections: (state, action) => {
            state.itemQueue = []
        },
        reloadQueue: (state, action) => {
            state.itemQueue = action.payload
        },
        loadItems: (state, action) => {
            const newSections = action.payload
            if (!newSections) return;
            const filteredSections = newSections.filter(ns => {
                // new section doesn't exist in itemQueue already...
                const q = state.itemQueue.find(s => ns.intro && s.intro?.uuid === ns.intro.uuid)
                return !q
            })
            state.itemQueue.push(...filteredSections)
            const q = state.itemQueue
            const iA = q.findIndex(i => i.kind === 'announcement')
            if (iA > 1 && q.length > 3)
            {
                [q[iA], q[1]] = [q[1], q[iA]]
            }
        },
        shiftToNextSection: (state, action) => {
            state.itemQueue.shift()
        },
        resetPlayingState: (state, action) => {
            state.playingStateIndex = 0
        },
        advancePlayingState: (state, action) => {
            state.playingStateIndex = (state.playingStateIndex + 1) % PlayingStatesCount
        },
        setIsPlaying: (state, action) => {
            const isPlaying = action.payload;
            state.isPlaying = isPlaying;
            state[role.presenter].isPlaying = isPlaying;
            state[role.coHost].isPlaying = isPlaying;
        },
        setNoSkip: (state, action) => {
            state.noSkip = action.payload
        },
        setTextToDisplay: (state, action) => {
            state.textToDisplay = action.payload;
        },
        setText: (state, action) => {
            _setText(state, action.payload)
        },
        setInsetCoHost: (state, action) => {
            state.insetCoHost = action.payload
        },
        setPresenterDuration: (state, action) => {
            const { url, duration } = action.payload;
            state[role.presenter].currentDuration = currentDuration(url, state[role.presenter], duration);
        },
        setCoHostDuration: (state, action) => {
            const { url, duration } = action.payload;
            state[role.coHost].currentDuration = currentDuration(url, state[role.coHost], duration);
        },
        setPresenterStatus: (state, action) => {
            state[role.presenter].status = statusFor(action.payload);
        },
        setCoHostStatus: (state, action) => {
            state[role.coHost].status = statusFor(action.payload);
        },
        setPresenterProgress: (state, action) => {
            state[role.presenter].progress = action.payload;
        },
        setCoHostProgress: (state, action) => {
            state[role.coHost].progress = action.payload;
        },
        setPresenterIdle: (state, action) => {
            _setPresenterIdle(state, action, true)
        },
        setCoHostIdle: (state, action) => {
            _setCoHostIdle(state, action, true)
        },
        setIdle: (state, action) => {
            _setPresenterIdle(state, action, state.isPlaying)
            _setCoHostIdle(state, action, state.isPlaying)
        },
        setPresenterURL: (state, action) => {
            const { url, timeStamp } = action.payload;
            state[role.presenter] = makePresenterState(url, state.isPlaying, timeStamp);
        },
        setCoHostURL: (state, action) => {
            const { url, timeStamp } = action.payload;
            state[role.coHost] = makePresenterState(url, state.isPlaying, timeStamp);
        },
        setGapDuration: (state, action) => {
            state.gapDuration = action.payload
        },
        resetGapProgress: (state, action) => {
            _resetGapProgress(state)
        },
        incrGapProgress: (state, action) => {
            state.gapProgress += action.payload
        },
        resetSessionState: (state, action) => {
            state.itemQueue = [] // resetSections
            state.playingStateIndex = 0 // resetPlayingState
            _setPresenterIdle(state, action, state.isPlaying)
            _setCoHostIdle(state, action, state.isPlaying)
            _setText(state, '')
            _resetGapProgress(state)
        },
        presenterDidEndURL: () => {
            // Use the slice to generate the action creator. Leaves room in the future
            // for the reducer to act on this action, but currently this is handled
            // in middleware, by the PuppetEngine.
            //
            // Default implemenation (for without PuppetEngine) is just return to
            // idle state.
            //
            // To use, add state and action arguments to this function.
            //
            // state[role.presenter] = makePresenterState(presenterIdleURL, true, action.payload);
        },
        coHostDidEndURL: () => {
            // state[role.coHost] = makePresenterState(coHostIdleURL, true, action.payload);
        },
        requestNext: () => {
            // Called by the PuppetEngineMiddleware when there is no sections remaining
            // in the queue, at the point where the last section is ready for playback
            // and the sentenceQueue will likely have sentences (unless the `manifest`
            // has no sentences for this section)
        }
    },
    extraReducers: builder =>
    {
        builder
            .addCase(logoutHappened, (state, action) =>
            {
                return initialState
            })
    }
});

function timeStamping(url) {
    return {
        payload: {
            url,
            timeStamp: Date.now()
        }
    };
}

function timeStamp() {
    return {
        payload: Date.now()
    };
}

function makeDuration(url, duration) {
    return {
        payload: {
            url,
            duration
        }
    };
}

export const setPresenterDuration = createAction("session/setPresenterDuration", (url, duration) => makeDuration(url, duration));
export const setCoHostDuration = createAction("session/setCoHostDuration", (url, duration) => makeDuration(url, duration));
export const setPresenterURL = createAction("session/setPresenterURL", url => timeStamping(url));
export const setCoHostURL = createAction("session/setCoHostURL", url => timeStamping(url));
export const setPresenterIdle = createAction("session/setPresenterIdle", () => timeStamp());
export const setCoHostIdle = createAction("session/setCoHostIdle", () => timeStamp());
export const setIdle = createAction("session/setIdle", () => timeStamp());
export const presenterDidEndURL = createAction("session/presenterDidEndURL", () => timeStamp());
export const coHostDidEndURL = createAction("session/coHostDidEndURL", () => timeStamp());

export const {
    resetSections,
    reloadQueue,
    loadItems,
    shiftToNextSection,
    resetPlayingState,
    advancePlayingState,
    setIsPlaying,
    setNoSkip,
    setTextToDisplay,
    setText,
    setInsetCoHost,
    setCoHostStatus,
    setPresenterStatus,
    setPresenterProgress,
    setCoHostProgress,
    setGapDuration,
    resetGapProgress,
    incrGapProgress,
    resetSessionState,
    requestNext
} = sessionSlice.actions;

export default sessionSlice.reducer;
