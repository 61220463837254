import ShaderProgram from "../VideoPlayer/chroma-renderer/lib/ShaderProgram";
import fragmentShaderPaintSrc from "../VideoPlayer/chroma-renderer/lib/shaders/fragmentShaderPaint";
import fragmentShaderBackgroundSrc from "../VideoPlayer/chroma-renderer/lib/shaders/fragmentShaderBackground";
import vertexShaderTransformSrc from "../VideoPlayer/chroma-renderer/lib/shaders/vertexShaderTransformable";
import VAO from "./VAO";

const crossfadeDuration = 500;

function buildShaders(gl) {

    const presenterShader = new ShaderProgram(gl, vertexShaderTransformSrc, fragmentShaderPaintSrc);
    const backgroundShader = new ShaderProgram(gl, vertexShaderTransformSrc, fragmentShaderBackgroundSrc);

    checkGLError(gl);

    return {presenterShader, backgroundShader};

}

function buildTexture(gl, media) {
    const texture = gl.createTexture();

    media.image = media;//TODO remove

    gl.bindTexture(gl.TEXTURE_2D, texture);

    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, media);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.bindTexture(gl.TEXTURE_2D, null);

    checkGLError(gl);

    return texture;

}

function checkGLError(gl) {
    const error = gl.getError();
    if (error !== 0) {
        throw new Error("GL Error: " + error);
    }
}

function bindTexture(gl, tex, pos, uniform) {
    if (tex) {
        uniform(pos);
        gl.activeTexture(gl.TEXTURE0 + pos)
        gl.bindTexture(gl.TEXTURE_2D, tex);
    }
}

function drawBackground(gl, objs) {
    objs.backgroundShader.useProgram();
    
    if (!objs.landscape) {
        bindTexture(gl, objs.backgroundTexture, 0, objs.backgroundShader.set_background);
        objs.backgroundShader.set_transformation([
            1.0, 0.0, 0.0, 0.0,
            0.0, 0.5, 0.0, 0.0,
            0.0, 0.0, 1.0, 0.0,
            0.0, 0.5, 0.0, 1.0,
        ]);
        objs.squareVAO.fullRender(gl);
        objs.backgroundShader.set_transformation([
            1.0, 0.0, 0.0, 0.0,
            0.0, 0.5, 0.0, 0.0,
            0.0, 0.0, 1.0, 0.0,
            0.0, -0.5, 0.0, 1.0,
        ]);
        objs.squareVAO.fullRender(gl);
    } else {
        objs.backgroundShader.set_transformation([
            1.0, 0.0, 0.0, 0.0,
            0.0, 1.0, 0.0, 0.0,
            0.0, 0.0, 1.0, 0.0,
            0.0, 0.0, 0.0, 1.0,
        ]);
        bindTexture(gl, objs.backgroundTexture, 0, objs.backgroundShader.set_background);
        objs.squareVAO.fullRender(gl);
    }
}

function drawPresenter(gl, shader, vao, tex, xfTex, startTime, transform) {

    shader.useProgram()

    bindTexture(gl, tex, 0, shader.set_presenter);
    bindTexture(gl, xfTex, 1, shader.set_presenterCross);
    const xfCoeff = Math.min((Date.now() - startTime) / crossfadeDuration, 1.0);
    shader.set_crossCoeff(xfCoeff);

    if (transform) {
        shader.set_transformation(transform);
    } else {
        shader.set_transformation([
            1.0, 0.0, 0.0, 0.0,
            0.0, 1.0, 0.0, 0.0,
            0.0, 0.0, 1.0, 0.0,
            0.0, 0.0, 0.0, 1.0,
        ]);
    }

    vao.fullRender(gl);

}

function drawScene(gl, objs) {

    /* do this every time */
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
    gl.enable(gl.BLEND);
    gl.disable(gl.DEPTH_TEST);

    gl.bindFramebuffer(gl.FRAMEBUFFER, null);

    drawBackground(gl, objs);

    if (!objs.landscape) {
        var scale = 0.5;
        if (objs.aspect > objs.videoAspect / 2.0) {
            const aspectCoeff = objs.videoAspect / (2.0 * objs.aspect);
            scale *= aspectCoeff;
        }
        objs.coHostTransform = [
            scale * 2.0, 0.0, 0.0, 0.0,
            0.0, scale, 0.0, 0.0,
            0.0, 0.0, 1.0, 0.0,
            0.0, -scale, 0.0, 1.0,
        ];
        objs.presenterTransform = [
            scale * 2.0, 0.0, 0.0, 0.0,
            0.0, scale, 0.0, 0.0,
            0.0, 0.0, 1.0, 0.0,
            0.0, scale, 0.0, 1.0,
        ];
    } else {
        var translation = 1.0 - 0.9 / (1.0 + 0.4 * objs.aspect);
        scale = objs.presenterScale;
        if (objs.aspect > objs.videoAspect) {
            const aspectCoeff = objs.videoAspect / objs.aspect
            scale *= aspectCoeff;
            translation *= (aspectCoeff / 2.0 + 0.5);
        }
        objs.coHostTransform = [
            scale, 0.0, 0.0, 0.0,
            0.0, scale, 0.0, 0.0,
            0.0, 0.0, 1.0, 0.0,
            translation, objs.presenterScale - 1, 0.0, 1.0,
        ];
        objs.presenterTransform = [
            scale, 0.0, 0.0, 0.0,
            0.0, scale, 0.0, 0.0,
            0.0, 0.0, 1.0, 0.0,
            -translation, objs.presenterScale - 1, 0.0, 1.0,
        ];
    }

    if (objs.coHostTexture) {
        drawPresenter(gl, objs.presenterShader, objs.squareVAO, objs.coHostTexture, objs.coHostXFTexture, objs.coHostXFStart, objs.coHostTransform);
    }
    if (objs.presenterTexture) {
        drawPresenter(gl, objs.presenterShader, objs.squareVAO, objs.presenterTexture, objs.presenterXFTexture, objs.presenterXFStart, objs.presenterTransform);
    }

    checkGLError(gl);
}

class Renderer {
    constructor(gl) {
        this._gl = gl;
        const squareVAO = VAO.makeTranslatedSquare(gl, 0.0, 0.0, 2.0, 2.0);
        const {presenterShader, backgroundShader} = buildShaders(gl);
        this.squareVAO = squareVAO;
        this.presenterShader = presenterShader;
        this.backgroundShader = backgroundShader;

        this.presenterXFStart = Date.now() - (crossfadeDuration + 1);
        this.coHostXFStart = Date.now() - (crossfadeDuration + 1);
    }

    //objs.presenterTexture
    //objs.coHostTexture
    //objs.backgroundTexture
    //objs.presenterXFTexture
    //objs.coHostXFTexture
    setBackgroundImage(image) {
        const gl = this._gl;
        if (this.backgroundTexture) {
            gl.bindTexture(gl.TEXTURE_2D, this.backgroundTexture);
            gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image);
            gl.bindTexture(gl.TEXTURE_2D, null);
        } else {
            this.backgroundTexture = buildTexture(gl, image);
        }
    }

    setPresenterImage(image) {
        const gl = this._gl;
        if (this.presenterTexture) {
            gl.bindTexture(gl.TEXTURE_2D, this.presenterTexture);
            gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image);
            gl.bindTexture(gl.TEXTURE_2D, null);
        } else {
            this.presenterTexture = buildTexture(gl, image);
        }
    }

    setCoHostImage(image) {
        const gl = this._gl;
        if (this.coHostTexture) {
            gl.bindTexture(gl.TEXTURE_2D, this.coHostTexture);
            gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image);
            gl.bindTexture(gl.TEXTURE_2D, null);
        } else {
            this.coHostTexture = buildTexture(gl, image);
        }
    }

    setPresenterXFImage(image) {
        const gl = this._gl;
        if (this.presenterXFTexture) {
            gl.bindTexture(gl.TEXTURE_2D, this.presenterXFTexture);
            gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image);
            gl.bindTexture(gl.TEXTURE_2D, null);
        } else {
            this.presenterXFTexture = buildTexture(gl, image);
        }
    }

    setCoHostXFImage(image) {
        const gl = this._gl;
        if (this.coHostXFTexture) {
            gl.bindTexture(gl.TEXTURE_2D, this.coHostXFTexture);
            gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image);
            gl.bindTexture(gl.TEXTURE_2D, null);
        } else {
            this.coHostXFTexture = buildTexture(gl, image);
        }
    }

    setPresenterScale(scale) {
        this.presenterScale = scale;
    }

    resetPresenterXFTimer() {
        this.presenterXFStart = Date.now();
    }

    resetCoHostXFTimer() {
        this.coHostXFStart = Date.now();
    }

    draw() {
        drawScene(this._gl, this);
    }

    setViewport(canvas, video) {

        if (!canvas || !video) { return; }

        const width = canvas.width;
        const height = canvas.height;

        this.aspect = canvas.width / canvas.height;

        var videoWidth = video.videoWidth;
        var videoHeight = video.videoHeight;

        if (videoWidth === 0 || videoHeight === 0) {
            videoWidth = 1920;
            videoHeight = 1080;
        }

        this.videoAspect = videoWidth / videoHeight;

        if (!this.landscape) {
            videoWidth /= 2.0;
        }

        const scale = Math.max(width / videoWidth, height / videoHeight);

        const x = (width / 2) - (videoWidth / 2) * scale;
        const y = (height / 2) - (videoHeight / 2) * scale;
        const drawWidth = videoWidth * scale;
        const drawHeight = videoHeight * scale;

        this._gl.viewport(x, y, drawWidth, drawHeight);
    }

    isPresenterMidXF() {
        return Date.now() - this.presenterXFStart < crossfadeDuration;
    }

    isCohostMidXF() {
        return Date.now() - this.coHostXFStart < crossfadeDuration;
    }

}


export default Renderer;
