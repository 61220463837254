import './HorizontalLine.css'

export default
function HorizontalLine({className})
{
  const classes = ['horizontal-line'].concat(className ? [className] : [])
  const line =
    <div className={classes.join(' ')}></div>
  return line
}
