import { initCursor, resetSection, setSection, setEntry, setMode, setPacing, setVideoHidden, setTextHidden, completeIntro, completeSentence, completeSection } from '../slices/learnerSlice'

// This process is solely responsible for propogating state changes to the server

export default
function StateMiddleware(api)
{
    return store => next => action =>
    {
        switch (action.type)
        {
        case initCursor.type:
            // in this case, we only use this at setup time, so no need to propogate state back to the server
            break
        case resetSection.type:
            // this has to be handled synchronously ...
            break
        case setSection.type:
            if ('push' in action.payload && action.payload.push) {
                api.newSection(store, action.payload.push)
            }
            break
        case setEntry.type:
            if ('push' in action.payload && action.payload.push) {
                api.updateEntry(store, action.payload.entry)
            }
            break
        case setMode.type:
            console.log('STMODE', action.payload.mode)
            if ('push' in action.payload && action.payload.push) {
                api.updateMode(store, action.payload.mode)
            }
            break
        case setPacing.type:
            if ('push' in action.payload && action.payload.push) {
                api.setPacing(store, action.payload.pacing)
            }
            break
        case setVideoHidden.type:
            if ('push' in action.payload && action.payload.push) {
                api.setVideoHidden(store, action.payload.hidden)
            }
            break
        case setTextHidden.type:
            if ('push' in action.payload && action.payload.push) {
                api.setTextHidden(store, action.payload.hidden)
            }
            break
        case completeIntro.type:
            api.completeIntro(store, action.payload)
            break
        case completeSentence.type:
            api.completeSentence(store, action.payload)
            break
        case completeSection.type:
            api.completeSection(store, action.payload)
            break
        default:
            break
        }
        let result = next(action)
        return result
    }
}
