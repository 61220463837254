import { useTranslate } from '../Translation'
import { automagickEndpoint } from '../Core/app/API'
import CenteredThings from 'ssi-react-lib/CenteredThings'
import LandingStandout from '../Widgets/LandingStandout'
import OneLineTextEntryField from '../Widgets/OneLineTextEntryField'
import SendIcon from 'ssi-react-lib/Icons/SendIcon'
import './InviteCode.css'

export default function InviteCode(props)
{
  const translate = useTranslate()

  const onSubmit = (guid) =>
  {
    const endpoint = automagickEndpoint(`signup/${guid}`)
    window.location.assign(endpoint)
  }

  const banner = translate('inviteCodeBanner')
  const description = translate('inviteCodeDescription')
  const placeholder = 'XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX'
  const icon = <SendIcon fgColor={"white"}/>
  const page =
    <LandingStandout className='invite-code-standout'>
      <CenteredThings><div className='invite-code-title'>{banner}</div></CenteredThings>
      <OneLineTextEntryField description={description} placeholder={placeholder} icon={icon} onSubmit={onSubmit} />
    </LandingStandout>
  return page
}
