import { useEffect, useState } from 'react'
import CheckBoxIcon from 'ssi-react-lib/Icons/CheckBox'
import './Checkbox.css'

export default function Checkbox(props) {
  const classes = ['checkbox'].concat(props.className ? [props.className] : [])
  const initialChecked = props.checked
  const [checked, setChecked] = useState(initialChecked == null ? false : initialChecked)
  useEffect(() =>
  {
    setChecked(initialChecked == null ? false : initialChecked)
  }, [initialChecked])
  // const checkedAttr = initialChecked ? { 'checked' : 'checked' } : { }
  const undo = () => {
    // TODO: don't completely understand the logic, so keep an eye on this...
    setChecked(checked)
  }
  const onChange = (e) => {
    const newState = !checked
    setChecked(newState)
    props.onChange(e, newState, undo)
  }
  const checkedClass = checked ? 'checked' : ''
  const box = <CheckBoxIcon checkboxClass={checkedClass} />
  const cbox =
    <div className={classes.join(' ')}>
      <div className='checkbox-box' onClick={onChange}>
        {box}
      </div>
      {props.label}
    </div>
  return cbox
}
