import SVGIcon from './SVGIcon'

export default function PencilIcon({className, color, onClick})
{
  return <SVGIcon
          name='pencil'
          className={className}
          size='76.93 77.04'
          color={color}
          onClick={onClick}
          paths={[
            'm14.87,72.64l49.41-49.51-10.55-10.55L4.32,62.09.17,73.37c-.83,2.25,1.46,4.25,3.42,3.52l11.28-4.25Zm54.59-54.64l5.47-5.47c2.59-2.59,2.69-5.71.2-8.25l-2.44-2.49c-2.49-2.54-5.71-2.29-8.3.24l-5.47,5.42,10.55,10.55Z'
            ]}
          />
}
