import { useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import joinedClassName from 'ssi-react-lib/joinedClassName'
import './DropDownIconBox.css'

export default function DropDownIconBox({className, active, onClick, dropDownLayout, children})
{
  const [fullyExpanded, setFullyExpanded] = useState(false)

  const onEntered = () =>
  {
    setFullyExpanded(true)
  }
  const onExit = () =>
  {
    setFullyExpanded(false)
  }

  const body = dropDownLayout ? dropDownLayout(fullyExpanded) : children

  const transition =
  <CSSTransition appear={true} in={active} classNames='grow-down-icon-transition' onEntered={onEntered} onExit={onExit} timeout={200} unmountOnExit>
      <div className={joinedClassName('grow-down-icon-box', className)} onClick={onClick}>
        {body}
      </div>
    </CSSTransition>
  return transition
}
