import PaperAirplaneIcon from 'ssi-react-lib/Icons/PaperAirplane'

export default function ResendAction({resend})
{
  const onResend = async (e) =>
  {
    e.preventDefault()
    e.stopPropagation()
    const row = e.target.closest('.list-elem')
    await resend(row)
  }

  const icon = <PaperAirplaneIcon onClick={onResend} />
  return icon
}
