export const multipleSlash = /\/{2,}/g;
export const multipeDot = /\.{2,}/g;
export const isUUID = /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    multipleSlash,
    multipeDot,
    isUUID
};
