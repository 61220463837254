import SVGIcon from './SVGIcon'

export default function SearchIcon({className, color, onClick})
{
  return <SVGIcon
          name='search'
          className={className}
          size='75.39 76.12'
          color={color}
          onClick={onClick}
          paths={[
            'm0,31.05c0,17.14,13.92,31.01,31.05,31.01,6.59,0,12.65-2.05,17.68-5.57l18.16,18.21c1.03.98,2.29,1.42,3.61,1.42,2.83,0,4.88-2.15,4.88-4.98,0-1.37-.54-2.59-1.37-3.52l-18.07-18.12c3.86-5.18,6.1-11.52,6.1-18.46C62.06,13.92,48.19,0,31.05,0S0,13.92,0,31.05Zm7.52,0c0-13.04,10.5-23.54,23.54-23.54s23.54,10.5,23.54,23.54-10.55,23.54-23.54,23.54S7.52,44.04,7.52,31.05Z'
            ]}
          />
}

