import './CloseButton.css'

export default
function CloseButton(props)
{
  const button =
    <div className='close-button-upper-right'>
      <div className='close-button' onClick={props.onClick}>×</div>
    </div>
  return button
}
