import { useEffect, useState } from 'react'
import { useAPI } from '../Core/app/API'
import { useTranslate } from '../Translation'

export function useGroups(startSpinning, stopSpinning)
{
  const api = useAPI()
  const translate = useTranslate()

  const [groups, setGroups] = useState([])
  const updateGroups = async () =>
  {
    if (startSpinning) startSpinning(translate('loading'));
    const grps = await api.groupsManages()
    if (stopSpinning) stopSpinning();
    if (!grps) return
    setGroups(grps)
  }
  useEffect(() =>
  {
    updateGroups()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return groups
}
