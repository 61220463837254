import { useEffect, useState } from 'react'
import { useAPI } from '../Core/app/API'
import { useAuth } from '../Auth'
import { useTranslate } from '../Translation'
import { useAlertDialog } from '../Hooks/AlertDialog'
import { usePopAndFade } from '../Widgets/PopAndFade'
import { useSpinner } from '../Hooks/Spinner'
import AdvanceProgressAction from '../Actions/AdvanceProgressAction'
import CenterNotice from 'ssi-react-lib/CenterNotice'
import CopyEmailAction from '../Actions/CopyEmailAction'
import DropsList from '../Widgets/DropsList'
import ExpireTrialAction from '../Actions/ExpireTrialAction'
import LabelledBox from 'ssi-react-lib/LabelledBox'
import NavPanel from '../Widgets/NavPanel'
import OneLineTextEntryField from '../Widgets/OneLineTextEntryField'
import ResendAction from '../Actions/ResendAction'
import SearchIcon from 'ssi-react-lib/Icons/Search'
import Tooltip from '../Widgets/Tooltip'
import TrashEntityAction from '../Actions/TrashEntityAction'
import './Admin.css'

export default function Admin(props)
{
  const api = useAPI()
  const auth = useAuth()
  const translate = useTranslate()
  const [users, setUsers] = useState(null)
  const [forgots, setForgots] = useState(null)
  const [forgotsStale, setForgotsStale] = useState(true)
  const [startSpinning, stopSpinning, spinOverlay] = useSpinner()
  const [showAlert, alertDialog] = useAlertDialog(translate('ok'))
  const [popNFade, popNFadeDialog] = usePopAndFade(2000)

  const attrs = auth.user()?.attributes ?? []
  const isPolymorth = attrs.find(attr => attr.attribute === 'polymorph')

  const onSubmit = async (x) =>
  {
    startSpinning()
    const results = await api.users(x)
    console.log('URES', results)
    const sorted = results.sort((a, b) => { return a.name.localeCompare(b.name) })
    stopSpinning()
    setUsers(sorted)
  }

  const tooltipped = (enabled, tip, action) =>
  {
    const wrap =
      <Tooltip enabled={enabled} tooltip={tip} direction='up' offset={-6}>
        {action}
      </Tooltip>
    return wrap
  }

  const updateForgot = async () =>
  {
    if (!forgotsStale) return;
    const fgts = await api.forgots()
    console.log('FORG', fgts)
    const sorted = fgts.sort((a, b) => { return a.email.localeCompare(b.email) })
    setForgots(sorted)
    setForgotsStale(false)
  }
  useEffect(() => {
    updateForgot()
  }, [forgotsStale]) // eslint-disable-line react-hooks/exhaustive-deps -- updateForgot

  const advanceProgress = async (uuid) =>
  {
    startSpinning()
    const error = await api.advanceProgress(uuid)
    stopSpinning()
    if (error && error.message)
    {
      console.log('Error called')
      showAlert(error.message)
    }
    else
    {
      console.log('popNFade called')
      popNFade('Progress advanced!')
    }
  }

  const expireTrial = async (uuid) =>
  {
    startSpinning()
    const result = await api.expireTrial(uuid)
    stopSpinning()
    if (result.error)
    {
      console.log('Error called')
      showAlert(result.error)
    }
    else
    {
      console.log('popNFade called')
      showAlert(`Trial expiry set to ${result.expiresAt}`)
    }
  }

  const userLayout = (usr) =>
  {
    const layout =
      <>
        <div className='person-name'>{usr.name}</div>
        <div className='person-email'>{usr.emails.join(',')}</div>
      </>
    return layout
  }
  const userDropLayout = (usr, enabled) =>
  {
    const getEmail = uuid => users.find(e => e.uuid === uuid)?.emails.join(', ')
    const layout =
      <>
        {tooltipped(enabled, 'copy email address', <CopyEmailAction getEmail={getEmail} popNFade={popNFade} />)}
        {isPolymorth ? tooltipped(enabled, 'advance progress to 30m', <AdvanceProgressAction advanceProgress={advanceProgress} />) : null}
        {isPolymorth ? tooltipped(enabled, 'make expiry earlier', <ExpireTrialAction expireTrial={expireTrial} />) : null}
      </>
    return layout
  }

  const onResendForgot = async (row) =>
  {
    const email = row.dataset.label
    if (email)
    {
      startSpinning()
      const result = await api.forgot(email)
      stopSpinning()
      if (result)
      {
        popNFade('Forgot email re-sent!')
      }
    }
  }

  const onTrashForgot = async (uuid) =>
  {
    startSpinning()
    const error = await api.deleteForgot(uuid)
    stopSpinning()
    if (error)
    {
      console.log('Error', error)
    } else {
      console.log('success?')
    }
    setForgotsStale(true)
  }

  const forgotLayout = fgt =>
  {
    const layout =
      <>
        <div className='forgot-email'>{fgt.email}</div>
        <div className='forgot-date'>{fgt.date}</div>
      </>
    return layout
  }
  const forgotDropLayout = (fgt, enabled) =>
  {
    const getEmail = uuid => forgots.find(e => e.uuid === uuid)?.email
    const layout =
      <>
        {tooltipped(enabled, 'copy email address', <CopyEmailAction getEmail={getEmail} popNFade={popNFade} />)}
        {tooltipped(enabled, 'resend forgot email', <ResendAction resend={onResendForgot} />)}
        {tooltipped(enabled, 'delete forgot request', <TrashEntityAction deleteEntity={onTrashForgot} />)}
      </>
    return layout
  }

  const description = null
  const placeholder = 'search…'
  const search = <OneLineTextEntryField description={description} decoration=<SearchIcon /> placeholder={placeholder} onSubmit={onSubmit} />
  const emptyMessage = translate('noresults')
  const searchContents = users !== null ?
    (users.length === 0 ? <CenterNotice><div className='search-empty'>{emptyMessage}</div></CenterNotice> :
    <DropsList items={users} getLabel={f => f.name} getKey={f => f.uuid} getMeta={f => f.uuid} itemLayout={userLayout} itemDropLayout={userDropLayout} />) :
    null

  const forotsLayout = <DropsList items={forgots} getLabel={f => f.name} getKey={f => f.uuid} getMeta={f => f.uuid} itemLayout={forgotLayout} itemDropLayout={forgotDropLayout} />
  const forgotContents = forgots !== null ? forotsLayout : null

  const admin =
    <>
      <NavPanel className='admin-content' backLabel={translate('learn')} backUrl='/app/learn'>
        <LabelledBox className='admin-users' label='Users' decoration={search}>
          {searchContents}
        </LabelledBox>
        <LabelledBox className='password-resets' label='Forgot Password Requests'>
          {forgotContents}
        </LabelledBox>
      </NavPanel>
      {spinOverlay}
      {alertDialog}
      {popNFadeDialog}
    </>
  return admin
}
