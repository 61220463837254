import './Button.css';

export default function Button(props) {
  const localAction = () =>
  {
    if (props.disabled) return;
    props.action()
  }
  const useInset = (props.label && !props.noBorder) ? ' inset-button' : ''
  const addlClass = props.class ? ` ${props.class}` : ''
  const disabledClass = props.disabled ? ' disabled' : ''
  const classNm = `button${useInset}${addlClass}${disabledClass}`
  const labelClass = props.labelClass ? { className: props.labelClass } : null
  const body = props.label ? <div {...labelClass}>{props.label}</div> : props.icon
  const button =
    <div id={props.name} className={classNm} onClick={localAction} >
      {body}
    </div>
  return button
}
