export default function SSiLogo(props)
{
  const color = props.color != null ? props.color : 'currentColor'
  const logo =
    <svg className='ssi-logo' viewBox='0 0 158.62 79.71' onClick={props.onClick}>
      <path fill='none' stroke={color} strokeWidth='2'
        d='M157.62,39.6V13c0-6.63-5.37-12-12-12h-36.2c-6.63,0-12,5.37-12,12v26.6c0,6.63,5.37,12,12,12h4.3l-7.25,17.49,25.93-17.49h13.22c6.63,0,12-5.37,12-12h0Z'/>
      <path fill={color}
        d='M79.78,36.41c-.74,.02-1.46-.25-2-.76-.58-.55-.88-1.33-.83-2.12-.05-.82,.23-1.63,.79-2.23,.51-.52,1.21-.81,1.94-.8,.71,0,1.39,.27,1.91,.76,.59,.6,.89,1.43,.83,2.27,.06,.8-.23,1.59-.79,2.16-.5,.47-1.16,.72-1.85,.72Zm-1.95,42.55V45.12h3.75v33.84h-3.75Z'/>
      <path fill={color}
        d='M54.22,32.95c2.14,0,4.29,.18,6.4,.54,2.12,.38,4.18,1.06,6.12,2l-1.58,5.41c-1.79-.86-3.66-1.55-5.58-2.05-1.66-.41-3.37-.61-5.08-.61-2.3-.13-4.58,.51-6.48,1.8-1.56,1.18-2.44,3.05-2.37,5-.07,1.65,.57,3.25,1.76,4.39,1.7,1.38,3.68,2.36,5.8,2.88l4.53,1.44c4.13,1.3,7.04,3.06,8.75,5.29,4.15,5.23,3.28,12.84-1.94,17-1.49,1.16-3.19,2.04-5,2.59-2.05,.62-4.19,.92-6.33,.9-2.35,0-4.69-.21-7-.61-2.45-.44-4.84-1.2-7.09-2.27l1.58-5.4c1.91,.96,3.93,1.7,6,2.23,2.03,.49,4.11,.73,6.19,.72,2.34,.07,4.64-.56,6.62-1.8,1.8-1.16,2.84-3.19,2.74-5.33,0-.82-.13-1.64-.4-2.41-.3-.79-.76-1.52-1.36-2.12-.77-.76-1.66-1.4-2.63-1.88-1.35-.66-2.74-1.22-4.18-1.65l-4.53-1.44c-2.86-.8-5.48-2.28-7.64-4.32-1.84-2.02-2.8-4.69-2.66-7.42-.17-3.58,1.27-7.05,3.92-9.47,2.63-2.27,6.44-3.4,11.44-3.41Z'/>
      <path fill={color}
        d='M16.78,32.81c2.62-.03,5.23,.26,7.77,.86,2.08,.52,4.09,1.27,6,2.23l-2.23,7.35c-1.83-.9-3.73-1.64-5.69-2.2-1.73-.49-3.53-.74-5.33-.75-1.88-.11-3.76,.36-5.36,1.36-1.22,.82-1.94,2.21-1.91,3.68-.06,1.39,.57,2.73,1.69,3.56,1.64,1.06,3.44,1.86,5.32,2.37l4.32,1.37c3.21,.96,6.12,2.72,8.46,5.11,2.04,2.21,3.14,5.13,3.06,8.14,.01,1.85-.35,3.69-1.08,5.4-.73,1.69-1.84,3.19-3.24,4.39-1.59,1.33-3.42,2.33-5.4,2.95-2.45,.76-5,1.13-7.56,1.08-5.16,.06-10.26-1.07-14.9-3.31l2.23-7.34c1.93,.95,3.94,1.73,6,2.34,2.02,.55,4.1,.83,6.19,.82,2.02,.14,4.03-.35,5.76-1.4,1.75-1.29,2.41-3.6,1.62-5.62-.26-.62-.67-1.16-1.19-1.58-.71-.6-1.5-1.11-2.34-1.51-1.24-.61-2.52-1.1-3.85-1.48l-3.81-1.29c-1.59-.53-3.13-1.2-4.61-2-1.31-.71-2.51-1.61-3.57-2.66-.99-1.03-1.77-2.24-2.3-3.57C.25,49.58-.03,47.96,0,46.32c-.02-1.87,.35-3.71,1.08-5.43,.72-1.66,1.82-3.13,3.21-4.29,1.55-1.27,3.33-2.22,5.25-2.8,2.35-.71,4.79-1.05,7.24-.99Z'/>
    </svg>
  return logo
}
