import SVGIcon from './SVGIcon'

export default function PlayIcon({className, color, onClick}) {
  return <SVGIcon
          name='play'
          className={className}
          size='58.45 65.04'
          color={color}
          onClick={onClick}
          paths={['M4.98,65.04c1.71,0,3.12-.63,4.79-1.61l43.95-25.29c3.56-2.1,4.74-3.42,4.74-5.62s-1.17-3.52-4.74-5.62L9.77,1.61c-1.66-.98-3.08-1.61-4.79-1.61C1.9,0,0,2.39,0,6.01V59.03c0,3.61,1.9,6.01,4.98,6.01Z']}
        />
}
