import { useSelector } from 'react-redux'
import { learnerSelector } from '../Core/app/store'
import Text from './Text'

export default function TextArea(props)
{
  const settings = useSelector(learnerSelector)

  const text = props.text.replace(/<\/(src|tgt)>/g, "</span>").replace(/<(src|tgt)>/g, "<span class='$1'>")

  const textArea =
    <Text className={props.className} display={!settings.textHidden} text={text} style={props.style} />
  return textArea
}
