import { useLocation, Routes, Route } from 'react-router-dom'
import AdminClass from '../Admin/Class'
import AdminTeacher from '../Admin/Teacher'
import GroupManager from '../Admin/GroupManager'
import GroupReport from '../Admin/GroupReport'
import GroupSwitch from '../Widgets/GroupSwitch'
import HeadOfWelsh from '../Admin/HeadOfWelsh'
import WBLAdmin from '../Admin/WBL'

export default function GroupsRoute()
{
  const location = useLocation()

  if (location.pathname === '/app/groups')
  {
    return <GroupSwitch />
  }

  const swtch =
    <Routes location={location}>
      <Route path='group/:groupId' element={<GroupManager />} />
      <Route path='group/:groupId/report' element={<GroupReport />} />
      <Route path='headOfWelsh/:groupId' element={<HeadOfWelsh />} />
      <Route path='teacher/:groupId' element={<AdminTeacher />} />
      <Route path='class/:groupId/:classId' element={<AdminClass />} />
      <Route path='wbl/:groupId' element={<WBLAdmin />} />
      <Route path='wbl/:groupId/report' element={<GroupReport />} />
      <Route path='wbl/:groupId/wait' element={<WBLAdmin waitCount={1} />} />} />
    </Routes>
  return swtch
}
