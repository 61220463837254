import { useSelector } from 'react-redux'
import { useTranslate, useTranslations } from '../Translation'
import { learnerSelector } from '../Core/app/store'
import ClockIcon from 'ssi-react-lib/Icons/Clock'
import './ProgressWidget.css'

export default
function ProgressWidget(props) {
  const translate = useTranslate()
  const locale = useTranslations().locale
  const settings = useSelector(learnerSelector)

  const tt = settings.totalTime ?? 0
  const hours = Math.trunc(tt / 3600)
  const minutes = Math.trunc(tt / 60) - hours * 60
  const phrase = hours > 0 ? translate('timeLong') : (hours === 0 && minutes < 1 ? translate('timeZero') : translate('timeShort'));
  const hoursLocal = hours.toLocaleString(locale)
  const minutsLocal = minutes.toLocaleString(locale)
  const timeString = phrase.replace(/%HOURS%/g, hoursLocal).replace(/%MINUTES%/g, minutsLocal)

  const widget =
    <div className='progress-widget-container'>
      <div className='progress-widget'>
        <ClockIcon color='black'/><span className='progress-time'>{timeString}</span>
      </div>
    </div>
  return widget
}
