import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslate } from '../Translation'
import { useAlertDialog } from '../Hooks/AlertDialog'
import { useAllClassListComponent} from './AllClassListComponent'
import { useClassListComponent } from './ClassListComponent'
import { useConfirmDialog } from '../Hooks/ConfirmDialog'
import { useGroupTreeInfo } from '../Hooks/GroupTreeInfo'
import { PersonType } from '../Core/PersonType'
import AddDialogComponent from './AddDialogComponent'
import AdminContainer from './AdminContainer'
import ManagerOverview from './ManagerOverview'
import NavPanel from '../Widgets/NavPanel'
import Overlay from 'ssi-react-lib/Overlay'
import PersonList from './PersonList'
import ReportIcon from 'ssi-react-lib/Icons/Report'
import './HeadOfWelsh.scss'

export default function HeadOfWelsh(props)
{
  const navigate = useNavigate()
  const params = useParams()
  const translate = useTranslate()
  const [spinning, setSpinning] = useState(false)
  const [showAlert, alertDialog] = useAlertDialog(translate('ok'))
  const [showConfirm, confirmDialog] = useConfirmDialog(translate('yes'), translate('cancel'))

  const groupId = params.groupId

  // eslint-disable-next-line no-unused-vars -- managersDict
  const [path, groupTree, managersDict, invited, refreshGroups] = useGroupTreeInfo(groupId, groupId, setSpinning)

  const [allClassList, addClassDialog] = useAllClassListComponent('all-class-column', groupTree, translate('classes'), refreshGroups, showAlert, showConfirm)

  const emailTitle = 'Paste your teachers’ email addresses here and we’ll send them an invitation for you:'
  const linkTitle = translate('copyLinkDescription').replace(/%ROLE%/g, 'a teacher of your school')
  const roles = [PersonType.manager, PersonType.schoolsTeacher]
  const [showAddTeacher, addTeacherDialog] = AddDialogComponent(groupId, translate('addTeachers'), roles, emailTitle, linkTitle, translate('thisGroup'), translate('teachers'), translate('send'), refreshGroups, refreshGroups, translate, showAlert)
  const addTeachers = () =>
  {
    showAddTeacher()
  }

  const myClassList = useClassListComponent('head-of-welsh-classes', groupTree, translate('myClasses'), translate('myClassesEmptyForTeachers'), refreshGroups, showAlert, showConfirm)

  const nameWithContext = groupTree ? [...path.slice().reverse(), groupTree].map(grp => grp.name).join(' ⟩ ') : null
  const managers = groupTree ? groupTree.managers : []
  const manager_invites = invited ? invited.filter(inv => inv.roles.some(rl => rl === PersonType.manager)) : []
  const getManagerEmail = uuid => groupTree.managers.find(e => e.uuid === uuid)?.email
  const onReport = () =>
  {
    navigate(`../group/${groupId}/report`)
  }
  const reportButton = <ReportIcon className={'head-of-welsh-stats'} onClick={onReport} />
  const page =
    <>
      <NavPanel className='head-of-welsh-content' backLabel={translate('back')} backUrl={-1} extraButtons={reportButton}>
        <AdminContainer className='head-of-welsh-container'>
          {allClassList}
          <ManagerOverview className='head-of-welsh-overview' banner={nameWithContext}>
            <div>
              {translate('headOfWelshOverviewBody')}
            </div>
          </ManagerOverview>
          {myClassList}
          <PersonList className='managers-column'
                      label={translate('teachers')}
                      roles={roles}
                      people={managers}
                      invited={manager_invites}
                      getEmail={getManagerEmail}
                      addPeople={addTeachers}
                      group={groupTree}
                      relnDescription='as a teacher of this school'
                      refreshGroup={refreshGroups}
                      refreshInvites={refreshGroups}
                      showAlert={showAlert}
                      showConfirm={showConfirm}
                      emptyMessage={translate('teachersEmpty')} />
        </AdminContainer>
      </NavPanel>
      <Overlay display={spinning} msg={translate('loading')} />
      {addClassDialog}
      {addTeacherDialog}
      {alertDialog}
      {confirmDialog}
    </>
  return page
}

