import { automagickEndpoint } from '../Core/app/API'
import CopyIcon from 'ssi-react-lib/Icons/Copy'

export default function CopyInviteAction({endpoint, popNFade})
{
  const onCopyInvitation = (e) =>
  {
    e.preventDefault()
    e.stopPropagation()
    const row = e.target.closest('.list-elem')
    const guid = row.dataset.meta
    const link = endpoint ? endpoint(guid) : automagickEndpoint(`signup/${guid}`)
    navigator.clipboard.writeText(link)
    popNFade('Invitation link copied to clipboard!')
  }

  const icon = <CopyIcon className='person-invite-copy' onClick={onCopyInvitation}/>
  return icon
}
