import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CenterNotice from 'ssi-react-lib/CenterNotice'
import DropDownIconBox from '../Widgets/DropDownIconBox'
import InfoIcon from 'ssi-react-lib/Icons/Info'
import LabelledBoxWithPlus from 'ssi-react-lib/LabelledBoxWithPlus'
import LabelWithNumber from './LabelWithNumber'
import List from 'ssi-react-lib/List'
import PlayIcon from 'ssi-react-lib/Icons/Play'
import TrashIcon from 'ssi-react-lib/Icons/Trash'
import './AllClassList.css'

export default function AllClassList({className, groupTree, groupId, label, emptyMessage, onAddClass, onPlay, onTrash})
{
  const navigate = useNavigate()
  const [selected, setSelected] = useState(null)

  const onInfo = (e) =>
  {
    e.stopPropagation()
    const row = e.target.closest('.list-elem')
    if (!row) return;
    const uuid = row.dataset.meta
    const link = `/app/groups/class/${groupId}/${uuid}`
    navigate(link)
  }

  const localPlay = (learner) => async (e) =>
  {
    e.stopPropagation()
    const row = e.target.closest('.list-elem')
    if (!row) return;
    const uuid = row.dataset.meta
    if (onPlay) onPlay(learner, uuid);
  }

  const localTrash = async (e) =>
  {
    e.stopPropagation()
    const row = e.target.closest('.list-elem')
    if (!row) return;
    const uuid = row.dataset.meta
    if (onTrash) onTrash(uuid);
  }

  const onClassClick = (uuid) =>
  {
    if (uuid === selected)
    {
      setSelected(null)
    } else {
      setSelected(uuid)
    }
  }

  const levels = groupTree ? groupTree.groups.slice().sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true })) : []
  const topItems = levels.map(level =>
  {
    const classes = level.groups.slice().sort((a, b) => a.name.localeCompare(b.name))
    const classItems = classes.map(cls =>
    {
      const isSelected = selected && cls.uuid === selected
      const detail =
        <DropDownIconBox active={isSelected}>
          <InfoIcon class='class-info' onClick={onInfo}/>
          {cls.learners.length > 0 ? <PlayIcon class='class-play' onClick={localPlay(cls.learners[0])}/> : null}
          <TrashIcon class='class-trash' onClick={localTrash}/>
        </DropDownIconBox>
      const contents =
        <div className='all-class-box'>
          <LabelWithNumber label={cls.name} number={cls.users.length} singular='student' plural='students' />
          {detail}
        </div>
      return { label: cls.name, contents: contents, key: cls.uuid, meta: cls.uuid, className: isSelected ? 'acl-selected' : null, draggable: true }
    })
    const contents =
      <>
        <div className='year-box'>
          <LabelWithNumber label={level.name} number={classItems.length} singular='class' plural='classes' />
        </div>
        <List className='year-level' onClick={onClassClick}>
          {classItems}
        </List>
      </>
    return { label: level.name, contents: contents, key: level.uuid, meta: level.uuid /*, children: classItems */ }
  })

  const classList =
    <List className='all-classes-list'>
      {topItems}
    </List>

  const emptyList = emptyMessage ?
    <CenterNotice>
      {emptyMessage}
    </CenterNotice> : null
  const finalList = classList ?? emptyList

  const list =
    <LabelledBoxWithPlus className={className} label={label} onAdd={onAddClass}>
      {finalList}
    </LabelledBoxWithPlus>
  return list
}
