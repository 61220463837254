import CopyIcon from 'ssi-react-lib/Icons/Copy'

export default function CopyUUIDAction({popNFade})
{
  const onCopyInvitation = (e) =>
  {
    e.preventDefault()
    e.stopPropagation()
    const row = e.target.closest('.list-elem')
    const uuid = row.dataset.meta
    navigator.clipboard.writeText(uuid)
    popNFade('Group id copied to clipboard!')
  }

  const icon = <CopyIcon onClick={onCopyInvitation}/>
  return icon
}
