import SVGIcon from './SVGIcon'

export default function CopyIcon({className, color, onClick}) {
  return <SVGIcon
          name='copy'
          className={className}
          size='81.84 81.64'
          color={color}
          onClick={onClick}
          paths={[
                  'M12.45,63.28h9.81v-7.03H12.55c-3.52,0-5.52-1.9-5.52-5.62V12.65c0-3.71,2-5.62,5.52-5.62H50.73c3.52,0,5.52,1.9,5.52,5.62v8.98h7.03V12.26c0-8.2-4.15-12.26-12.45-12.26H12.45C4.1,0,0,4.05,0,12.26V51.03c0,8.2,4.1,12.26,12.45,12.26Z',
                  'M31.01,81.64h38.38c8.3,0,12.45-4.05,12.45-12.26V30.62c0-8.2-4.15-12.26-12.45-12.26H31.01c-8.35,0-12.45,4.05-12.45,12.26v38.77c0,8.2,4.1,12.26,12.45,12.26Zm.1-7.03c-3.52,0-5.52-1.9-5.52-5.62V31.01c0-3.71,2-5.62,5.52-5.62h38.18c3.52,0,5.52,1.9,5.52,5.62v37.99c0,3.71-2,5.62-5.52,5.62H31.1Z'
                  ]}
        />
}
