import { useEffect, useRef } from "react";

const useAnimationFrame = callback => {

    const requestRef = useRef(null);
    const previousTimeRef = useRef(null);

    const animate = (time) => {

        if (previousTimeRef.current !== undefined) {
            const deltaTime = time - previousTimeRef.current;
            callback(deltaTime);
        }

        previousTimeRef.current = time;
        requestRef.current = requestAnimationFrame(animate);

    };

    useEffect(() => {
        requestRef.current = requestAnimationFrame(animate);
        return () => cancelAnimationFrame(requestRef.current);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

};

export default useAnimationFrame;
