import { useNavigate } from 'react-router-dom'
import Button from 'ssi-react-lib/Button'
import LandingStandout from '../Widgets/LandingStandout'
import './LandingPageASR.css'

export default function LandingPageASR(props) {
  const navigate = useNavigate()

  const onArabic = () =>
  {
    navigate('ar')
  }
  const onDari = () =>
  {
    navigate('prs')
  }
  const onPashto = () =>
  {
    navigate('pus')
  }

  const page =
    <>
      <LandingStandout>
        <div className='welcome-div'>
          <div className='welcome-text'>
            <div className='welcome-container'>
              <div className='welcome-top-banner'>Welcome to SaySomethinginWelsh!</div>
              <div className='welcome-top-body'>
                On this page you can get access to the online audio course developed in partnership with National Centre for Learning Welsh so that Arabic, Dari, and Pashto speakers can learn Welsh directly in their own language.<br /><br />
                Please select one of the options below to start becoming a Welsh speaker.<br /><br />
                If you are an English speaker, please visit <a href='https://saysomethingin.com'>saysomethingin.com</a>
              </div>
            </div>
          </div>
          <div className='ispeak-body'>
            <Button class='color-botton submit-button' labelClass='ispeak' label={<span dir='rtl'>أنا أتحدث العربية</span>} action={onArabic} />
            <Button class='color-botton submit-button' labelClass='ispeak' label={<span dir='rtl'>من به زبان دری صحبت میکنم.</span>} action={onDari} />
            <Button class='color-botton submit-button' labelClass='ispeak' label={<span dir='rtl'>زه په پښتو ژبه خبرې کوم</span>} action={onPashto} />
          </div>
        </div>
      </LandingStandout>
    </>
  return page
}
