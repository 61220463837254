import joinedClassName from './joinedClassName'
import Button from './Button'
import LabelledBox from './LabelledBox'
import PlusIcon from './Icons/PlusIcon'
import './LabelledBoxWithPlus.css'

export default
function LabelledBoxWithPlus({className, label, onAdd, children})
{
  const addButton = <Button class='box-add-button' icon=<PlusIcon/> action={onAdd} />
  const box =
    <LabelledBox className={joinedClassName('labelled-box-with-plus', className)} label={label} decoration={addButton}>
      {children}
    </LabelledBox>
  return box
}
