import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslate } from '../Translation'
import { useAuth } from '../Auth'
import { loginHappened } from '../Core/app/slices/autoMagicSlice'
import Button from 'ssi-react-lib/Button'
import CenteredThings from 'ssi-react-lib/CenteredThings'
import LandingStandout from '../Widgets/LandingStandout'
import { Login } from './Login'
import './LandingPage.scss'

export default function LandingPage(props) {
  const dispatch = useDispatch()
  const translate = useTranslate()
  const auth = useAuth()
  const navigate = useNavigate()
  useEffect(() => {
    (async () => {
      await auth.check([], () => {
        // not logged in .. re-route to login
        // navigate('/login', { replace: true })
      }, (user) => {
        // logged in .. re-route to learn
        dispatch(loginHappened(user))
        navigate('/app', { replace: true })
      })
    })()
  })

  const onHaveInvite = () =>
  {
    navigate('/invite-code')
  }

  const haveInviteButton =
    <CenteredThings>
      <Button label={translate('haveInviteCode')} class='forgot login' noBorder action={onHaveInvite} />
    </CenteredThings>
  const page =
    <LandingStandout className='landing-standout'>
      <div className='welcome-div'>
        <div className='welcome-box'>
          <div className='welcome-text'>
            <div className='welcome-top'>Welcome to AutoMagic!</div>
            <div className='welcome-body'>
              If you’re already an AutoMagic learner, just sign-in below.  If you are an existing SaySomethingin learner, sign-in with your SaySomethingin details to get started.
            </div>
          </div>
        </div>
        <Login extraItems={haveInviteButton} />
      </div>
    </LandingStandout>
  return page
}
