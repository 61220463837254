import { useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useAPI } from '../Core/app/API'
import { autoMagicSelector, learnerSelector } from '../Core/app/store'
import { setCourses } from '../Core/app/slices/autoMagicSlice'
import { setIsPlaying } from '../Core/app/slices/session/sessionSlice'
import Dropdown from './Dropdown'
import './CourseChooser.css'

export default
function CourseChooser(props)
{
  const dispatch = useDispatch()
  const store = useStore()
  const api = useAPI()
  const autoMagicSettings = useSelector(autoMagicSelector)

  // update the courses .. once
  useEffect(() =>
  {
    (async () =>
    {
      if (props.courses) return;
      const cinfo = await api.courses()
      dispatch(setCourses(cinfo))
    })()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const coursesInfo = props.courses ?? autoMagicSettings.courses
  const courses = coursesInfo ?? []

  const learningSettings = useSelector(learnerSelector)
  let selected = ''
  let course_id
  if (!autoMagicSettings.settingUp && courses && courses.length > 0)
  {
    let courseInfo = courses.find(course => course.uuid === learningSettings.course) ?? courses[0]
    if (courseInfo)
    {
      selected = courseInfo.name
      course_id = courseInfo.uuid
    }
  } else
  {
    const mesg = autoMagicSettings.settingUp ? 'Loading…' : 'No courses are available'
    selected = <span className='none-available'>{mesg}</span>
  }
  const onInitialSelection = props.onInitialSelection
  useEffect(() =>
  {
    if (onInitialSelection && typeof(selected) === 'string') onInitialSelection({ course_id: course_id });
    // We only want this updated if the initial selection (i.e. the course currently selected in user prefs) changes
    // so ignore the siren song about other dependencies ...
    // eslint-disable-next-line react-hooks/exhaustive-deps -- course_id and onInitialSelection
  }, [selected])

  const menuItems = courses.map(info =>
  {
    return { label: info.name, key: info.uuid, meta: { course_id: info.uuid } }
  })

  const defaultOnCourseSelected = async (meta) =>
  {
    dispatch(setIsPlaying(false))
    if (meta.course_id === learningSettings.course) { console.log('short-cut no change!'); return }
    const learner = autoMagicSettings.learner
    const error = await api.changeToCourse(store, learner.uuid, meta.course_id)
    if (error && props.onError)
    {
      props.onError(error)
    }
  }
  const onCourseSelected = props.onCourseSelected ?? defaultOnCourseSelected

  const chooser =
    <Dropdown menuItems={menuItems} selected={selected} onSelect={onCourseSelected} />
  return chooser
}
