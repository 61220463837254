import { useEffect, useState } from 'react'
import { Assets } from '../Assets'
import { useMedia } from '../Loader'

export default function AudioPlayer({
    classNm,
    setReady,
    presenterState,
    coHostState,
    onPresenterDuration,
    onCoHostDuration,
    onPresenterEnded,
    onCoHostEnded,
    onError,
    onStalled,
    onPresenterStatusChange,
    onCoHostStatusChange,
    playbackRate
  })
{
  const loader = useMedia()

  const [element, setElement] = useState(null)
  // This useEffect is for mount of the audio/video element
  // This is why we don't want, e.g., playbackRate listed in the dependencies
  useEffect(() =>
  {
    if (!element) return;
    element.addEventListener('error', (e) => {
        const err = element.error
        console.log('audio error', err)
        onError(err)
    }, true)
    // console.log('defaultPlaybackRate', playbackRate)
    if (playbackRate) element.defaultPlaybackRate = playbackRate;
    setReady(true)
  }, [element]) // eslint-disable-line react-hooks/exhaustive-deps -- onError, playbackRate, and setReady

  // This useEffect is for when playbackRate changes, so we don't want element listed in the dependencies
  useEffect(() =>
  {
    if (playbackRate && element) {
      // console.log('playbackRate', playbackRate)
      element.defaultPlaybackRate = playbackRate
      element.playbackRate = playbackRate
    }
  }, [playbackRate]) // eslint-disable-line react-hooks/exhaustive-deps -- element

  const playAudio = () => {
      if (!element) return;
      element.play().catch((error) => {
          console.error('error', error.code, error, element.src);
          if (error.code === 0) {
              // on iOS at least, this seems to indicate a permission failure... pause so the user can try again
              onStalled()
          }
        /*
          if (error.code != 20) {
              onInterupted(error)
          } */
      });
  }

  // this useEffect is for change of play state, hence we don't want it triggered on mount of element
  useEffect(() =>
  {
    if (presenterState.isPlaying || coHostState.isPlaying) {
      playAudio()
    } else {
      element?.pause()
    }
  }, [presenterState.isPlaying, presenterState.timestamp, coHostState.isPlaying, coHostState.timestamp]) // eslint-disable-line react-hooks/exhaustive-deps -- element and playAudio

  const presenterActive = presenterState.url !== null

  const src = presenterState.url ?? coHostState.url ?? Assets.silenceURL
  const [srcUrl, setSrcUrl] = useState(null)
  useEffect(() =>
  {
    setReady(false);
    (async () =>
    {
      const url = await loader.load(src)
      if (!url)
      {
          onStalled()
          return
      }
      setSrcUrl(url)
      setReady(true)
    })()
  }, [src]) // eslint-disable-line react-hooks/exhaustive-deps -- loader, onStalled, and setReady

  // This useEffect is for change of URL, so we don't want it triggered based on change of play state
  useEffect(() =>
  {
    if (presenterState.isPlaying || coHostState.isPlaying) {
      playAudio()
    }
  }, [srcUrl]) // eslint-disable-line react-hooks/exhaustive-deps -- coHostState.isPlaying', 'playAudio', and 'presenterState.isPlaying

  const onEnded = () =>
  {
    loader.prune() // it's safe to prune here because we've just used up the thing we had "handed out" earlier
    if (presenterActive) {
      onPresenterEnded()
    } else {
      onCoHostEnded()
    }
  }

  const onDurationChange = (e) =>
  {
    const { src, duration } = e.target
    if (presenterActive) {
      onPresenterDuration(src, duration)
    } else {
      onCoHostDuration(src, duration)
    }
  }

  const onStatusChange = (e) =>
  {
    if (presenterActive) {
      onPresenterStatusChange(e.type)
    } else {
      onCoHostStatusChange(e.type)
    }
  }

  const player =
    <video
      ref={setElement}
      className={classNm}
      crossOrigin='use-credentials'
      src={srcUrl}
      playsInline
      onEnded={onEnded}
      onDurationChange={onDurationChange}
      onStalled={onStatusChange}
      onCanPlay={onStatusChange}
      onCanPlayThrough={onStatusChange}
      onSuspend={onStatusChange}
      onWaiting={onStatusChange}
      onPlaying={onStatusChange}
      onPlay={onStatusChange}
      onPause={onStatusChange} />
  return player
}
