export default function AddIcon(props) {
  const color = props.color != null ? props.color : "currentColor"
  let icon =
    <svg className="icon add-icon" viewBox="0 0 71.14 71.19">
      <path fill={color}
        d="M12.45,71.19H58.69c8.35,0,12.45-4.1,12.45-12.3V12.3c0-8.2-4.1-12.3-12.45-12.3H12.45C4.15,0,0,4.1,0,12.3V58.89c0,8.2,4.15,12.3,12.45,12.3Z"/>
      <path fill={props.fgColor}
        d="M16.46,35.6c0-2.29,1.61-3.86,3.91-3.86h11.43v-11.43c0-2.29,1.51-3.86,3.76-3.86s3.81,1.56,3.81,3.86v11.43h11.47c2.25,0,3.86,1.56,3.86,3.86s-1.61,3.76-3.86,3.76h-11.47v11.43c0,2.29-1.51,3.86-3.81,3.86s-3.76-1.61-3.76-3.86v-11.43h-11.43c-2.3,0-3.91-1.51-3.91-3.76Z"/>
    </svg>
  return icon
}
