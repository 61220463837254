export default function SSiFullLogo(props) {
  const color = props.color != null ? props.color : 'currentColor'
  let logo =
    <svg className='ssi-logo' viewBox='0 0 286 64.665'>
      <path fill={color}
        d='M1.2,51.138a15.015,15.015,0,0,0,6.525,1.621c2.7,0,4.131-1.12,4.131-2.819,0-1.621-1.236-2.547-4.362-3.667-4.33-1.5-7.149-3.9-7.149-7.683,0-4.44,3.706-7.837,9.846-7.837a15.936,15.936,0,0,1,6.639,1.312L15.52,36.814a12.386,12.386,0,0,0-5.443-1.235c-2.549,0-3.783,1.159-3.783,2.509,0,1.66,1.47,2.394,4.826,3.667,4.595,1.7,6.756,4.094,6.756,7.761,0,4.362-3.358,8.108-10.5,8.108A16.973,16.973,0,0,1,0,56Z'
        transform='translate(0 -1.066)' />
      <path fill={color}
        d='M37.818,52.86a29.234,29.234,0,0,0,.267,4.595H32.8l-.347-1.891h-.116a6.8,6.8,0,0,1-5.4,2.315,5.787,5.787,0,0,1-6.1-5.791c0-4.9,4.4-7.257,11.08-7.217V44.6c0-1-.535-2.432-3.436-2.432A10.619,10.619,0,0,0,23.268,43.6l-1.08-3.783a15.594,15.594,0,0,1,7.334-1.66c6.293,0,8.3,3.706,8.3,8.146ZM32.1,48.537c-3.089-.039-5.479.695-5.479,2.972a2.108,2.108,0,0,0,2.315,2.239,3.207,3.207,0,0,0,3.048-2.168,4.207,4.207,0,0,0,.116-1Z'
        transform='translate(-0.721 -1.322)' />
      <path fill={color}
        d='M46.243,38.592l2.817,9.266c.309,1.12.695,2.51.926,3.514H50.1c.267-1,.579-2.432.85-3.514l2.317-9.266h6.292l-4.4,12.429c-2.7,7.484-4.517,10.5-6.641,12.393A10.825,10.825,0,0,1,42.843,66l-1.235-4.98a7.4,7.4,0,0,0,2.547-1,6.749,6.749,0,0,0,2.432-2.355,1.53,1.53,0,0,0,.309-.85,2.138,2.138,0,0,0-.267-.965L39.716,38.592Z'
        transform='translate(-1.376 -1.337)' />
      <path fill={color}
        d='M62.786,53.108a11.894,11.894,0,0,0,6.023,1.7c3.436,0,5.443-1.815,5.443-4.44,0-2.432-1.39-3.822-4.9-5.175-4.253-1.5-6.872-3.7-6.872-7.372,0-4.055,3.36-7.066,8.42-7.066a11.818,11.818,0,0,1,5.747,1.274l-.926,2.741a10.364,10.364,0,0,0-4.945-1.236c-3.552,0-4.9,2.124-4.9,3.9,0,2.432,1.584,3.628,5.175,5.018,4.4,1.7,6.641,3.822,6.641,7.643,0,4.009-2.974,7.528-9.112,7.528a13.433,13.433,0,0,1-6.641-1.7Z'
        transform='translate(-2.147 -1.067)' />
      <path fill={color}
        d='M99.268,47.965c0,6.909-4.787,9.922-9.3,9.922-5.057,0-8.954-3.706-8.954-9.613,0-6.255,4.094-9.923,9.267-9.923,5.366,0,9,3.9,9,9.614m-14.825.192c0,4.092,2.355,7.181,5.675,7.181,3.242,0,5.675-3.05,5.675-7.257,0-3.166-1.584-7.181-5.6-7.181s-5.747,3.706-5.747,7.258'
        transform='translate(-2.807 -1.329)' />
      <path fill={color}
        d='M103.8,43.836c0-1.931-.039-3.514-.155-5.057h2.972l.155,3.011h.116a6.439,6.439,0,0,1,5.867-3.436,5.525,5.525,0,0,1,5.29,3.742h.076a7.717,7.717,0,0,1,2.085-2.432,6.377,6.377,0,0,1,4.131-1.312c2.471,0,6.138,1.621,6.138,8.108v11h-3.32V46.887c0-3.591-1.312-5.747-4.053-5.747a4.4,4.4,0,0,0-4.009,3.089,5.6,5.6,0,0,0-.267,1.7V57.47H115.5v-11.2c0-2.974-1.312-5.135-3.9-5.135a4.643,4.643,0,0,0-4.208,3.4,4.7,4.7,0,0,0-.267,1.66V57.465h-3.32Z'
        transform='translate(-3.592 -1.33)' />
      <path fill={color}
        d='M138.529,48.735c.078,4.595,3.011,6.486,6.415,6.486a12.3,12.3,0,0,0,5.173-.965l.579,2.432a15.006,15.006,0,0,1-6.216,1.2c-5.747,0-9.189-3.822-9.189-9.458s3.321-10.077,8.764-10.077c6.1,0,7.721,5.366,7.721,8.8a12.853,12.853,0,0,1-.115,1.584ZM148.49,46.3c.039-2.162-.887-5.521-4.71-5.521-3.436,0-4.945,3.166-5.212,5.521Z'
        transform='translate(-4.688 -1.327)' />
      <path fill={color}
        d='M160.231,34.152v4.474H165.1v2.587h-4.865V51.291c0,2.317.656,3.63,2.549,3.63a6.3,6.3,0,0,0,1.969-.233l.155,2.549a8.183,8.183,0,0,1-3.011.5,4.732,4.732,0,0,1-3.669-1.47,6.926,6.926,0,0,1-1.312-4.865V41.218h-2.9V38.626h2.9V35.194Z'
        transform='translate(-5.338 -1.183)' />
      <path fill={color}
        d='M168.626,29.753h3.4V41.412h.079a6.281,6.281,0,0,1,2.431-2.394,6.99,6.99,0,0,1,3.475-.965c2.51,0,6.526,1.545,6.526,7.992V57.165h-3.4V46.431c0-3.012-1.121-5.56-4.325-5.56a4.883,4.883,0,0,0-4.556,3.4,4.131,4.131,0,0,0-.231,1.621V57.162h-3.4Z'
        transform='translate(-5.845 -1.031)' />
      <path fill={color}
        d='M193.811,33.277a2.1,2.1,0,0,1-4.208,0,2.07,2.07,0,0,1,2.124-2.124,2.017,2.017,0,0,1,2.084,2.124m-3.783,5.251h3.4V57.214h-3.4Z'
        transform='translate(-6.572 -1.079)' />
      <path fill={color}
        d='M198.593,43.835c0-1.931-.039-3.514-.155-5.057h3.011l.194,3.089h.077a6.871,6.871,0,0,1,6.177-3.514c2.586,0,6.6,1.545,6.6,7.953V57.464h-3.4V46.692c0-3.011-1.12-5.52-4.323-5.52a4.827,4.827,0,0,0-4.556,3.475,4.913,4.913,0,0,0-.231,1.582V57.465h-3.4Z'
        transform='translate(-6.878 -1.329)' />
      <path fill={color}
        d='M236.107,54.762c0,4.285-.85,6.911-2.664,8.532a9.9,9.9,0,0,1-6.794,2.24,12.059,12.059,0,0,1-6.216-1.545l.85-2.587a10.523,10.523,0,0,0,5.479,1.47c3.475,0,6.023-1.814,6.023-6.523V54.263h-.078a6.67,6.67,0,0,1-5.946,3.127c-4.633,0-7.953-3.937-7.953-9.111,0-6.332,4.131-9.923,8.42-9.923a6.216,6.216,0,0,1,5.83,3.244h.078l.155-2.819h2.972c-.078,1.351-.155,2.857-.155,5.136Zm-3.358-8.609a4.839,4.839,0,0,0-.192-1.545,4.9,4.9,0,0,0-4.748-3.59c-3.244,0-5.56,2.741-5.56,7.066,0,3.667,1.854,6.717,5.521,6.717a4.966,4.966,0,0,0,4.71-3.475,5.906,5.906,0,0,0,.267-1.815Z'
        transform='translate(-7.585 -1.33)' />
      <path fill={color}
        d='M244.363,33.414A1.509,1.509,0,0,1,242.818,35a1.486,1.486,0,0,1-1.427-1.582,1.584,1.584,0,0,1,1.5-1.621,1.506,1.506,0,0,1,1.47,1.621m-2.394,5.29h1.815V57.235h-1.815Z'
        transform='translate(-8.368 -1.101)' />
      <path fill={color}
        d='M249.292,43.377c0-1.7-.078-2.974-.155-4.44h1.7l.155,3.358h.078a6.949,6.949,0,0,1,6.371-3.783c1.775,0,6.447.889,6.447,7.837V57.474h-1.815V46.515c0-3.36-1.312-6.415-5.135-6.415a5.92,5.92,0,0,0-5.56,4.246,5.536,5.536,0,0,0-.267,1.66V57.474h-1.815Z'
        transform='translate(-8.635 -1.335)' />
      <path fill={color}
        d='M288.613,2.255A4.255,4.255,0,0,1,292.855,6.5V25.053a4.255,4.255,0,0,1-4.243,4.243h-4.945l-8.917,9.58V29.3h-5.407a4.255,4.255,0,0,1-4.243-4.243V6.5a4.255,4.255,0,0,1,4.243-4.243Zm0-2.255H269.344a6.5,6.5,0,0,0-6.5,6.5V25.053a6.5,6.5,0,0,0,6.5,6.5H272.5V44.607l3.9-4.194,8.249-8.862h3.964a6.5,6.5,0,0,0,6.5-6.5V6.5a6.5,6.5,0,0,0-6.5-6.5'
        transform='translate(-9.111 0)' />
    </svg>
  return logo
}
