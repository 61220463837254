import { useEffect, useState } from 'react'
import { useAPI } from '../Core/app/API'
import { BarChart, XAxis, YAxis, Bar } from 'recharts'

export default function ClassStats({groupTree})
{
  const api = useAPI()

  const [stats, setStats] = useState([])
  const updateStats = async (uuid) =>
  {
    const f = api.groupStats
    const sts = await f(uuid, 'weeks', 10)
    // WTF .. this yields "not a function"
    // const sts = await api.groupsStats(uuid, 'weeks')
    setStats(sts)
  }
  useEffect(() =>
  {
    if (!groupTree) return;
    updateStats(groupTree.uuid)
  }, [groupTree]) // eslint-disable-line react-hooks/exhaustive-deps -- updateStats

  const data = stats.map(stat => { return { 'date' : stat.xLabel, 'y' : stat.y }})
  if (data.length > 0) console.log('data', data);
  const classStats =
    <BarChart width={300} height={200} data={data}>
      <XAxis dataKey='date' />
      <YAxis />
      <Bar dataKey='y' fill='#555' />
    </BarChart>
  return classStats
}
