import SSiBannerLogo from '../SSiBannerLogo'
import joinedClassName from 'ssi-react-lib/joinedClassName'
import './LandingStandout.scss'

export default function LandingStandout({className, children})
{
  const standout =
    <div className={joinedClassName('standout-container', className)}>
      <div className='standout-box'>
        <SSiBannerLogo withCatchPhrase />
        {children}
      </div>
    </div>
  return standout
}
