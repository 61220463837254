import { useNavigate, useParams } from 'react-router-dom'
import './TabBarController.scss'

export default function TabBarController({tabs})
{
  // const [selected, setSelected] = useState(null)
  const navigate = useNavigate()
  const params = useParams()
  const selected = tabs.find(tb => tb.identifier === params.selected)

  const content = selected ? selected.body : null

  const onClick = (e) =>
  {
    const sel = e.target.closest('.tab-bar-selector')
    const tab = tabs.find(t => t.identifier === sel.dataset.identifier)
    if (!tab) return;
    navigate('../' + tab.identifier)
  }

  const tabIcons = tabs.map(ti =>
  {
    const seld = (selected && selected.identifier === ti.identifier) ? ' selected' : ''
    const tabIcon =
      <div className={`tab-bar-selector${seld}`} key={ti.identifier} data-identifier={ti.identifier}>
        <div className='icon-box'>
          {ti.icon}
          <div className='tab-name'>{ti.name}</div>
        </div>
      </div>
    return tabIcon
  })

  const scene =
    <div className='tab-bar-container'>
      <div className='tab-bar-content'>
        {content}
      </div>
      <div className='tab-bar-itself' onClick={onClick}>
        {tabIcons}
      </div>
    </div>
  return scene
}
