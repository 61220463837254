import SVGIcon from './SVGIcon'

export default function SchoolIcon({className, color, onClick}) {
  return <SVGIcon
          name='school'
          className={className}
          size='104.59 95.56'
          color={color}
          onClick={onClick}
          paths={[
                  'm52.29,51.91c3.37.05,6.69-.88,10.79-2.73l37.45-17.09c2.73-1.22,4.05-3.66,4.05-6.1s-1.32-4.93-4.05-6.15L63.09,2.74C58.98.88,55.66-.05,52.29,0c-3.32-.05-6.69.88-10.79,2.73L4.05,19.83c-2.73,1.22-4.05,3.71-4.05,6.15s1.32,4.88,4.05,6.1l37.45,17.09c4.1,1.86,7.47,2.78,10.79,2.73Zm0-6.93c-2.49,0-4.93-.63-8.15-2.15L9.57,27.15c-.63-.29-.93-.68-.93-1.17,0-.54.29-.93.93-1.22l34.57-15.67c3.22-1.46,5.66-2.15,8.15-2.15s4.93.68,8.15,2.15l34.57,15.67c.63.29.98.68.98,1.22,0,.49-.34.88-.98,1.17l-34.57,15.67c-3.22,1.51-5.66,2.15-8.15,2.15Zm-37.84,9.77c0,12.45,15.09,21.97,37.84,21.97s37.84-9.52,37.84-21.97v-20.41h-7.03v20.41c0,8.5-12.26,15.38-30.81,15.38s-30.81-6.88-30.81-15.38v-20.41h-7.03v20.41Zm6.59,25.49v10.06c0,3.17,2.1,5.27,5.27,5.27h1.07c3.17,0,5.27-2.1,5.27-5.27v-10.06c0-3.17-2.1-5.27-5.27-5.27h-1.07c-3.17,0-5.27,2.1-5.27,5.27Zm3.03-1.22h5.62v-38.23l-5.62-2.73v40.97Zm5.62-38.23l22.12-12.06-2.39-4.44-25.34,13.53,5.62,2.98Zm22.8-10.3c4.05,0,8.3-1.86,8.3-4.49s-4.25-4.44-8.3-4.44-8.35,1.81-8.35,4.44,4.3,4.49,8.35,4.49Z'
                  ]}
        />
}
