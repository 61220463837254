import { useEffect, useState } from 'react'
import { useAuth } from '../../Auth'
import { useAPI } from '../../Core/app/API'
import { useTranslate } from '../../Translation'
import { useAlertDialog } from '../../Hooks/AlertDialog'
import { usePopAndFade } from '../../Widgets/PopAndFade'
import { useSpinner } from '../../Hooks/Spinner'
import Button from 'ssi-react-lib/Button'
import TranslatedText from '../../Widgets/TranslatedText'

export default function UserPassword()
{
  const auth = useAuth()
  const user = auth.user()
  const direction = user?.language?.direction ?? 'ltr'
  const api = useAPI()
  const translate = useTranslate()
  const [showAlert, alertDialog] = useAlertDialog(translate('ok'))

  const [passwd1, setPasswd1] = useState('')
  const [passwd2, setPasswd2] = useState('')
  const [explanation, setExplanation] = useState('')
  const [buttonEnabled, setButtonEnabled] = useState(false)
  const [startSpinning, stopSpinning, spinOverlay] = useSpinner(translate('updating'))
  const [popNFade, popNFadeDialog] = usePopAndFade(2000)

  const onPass1 = event =>
  {
    setPasswd1(event.target.value)
  }
  const onPass2 = event =>
  {
    setPasswd2(event.target.value)
  }
  useEffect(() =>
  {
    const pv1 = passwd1.length > 4
    const pv2 = passwd1 === passwd2
    // console.log('nv', nv, 'ev', ev, 'pv', pv, 'valid', nv && ev && pv)
    setButtonEnabled(pv1 && pv2)
    if (!pv2) {
      // TODO: should be translated
      setExplanation('The passwords don\'t match')
    } else if (!pv1) {
      setExplanation('The password is not long enough')
    }
  }, [passwd1, passwd2])

  const onUpdate = async () =>
  {
    if (!buttonEnabled)
    {
      showAlert(explanation)
      return
    }
    startSpinning()
    const error = await api.updateUser({ password: passwd1 })
    stopSpinning()
    if (error) {
      showAlert(error)
    } else {
      popNFade(translate('updateSuccess'))
    }
  }

  const disabled = buttonEnabled ? '' : ' disabled'
  const passwordDialog =
    <>
      <TranslatedText class='user-label' translationKey='changePassword' />
      <input className='form-field' id='user_passwd1' name='password' type='password' dir={direction} value={passwd1} autoFocus onChange={onPass1} />
      <TranslatedText class='user-label' translationKey='changePasswordAgain' />
      <input className='form-field' id='user_passwd2' name='password' type='password' dir={direction} value={passwd2} onChange={onPass2} />
      <Button name='update' class={`submit-button below${disabled}`} action={onUpdate} label={translate('update')} />
      {spinOverlay}
      {alertDialog}
      {popNFadeDialog}
    </>
  return passwordDialog
}
