import SVGIcon from './SVGIcon'

export default function RightArrowIcon({className, color, onClick})
{
  return <SVGIcon
          name='right-arrow'
          className={className}
          size='71.04 58.59'
          color={color}
          onClick={onClick}
          paths={[
            'M71.04,29.3c0-1.07-.49-2.1-1.37-2.93L44.48,1.22c-.88-.83-1.86-1.22-2.88-1.22-2.15,0-3.81,1.61-3.81,3.81,0,1.03,.34,2.05,1.07,2.73l6.74,6.98,16.5,14.89,.88-2.15-12.99-.93H3.91c-2.29,0-3.91,1.66-3.91,3.96s1.61,3.96,3.91,3.96H50l12.99-.93-.88-2.1-16.5,14.84-6.74,6.98c-.73,.68-1.07,1.71-1.07,2.73,0,2.2,1.66,3.81,3.81,3.81,1.03,0,2-.39,2.88-1.22l25.2-25.15c.88-.83,1.37-1.86,1.37-2.93Z'
            ]}
          />
}
