import { useEffect, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import './Floating.css'

export default function Floating({display, children})
{
  const nodeRef = useRef(null)

  useEffect(() =>
  {
    if (!display) return;
    const float = nodeRef.current
    const mouseDown = (ev) =>
    {
      if (ev.target !== float) return;
      const rect = float.getBoundingClientRect()
      float.style.left = `${rect.left}px`
      float.style.top = `${rect.top}px`
      const offX = ev.pageX - rect.left
      const offY = ev.pageY - rect.top
      const onMouseMove = (ev2) =>
      {
        float.style.left = ev2.pageX - offX + 'px'
        float.style.top = ev2.pageY - offY + 'px'
      }
      const onMouseUp = (ev2) =>
      {
        document.removeEventListener('mousemove', onMouseMove)
        document.removeEventListener('mouseup', onMouseUp)
      }
      document.addEventListener('mousemove', onMouseMove)
      document.addEventListener('mouseup', onMouseUp)
    }
    float.addEventListener('mousedown', mouseDown)
    return () =>
    {
      float.removeEventListener('mousedown', mouseDown)
    }
  }, [display])

  const floating =
    <CSSTransition nodeRef={nodeRef} in={display} classNames='modal2' timeout={300} unmountOnExit>
      <div ref={nodeRef} className='floating-container'>
        {children}
      </div>
    </CSSTransition>
  return floating
}
