import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAPI } from '../Core/app/API'
import { useAuth } from '../Auth'
import { useTranslate } from '../Translation'
import { setAnnouncement, setOnboardingUser } from '../Core/app/slices/autoMagicSlice'
import { setDisplayHowAreYouFeeling } from '../Core/app/slices/settings/settingsSlice'
import {
  coHostDidEndURL,
  presenterDidEndURL,
  setCoHostDuration,
  setCoHostProgress,
  setCoHostStatus,
  setIsPlaying,
  setPresenterDuration,
  setPresenterProgress,
  setPresenterStatus,
  setPresenterIdle,
  setCoHostIdle
} from '../Core/app/slices/session/sessionSlice'
import { LearningMode } from '../Core/app/slices/learnerSlice'
import { sessionSelector, autoMagicSelector, learnerSelector, settingsSelector } from '../Core/app/store'
import { playingStates, PlayingState } from '../Core/app/PuppetEngine/PuppetEngine'
import { useAlertDialog } from '../Hooks/AlertDialog'
import { useBigLearnerChooser } from '../Hooks/BigLearnerChooser'
import { useConfirmDialog } from '../Hooks/ConfirmDialog'
import { useKeyPress } from '../Hooks/KeyPress'
import { useSpinner } from '../Hooks/Spinner'
import CompositeVideoPlayer from '../Core/CompositeVideoPlayer'
import Button from 'ssi-react-lib/Button'
import DebugView from '../Widgets/Debug'
import Demo from '../Widgets/Demo'
import HowAreYouFeeling from '../Widgets/HowAreYouFeeling'
import HTML from '../HTML'
import InlineVideo from '../Widgets/InlineVideo'
import LearnerChooser from '../Widgets/LearnerChooser'
import LearnerId from '../Widgets/LearnerId'
import PlayPauseIcon from 'ssi-react-lib/Icons/PlayPause'
import ProgressWidget from '../Widgets/ProgressWidget'
import RevisitSkip from '../Widgets/RevisitSkip'
import SSiLogo from 'ssi-react-lib/Icons/SSiLogo'
import Stack from 'ssi-react-lib/Stack'
import TextArea from '../Widgets/TextArea'
import Tooltip from '../Widgets/Tooltip'
import CircularProgressBar from '../Widgets/CircularProgressBar'
import './Learn.scss'

export default function Learn()
{
  const dispatch = useDispatch()
  const api = useAPI()
  const auth = useAuth()
  const translate = useTranslate()

  const [startSpinning, stopSpinning, spinOverlay] = useSpinner(translate('loading'))
  const [showAlert, alertDialog] = useAlertDialog(translate('ok'))
  const [showConfirm, confirmDialog] = useConfirmDialog('Subscribe', 'Not now, thanks')
  const [showDemo, setShowDemo] = useState(false)
  const [showDebug, setShowDebug] = useState(false)

  const settings = useSelector(settingsSelector)
  const [bigLearnerChooserAvailable, showBigLearnerChooser, bigLearnerChooser] = useBigLearnerChooser()
  useEffect(() => {
    if (bigLearnerChooserAvailable && settings.displaySelectLearner)
    {
      showBigLearnerChooser()
    }
  }, [bigLearnerChooserAvailable, settings.displaySelectLearner, showBigLearnerChooser])

  const autoMagicSettings = useSelector(autoMagicSelector)
  const onboardingUser = autoMagicSettings.onboardingUser
  useEffect(() => {
    // TODO: we can just use auth.user, yes?
    if (onboardingUser)
    {
      const attrTeacher = onboardingUser.attributes.find(attr => attr.attribute === 'role:schools_teacher')
      const attrHeadOfWelsh = onboardingUser.attributes.find(attr => attr.attribute === 'role:schools_headOfWelsh')
      const attrTrial = onboardingUser.attributes.find(attr => attr.expiresAt)
      const body = attrTeacher ? translate('teacherOnboardMessage').replace(/%SCHOOL%/g, attrTeacher.name) : attrHeadOfWelsh ? translate('headOfWelshOnboardMessage').replace(/%SCHOOL%/g, attrHeadOfWelsh.name) : attrTrial ? translate('trialsOnboardMessage') : translate('userOnboardMessage')
      const mesg = <HTML body={body} />
      showAlert(mesg, async () =>
      {
        dispatch(setOnboardingUser(null))
        /*
        if (attrTeacher || attrHeadOfWelsh)
        {
          await api.userOnboard()
        } */
        await api.userOnboard()
      })
    }
  }, [onboardingUser]) // eslint-disable-line react-hooks/exhaustive-deps -- dispatch, showAlert, and translate

  const { isPlaying,
          noSkip,
          text,
          opacity,
          presenterState,
          coHostState,
          playingStateIndex,
          gapDuration,
          gapProgress
  } = useSelector(sessionSelector)
  const learner = autoMagicSettings.learner
  const learnerSettings = useSelector(learnerSelector)
  const videoHidden = learnerSettings.videoHidden
  const pacing = learnerSettings.pacing ?? 2 // TODO: sloppy defaults!
  const playbackRate = autoMagicSettings.velocities[pacing]?.audioParameters.playbackRate

  useEffect(() =>
  {
    if (autoMagicSettings.expired)
    {
      const onConfirm = (cancel) =>
      {
        if (!cancel)
        {
          const callback = encodeURIComponent(`${process.env.REACT_APP_AM_ROOT}/post-subscribe`)
          console.log('CALLBACK', callback)
          const endpoint = `${process.env.REACT_APP_RAILS_ROOT}/welsh/wall/am?url=${callback}`
          console.log('ENDPOINT', endpoint)
          window.location.href = endpoint
        }
      }
      showConfirm(translate('trialExpired'), onConfirm)
    }
  }, [autoMagicSettings.expired, showConfirm, translate])

  const [showAnnouncement, announcementDialog] = useAlertDialog(translate('ok'), () =>
  {
    dispatch(setAnnouncement(null))
  })
  useEffect(() =>
  {
    const announcement = autoMagicSettings.announcement
    if (announcement)
    {
      const mesg =
        <>
          <div className='announcement-body'><HTML body={translate(announcement.body)}/></div>
        </>

      showAnnouncement(mesg)
    }
  }, [autoMagicSettings.announcement, showAnnouncement, translate])

  const playClass = 'transport play' + (isPlaying ? ' active' : '')

  const onPresenterDuration = (url, duration) => void dispatch(setPresenterDuration(url, duration))
  const onCoHostDuration = (url, duration) => void dispatch(setCoHostDuration(url, duration))
  const onPresenterTimeUpdate = (percent) => void dispatch(setPresenterProgress(percent))
  const onCoHostTimeUpate = (percent) => void dispatch(setCoHostProgress(percent))
  const presenterOnEnd = () => void dispatch(presenterDidEndURL())
  const coHostOnEnd = () => void dispatch(coHostDidEndURL())
  const onInterupted = () => { console.log('was interrupted!'); void dispatch(setIsPlaying(false)) }
  const onPresenterStatusChange = (status) => void dispatch(setPresenterStatus(status))
  const onCoHostStatusChange = (status) => void dispatch(setCoHostStatus(status))

  const [ready, setReady] = useState(false)
  const [steady, setSteady] = useState(true)
  const [skipRevisitWorking, setSkipRevisitWorking] = useState(false)
  const [transportVisible, setTransportVisible] = useState(true)

  useEffect(() =>
  {
    if (ready && steady && !skipRevisitWorking) { stopSpinning() } else { startSpinning() }
  }, [ready, steady, skipRevisitWorking, startSpinning, stopSpinning])

  const learnerInfo =
    <div className='learner-info'>
      <LearnerId className='current-learner' learner={learner} />
      <ProgressWidget />
    </div>

  // deprecate?
  // const inlaidClass = transportVisible || videoHidden ? '' : ' hide'

  const [preloading, setPreloading] = useState(true)
  const onPlay = () =>
  {
    if (preloading) return;
    setTransportVisible(isPlaying)
    dispatch(setDisplayHowAreYouFeeling(false))
    dispatch(setIsPlaying(!isPlaying))
  }
  const managePreload = (playing) => {
    if (!preloading) return;
    if (playing) {
      // console.log('start preload')
      dispatch(setPresenterIdle())
      dispatch(setCoHostIdle())
    } else {
      // console.log('end preload')
      setPreloading(false)
      dispatch(setIsPlaying(false))
    }
  }
  const onCanvasClick = () =>
  {
    if (isPlaying) {
      onPlay()
    } else {
      setTransportVisible(!transportVisible)
    }
  }
  const onError = (e) =>
  {
    if (e.constructor.name === 'MediaError')
    {
      console.log('MediaError')
    }
    console.log('onError', e)
  }
  const onStalled = () =>
  {
    onPause()
    setTransportVisible(true)
  }
  const onPause = () => void dispatch(setIsPlaying(false))

  const onAudioPlay = () =>
  {
    // setTransportVisible(isPlaying)
    dispatch(setDisplayHowAreYouFeeling(false))
    if (isPlaying)
    {
      // response to pause needs to be immediate
      dispatch(setIsPlaying(false))
      console.log('PAUSE!')
    } else {
      console.log('PLAY!')
      // but response to play can be deferred
      setSteady(false)
      api.startPlayback(() => { setSteady(true) })
    }
  }

  useKeyPress(key =>
  {
    switch (key)
    {
        case ' ': onAudioPlay(); break;
        case 'd': setShowDemo(!showDemo); break;
        case 'D': setShowDebug(!showDebug); break;
        default: break;
    }
  })

  let txt
  let txtDirection
  if (typeof text === 'object' && text !== null) {
    txt = text.text
    txtDirection = text.direction
  } else {
    txt = text
  }
  if (txt.substr(0,1) === '#') {
    txt = translate(txt.slice(1))
    txtDirection = auth.user()?.language?.direction ?? 'ltr'
  }
  const textStyle = txtDirection ? {opacity: opacity, direction: txtDirection} : {opacity: opacity}
  const textClass = !learnerSettings.textHidden ? '' : ' hidden'

  const playingState = playingStates[playingStateIndex]
  const inResponsePrompt = learnerSettings.entry >= 0 && playingState === PlayingState.prompt
  const gapProgressPercentage = gapDuration === 0 ? 0 : (gapProgress / gapDuration) * 100
  const gapRemaining = gapDuration - gapProgress
  /*
  if (gapDuration)
  {
    console.log(`gapDuration: ${gapDuration.toFixed(2)}, gapProgress: ${gapProgress.toFixed(2)}, gapProgress%: ${gapProgressPercentage.toFixed(2)}, remaining: ${gapRemaining.toFixed(2)}`)
  } */
  const progress = isPlaying ? (inResponsePrompt ? 0 : 100) : gapProgressPercentage
  const modeClass = videoHidden ? 'audio' : 'video'
  const learnerChooser = <LearnerChooser onSelect={onPause} />
  const playChooser = learnerChooser ?
    <Stack className='play-chooser'>
      {learnerChooser}
    </Stack> : null
  const transport =
    <div className={`transport-controls ${modeClass}`}>
      <div className='play-container'>
        <CircularProgressBar strokeWidth={10} sqSize={100} percentage={progress} animationDuration={gapRemaining} />
        <Button name='play' action={onAudioPlay} icon=<PlayPauseIcon /> class={playClass} />
      </div>
      {playChooser}
    </div>

  const videoPlayer =
    <div className='combo-container'>
      <div className='video-container'>
        <Ready ready={ready} />
        <CompositeVideoPlayer
          setReady={setReady}
          presenterState={presenterState}
          coHostState={coHostState}
          backgroundSource='/assets/sample-background.jpg'
          onPresenterDuration={onPresenterDuration}
          onCoHostDuration={onCoHostDuration}
          onPresenterTimeUpdate={onPresenterTimeUpdate}
          onCoHostTimeUpdate={onCoHostTimeUpate}
          onPresenterEnded={presenterOnEnd}
          onCoHostEnded={coHostOnEnd}
          onInterupted={onInterupted}
          onError={onError}
          onStalled={onStalled}
          onPresenterStatusChange={onPresenterStatusChange}
          onCoHostStatusChange={onCoHostStatusChange}
          presenterScale={0.95}
          setAutoPlayback={managePreload}
          onCanvasClick={onCanvasClick}
          onChangeOrientation={(isLandscape) => {/*console.log('new orientation is landscape', isLandscape)*/}}
          onImageReady={() => {}}
          disableRender={videoHidden} />
        <div className={`text-container${textClass}`} style={textStyle} >
          <TextArea text={txt} />
        </div>
      </div>
      {transport}
    </div>

  const audioPlayer =
    <div className='combo-container'>
      <div className={'text-mode-container'}>
        <Ready ready={ready} />
        <InlineVideo
          setReady={setReady}
          presenterState={presenterState}
          coHostState={coHostState}
          onPresenterDuration={onPresenterDuration}
          onCoHostDuration={onCoHostDuration}
          onPresenterTimeUpdate={onPresenterTimeUpdate}
          onCoHostTimeUpdate={onCoHostTimeUpate}
          onPresenterEnded={presenterOnEnd}
          onCoHostEnded={coHostOnEnd}
          onError={onError}
          onStalled={onStalled}
          onPresenterStatusChange={onPresenterStatusChange}
          onCoHostStatusChange={onCoHostStatusChange}
          playbackRate={playbackRate} />
        <TextArea className={textClass} text={txt} style={textStyle} />
      </div>
      {transport}
    </div>
  const player = videoHidden ? audioPlayer : videoPlayer

  const visitMainSite = () =>
  {
    window.location.href = process.env.REACT_APP_RAILS_ROOT
  }

  const revisitDisabled = noSkip // || (learnerSettings.mode === LearningMode.skip)
  const skipDisabled = noSkip // || (learnerSettings.mode === LearningMode.revisit)

  const attrs = auth.user()?.attributes ?? []
  const useRevisitSkip = true // attrs.find(attr => attr.attribute === 'revisitSkip')
  const userControl = useRevisitSkip ? <RevisitSkip revisitDisabled={revisitDisabled} skipDisabled={skipDisabled} setSkipRevisitWorking={setSkipRevisitWorking} /> : <HowAreYouFeeling />

  const learn =
    <>
      <div className='learn-container'>
        <div className='status-row'>
          <Tooltip enabled={true} tooltip='visit SaySomethingin website' direction='down' offsetOther={60}>
            <SSiLogo onClick={visitMainSite} />
          </Tooltip>
          {learnerInfo}
        </div>
        {player}
        {userControl}
      </div>
      <DebugView display={showDebug} onPlay={onAudioPlay} />
      <Demo display={showDemo} />
      {spinOverlay}
      {bigLearnerChooser}
      {alertDialog}
      {announcementDialog}
      {confirmDialog}
    </>
  return learn
}

function Ready(props)
{
  const readyClass = props.ready ? 'ready' : 'waiting'
  const status =
    <div className={`status-indicator ${readyClass}`}>
    </div>
  return status
}
