import { useState } from 'react'
import { useStore } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAPI } from '../Core/app/API'
import { useAuth } from '../Auth'
import { findGroupInTree } from '../Hooks/GroupTreeInfo'
import { PersonType } from '../Core/PersonType'
import AllClassList from './AllClassList'
import SchoolsAddClassDialog from './SchoolsAddClassDialog'

export function useAllClassListComponent(className, groupTree, label, refreshGroups, showAlert, showConfirm)
{
  const store = useStore()
  const navigate = useNavigate()
  const api = useAPI()
  const auth = useAuth()
  const displayState = useState(false)
  const selectedState = useState(null)
  // const playAsClassState = useState(false)

  const groupId = groupTree ? groupTree.uuid : null

  const onAddLevel = async (name) =>
  {
    const result = await api.createGroup(groupId, name)
    if (result.error)
    {
      showAlert(result.reason)
      return
    }
    refreshGroups()
  }

  const onAddClass = async (name, yearGroup, playAsClass) =>
  {
    if (!yearGroup) return;
    const result = await api.createGroup(yearGroup, name)
    if (result.error)
    {
      showAlert(result.reason)
      return
    }
    if (playAsClass)
    {
      const result2 = await api.addLearnerToGroup(result.result)
      if (result2.error)
      {
        showAlert(result2.reason)
      } else {
        // showAlert('Successfully added')
      }
    }
    refreshGroups()
  }

  const warning = null
  // const levelDescription = 'Create a new year group (e.g. Year 7, Year 8, etc):'
  const chooserDescription = 'Choose the year group:'
  const classDescription = 'And enter the name of your class here:'
  const yearList = groupTree ? groupTree.groups.map(grp => { return { label: grp.name, meta: grp.uuid } }).sort((a, b) => a.label.localeCompare(b.label)) : null
  const [showAddClass, addClassDialog] = SchoolsAddClassDialog('Add a Class', warning, classDescription, 'class name', chooserDescription, yearList, displayState, selectedState, [true, null], onAddLevel, onAddClass)

  const onPlay = async (learner, uuid) =>
  {
    const error = await api.addToGroup(auth.user().uuid, [PersonType.manager], uuid, true)
    if (error)
    {
      showAlert(error.reason)
      return
    }
    // select playing-as...
    await api.switchToLearner(store, learner)
    const link = '/app'
    navigate(link)
  }

  const onTrash = async (uuid) =>
  {
    const grp = findGroupInTree(uuid, groupTree)
    // const grp = group.groups.find(g => g.uuid === uuid)
    const confirmMessage = `Are you sure you want to permanently delete “${grp.name}”?`
    const onConfirm = async (cancelled) =>
    {
      if (cancelled) return;
      const error = await api.deleteGroup(uuid)
      if (error)
      {
        showAlert(error.reason)
        return
      }
      refreshGroups()
    }
    showConfirm(confirmMessage, onConfirm)
  }

  const comp = <AllClassList className={className}
                             groupTree={groupTree}
                             groupId={groupId}
                             label={label}
                             onAddClass={showAddClass}
                             onPlay={onPlay}
                             onTrash={onTrash} />
  return [comp, addClassDialog]
}
