import joinedClassName from './joinedClassName'
import './IconWithLabel.css'

export default
function IconWithLabel({className, label, children})
{
  const licon =
    <div className={joinedClassName('labelledicon-box', className)}>
      {children}
      <div className='labelledicon-name'>{label}</div>
    </div>
  return licon
}
