import './GroupAction.scss'

export const mkGroupAction = (label, icon, onAction) => (key) =>
{
  const action =
    <div className='group-action' key={key} onClick={onAction} >
      {icon} <span className='group-action-label'>{label}</span>
    </div>
  return action
}
