import { useState } from "react"
import Modal from '../Widgets/Modal'

export function useModal(onDismiss)
{
  const [display, setDisplay] = useState(false)
  const [body, setBody] = useState(null)
  const [dismiss, setDismiss] = useState(null)

  const onDisplay = (body, dismiss) => {
    setBody(body)
    setDisplay(true)
    if (dismiss) {
      setDismiss(() => dismiss)
    }
  }

  const localDismiss = () => {
    if (dismiss) {
      dismiss()
      setDismiss(null)
    }
    if (onDismiss) onDismiss()
    setDisplay(false)
  }

  let dialog =
    <Modal display={display} onDismiss={localDismiss}>
      {body}
    </Modal>
  return [onDisplay, localDismiss, dialog]
}
