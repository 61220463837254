import { useParams } from 'react-router-dom'
import { useTranslate } from '../Translation'

export default function ErrorPage(props)
{
  const params = useParams()
  const translate = useTranslate()
  const message = translate(params.key)

  const page =
    <div className='error-fallback'>
      <p className='error-message'>{message}</p>
    </div>
  return page
}
