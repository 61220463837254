import Box from 'ssi-react-lib/Box'
import './ManagerOverview.scss'

export default function ManagerOverview({className, banner, onClickBanner, children})
{
  const overview =
    <Box className={className}>
      <div className='manager-overview-banner' onClick={onClickBanner}>
        {banner}
      </div>
      {children}
    </Box>
  return overview
}
