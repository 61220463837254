import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslate } from '../Translation'
import { useAPI } from '../Core/app/API'
import { useAlertDialog } from '../Hooks/AlertDialog'
import { useFormField, useEmailFormField, usePasswordFormField } from '../Hooks/FormFields'
import { useSpinner } from '../Hooks/Spinner'
import Button from 'ssi-react-lib/Button'
import LandingStandout from '../Widgets/LandingStandout'
import Stack from 'ssi-react-lib/Stack'

export default function WBLSignup(props)
{
  const navigate = useNavigate()
  const translate = useTranslate()
  const api = useAPI()

  const [name, nameField] = useFormField({label: translate('name')})
  const [email, emailField] = useEmailFormField({label: translate('email')})
  const [orgName, orgNameField] = useFormField({label: translate('orgName')})
  const [passwd1, passwdField1] = usePasswordFormField({label: translate('newPassword')})
  const [passwd2, passwdField2] = usePasswordFormField({label: translate('newPasswordAgain')})

  const [buttonEnabled, setButtonEnabled] = useState(false)
  const [startSpinning, stopSpinning, spinOverlay] = useSpinner(translate('settingup'))
  const [explanation, setExplanation] = useState('')
  const [showAlert, alertDialog] = useAlertDialog(translate('ok'))

  const direction = 'ltr'

  useEffect(() => {
    function emailValid(email)
    {
      return /^\w+([.+-]\w+)*@\w+([.-]\w+)*(\.\w{2,6})+$/.test(email);
    }
    const nv1 = name.length > 0
    const nv2 = email.length > 0
    const ev = emailValid(email)
    const ov = orgName.length > 0
    const pv1 = passwd1.length > 4
    const pv2 = passwd1 === passwd2
    // console.log('nv', nv, 'ev', ev, 'pv', pv, 'valid', nv && ev && pv)
    setButtonEnabled(ev && nv1 && nv2 && ov && pv1 && pv2)
    if (!nv1) {
      setExplanation('Please enter a name')
    } else if (!nv2) {
      setExplanation('Please enter an email address')
    } else if (!ev) {
      setExplanation('The email doesn\'t appear to be valid')
    } else if (!ov) {
      setExplanation('Please enter an organization name')
    } else if (!pv2) {
      setExplanation('The passwords don\'t match')
    } else if (!pv1) {
      setExplanation('The password is not long enough')
    }
  }, [name, email, orgName, passwd1, passwd2])

  async function onSignup(e) {
    if (!buttonEnabled) {
      showAlert(explanation)
      return
    }
    startSpinning()
    // TODO: is hard-coded 'en' the best we can do?
    const result = await api.signupWBL(name, email, orgName, 'en', passwd1)
    stopSpinning()
    if (result.ok)
    {
      navigate(`/app/groups/wbl/${result.groupId}`)
    } else {
      const mesg = result.reason.split(' ').length < 2 ? translate(result.reason).replace(/%EMAIL%/g, email) : result.reason
      showAlert(mesg)
    }
  }

  const disabled = buttonEnabled ? '' : ' disabled'
  const buttonPos = direction === 'rtl' ? ' right' : ''
  const signup =
    <>
      <LandingStandout>
        <Stack className='signup-section'>
          <div>
            {translate('wblOrgSignupIntro')}
          </div>
          {orgNameField}
          <div>
            {translate('wblMgrSignupIntro')}
          </div>
          {nameField}
          {emailField}
          {passwdField1}
          {passwdField2}
          <Button label={translate('signup')} labelClass='signup-button-label' class={`submit-button signup${disabled}${buttonPos}`} action={onSignup} />
        </Stack>
      </LandingStandout>
      {spinOverlay}
      {alertDialog}
    </>
  return signup
}
