export default function SerialQueue()
{
    let queue = []

    async function nudge()
    {
        if (queue.length === 0) return;
        const top = queue[0]
        const { /* label, */ request, resolve } = top
        if (top.loading) return;
        queue[0].loading = true
        // console.log('ENTER', label)
        const rslt = await request()
        // console.log('EXIT', label)
        queue.shift()
        resolve(rslt)
        nudge()
    }

    async function execute(label, action)
    {
        const promise = new Promise(resolve =>
        {
            queue.push({ label: label, request: action, resolve: resolve, loading: false })
        })
        nudge()
        const result = await promise
        return result
    }

    return { execute: execute }
}
