export const SpecialRoles =
{
  'role:canolfan_tiwtor' : (uuid) => `/tiwtor/${uuid}`,
  'role:schools_headOfWelsh' : (uuid) => `/headOfWelsh/${uuid}`,
  'role:schools_teacher' : (uuid) => `/teacher/${uuid}`,
  'role:wbl_manager' : (uuid) => `/wbl/${uuid}`
}

export function endpointForAttribute(attr, prefix)
{
  if (!attr) return null;
  const fn = SpecialRoles[attr.attribute]
  const rslt = fn ? (prefix ?? '') + fn(attr.uuid) : null
  return rslt
}
