import React from 'react'
import { useCallback, useLayoutEffect, useRef, useState } from 'react'
import hoverintent from 'hoverintent'
import { useElementSize } from '../Hooks/ElementSize'
import joinedClassName from 'ssi-react-lib/joinedClassName'
import './Tooltip.css'

export default function Tooltip({className, enabled, tooltip, direction, offset, offsetOther, expiry, children})
{
  const tipRef = useRef()
  const [tipStyle, setTipStyle] = useState({})
  const timer = useRef(null)
  const [wrapperRef, { width: childWidth, height: childHeight }] = useElementSize()
  // console.log(`width: ${childWidth}, height: ${childHeight}`)

  const updateTipStyle = useCallback(() =>
  {
    const offs = offset ?? 12
    const offso = offsetOther ?? 0
    const tip = tipRef.current
    if (!tip) return;
    switch (direction ?? 'down') // eslint-disable-line default-case
    {
      case 'up':
        setTipStyle({ bottom: childHeight + offs, left: childWidth / 2 - tip.offsetWidth / 2 + offso })
        break
      case 'down':
        setTipStyle({ top: childHeight + offs, left: childWidth / 2 - tip.offsetWidth / 2 + offso })
        break
      case 'left':
        setTipStyle({ top: childHeight / 2 - tip.offsetHeight / 2 + offso, right: childWidth + offs })
        break
      case 'right':
        setTipStyle({ top: childHeight / 2 - tip.offsetHeight / 2 + offso, left: childWidth + offs })
        break
    }
  }, [offset, direction, childWidth, childHeight])

  useLayoutEffect(() =>
  {
    const tip = tipRef.current
    if (!tip) return;
    const control = tip.previousSibling
    const f = () =>
    {
      updateTipStyle()
      tip.classList.add('visible')
      clearTimeout(timer.current)
      timer.current = setTimeout(() => { tip.classList.remove('visible') }, expiry ?? 3000)
    }
    const g = () => {
      clearTimeout(timer.current)
      tip.classList.remove('visible')
    }
    const hoverListener = hoverintent(control, f, g).options({interval: 200})
    return () => hoverListener.remove()
  }, [expiry, updateTipStyle])

  const tipster = enabled === true ?
    <div ref={wrapperRef} className={joinedClassName('tooltip-container', className)}>
      {children}
      <div ref={tipRef} className='tooltip' style={tipStyle}>{tooltip}</div>
    </div> : children
  return tipster
}
