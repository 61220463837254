import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslate } from '../Translation'
import { useAlertDialog } from '../Hooks/AlertDialog'
import { useAllClassListComponent } from './AllClassListComponent'
import { useClassListComponent } from './ClassListComponent'
import { useConfirmDialog } from '../Hooks/ConfirmDialog'
import { useGroupTreeInfo } from '../Hooks/GroupTreeInfo'
import AdminContainer from './AdminContainer'
import LabelledBox from 'ssi-react-lib/LabelledBox'
import ManagerOverview from './ManagerOverview'
import NavPanel from '../Widgets/NavPanel'
import Overlay from 'ssi-react-lib/Overlay'
import './Teacher.css'

export default function AdminTeacher(props)
{
  const params = useParams()
  const translate = useTranslate()
  const [spinning, setSpinning] = useState(false)
  const [showAlert, alertDialog] = useAlertDialog(translate('ok'))
  const [showConfirm, confirmDialog] = useConfirmDialog(translate('yes'), translate('cancel'))

  const groupId = params.groupId

  // eslint-disable-next-line no-unused-vars -- managersDict, invited
  const [path, groupTree, managersDict, invited, refreshGroups] = useGroupTreeInfo(groupId, groupId, setSpinning)

  const [allClassList, addClassDialog] = useAllClassListComponent('all-class-column', groupTree, translate('classes'), refreshGroups, showAlert, showConfirm)

  const myClassList = useClassListComponent('teacher-classes', groupTree, translate('myClasses'), translate('myClassesEmptyForTeachers'), refreshGroups, showAlert, showConfirm)

  const nameWithContext = groupTree ? [...path.slice().reverse(), groupTree].map(grp => grp.name).join(' ⟩ ') : null
  const page =
    <>
      <NavPanel className='teacher-content' backLabel={translate('back')} backUrl={-1}>
        <AdminContainer className='teacher-container'>
          {allClassList}
          <ManagerOverview className='head-of-welsh-overview' banner={nameWithContext}>
            <div>
              {translate('teacherOverviewBody')}
            </div>
          </ManagerOverview>
          {myClassList}
          <LabelledBox className='teacher-stats' label={translate('statistics')}>
            <i>Once you have some learners signed up and using the course, you’ll be able to see an overview of the statistics here.</i>
          </LabelledBox>
        </AdminContainer>
      </NavPanel>
      <Overlay display={spinning} msg={translate('loading')} />
      {addClassDialog}
      {alertDialog}
      {confirmDialog}
    </>
  return page
}
