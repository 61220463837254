import { useEffect, useState } from 'react'
import LeftArrowIcon from 'ssi-react-lib/Icons/LeftArrow'
import RightArrowIcon from 'ssi-react-lib/Icons/RightArrow'
import TranslatedText from './TranslatedText'
import './LeftRightChooser.css'

export default
function LeftRightChooser(props) {
  const onUpdate = props.onUpdate
  const description = props.description
  const choices = props.choices
  const initialSelected = props.selected
  const fgStyles = props.fgStyles
  const onClickPhrase = props.onClickPhrase
  const onClickDetails = props.onClickDetails

  const [selected, setSelected] = useState(initialSelected)
  const [userInitiated, setUserInitiated] = useState(false)
  const [leftDisabled, setLeftDisabled] = useState(true)
  const [rightDisabled, setRightDisabled] = useState(false)
  useEffect(() =>
  {
    setSelected(initialSelected ?? 0)
  }, [initialSelected])
  useEffect(() =>
  {
    setLeftDisabled(selected === 0)
    setRightDisabled(selected >= choices.length - 1)
    if (userInitiated)
    {
      // This is how we have to work-around the lack of a post `set' hook.. it's not perfect...
      setUserInitiated(false)
      onUpdate(selected)
    }
  }, [selected, choices.length]) // eslint-disable-line react-hooks/exhaustive-deps
  const selectedPhrase = choices[selected].phrase
  const selectedDetails = choices[selected].details

  const goLeft = () =>
  {
    if (selected > 0)
    {
      setUserInitiated(true)
      setSelected(selected - 1)
    }
  }
  const goRight = () =>
  {
    if (selected < choices.length - 1)
    {
      setUserInitiated(true)
      setSelected(selected + 1)
    }
  }

  const leftClass = leftDisabled ? ' disabled' : ''
  const rightClass = rightDisabled ? ' disabled' : ''
  const chooser =
    <div className={`lrchooser ${fgStyles}`}>
      <TranslatedText class={`lrchooser-description ${fgStyles}`} translationKey={description} />
      <div className={'lrchooser-choice-row'}>
        <LeftArrowIcon onClick={goLeft} class={leftClass} />
        <TranslatedText class={`lrchooser-selected ${fgStyles}`} translationKey={selectedPhrase} onClick={onClickPhrase} />
        <RightArrowIcon onClick={goRight} class={rightClass} />
      </div>
      <div className={`lrchooser-details ${fgStyles}`} onClick={onClickDetails}>{selectedDetails}</div>
    </div>
  return chooser
}
