import { useCallback, useEffect, useLayoutEffect, useRef } from 'react'

export function useKeyPress(callback, node = null)
{
  const callbackRef = useRef(callback)
  useLayoutEffect(() =>
  {
    callbackRef.current = callback
  })

  const handleKeyPress = useCallback(event =>
  {
    const onKeyPress = callbackRef.current
    if (!onKeyPress) return;
    onKeyPress(event.key, event)
  }, [])

  useEffect(() =>
  {
    const targetNode = node ?? document;
    targetNode &&
      targetNode.addEventListener("keydown", handleKeyPress);

    return () =>
      targetNode &&
        targetNode.removeEventListener("keydown", handleKeyPress);
  }, [handleKeyPress, node])
}
