import { useCallback, useState } from "react"
import Button from 'ssi-react-lib/Button'
import CenteredThings from 'ssi-react-lib/CenteredThings'
import Modal from '../Widgets/Modal'
import './AlertDialog.css'

export function useAlertDialog(icon, onDismiss)
{
  const [display, setDisplay] = useState(false)
  const [message, setMessage] = useState('')
  const [dismiss, setDismiss] = useState(null)

  const onDisplay = useCallback((message, dismiss) => {
    setMessage(message)
    setDisplay(true)
    if (dismiss) {
      setDismiss(() => dismiss)
    }
  }, [])

  const localDismiss = () => {
    if (dismiss) {
      dismiss()
      setDismiss(null)
    }
    if (onDismiss) onDismiss()
    setDisplay(false)
  }

  let dialog =
    <Modal display={display} onDismiss={onDismiss}>
      <div className='alert-dialog-container'>
        {message}
        <CenteredThings topSpacing={12}>
          <Button class='submit-button' label={icon} action={localDismiss} />
        </CenteredThings>
      </div>
    </Modal>
  return [onDisplay, dialog]
}
