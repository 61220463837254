// eslint-disable-next-line import/no-anonymous-default-export
export default `#version 300 es
  precision mediump float;

  in vec2 vTexCoord;
  out vec4 pixel;

  uniform sampler2D background;

  void main(void) {
    pixel = texture(background, vTexCoord);
    pixel.a = 1.0;
  }
`;
