import { useEffect } from 'react'

export default function Subscribe(props)
{
  useEffect(() =>
  {
    const callback = encodeURIComponent(`${process.env.REACT_APP_AM_ROOT}/post-subscribe`)
    const endpoint = `${process.env.REACT_APP_RAILS_ROOT}/welsh/wall/am?url=${callback}`
    window.location.href = endpoint
  }, [])
  const page = null
  return page
}
