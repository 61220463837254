import { useRef } from 'react'
import { useAuth } from '../Auth'
import { useTranslate } from '../Translation'

export default function TranslatedText(props)
{
  const auth = useAuth()
  const direction = auth.user()?.language?.direction ?? 'ltr'
  const translate = useTranslate()
  const ttext =
    <div className={props.class} dir={direction} onClick={props.onClick}>
      {translate(props.translationKey)}
    </div>
  return ttext
}

export function TranslatedSpan({className, translationKey, onClick})
{
  const auth = useAuth()
  const direction = auth.user()?.language?.direction ?? 'ltr'
  const translate = useTranslate()
  const ttext =
    <span className={className} dir={direction} onClick={onClick}>
      {translate(translationKey)}
    </span>
  return ttext
}

export function TranslatedFancySpan({className, translationKey, onClick})
{
  // HTML markup is allowed in the translation
  // Since all translations come from our server, we trust them not to inject nasty HTML
  const spanRef = useRef()
  const auth = useAuth()
  const direction = auth.user()?.language?.direction ?? 'ltr'
  const translate = useTranslate()
    console.log('translating ... ', translationKey)
  const translated = translate(translationKey)
  if (spanRef.current) spanRef.current.innerHTML = translated;
  const ttext =
    <span ref={spanRef} className={className} dir={direction} onClick={onClick}>
    </span>
  return ttext
}
