import { useCallback, useLayoutEffect, useState } from 'react'

export function useElementSize() {
  // Mutable values like 'ref.current' aren't valid dependencies
  // because mutating them doesn't re-render the component.
  // Instead, we use a state as a ref to be reactive.
  const [ref, setRef] = useState(null)
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  })

  // Prevent too many rendering using useCallback
  const handleSize = useCallback(() => {
    if (!ref) return;
    console.log(`W: ${ref?.offsetWidth}, H: ${ref?.offsetHeight}`)
    setSize({
      width: ref?.offsetWidth || 0,
      height: ref?.offsetHeight || 0,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.offsetHeight, ref?.offsetWidth])

  /* cute, but you have no idea whether the transition is relevant so this may fire A LOT
  useEffect(() => {
    const onResize = () => { console.log('TRANSITIONEND'); handleSize() }
    window.addEventListener('transitionend', onResize, { passive: true });
    return () => window.removeEventListener('transitionend', onResize)
  }, [handleSize]) */
  /* TODO: this is probably a good idea.. ?
  useEffect(() => {
    const onResize = () => { console.log('RESIZE'); handleSize() }
    window.addEventListener('resize', onResize, { passive: true });
    return () => window.removeEventListener('resize', onResize)
  }, [handleSize]) */
  /* useEventListener('resize', handleSize) */

  useLayoutEffect(() => {
    handleSize()
  }, [ref?.offsetHeight, ref?.offsetWidth, handleSize])

  return [setRef, size]
}
