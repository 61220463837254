import { createSlice } from "@reduxjs/toolkit"

export const initialState = {
    user: null,
    learner: null,
    courses: null,
    velocities: [],
    sessionExpired: false,
    sessionError: null,
    announcement: null,
    expired: false,
    onboardingUser: null,
    settingUp: true,
    inGroups: false
}

export const autoMagicSlice = createSlice({
name: "am",
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload
            // updates to the user can include updates to the learner
            // TODO: this isn't ideal, but will do for now
            if (state.learner)
            {
                const l = state.user.learners.find(lrnr => lrnr.uuid === state.learner.uuid)
                state.learner.name = l.name
            }
        },
        setLearner: (state, action) => {
            state.learner = action.payload
        },
        setCourses: (state, action) => {
            state.courses = action.payload
        },
        setVelocities: (state, action) => {
            state.velocities = action.payload
        },
        loginHappened: (state, action) => {
            // stuff happens in middleware
        },
        logoutHappened: (state, action) => {
            return initialState
        },
        preloadItem: (state, action) => {
            // nothing here
        },
        setSessionExpired: (state, action) => {
            state.sessionExpired = action.payload
        },
        setSessionError: (state, action) => {
            state.sessionError = action.payload
        },
        setAnnouncement: (state, action) => {
            console.log('Announcement set!')
            state.announcement = action.payload
        },
        setExpired: (state, action) => {
            console.log('Expired set!', action.payload)
            state.expired = action.payload
        },
        setOnboardingUser: (state, action) => {
            state.onboardingUser = action.payload
        },
        settingUp: (state, action) => {
            state.settingUp = action.payload
        },
        setInGroups: (state, action) => {
            state.inGroups = action.payload
        }
    }
})

export const {
    setUser,
    setLearner,
    setCourses,
    setVelocities,
    loginHappened,
    logoutHappened,
    preloadItem,
    setSessionExpired,
    setSessionError,
    setAnnouncement,
    setExpired,
    setOnboardingUser,
    settingUp,
    setInGroups
} = autoMagicSlice.actions

export default autoMagicSlice.reducer
