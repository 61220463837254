import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useAPI } from '../Core/app/API'
import { useAlertDialog } from '../Hooks/AlertDialog'
import { useFormField, useEmailFormField, usePasswordFormField } from '../Hooks/FormFields'
import { useSpinner } from '../Hooks/Spinner'
import Button from 'ssi-react-lib/Button'
import CenteredThings from 'ssi-react-lib/CenteredThings'
import LandingStandout from '../Widgets/LandingStandout'
import Stack from 'ssi-react-lib/Stack'
import TranslatedFancySpan from '../Widgets/TranslatedText'
import './SignupPassword.scss'

export default function SignupPassword(props)
{
  const [queryParams, setQueryParams] = useSearchParams() // eslint-disable-line no-unused-vars
  const guid = queryParams.get('guid')
  const qemail = queryParams.get('email')
  const direction = queryParams.get('direction')
  const trName = queryParams.get('tr-name')
  const trEmail = queryParams.get('tr-email')
  const trNewPassword = queryParams.get('tr-newPassword')
  const trNewPasswordAgain = queryParams.get('tr-newPasswordAgain')
  const trSignup = queryParams.get('tr-signup')
  const trLoggingin = queryParams.get('tr-loggingin')
  const trOk = queryParams.get('tr-ok')
  const trAlreadyHaveAccount = queryParams.get('tr-alreadyHaveAccount') ?? 'I’ve already got an account'

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const api = useAPI()

  const [name, nameField] = useFormField({label: trName, direction})
  const [email, emailField] = useEmailFormField({label: trEmail, direction, initialValue: qemail})
  const [passwd1, passwdField1] = usePasswordFormField({label: trNewPassword, direction})
  const [passwd2, passwdField2] = usePasswordFormField({label: trNewPasswordAgain, direction})

  const [buttonEnabled, setButtonEnabled] = useState(false)
  const [startSpinning, stopSpinning, spinOverlay] = useSpinner(trLoggingin)
  const [explanation, setExplanation] = useState('')
  const [showAlert, alertDialog] = useAlertDialog(trOk)

  useEffect(() => {
    function emailValid(email)
    {
      if (email === qemail) return true;
      return /^\w+([.+-]\w+)*@\w+([.-]\w+)*(\.\w{2,6})+$/.test(email);
    }
    const nv1 = name.length > 0
    const nv2 = email.length > 0
    const ev = emailValid(email)
    const pv1 = passwd1.length > 4
    const pv2 = passwd1 === passwd2
    // console.log('nv', nv, 'ev', ev, 'pv', pv, 'valid', nv && ev && pv)
    setButtonEnabled(ev && nv1 && nv2 && pv1 && pv2)
    if (!nv1) {
      setExplanation('Please enter a name')
    } else if (!nv2) {
      setExplanation('Please enter an email address')
    } else if (!ev) {
      setExplanation('The email doesn\'t appear to be valid')
    } else if (!pv2) {
      setExplanation('The passwords don\'t match')
    } else if (!pv1) {
      setExplanation('The password is not long enough')
    }
  }, [name, email, qemail, passwd1, passwd2])

  async function onSignup(e) {
    if (!buttonEnabled) {
      showAlert(explanation)
      return
    }
    startSpinning()
    // TODO: is hard-coded 'en' the best we can do?
    const error = await api.signup(name, email, 'en', passwd1, guid, dispatch)
    stopSpinning()
    if (!error)
    {
      navigate('/app')
    } else {
      showAlert(error)
    }
  }

  const onHaveAccount = () =>
  {
    navigate(`/login/with/${guid}`)
  }
  const haveAccountButton =
    <CenteredThings className='have-account'>
      <Button label={trAlreadyHaveAccount} class='forgot login' noBorder action={onHaveAccount} />
    </CenteredThings>

  const prelude = <div><TranslatedFancySpan class='signup-prelude' translationKey={'signupPrelude'} /></div>
  const disabled = buttonEnabled ? '' : ' disabled'
  const buttonPos = direction === 'rtl' ? ' right' : ''
  const signup =
    <>
      <LandingStandout>
        <Stack className='signup-section'>
          {prelude}
          {nameField}
          {emailField}
          {passwdField1}
          {passwdField2}
          <Button label={trSignup} labelClass='signup-button-label' class={`submit-button signup${disabled}${buttonPos}`} action={onSignup} />
          {haveAccountButton}
        </Stack>
      </LandingStandout>
      {spinOverlay}
      {alertDialog}
    </>
  return signup
}
