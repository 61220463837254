import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAPI } from '../Core/app/API'
import { useAuth } from '../Auth'
import { useTranslate } from '../Translation'
import { learnerSelector } from '../Core/app/store'
import { loginHappened } from '../Core/app/slices/autoMagicSlice'
import { resetProgress, setLastInterjection, resetAtSection } from '../Core/app/slices/learnerSlice'
import { useConfirmDialog } from '../Hooks/ConfirmDialog'
import { useEmailFormField, usePasswordFormField } from '../Hooks/FormFields'
import Box from 'ssi-react-lib/Box'
import Button from 'ssi-react-lib/Button'
import Dropdown from '../Widgets/Dropdown'
import Floating from '../Widgets/Floating'
import Row from 'ssi-react-lib/Row'
import Stack from 'ssi-react-lib/Stack'
import TranslatedText from '../Widgets/TranslatedText'
import './Demo.css'

export default function Demo({display})
{
  const dispatch = useDispatch()
  const api = useAPI()
  const auth = useAuth()
  const translate = useTranslate()

  const learningSettings = useSelector(learnerSelector)

  const [interjectionIndex, setInterjectionIndex] = useState(1)
  const [blockItems, setBlockItems] = useState([])
  const [blockIdx, setBlockIdx] = useState(null)
  const [iuItems, setIuItems] = useState([])
  const [showConfirm, confirmDialog] = useConfirmDialog(translate('yes'), translate('cancel'))

  const onReset = (cancelled) =>
  {
    if (cancelled) return;
    void dispatch(resetProgress())
  }

  const onConfirmReset = () =>
  {
    showConfirm(translate('cfmRstProgress'), onReset)
  }

  const onResetOnboarded = async () =>
  {
    await api.resetOnboard()
  }

  const interjectionItems = [...Array(50)].map((_,i) => { return { label: i, meta: i } })

  const onSetInterjectionIndex = (idx) =>
  {
    setInterjectionIndex(idx)
  }

  const onSetLastInterjection = () =>
  {
    dispatch(setLastInterjection(interjectionIndex))
  }

  // block index
  const updateBlocks = async () =>
  {
    if (!learningSettings.course) return;
    const resp = await api.getBlocks(learningSettings.course)
    if (!resp) return;
    const items = [...Array(resp)].map((_,i) => { return { label: i+1, meta: i+1 } })
    setBlockItems(items)
  }
  useEffect(() =>
  {
    updateBlocks()
  }, [learningSettings.course]) // eslint-disable-line react-hooks/exhaustive-deps -- updateBlocks

  const onSelectBlockIdx = async (idx) =>
  {
    if (!idx) return;
    setBlockIdx(idx)
  }

  // IU
  const updateIUs = async () =>
  {
    if (!learningSettings.course || !blockIdx) return;
    const resp = await api.getIUs(learningSettings.course, blockIdx)
    if (!resp) return;
    const items = resp.map(iu => { return { label: iu.phrase, meta: iu.uuid } })
    setIuItems(items)
  }
  useEffect(() =>
  {
    updateIUs()
  }, [learningSettings.course, blockIdx]) // eslint-disable-line react-hooks/exhaustive-deps -- updateIUs

  const onSelectIU = async (uuid) =>
  {
    if (!uuid) return;
    void dispatch(resetAtSection(uuid))
  }

  const polymorph = auth.hasAttribute('polymorph') ?
    <Polymorph /> : null

  const displayIfHaveAcess = display && blockItems.length > 0
  const demo =
    <>
      <Floating display={displayIfHaveAcess}>
        <Box className='demo-box'>
          <Button class='submit-button' action={onResetOnboarded} label='Reset Onboarded' />
          <Button class='submit-button' action={onConfirmReset} label='Reset Progress' />
          <Row className='demo-row'>
            <Button class='submit-button' action={onSetLastInterjection} label='Set Last Interjection' />
            <Dropdown menuItems={interjectionItems} onSelect={onSetInterjectionIndex} />
          </Row>
        </Box>
        <Box className='demo-box'>
          <Row className='demo-row'>
            <Dropdown menuItems={blockItems} selected={blockIdx} onSelect={onSelectBlockIdx} />
            <span>Block Number</span>
          </Row>
          <Row>
            <Dropdown className='iu-dropdown' menuItems={iuItems} nothingSelected='choose a block' onSelect={onSelectIU} />
          </Row>
        </Box>
        {polymorph}
      </Floating>
      {confirmDialog}
    </>
  return demo
}

function Polymorph()
{
  const dispatch = useDispatch()
  const api = useAPI()
  const navigate = useNavigate()
  const translate = useTranslate()

  const [email, emailField] = useEmailFormField({})
  const [passwd, passwdField] = usePasswordFormField({})

  const onLogin = async (x) =>
  {
    const user = await api.polyLogin(email, passwd)
    if (user)
    {
      dispatch(loginHappened(user))
      navigate('/app')
    }
  }

  const polymorph =
    <Box className='demo-box'>
      <Stack>
        <div className='polymorph-title'>Log-in as:</div>
        <div>
          <TranslatedText class='login-field-label' translationKey='email' />
          {emailField}
        </div>
        <div>
          <div className='login-field-label'><em><b>Your</b></em> Password</div>
          {passwdField}
        </div>
        <Button label={translate('login')} labelClass='login-button-label' class='submit-button login wide' action={onLogin} />
      </Stack>
    </Box>
  return polymorph
}
