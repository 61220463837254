import { useState } from 'react'
import { useAPI } from '../Core/app/API'
import joinedClassName from 'ssi-react-lib/joinedClassName'
import { useEditFieldDialog } from '../Hooks/EditFieldDialog'
import { usePopAndFade } from '../Widgets/PopAndFade'
import CenterNotice from 'ssi-react-lib/CenterNotice'
import CopyEmailAction from '../Actions/CopyEmailAction'
import CopyInviteAction from '../Actions/CopyInviteAction'
import DropDownIconBox from '../Widgets/DropDownIconBox'
import EditInviteAction from '../Actions/EditInviteAction'
import LabelledBoxWithPlus from 'ssi-react-lib/LabelledBoxWithPlus'
import List from 'ssi-react-lib/List'
import ResendInviteAction from '../Actions/ResendInviteAction'
import SendIcon from 'ssi-react-lib/Icons/SendIcon'
import TrashEntityAction from '../Actions/TrashEntityAction'
import './PersonList.css'

export default function PersonList({className, label, roles, people, invited, emptyMessage, personLayout, personDropLayout, inviteDropLayout, getEmail, addPeople, group, relnDescription, disableTrash, refreshGroup, refreshInvites, showAlert, showConfirm})
{
  const api = useAPI()
  const [selected, setSelected] = useState(null)
  const [popNFade, popNFadeDialog] = usePopAndFade(2000)

  const onClick = (uuid) =>
  {
    if (uuid === selected)
    {
      setSelected(null)
    } else {
      setSelected(uuid)
    }
  }

  const deletePerson = async (uuid) =>
  {
    const person = people.find(g => g.uuid === uuid)
    const confirmMessage = `Are you sure you want to remove “${person.name}” ${relnDescription}?`
    const onConfirm = async (cancelled) =>
    {
      if (cancelled) return;
      const error = await api.removeFromGroup(uuid, roles, group.uuid)
      if (error)
      {
        showAlert(error.reason)
        return
      }
      refreshGroup()
    }
    showConfirm(confirmMessage, onConfirm)
  }

  const deleteInvite = async (guid) =>
  {
    await api.deleteInvite(group.uuid, guid)
    refreshInvites()
  }

  const persLayout = pers => personLayout ? personLayout(pers) :
    <>
      <div className='person-name'>{pers.name}</div>
      <div className='person-email'>{pers.email}</div>
    </>
  const persDropLayout = pers => personDropLayout ? personDropLayout(pers, deletePerson, popNFade) :
    <>
      <CopyEmailAction getEmail={getEmail} popNFade={popNFade}/>
      {!disableTrash ? <TrashEntityAction deleteEntity={deletePerson}/> : null}
    </>
  const items = people.map(pers =>
  {
    const isSelected = selected && pers.uuid === selected
    const controls =
      <DropDownIconBox active={isSelected}>
        {persDropLayout(pers)}
      </DropDownIconBox>
    const contents =
      <div className='person-row-box'>
        <div className='person-row'>
          {persLayout(pers)}
        </div>
        {controls}
      </div>
    return { label: pers.name, key: pers.uuid, meta: pers.uuid, className: isSelected ? 'pl-selected' : null, contents: contents }
  })
  const itemsSorted = items.sort((a, b) =>
  {
    return a.label.localeCompare(b.label)
  })

  const onSubmitEditInvite = async (email, guid) =>
  {
    await api.editInvite(group.uuid, guid, email)
    refreshInvites()
  }
  const [editInvite, editInviteDialog] = useEditFieldDialog({
                                                              title: 'Edit Invite',
                                                              description: 'enter the updated email address:',
                                                              placeholder: 'email address',
                                                              icon: <SendIcon fgColor={"white"}/>,
                                                              onSubmit: onSubmitEditInvite
                                                            })

  const invtLayout = inviteDropLayout ? inviteDropLayout(editInvite, popNFade) :
    <>
      <CopyInviteAction popNFade={popNFade} />
      <ResendInviteAction groupId={group?.uuid} roles={roles} api={api} popNFade={popNFade} />
      <EditInviteAction editInvite={editInvite} />
      {!disableTrash ? <TrashEntityAction deleteEntity={deleteInvite} /> : null}
    </>
  const invitedItems = invited ? invited.filter(inv =>
  {
    return inv.email !== '' && roles.every(rl => inv.roles.includes(rl)) //  inv.roles.find(r => r === personType)
  }).map(inv =>
  {
    const isSelected = selected && inv.uuid === selected
    const controls =
      <DropDownIconBox active={isSelected}>
        {invtLayout}
      </DropDownIconBox>
    const contents =
      <div className='person-row-box'>
        <div className='person-row'>
          <div className='invite-person-email'>{inv.email}</div>
        </div>
        {controls}
      </div>
    return { label: inv.email, key: inv.uuid, meta: inv.uuid, className: isSelected ? 'pl-selected deemphasized' : 'deemphasized', contents: contents }
  }).sort((a, b) =>
  {
    return a.label.localeCompare(b.label)
  }) : []

  const allItems = itemsSorted.concat(invitedItems)
  const list = allItems.length > 0 ?
    <List className='person-list entity' onClick={onClick}>
      {allItems}
    </List> : null
  const emptyList = emptyMessage ?
    <CenterNotice>
      {emptyMessage}
    </CenterNotice> : null
  const wrappedList = list ?? emptyList

  const result =
    <>
      <LabelledBoxWithPlus className={joinedClassName('person-list-box', className)} label={label} onAdd={addPeople}>
        {wrappedList}
      </LabelledBoxWithPlus>
      {editInviteDialog}
      {popNFadeDialog}
    </>
  return result
}
