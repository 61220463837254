// eslint-disable-next-line import/no-anonymous-default-export
export default `#version 300 es
  precision mediump float;

  in vec3 position;
  in vec2 texCoord;

  out vec2 vTexCoord;

  uniform mat4 transformation;

  void main(void) {
    gl_Position = transformation * vec4(position, 1.0);
    vTexCoord = vec2(texCoord.s, texCoord.t);
  }
`;
