import { createContext, useCallback, useContext, useState, useEffect } from 'react';
import { useAuth } from './Auth';
import { automagickEndpoint } from "./Core/app/API";

let TranslationContext = createContext(null)

export function TranslationProvider(props) {
  const [dictionary, setDictionary] = useState(null)
  const auth = useAuth()
  const lang = auth.user()?.language?.iso639 ?? 'en'
  let locale = lang
  // eslint-disable-next-line default-case
  switch (lang)
  {
      case 'prs': locale = 'fa-AF'; break;
      case 'pus': locale = 'ps-AF'; break;
  }

  useEffect(() => {
    (async () =>
     {
      const response = await fetch(automagickEndpoint(`translations/${lang}`))
      if (!response.ok) {
        setDictionary({}) // this will at least render the keys
      } else {
        const dict = await response.json()
        setDictionary(dict)
      }
    })()
  }, [lang])
  const value = { dictionary: dictionary, locale: locale }
  const prov =
    <TranslationContext.Provider value={value}>
      {props.children}
    </TranslationContext.Provider>
  return prov
}

export function useTranslations() {
  return useContext(TranslationContext)
}

export function useTranslate() {
  const translations = useContext(TranslationContext)
  const translate = useCallback(key => {
    const dict = translations.dictionary
    const translation = dict ? (dict[key] ?? key) : key
    const translation2 = translation.replace(/%(\w+)%/g, (match, key, offset, input) =>
    {
      return dict ? (dict[key] ?? match) : match
    })
    // console.log('translating', key)
    // console.log('to         ', translation)
    return translation2
  }, [translations.dictionary])
  return translate
}
