import { useNavigate } from 'react-router-dom'
import { useAuth } from '../Auth'
import joinedClassName from 'ssi-react-lib/joinedClassName'
import CenterNotice from 'ssi-react-lib/CenterNotice'
import CloseButton from 'ssi-react-lib/CloseButton'
import InfoIcon from 'ssi-react-lib/Icons/Info'
import LabelledBox from 'ssi-react-lib/LabelledBox'
import LabelWithNumber from './LabelWithNumber'
import List from 'ssi-react-lib/List'
import PlayIcon from 'ssi-react-lib/Icons/Play'
import './ClassList.scss'

export default function ClassList({className, groupTree, groupId, label, emptyMessage, onDragAndDrop, onPlay, onRemove})
{
  const navigate = useNavigate()
  const auth = useAuth()
  const user = auth.user()

  let grpList = []
  function getGroups(tree)
  {
    const matching = tree.attributes.find(attr => attr.uuid === user.uuid)
    if (matching)
    {
      grpList.push(tree)
    }
    for (const group of tree.groups)
    {
      getGroups(group)
    }
  }
  if (groupTree)
  {
    for (const group of groupTree.groups)
    {
      getGroups(group)
    }
  }

  const onInfo = (e) =>
  {
    e.stopPropagation()
    const row = e.target.closest('.list-elem')
    if (!row) return;
    const uuid = row.dataset.meta
    const link = `/app/groups/class/${groupId}/${uuid}`
    navigate(link)
  }

  const localPlay = (learner) => async (e) =>
  {
    e.stopPropagation()
    const row = e.target.closest('.list-elem')
    if (!row) return;
    const uuid = row.dataset.meta
    if (onPlay) onPlay(learner, uuid);
  }

  const onRemoveClass = (uuid) => () =>
  {
    if (onRemove) onRemove(uuid)
  }

  const groupItems = grpList.slice().sort((a, b) => a.name.localeCompare(b.name)).map(cls =>
  {
    const detail =
      <div className='class-detail-box'>
        <InfoIcon class='class-info' onClick={onInfo}/>
        {cls.learners.length > 0 ? <PlayIcon class='class-play' onClick={localPlay(cls.learners[0])}/> : null}
      </div>
    const contents =
      <div className='class-box outlined'>
        <LabelWithNumber label={cls.name} number={cls.users.length} singular='student' plural='students' />
        {detail}
        <CloseButton onClick={onRemoveClass(cls.uuid)} />
      </div>
    return { label: cls.name, contents: contents, key: cls.uuid, meta: cls.uuid }
  })
  const groupList = groupItems.length > 0 ?
    <List className='class-list'>
      {groupItems}
    </List> : null
  const emptyList = emptyMessage ?
    <CenterNotice>
      {emptyMessage}
    </CenterNotice> : null
  const classList = groupList ?? emptyList

  let lastEntered = null
  let internalEnter = false
  const draggy = onDragAndDrop ?
  {
    'onDragEnter': e =>
    {
      if (e.target.classList.contains('dragging')) return;
      if (!lastEntered)
      {
        const box = e.target.closest('.labelled-box')
        box.classList.add('dragndrop-hilight')
      } else {
        internalEnter = true
      }
      lastEntered = e.target
    },
    'onDragLeave': e =>
    {
      if (internalEnter)
      {
        internalEnter = false
        return
      }
      const box = e.target.closest('.labelled-box')
      box.classList.remove('dragndrop-hilight')
      lastEntered = null
      internalEnter = false
    },
    'onDragOver': e =>
    {
      e.preventDefault()
    },
    'onDrop': e =>
    {
      const draggedItem = e.dataTransfer.getData('text')
      const box = e.target.closest('.labelled-box')
      box.classList.remove('dragndrop-hilight')
      onDragAndDrop(draggedItem)
      lastEntered = null
      internalEnter = false
    }
  } : null

  const list =
    <LabelledBox className={joinedClassName('class-list-box', className)} label={label} addtlAttrs={draggy}>
      {classList}
    </LabelledBox>
  return list
}
