import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { autoMagicSelector } from '../Core/app/store'
import { endpointForAttribute } from '../Core/SpecialRoles'
import { useGroups } from '../Hooks/Groups'
import { useSpinner } from '../Hooks/Spinner'
import List from 'ssi-react-lib/List'
import './Groups.scss'

export default function Groups()
{
  const navigate = useNavigate()
  const [startSpinning, stopSpinning, spinOverlay] = useSpinner()
  const groups = useGroups(startSpinning, stopSpinning)
  const autoMagicSettings = useSelector(autoMagicSelector)

  const items = (groups?.map(group =>
  {
    return { label: group.name, key: group.uuid, meta: group.uuid }
  }) ?? []).sort((a,b) => { return a.label.localeCompare(b.label) })

  const onGroupClick = (uuid) =>
  {
    // navigate(`/app/groups/group/${uuid}`)
    const attrs = autoMagicSettings.user?.attributes ?? []
    const attr = attrs.find(attr => attr.attribute.startsWith('role:') && attr.uuid === uuid)
    const endpoint = endpointForAttribute(attr, '/app/groups') ?? `/app/groups/group/${uuid}`
    navigate(endpoint)
  }

  const header = <span>My Groups</span>
  const body = items && items.length > 0 ?
    <List className='groups-list' header={header} onClick={onGroupClick}>
      {items}
    </List> : null;

  const dialog =
    <div className='groups-container'>
      {body}
      {spinOverlay}
    </div>
  return dialog
}
