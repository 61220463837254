import React from 'react'
import { setSessionError } from './app/slices/autoMagicSlice'
import { useAPI } from "./app/API"
import { Assets } from './Assets'

let MediaContext = React.createContext(null)

export default function MediaProvider(props)
{
    const api = useAPI()
    let cache = new Map()
    const later = (delay) =>
        new Promise(resolve => setTimeout(resolve, delay))

    const loader = {
        cache: cache,
        load: async function(url, pre)
        {
            if (!url) return null;
            // console.log(pre ? 'pre-load' : 'load', url)
            const key = url
            const val = cache.get(key)
            if (val) {
                if ('url' in val) {
                    return val.url
                }
                if ('loading' in val) {
                    const promise = new Promise(function(resolve, reject) {
                        if ('promises' in val) {
                            val.promises.push(resolve)
                        } else {
                            val.promises = [resolve]
                        }
                    })
                    const url = await promise
                    return url
                }
                // it's not loading, but there's no data, so must have failed .. try again?
                // console.log('no url and no loading ...')
            } else {
                // console.log('loading...', url)
                let vl = { loading: true }
                cache.set(key, vl)
                let attempt = 0
                const delays = [0.5, 1, 3]
                while (attempt < delays.length) {
                    const result = await api.fetchMedia(url)
                    if (!('loading' in vl)) return; // we've been cancelled
                    if ('blob' in result)
                    {
                        const blobUrl = URL.createObjectURL(result.blob)
                        vl.url = blobUrl
                        // console.log(`map ${key} ⟹ ${blobUrl}`)
                        delete vl.loading
                        if ('promises' in vl) {
                            while (vl.promises.length > 0) {
                                const f = vl.promises.shift()
                                f(blobUrl)
                            }
                        }
                        return blobUrl
                    }
                    const response = result.response
                    if (response.status === 404) {
                        const mesg = result.info?.reason ?? 'Media not found'
                        api.dispatch(setSessionError(mesg))
                        return null // don't persist in trying
                    }
                    if ('error' in result) {
                        console.log('error', result.error)
                    }
                    // console.log('delaying...', delays[attempt])
                    await later(delays[attempt])
                    // try again...
                    attempt++
                }
            }
            return null
        },
        prune: function()
        {
            // only call this when we know that we don't have any outstanding cache values "handed out"
            if (cache.size > 28) {
                let count = cache.size - 28
                // console.log(`pruning ${count} items`)
                for (const [key, kval] of cache) {
                    if (key === Assets.silenceURL || key === Assets.coHostIdleURL || key === Assets.presenterIdleURL || 'loading' in kval) continue;
                    // console.log(`pruning ${kval.url}`)
                    URL.revokeObjectURL(kval.url)
                    cache.delete(key)
                    if (--count <= 0) break;
                }
            }
        },
        clear: function()
        {
            console.log('clearing cache')
            for (const [key, val] of cache) {
                if ('loading' in val) {
                    delete val.loading
                } else {
                    URL.revokeObjectURL(val.url)
                }
                cache.delete(key)
            }
        }
    }

    const prov =
      <MediaContext.Provider value={loader}>
        {props.children}
      </MediaContext.Provider>

    return prov
}

export function useMedia() {
  return React.useContext(MediaContext)
}
