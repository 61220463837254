import { useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useAPI } from '../Core/app/API'
import { useTranslate } from '../Translation'
import { sessionSelector } from '../Core/app/store'
import { resetSessionState, setIsPlaying } from '../Core/app/slices/session/sessionSlice'
import Button from 'ssi-react-lib/Button'
import GoBackwardIcon from 'ssi-react-lib/Icons/GoBackward'
import GoForwardIcon from 'ssi-react-lib/Icons/GoForward'
import IconWithLabel from 'ssi-react-lib/IconWithLabel'
import './RevisitSkip.css'

export default function RevisitSkip({revisitDisabled, skipDisabled, setSkipRevisitWorking})
{
  const dispatch = useDispatch()
  const store = useStore()
  const api = useAPI()
  const translate = useTranslate()
  const session = useSelector(sessionSelector)
  const [waitingForRevisit, setWaitingForRevisit] = useState(false)
  const [waitingForSkip, setWaitingForSkip] = useState(false)

  const revisitIcon =
    <IconWithLabel label={translate('revisit')}>
      <GoBackwardIcon />
    </IconWithLabel>
  const skipIcon =
    <IconWithLabel label={translate('skip')}>
      <GoForwardIcon />
    </IconWithLabel>

  const revisit = async () =>
  {
    setWaitingForRevisit(true)
    setSkipRevisitWorking(true)
    const isPlaying = session.isPlaying
    dispatch(setIsPlaying(false))
    dispatch(resetSessionState())
    await api.revisit(store, isPlaying, () =>
    {
      setWaitingForRevisit(false)
      setSkipRevisitWorking(false)
    })
  }

  const skip = async () =>
  {
    setWaitingForSkip(true)
    setSkipRevisitWorking(true)
    const isPlaying = session.isPlaying
    dispatch(setIsPlaying(false))
    dispatch(resetSessionState())
    await api.skip(store, isPlaying, () =>
    {
      setWaitingForSkip(false)
      setSkipRevisitWorking(false)
    })
  }

  const panel =
    <div className='revisitskip-container'>
      <div className='revisitskip-box'>
        <Button icon={revisitIcon} disabled={waitingForSkip || revisitDisabled} action={revisit} />
        <Button icon={skipIcon} disabled={waitingForRevisit || skipDisabled} action={skip} />
      </div>
    </div>
  return panel
}
