import './CircularProgressBar.scss'

export default function CircularProgressBar({ strokeWidth, sqSize, animationDuration, percentage })
{
  const radius = (sqSize - strokeWidth) / 2
  const viewBox = `0 0 ${sqSize} ${sqSize}`
  const dashLength = 100
  const dashOffset = dashLength - percentage;

  const attrs = animationDuration ? { style : { transition: `stroke-dashoffset ${animationDuration}s linear`} } : null
  const cp =
    <svg className='circular-progress' viewBox={viewBox}>
      <circle
        className='circular-progress-background'
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
      />
      <circle
        className='circular-progress-foreground'
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        pathLength={100}
        strokeDasharray={dashLength}
        strokeDashoffset={dashOffset}
        {...attrs}
      />
    </svg>
  return cp
}
