import React from 'react'
import { useEffect, useCallback, useLayoutEffect, useRef, useState } from 'react'
import { useAuth } from '../Auth'
import joinedClassName from 'ssi-react-lib/joinedClassName'
import './Dropdown.css'

export default function Dropdown(props)
{
  const auth = useAuth()
  const direction = auth.user()?.language?.direction ?? 'ltr'

  // const firstRender = useRef(true)
  const dropListRef = useRef()
  const initialSelected = props.selected !== undefined ? props.selected : ((props.menuItems !== undefined && props.menuItems !== null) ? props.menuItems[0]?.label : <span className='dropdown-loading'>loading…</span>)
  const [dropped, setDropped] = useState(false)
  const [dropListHeight, setDropListHeight] = useState(null)
  const [selected, setSelected] = useState(initialSelected)
  useEffect(() =>
  {
    setSelected(props.selected)
  }, [props.selected])
  useEffect(() =>
  {
    if (!props.menuItems) return;
    // We really do want `==' here because the label might be an integer unintentionally rendered as a string
    if (selected && props.menuItems.find(i => i.label == selected))
    {
      // menuItems changed, but the selected item is still in the list.. do nothing
      return
    }
    const deflt = props.menuItems[0]?.label
    setSelected(deflt)
  }, [props.menuItems, selected])
  const displaySelected = selected ?? <span className='dropdown-loading'>{props.nothingSelected}</span>

  const entries = props.menuItems.map(item => {
    const seld = item.label === selected ? ' w--current' : ''
    const meta = JSON.stringify(item.meta)
    const entry = <div className={`block-entry w-dropdown-link${seld}`} dir={direction} key={item.key} data-meta={meta} >{item.label}</div>
    return entry
  })

  const toggleClass = dropped ? 'open' : null
  const wtoggleClass = dropped ? 'w--open' : null

  const menuToggle = () =>
  {
    setDropped(!dropped)
  }

  const menuClick = (ev) =>
  {
    const targ = ev.target.closest('.w-dropdown-link')
    const label = targ.innerText
    setDropped(false)
    setSelected(label)
    // eslint-disable-next-line eqeqeq -- intentionally == to simplify handling when one is Int and one is String
    const item = props.menuItems.find(itm => itm.label == label)
    if (item) props.onSelect(item.meta)
  }

  const updateHeight = useCallback(() =>
  {
    if (!dropListRef.current) return;
    const dropList = dropListRef.current
    const rect = dropList.getBoundingClientRect()
    let viewBottom = window.innerHeight || document.documentElement.clientHeight
    if (viewBottom > 6) viewBottom -= 6;
    if (rect.bottom > viewBottom)
    {
      // must do scrolling ...
      const height = viewBottom - rect.top
      if (height !== dropListHeight) setDropListHeight(height);
    }
  }, [dropListHeight])
  useLayoutEffect(() =>
  {
    updateHeight()
  })
  useEffect(() => {
    const onResize = () => setDropListHeight(null)
    window.addEventListener('resize', onResize, { passive: true});
    return () => window.removeEventListener('resize', onResize)
  }, [updateHeight])

  /*
  useEffect(() =>
  {
    // This effect is used to fire off the callback, so we don't want it triggering at setup time
    // THIS DOESN'T WORK ...
    if (firstRender.current) { firstRender.current = false; return }
    if (props.onSelect == null) return;
    const item = props.menuItems.find(itm => itm.label === selected)
    if (!item) return;
    props.onSelect(item.meta)
  }, [selected]) */

  const otherClick = () => { setDropped(false) }

  const dropListStyle = dropListHeight ? { height: dropListHeight + 'px' } : null
  const dropdown =
    <div className={joinedClassName('dropdown w-dropdown', toggleClass, props.className)}>
      <div className={joinedClassName('dropdown-toggle x-dropdown-toggle', wtoggleClass)} dir={direction} onClick={menuToggle}>
        <div className='w-icon-dropdown-toggle'></div>
        {displaySelected}
      </div>
      <div className={joinedClassName('dropdown-back', toggleClass)} onClick={otherClick}></div>
      <nav ref={dropListRef} className={joinedClassName('w-dropdown-list', wtoggleClass)} style={dropListStyle} onClick={menuClick}>
        <div className='dropdown-menu-entries'>
          {entries}
        </div>
      </nav>
    </div>
  return dropdown
}
