import SVGIcon from './SVGIcon'

export default function ReportIcon({className, color, onClick})
{
  return <SVGIcon
          name='report'
          className={className}
          size='65.04 82.32'
          color={color}
          onClick={onClick}
          paths={[
            'M0,69.87c0,8.35,4.05,12.45,12.26,12.45H52.73c8.2,0,12.3-4.1,12.3-12.45V12.45c0-8.3-4.1-12.45-12.3-12.45H12.26C4.05,0,0,4.15,0,12.45v57.42Zm7.03-.1V12.55c0-3.52,1.9-5.52,5.62-5.52H52.39c3.66,0,5.57,2,5.57,5.52v57.23c0,3.52-1.9,5.52-5.57,5.52H12.65c-3.71,0-5.62-2-5.62-5.52Zm11.33-33.25h28.37c1.51,0,2.64-1.22,2.64-2.73,0-1.42-1.12-2.59-2.64-2.59H18.36c-1.56,0-2.69,1.17-2.69,2.59,0,1.51,1.12,2.73,2.69,2.73Zm0-13.33h28.37c1.51,0,2.64-1.17,2.64-2.69s-1.12-2.59-2.64-2.59H18.36c-1.56,0-2.69,1.12-2.69,2.59s1.12,2.69,2.69,2.69Zm0,26.61h13.28c1.51,0,2.64-1.12,2.64-2.59s-1.12-2.69-2.64-2.69h-13.28c-1.56,0-2.69,1.17-2.69,2.69s1.12,2.59,2.69,2.59Z'
            ]}
          />
}
