import SVGIcon from './SVGIcon'

export default function LearnerIcon({className, color, onClick})
{
  return <SVGIcon
          name='learner'
          className={className}
          size='67.38 71.48'
          color={color}
          onClick={onClick}
          paths={[
            'M10.06,71.48H57.32c6.84,0,10.06-2,10.06-6.4,0-10.06-12.7-24.76-33.69-24.76S0,55.03,0,65.09c0,4.39,3.27,6.4,10.06,6.4Zm23.63-35.64c9.23,0,16.8-8.2,16.8-18.16S43.02,0,33.69,0,16.89,7.91,16.89,17.77s7.62,18.07,16.8,18.07ZM7.86,64.89c-.54,0-.83-.15-.83-.68,0-5.71,9.38-17.29,26.66-17.29s26.66,11.57,26.66,17.29c0,.54-.29,.68-.83,.68H7.86ZM33.69,29.3c-5.18,0-9.77-5.13-9.77-11.52s4.44-11.18,9.77-11.18,9.77,4.69,9.77,11.08-4.49,11.62-9.77,11.62Z'
            ]}
          />
}
