import TimeForwardIcon from 'ssi-react-lib/Icons/TimeForward'

export default function AdvanceProgressAction({advanceProgress})
{
  const onAdvance = async (e) =>
  {
    e.preventDefault()
    e.stopPropagation()
    const row = e.target.closest('.list-elem')
    const guid = row.dataset.meta
    await advanceProgress(guid)
  }

  const icon = <TimeForwardIcon className='admin-advance-progress' onClick={onAdvance}/>
  return icon
}
