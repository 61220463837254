import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslate } from '../../Translation'
import { useAuth } from '../../Auth'
import { autoMagicSelector, learnerSelector } from '../../Core/app/store'
import { FixedCourses, setVideoHidden, setTextHidden } from '../../Core/app/slices/learnerSlice'
import { useAlertDialog } from '../../Hooks/AlertDialog'
import { useSpinner } from '../../Hooks/Spinner'
import HowAreYouFeeling from '../../Widgets/HowAreYouFeeling'
import LearnerChooser from '../../Widgets/LearnerChooser'
import Toggle from '../../Widgets/Toggle'
import { TranslatedSpan } from '../../Widgets/TranslatedText'
import './Learning.css'

export default function UserLearning()
{
  const dispatch = useDispatch()
  const translate = useTranslate()
  const auth = useAuth()
  const [showAlert, alertDialog] = useAlertDialog(translate('ok'))
  const [startSpinning, stopSpinning, spinOverlay] = useSpinner(translate('loading'))

  const autoMagicSettings = useSelector(autoMagicSelector)
  const settings = useSelector(learnerSelector)
  const displayVideo = !settings.videoHidden
  const displayText = !settings.textHidden
  const onToggleVideo = () =>
  {
    dispatch(setVideoHidden({hidden: displayVideo, push: true}))
  }
  const onToggleText = () =>
  {
    dispatch(setTextHidden({hidden: displayText, push: true}))
  }

  useEffect(() =>
  {
    if (autoMagicSettings.settingUp)
    {
      startSpinning()
    } else
    {
      stopSpinning()
    }
  }, [autoMagicSettings.settingUp]) // eslint-disable-line react-hooks/exhaustive-deps -- startSpinning, stopSpinning

  const onChooserError = (mesg) =>
  {
    showAlert(mesg, () =>
    {
      window.location.reload()
    })
  }

  /*
  const onReset = (cancelled) =>
  {
    if (cancelled) return;
    void dispatch(resetProgress())
  }
  const onConfirmReset = () =>
  {
    showConfirm(translate('cfmRstProgress'), onReset)
  }
  const resetControlBody =
    <div className='user-settings-box below'>
      <Button name='reset' class='submit-button' action={onConfirmReset} label='Reset Progress' />
    </div> */
  const resetControl = null // resetControlBody

  // Add back later ...
  // <LanguageChooser onSelect={onChangeLanguage} />

  const videoToggle = settings.course !== FixedCourses.SaySomethinginSpanish ?
    <div className='settings-box below settings-box-toggle'>
      <Toggle value={displayVideo} action={onToggleVideo} />
      <TranslatedSpan className='settings-label' translationKey='showVideo' />
    </div> : null

  const attrs = auth.user()?.attributes ?? []
  const useRevisitSkip = true // attrs.find(attr => attr.attribute === 'revisitSkip')
  const howAreYouFeeling = useRevisitSkip ?
    <div className='settings-box below'>
      <HowAreYouFeeling />
    </div> : null
  const learning =
    <>
      <div className='settings-box below'>
        <LearnerChooser withCourseChooser onError={onChooserError} />
      </div>
      {videoToggle}
      <div className='settings-box below settings-box-toggle'>
        <Toggle value={displayText} action={onToggleText} />
        <div className='settings-label'>
          <span>{translate('showText')}</span>
        </div>
      </div>
      {howAreYouFeeling}
      {resetControl}
      {spinOverlay}
      {alertDialog}
    </>
  return learning
}
