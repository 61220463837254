export default function CheckBoxIcon(props) {
  const color = props.color != null ? props.color : "currentColor"
  let icon =
    <svg className={`icon checkbox-icon ${props.class}`} viewBox="0 0 71.14 71.19" onClick={props.onClick}>
      <path fill={color}
        d="M12.45,71.19H58.69c8.35,0,12.45-4.1,12.45-12.3V12.3c0-8.2-4.1-12.3-12.45-12.3H12.45C4.15,0,0,4.1,0,12.3V58.89c0,8.2,4.15,12.3,12.45,12.3Zm.1-7.08c-3.52,0-5.52-1.86-5.52-5.57V12.65c0-3.66,2-5.57,5.52-5.57H58.59c3.52,0,5.52,1.9,5.52,5.57V58.54c0,3.71-2,5.57-5.52,5.57H12.55Z"/>
      <path className={`checkbox-check${props.checkboxClass}`} fill={color}
        d="M31.4,53.71c1.46,0,2.69-.68,3.52-2.05l16.8-26.56c.54-.88,1.07-1.86,1.07-2.83,0-1.95-1.71-3.17-3.56-3.17-1.12,0-2.2,.63-2.98,1.95l-15.04,24.32-6.74-9.08c-1.03-1.42-2.05-1.76-3.22-1.76-1.9,0-3.37,1.51-3.37,3.47,0,.93,.39,1.86,.98,2.69l8.79,10.99c1.12,1.46,2.29,2.05,3.76,2.05Z"/>
    </svg>
  return icon
}
