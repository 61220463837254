import { useEffect } from 'react'
import { useAPI } from '../Core/app/API'
import { useNavigate } from 'react-router-dom'
import { useTranslate } from '../Translation'
import { useSpinner } from '../Hooks/Spinner'

export default function PostExpiryAndSubscribe()
{
  const navigate = useNavigate()
  const api = useAPI()
  const translate = useTranslate()
  const [startSpinning, stopSpinning, spinOverlay] = useSpinner(translate('loading'))

  useEffect(() =>
  {
    if (startSpinning) startSpinning();
    (async () =>
    {
      await api.clearTrialExpiry() // doesn't actually clear it unless twas already cleared on rails side
      navigate('/app/learn', { replace: true })
    })()
  }, [startSpinning])

  return spinOverlay
}
