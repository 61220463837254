import joinedClassName from 'ssi-react-lib/joinedClassName'
import './AdminContainer.scss'

export default function AdminContainer({className, children})
{
  const container =
    <div className={joinedClassName('admin-container', className)}>
      {children}
    </div>
  return container
}
