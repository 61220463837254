import { useNavigate } from 'react-router-dom'
import { useTranslate } from '../Translation'
import ManagerOverview from './ManagerOverview'
import './GroupManagerOverview.scss'

export default function GroupManagerOverview({group, groups, actions, moveGroup, showConfirm})
{
  const navigate = useNavigate()
  const translate = useTranslate()

  const draggy =
  {
    'onDragEnter': e =>
    {
      const elt = e.target.closest('.path-segment')
      elt.classList.add('dragndrop-hilight')
    },
    'onDragLeave': e =>
    {
      const elt = e.target.closest('.path-segment')
      elt.classList.remove('dragndrop-hilight')
    },
    'onDragOver': e =>
    {
      e.preventDefault()
    },
    'onDrop': e =>
    {
      const draggedItem = e.dataTransfer.getData('text')
      const elt = e.target.closest('.path-segment')
      elt.classList.remove('dragndrop-hilight')
      const draggedGroup = groups.find(grp => grp.uuid === draggedItem)
      const targetGroup = group.ancestors.find(grp => grp.uuid === elt.dataset.meta)
      showConfirm(`Move "${draggedGroup.name}" to be a subfolder of "${targetGroup.name}"?`, (cancelled) =>
      {
        if (cancelled) return;
        moveGroup(draggedItem, elt.dataset.meta)
      })
    }
  }

  const nameWithContext = group ? [...(group.ancestors.slice().reverse().flatMap((a,i) => [<span key={i} className='path-segment' data-meta={a.uuid} {...draggy}>{a.name}</span>, ' ⟩ '])), group.name] : null

  const onClickPathSegment = (e) =>
  {
    e.preventDefault()
    const row = e.target.closest('.path-segment')
    if (!row) return;
    const guid = row.dataset.meta
    navigate(`../group/${guid}`)
  }

  const overview =
    <ManagerOverview className='group-manager-overview'
                     banner={nameWithContext}
                     onClickBanner={onClickPathSegment}>
      <div className='group-manager-overview-body'>
        {translate('groupMgrOverviewBody')}
      </div>
      <div className='group-actions'>
        {actions}
      </div>
    </ManagerOverview>
  return overview
}
