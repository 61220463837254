export default function SVGIcon({name, className, size, color, paths, onClick})
{
  const addtlClass = className ? ' ' + className : ''
  const svgcolor = color ?? 'currentColor'
  const svgpaths = paths.map((path,i) => <path key={i} fill={svgcolor} d={path} />)
  let icon =
    <svg className={`icon ${name}-icon${addtlClass}`} viewBox={`0 0 ${size}`} onClick={onClick}>
      {svgpaths}
    </svg>
  return icon
}
