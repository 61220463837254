import { createSlice } from '@reduxjs/toolkit'
import { logoutHappened } from './autoMagicSlice'
import { ManifestKind } from './session/sessionSlice'

export const LearningMode = {
    normal: 'normal',
    revisit: 'revisit',
    skip: 'skip',
}

export const FixedCourses = {
    SaySomethinginWelsh: '42933F3E-A4BB-4C40-8902-8C7E67182EB8',
    SaySomethinginSpanish: '19698E04-1D14-4FF0-88B9-EFD4DCC71BFA'
}

export const initialState = {
    course: null,
    section: null,
    entry: -1,
    mode: LearningMode.normal,
    pacing: 2,
    spacing: 0,
    videoHidden: true,
    textHidden: true
};

export const learnerSlice = createSlice({
    name: "learner",
    initialState,
    reducers: {
        setCourse: (state, action) => {
            state.course = action.payload.course
        },
        initCursor: (state, action) => {
            state.section = action.payload.section
            state.entry = action.payload.entry
            console.log('INTC', action.payload.mode)
            state.mode = action.payload.mode
            // console.log(`initCursor: ${state.section} / ${state.entry} / ${state.mode}`)
        },
        resetSection: (state, action) => {
            state.section = null
            state.entry = -1
            state.mode = LearningMode.normal
            console.log('REST', action.payload.mode)
        },
        setSection: (state, action) => {
            state.section = action.payload.section
            state.entry = -1
            state.mode = LearningMode.normal
            console.log('SETC', action.payload.mode)
        },
        setEntry: (state, action) => {
            state.entry = action.payload.entry
        },
        setMode: (state, action) => {
            console.log('SETMD', action.payload.mode)
            state.mode = action.payload.mode
        },
        setPacing: (state, action) => {
            const pacing = action.payload.pacing
            state.spacing = pacing
            state.reps = pacing
            state.pacing = pacing
        },
        setVideoHidden: (state, action) => {
            state.videoHidden = action.payload.hidden
        },
        setTextHidden: (state, action) => {
            state.textHidden = action.payload.hidden
        },
        incrSessionTime: (state, action) => {
            const sessionTime = state.sessionTime ?? 0
            state.sessionTime = sessionTime + action.payload
        },
        setSessionTime: (state, action) => {
            state.sessionTime = action.payload
        },
        setTotalTime: (state, action) => {
            state.totalTime = action.payload
        },
        setIntroToComplete: (state, action) => {
            state.introToComplete = action.payload
        },
        setSentenceToComplete: (state, action) => {
            state.sentenceToComplete = action.payload
        },
        setSectionToComplete: (state, action) => {
            state.sectionToComplete = action.payload
        },
        completeIntro: (state, action) => {
            state.introToComplete = null
        },
        completeSentence: (state, action) => {
            state.sentenceToComplete = null
        },
        completeSection: (state, action) => {
            state.sectionToComplete = null
        },
        // These are just triggers for actions
        resetProgress: () => { },
        setLastInterjection: () => { },
        resetAtSection: () => { }
    },
    extraReducers: builder =>
    {
        builder.addCase(logoutHappened, (state, action) =>
        {
            return initialState
        })
    }
});

export const {
    setCourse,
    initCursor,
    resetSection,
    setSection,
    setEntry,
    setMode,
    setPacing,
    setVideoHidden,
    setTextHidden,
    incrSessionTime,
    setSessionTime,
    setTotalTime,
    setIntroToComplete,
    setSentenceToComplete,
    setSectionToComplete,
    completeIntro,
    completeSentence,
    completeSection,
    resetProgress,
    setLastInterjection,
    resetAtSection
} = learnerSlice.actions;

export default learnerSlice.reducer
