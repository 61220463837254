import capabilityReducer from "./slices/capability/capabilitySlice";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import AutoMagicMiddleware from "./middleware/autoMagicMiddleware";
import PuppetMiddleware from "./middleware/puppetMiddleware";
import StateMiddleware from "./middleware/StateMiddleware";
import sessionReducer from "./slices/session/sessionSlice";
import settingsReducer from "./slices/settings/settingsSlice";
import autoMagicReducer from './slices/autoMagicSlice'
import learnerReducer from './slices/learnerSlice'

export function capabilitySelector(appState) {
    return appState.capabilty
}

export function settingsSelector(appState) {
    return appState.settings
}

export function sessionSelector(appState) {
    return appState.session
}

export function autoMagicSelector(appState) {
    return appState.autoMagic
}

export function learnerSelector(appState) {
    return appState.learner
}

export function modeSelector(appState) {
    return appState.settings.mode
}

/*
function TimingMiddleware()
{
    return store => next => action =>
    {
        performance.mark(`${action.type}_start`);
        let result = next(action);
        performance.mark(`${action.type}_end`);
        performance.measure(
                            `${action.type}`,
                            `${action.type}_start`,
                            `${action.type}_end`
                            );
        return result;
    }
} */

const makeMiddleware = (api, loader) => {
    return (getDefaultMiddleware) => [
        ...getDefaultMiddleware(),
        AutoMagicMiddleware(api, loader),
        PuppetMiddleware(api),
        StateMiddleware(api)
    ]
}

const combinedReducer = combineReducers({
    capabilty: capabilityReducer,
    settings: settingsReducer,
    session: sessionReducer,
    autoMagic: autoMagicReducer,
    learner: learnerReducer
});

// TODO: turn off before production - mostly handy for debugging...
const rootReducer = (state, action) => {
    // console.log('root', action.type)
    return combinedReducer(state, action);
};

export const makeStore = (api, loader) => configureStore({
    reducer: rootReducer,
    middleware: makeMiddleware(api, loader)
})
