import joinedClassName from './joinedClassName'
import './LabelledBox.css'

// TODO: prolly can fold into Box (where box has a label and decoration as options)
export default
function LabelledBox({className, addtlAttrs, label, decoration, children})
{
  const decor = decoration ? <div className='box-decoration'>{decoration}</div> : null
  const box =
    <div className={joinedClassName('labelled-box', className)} {...addtlAttrs}>
      <div className='box-header'>
        <div className='box-header-label'>{label}</div>
        {decor}
      </div>
      {children}
    </div>
  return box
}
