import joinedClassName from './joinedClassName'
import './CenterNotice.css'

export default
function CenterNotice({className, children}) {
  const container =
    <div className={joinedClassName('center-notice-container', className)}>
      <div className='center-notice'>
        {children}
      </div>
      <div/>
    </div>
  return container
}
