import { useEffect, useState } from 'react'
import { useAPI } from '../Core/app/API'
import { useTranslate } from '../Translation'

export function useGroupManagement(groupId, startSpinning, stopSpinning)
{
  const api = useAPI()
  const translate = useTranslate()

  const [group, setGroup] = useState(null)
  const [groupStale, setGroupStale] = useState(true)
  const [invites, setInvites] = useState(null)
  const [invitesStale, setInvitesStale] = useState(true)

  useEffect(() =>
  {
    setGroupStale(true)
  }, [groupId])

  const updateGroup = async () =>
  {
    if (!groupStale) return;
    startSpinning(translate('loading'))
    const info = await api.group(groupId)
    setGroup(info)
    setGroupStale(false)
    stopSpinning()
  }
  useEffect(() =>
  {
    updateGroup()
  }, [groupStale]) // eslint-disable-line react-hooks/exhaustive-deps -- updateGroup
  const refreshGroup = () =>
  {
    setGroupStale(true)
  }
  const updateInvites = async () =>
  {
    if (!invitesStale) return;
    startSpinning(translate('loading'))
    const invited = await api.getInvites(groupId)
    setInvites(invited)
    setInvitesStale(false)
    stopSpinning()
  }
  useEffect(() =>
  {
    updateInvites()
  }, [invitesStale]) // eslint-disable-line react-hooks/exhaustive-deps -- updateInvites
  const refreshInvites = () =>
  {
    setInvitesStale(true)
  }

  return [group, invites, refreshGroup, refreshInvites]
}

