import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useTranslate } from '../Translation'
import { useAPI } from '../Core/app/API'
import { useAlertDialog } from '../Hooks/AlertDialog'
import { useConfirmDialog } from '../Hooks/ConfirmDialog'
import { useGroupManagement } from '../Hooks/GroupManagement'
import { mkGroupAction } from './GroupAction'
import { useModal } from '../Hooks/Modal'
import { useSpinner } from '../Hooks/Spinner'
import delay from 'ssi-react-lib/delay'
import { PersonType } from '../Core/PersonType'
import AddDialog from './AddDialog'
import AddDialogComponent from './AddDialogComponent'
import AdminContainer from './AdminContainer'
import Button from 'ssi-react-lib/Button'
import CenteredThings from 'ssi-react-lib/CenteredThings'
import CopyInviteAction from '../Actions/CopyInviteAction'
import EditInviteAction from '../Actions/EditInviteAction'
import List from 'ssi-react-lib/List'
import ManagerOverview from './ManagerOverview'
import NavPanel from '../Widgets/NavPanel'
import PersonList from './PersonList'
import ReportIcon from 'ssi-react-lib/Icons/Report'
import ResendInviteAction from '../Actions/ResendInviteAction'
import './WBL.scss'

export default function WBLAdmin({waitCount})
{
  const translate = useTranslate()

  const admin =
    <NavPanel className='wbl-content' backLabel={translate('back')} backUrl={-1}>
      <WBLContent waitCount={waitCount} />
    </NavPanel>
  return admin
}

export function WBLPage()
{
  let admin =
    <div className='wbl-page'>
      <WBLContent />
    </div>
  return admin
}

function WBLContent(props)
{
  const navigate = useNavigate()
  const params = useParams()
  const [queryParams, setQueryParams] = useSearchParams() // eslint-disable-line no-unused-vars
  const translate = useTranslate()
  const api = useAPI()
  const [waitCount, setWaitCount] = useState(props.waitCount)
  const [showAlert, alertDialog] = useAlertDialog(translate('ok'))
  const [showConfirm, confirmDialog] = useConfirmDialog(translate('yes'), translate('cancel'))
  const [startSpinning, stopSpinning, spinOverlay] = useSpinner()

  const groupId = params.groupId
  const [group, invites, refreshGroup, refreshInvites] = useGroupManagement(groupId, startSpinning, stopSpinning)

  const changeSetId = queryParams.get('cs')

  useEffect(() =>
  {
    (async () =>
    {
      if (props.waitCount && !waitCount)
      {
        setWaitCount(1)
      }
      if (waitCount && changeSetId)
      {
        startSpinning()
        const paid = await api.checkPayment(groupId, changeSetId)
        if (paid === false)
        {
          await delay(2000)
          setWaitCount(waitCount + 1)
        } else
        {
          stopSpinning()
          refreshInvites()
          navigate(`/app/groups/wbl/${groupId}`)
          // navigate('..')
        }
      }
    })()
  }, [props.waitCount, waitCount, groupId, changeSetId]) // eslint-disable-line react-hooks/exhaustive-deps -- 'api', 'navigate', 'refreshGroup', 'startSpinning', and 'stopSpinning'

  const name = group ? group.name : ''
  const managers = group ? group.managers : []
  const manager_invites = invites ? invites.filter(inv => inv.roles.some(rl => rl === PersonType.manager)) : []
  const learners = group ? group.users : []
  const learner_invites = invites ? invites.filter(inv => inv.roles.some(rl => rl === PersonType.learner)) : []

  const emailTitle = 'Paste your managers’ email addresses here and we’ll send them an invitation for you:'
  const roles = [PersonType.manager, PersonType.wblManager]
  const [showAddManager, addManagerDialog] = AddDialogComponent(groupId, translate('addManagers'), roles, emailTitle, null, translate('thisGroup'), translate('managers'), translate('send'), refreshInvites, refreshGroup, translate, showAlert)
  const addManagers = () =>
  {
    showAddManager()
  }

  const [showAddDialog, dismissAddDialog, addDialog] = useModal()
  const onNext = async (emails) =>
  {
    const response = await api.preinviteWBL(groupId, emails, false)
    const preresult = response.result
    showAddDialog(<ConfirmationDialog
                   groupId={groupId}
                   toInvite={preresult.no_account.concat(preresult.no_subscription)}
                   alreadyHave={preresult.has_sub}
                   alreadyInWorkplace={preresult.already_member}
                   unaccepted_same={preresult.unaccepted_same}
                   unaccepted_other={preresult.unaccepted_other}
                   duplicates={preresult.duplicates}
                   invalids={preresult.invalids}
                   cost={preresult.cost}
                   dismiss={dismissAddDialog}
                   api={api}
                   navigate={navigate} />)
  }
  const learnerEmailTitle = 'Enter or paste your learners’ email addresses here:'
  const [showAddLearnerDialog, addLearnerDialog] = AddDialog(translate('addLearners'), [PersonType.learner], null, learnerEmailTitle, translate('emailPlaceholder'), translate('learners'), translate('next'), null, onNext)
  const addLearners = () =>
  {
    showAddLearnerDialog()
  }

  const onReport = () =>
  {
    navigate('report')
  }
  const statsAction = mkGroupAction('View statistics report', <ReportIcon class={'group-stats-report visible'} />, onReport)
  const actions = [statsAction].map((f,i) => f ? f(i) : null).filter(x => x)

  const getManagerEmail = uuid => group.managers.find(e => e.uuid === uuid)?.email
  const getUserEmail = uuid => group.users.find(e => e.uuid === uuid)?.email

  const inviteEndpoint = guid => `${process.env.REACT_APP_RAILS_ROOT}/activate/${guid}`
  const inviteDropLayout = (editInvite, popNFade) =>
    <>
      <CopyInviteAction endpoint={inviteEndpoint} popNFade={popNFade} />
      <ResendInviteAction groupId={group?.uuid} roles={[PersonType.learner]} api={api} popNFade={popNFade} />
      <EditInviteAction editInvite={editInvite} />
    </>

  const page =
    <>
      <AdminContainer className='wbl-container'>
        <PersonList className='wbl-managers'
                    label={translate('managers')}
                    roles={[PersonType.manager, PersonType.wblManager]}
                    people={managers}
                    invited={manager_invites}
                    getEmail={getManagerEmail}
                    addPeople={addManagers}
                    group={group}
                    relnDescription='as a manager'
                    refreshGroup={refreshGroup}
                    refreshInvites={refreshInvites}
                    showAlert={showAlert}
                    showConfirm={showConfirm} />
        <ManagerOverview className='wbl-overview' banner={name}>
          <div>
            {translate('wblOverviewBody')}
          </div>
          <div className='group-actions'>
            {actions}
          </div>
        </ManagerOverview>
        <PersonList className='wbl-learners'
                    label={translate('learners')}
                    roles={[PersonType.learner]}
                    people={learners}
                    invited={learner_invites}
                    emptyMessage={translate('learnersEmpty')}
                    inviteDropLayout={inviteDropLayout}
                    getEmail={getUserEmail}
                    addPeople={addLearners}
                    group={group}
                    relnDescription='as a learner'
                    disableTrash
                    refreshGroup={refreshGroup}
                    refreshInvites={refreshInvites}
                    showAlert={showAlert}
                    showConfirm={showConfirm} />
      </AdminContainer>
      {addManagerDialog}
      {addLearnerDialog}
      {addDialog}
      {spinOverlay}
      {alertDialog}
      {confirmDialog}
    </>
  return page
}

function ConfirmationDialog({groupId, toInvite, alreadyHave, alreadyInWorkplace, unaccepted_same, unaccepted_other, duplicates, invalids, cost, dismiss, api, navigate})
{
  function process(emails, description)
  {
    const items = emails.map(em =>
    {
      return { label: em, meta: em }
    })
    const toDisplay = emails.length > 0 ?
      <>
        <div>{description}</div>
        <List className='invite-email-list'>{items}</List>
      </> : null
    return toDisplay
  }
  const willInvite = process(toInvite, 'The following addresses will be sent invitations to join your workplace:')
  const willNotInvite = process(alreadyHave, 'The following addresses already have paid accounts and will not be added:')
  const willNotInvite2 = process(alreadyInWorkplace, 'The following addresses are already members of your workplace and will not be added:')
  const willNotInvite3 = process(unaccepted_same, 'The following addresses already have unnaccepted invititations to this workplace and will not be added:')
  const willNotInvite4 = process(unaccepted_other, 'The following addresses already have unnaccepted invititations to other workplaces or groups and will not be added (please discuss with your employee and/or contact support@saysomethingin.com):')
  const noteDuplicates = process(duplicates, 'The following addresses appeared more than once, but will only be counted once')
  const noteInvalids = process(invalids, 'The following addresses appear invalid and will not be added:')

  const costing = cost > 0 ?
    <div>Please check that the emails look right before proceeding to payment.<br/><br/>The total cost will be £{cost}+VAT.</div> :
    null;
  const actionLabel = cost > 0 ? 'Proceed' : 'Ok'

  const action = async () =>
  {
    dismiss()
    if (cost === 0) return;
    const emails = toInvite
    const response = await api.preinviteWBL(groupId, emails, true)
    const result = response.result
    if (result.checkout_url)
    {
      window.location.href = result.checkout_url
    }
    else
    {
      console.log('WHOA.. expected a checkout_url!')
    }
  }
  const dialog =
    <div className='confirm-dialog'>
      {willInvite}
      {willNotInvite}
      {willNotInvite2}
      {willNotInvite3}
      {willNotInvite4}
      {noteDuplicates}
      {noteInvalids}
      {costing}
      <CenteredThings>
        <Button class='submit-button' label={actionLabel} action={action} />
      </CenteredThings>
    </div>
  return dialog
}
