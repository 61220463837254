import { useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import './PopAndFade.css'

export function usePopAndFade(duration)
{
  const [display, setDisplay] = useState(false)
  const [body, setBody] = useState(null)
  const timer = useRef(null)

  const onDisplay = (body) => {
    setBody(body)
    setDisplay(true)
    timer.current = setTimeout(() => { setDisplay(false) }, duration)
  }

  const onDismiss = () =>
  {
    clearTimeout(timer.current)
    setDisplay(false)
    timer.current = null
  }

  // maybe make this a regular Modal, but parameterized on, e.g., background color
  const popnfade =
    <CSSTransition in={display} classNames='popnfade' timeout={300} unmountOnExit>
      <div className='popnfade-content' onClick={onDismiss}>
        {body}
      </div>
    </CSSTransition>
  return [onDisplay, popnfade]
}
