import { Navigate } from 'react-router-dom'
import { endpointForAttribute } from '../Core/SpecialRoles'
import { useAttributes } from '../Hooks/Attributes'
import Groups from '../Pages/Groups'

export default function GroupSwitch()
{
  const attrs = useAttributes()

  let numManagerAttrs = 0
  for (let attr of attrs)
  {
    if (attr.attribute == 'manager') numManagerAttrs++
  }
  const endpoints = attrs.reduce((result, attr) =>
  {
    const endpoint = endpointForAttribute(attr)
    return endpoint ? result.concat(endpoint) : result
  }, [])
  const isForester = attrs.find(attr => attr.attribute === 'forester')
  if (numManagerAttrs < 3 && endpoints.length === 1 && !isForester)
  {
    const endpoint = endpoints[0]
    return <Navigate to={`/app/groups${endpoint}`} />
  }
  return <Groups />
}
