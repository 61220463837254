import CopyIcon from 'ssi-react-lib/Icons/Copy'

export default function CopyEmailAction({getEmail, popNFade})
{
  const onCopyEmail = (e) =>
  {
    e.preventDefault()
    e.stopPropagation()
    const row = e.target.closest('.list-elem')
    const guid = row.dataset.meta
    const email = getEmail(guid)
    if (email)
    {
      navigator.clipboard.writeText(email)
      popNFade('Email copied to clipboard!')
    } else
    {
      popNFade('No email address found!')
    }
  }

  const icon = <CopyIcon className='person-email-copy' onClick={onCopyEmail}/>
  return icon
}
