import PencilIcon from 'ssi-react-lib/Icons/PencilIcon'

export default function EditGroupDetailsAction({editGroupDetails})
{
  const onEditInvite = (e) =>
  {
    e.preventDefault()
    e.stopPropagation()
    const row = e.target.closest('.list-elem')
    const guid = row.dataset.meta
    editGroupDetails(guid)
  }

  const icon = <PencilIcon onClick={onEditInvite} />
  return icon
}
