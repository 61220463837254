import { Assets } from '../../Assets'
import { sessionSelector, learnerSelector, autoMagicSelector } from '../store'
import { speedDescriptor } from '../slices/settings/speed'
import {
    ManifestKind,
    shiftToNextSection,
    advancePlayingState,
    resetPlayingState,
    requestNext,
    setIsPlaying,
    setNoSkip,
    setCoHostIdle,
    setCoHostURL,
    setIdle,
    setPresenterIdle,
    setPresenterURL,
    setGapDuration,
    resetGapProgress,
    incrGapProgress,
    setTextToDisplay,
    setText,
    resetSessionState
} from '../slices/session/sessionSlice'
import { setAnnouncement, setExpired } from '../slices/autoMagicSlice'
import { LearningMode, readyToMoveToNextItem, allContentInItemUsedUp, setSection, setEntry, setIntroToComplete, setSentenceToComplete, setSectionToComplete } from '../slices/learnerSlice'
import { automagickEndpoint } from '../API'
import uuidToUrl from "./uuid-to-url"

function isIdleDidEnd(url) {
    return url === null
}

export function handleIdleEnd(url, dispatch) {

    switch (url) {
        
        case Assets.presenterIdleURL:
            dispatch(setPresenterIdle());
            break;

        case Assets.coHostIdleURL:
            dispatch(setCoHostIdle());
            break;

        default:
            break;
    }
}

export const PlayingState = {
    prompt: "prompt",
    gap: "gap",
    target1: "target1",
    target1Gap: "t1Gap",
    target2: "target2",
    target2Gap: "t2Gap",
};

export const playingStates = [
    PlayingState.prompt,
    PlayingState.gap,
    PlayingState.target1,
    PlayingState.target1Gap,
    PlayingState.target2,
    PlayingState.target2Gap
];
export const PlayingStatesCount = playingStates.length

export default function PuppetEngine(store, videoURL = automagickEndpoint('media')) {

    const getCurrentSentence = (state) => {
        const session = sessionSelector(state)
        const learner = learnerSelector(state)
        if (session.itemQueue.length < 1) {
            return [null, null]
        }
        let currentItem = session.itemQueue[0]
        let entry = learner.entry
        if (entry < 0)
        {
            if (currentItem.intro) { return [currentItem.intro, null]; }
            entry = 0
            store.dispatch(setEntry({ entry: 0 }))
        }
        const sentences = currentItem.sentences
        if (learner.mode === LearningMode.skip) {
            entry = 0
        }
        if (entry >= sentences.length) {
            store.dispatch(shiftToNextSection())
            store.dispatch(setEntry({ entry: -1 }))
            return getCurrentSentence(state)
        }
        const sentence = sentences[entry]
        if (entry + 1 >= sentences.length && currentItem.intro)
        {
            const section = currentItem.intro.uuid
            return [sentence, section]
        }
        return [sentence, null]
    }

    const needsNext = () => {
        const state = store.getState()
        const session = sessionSelector(state)
        const learner = learnerSelector(state)
        const itemQueue = session.itemQueue
        const cnts = itemQueue.map((item, i) =>
        {
            if (i === 0)
            {
                const sentences = item.sentences
                return sentences.length - learner.entry - 1
            }
            return item.sentences.length
        })
        const totalAvailable = cnts.reduce((a, b) => a + b, 0)
        // console.log('TOTAL A', totalAvailable)
        return totalAvailable < 10
    }

    let gapTimer = null
    let gapTimerStart
    let gapTimer2 = null // used to cancel display of the prompt text

    const play = (shouldPlay, store) => {
        if (shouldPlay) {
            store.dispatch(setText(''))
            resumeCurrentState("", store)
        } else {
            const gapDelta = Date.now() - gapTimerStart
            if (gapTimer) clearTimeout(gapTimer);
            gapTimer = null
            if (gapTimer2) clearTimeout(gapTimer2);
            gapTimer2 = null
            store.dispatch(incrGapProgress(gapDelta / 1000))
        }
    }

    const resumeCurrentState = (url, store, gap = 1000) => {

        const dispatch = store.dispatch

        if (isIdleDidEnd(url)) { handleIdleEnd(url, dispatch); return; }

        const state = store.getState()
        const session = sessionSelector(state)
        const learner = learnerSelector(state)
        const videoEnabled = !learner.videoHidden

        const currentItem = session.itemQueue[0]
        if (!currentItem) {
            dispatch(setIdle())
            // dispatch(requestNext())
            return
        }

        dispatch(setNoSkip(currentItem.kind !== ManifestKind.section && currentItem.kind !== ManifestKind.summary))
        let actualGap
        const playingState = playingStates[session.playingStateIndex]
        if (learner.entry < 0) {
            // eslint-disable-next-line default-case
            switch (playingState) {
                case PlayingState.prompt:
                    const format = (currentItem.kind === ManifestKind.infoVideo) ? 'video' : videoEnabled
                    const headingUrl = uuidToUrl(currentItem.intro.descriptor.uuid, format, videoURL)
                    dispatch(setPresenterURL(headingUrl))
                    dispatch(setCoHostIdle())
                    // const amSettings = autoMagicSelector(state)
                    // const courseInfo = amSettings.courses.find(course => course.uuid === learner.course)
                    // const srcLang = courseInfo?.sourceLanguage
                    // const text = currentItem.intro.text
                    // const txt = srcLang ? { text: text, direction: srcLang.direction } : text;
                    const txt = ''
                    dispatch(setTextToDisplay(txt))
                    dispatch(setIntroToComplete(currentItem.intro.uuid))
                    break
                case PlayingState.gap:
                    dispatch(setIdle())
                    dispatch(setText(''))
                    const headingDetails = currentItem.intro.descriptor.videoDetails
                    const srcDetails = currentItem.sentences[0]?.sourceDescriptor
                    actualGap = 1.0
                    if (videoEnabled) {
                        actualGap = Math.max(actualGap - headingDetails.endPadding - (srcDetails?.startPadding ?? 0), 0)
                    }
                    gapTimer = setTimeout(function () {
                        transitionToNextState(url, store)
                    }, actualGap * 1000)
                    break
            }
        } else {
            const [currentSentence, sectionToComplete] = getCurrentSentence(state)
            // eslint-disable-next-line default-case
            switch (playingState) {

                case PlayingState.prompt:
                    const promptUrl = uuidToUrl(currentSentence.sourceDescriptor.uuid, videoEnabled, videoURL)
                    dispatch(setPresenterURL(promptUrl))
                    dispatch(setCoHostIdle())
                    const source = `<src>${currentSentence.sourceText}</src>`
                    dispatch(setTextToDisplay(source))
                    store.dispatch(resetGapProgress())
                    break;

                case PlayingState.gap:
                    dispatch(setIdle())
                    const settings = autoMagicSelector(state)
                    const { delayConstant, delayFactor, r1Factor, r2Factor } = speedDescriptor(learner.spacing, settings.velocities)
                    const srcDetails = currentSentence.sourceDescriptor.videoDetails
                    const r1Details = currentSentence.target1Descriptor.videoDetails
                    const r2Details = currentSentence.target2Descriptor.videoDetails
                    const r1Duration = r1Details.duration - r1Details.startPadding - r1Details.endPadding
                    const r2Duration = r2Details.duration - r2Details.startPadding - r2Details.endPadding
                    actualGap = delayConstant + delayFactor * (r1Factor * r1Duration + r2Factor * r2Duration)
                    if (videoEnabled) {
                        actualGap = Math.max(actualGap - srcDetails.endPadding - r1Details.startPadding, 0)
                    }
                    dispatch(setGapDuration(actualGap))
                    actualGap -= session.gapProgress
                    if (actualGap > 1) {
                        const shorterGap = actualGap - 1
                        gapTimer2 = setTimeout(function () {
                            dispatch(setText(''))
                        }, shorterGap * 1000);
                    } else {
                        dispatch(setText(''))
                    }
                    gapTimerStart = Date.now()
                    gapTimer = setTimeout(function () {
                        transitionToNextState(url, store)
                    }, actualGap * 1000);
                    break;

                case PlayingState.target1:
                    dispatch(setPresenterIdle());
                    dispatch(setGapDuration(null))
                    const targetVoice1Url = uuidToUrl(currentSentence.target1Descriptor.uuid, videoEnabled, videoURL);
                    dispatch(setCoHostURL(targetVoice1Url));
                    break;

                case PlayingState.target1Gap:
                    dispatch(setIdle());
                    const r1Deets = currentSentence.target1Descriptor.videoDetails
                    const r2Deets = currentSentence.target2Descriptor.videoDetails
                    actualGap = 1.0
                    if (videoEnabled) {
                        actualGap = Math.max(actualGap - r1Deets.endPadding - r2Deets.startPadding, 0)
                    }
                    gapTimer = setTimeout(function () {
                        transitionToNextState(url, store)
                    }, actualGap * 1000)
                    break

                case PlayingState.target2:
                    const targetVoice2Url = uuidToUrl(currentSentence.target2Descriptor.uuid, videoEnabled, videoURL);
                    dispatch(setPresenterURL(targetVoice2Url));
                    dispatch(setCoHostIdle());
                    const target = `<tgt>${currentSentence.targetText}</tgt>`;
                    dispatch(setTextToDisplay(target));
                    dispatch(setSentenceToComplete(currentSentence.uuid))
                    if (sectionToComplete)
                    {
                        dispatch(setSectionToComplete(sectionToComplete))
                    }
                    break

                case PlayingState.target2Gap:
                    dispatch(setIdle());
                    dispatch(setText(''));
                    const r2Doots = currentSentence.target2Descriptor.videoDetails
                    actualGap = 1.0
                    if (videoEnabled) {
                        actualGap = Math.max(actualGap - r2Doots.startPadding, 0) // don't bother tryng to figure out the next ...
                    }
                    gapTimer = setTimeout(function () {
                        transitionToNextState(url, store)
                    }, actualGap * 1000)
                    break

            }
        }

    };

    const transitionToNextState = (url, store) => {
        const dispatch = store.dispatch
        dispatch(advancePlayingState())
        const state = store.getState()
        const session = sessionSelector(state)
        const learner = learnerSelector(state)
        if (session.itemQueue.length < 1) {
            // TODO: pause playback / notify user
            dispatch(setIsPlaying(false))
            return
        }
        const currentItem = session.itemQueue[0]
        if (!currentItem) {
            console.log('trying to PAUSE')
            dispatch(setIsPlaying(false))
            return
        }
        const readyToMoveToNextItem = (learner.entry === -1 && session.playingStateIndex > 1) || session.playingStateIndex === 0
        if (readyToMoveToNextItem) {
            const allContentInItemUsedUp = learner.entry + 1 >= currentItem.sentences.length
            if (allContentInItemUsedUp) {
                dispatch(shiftToNextSection())
                // TODO: do we need to deal with announcements and expirations, or will those have been dealt with already elsewhere?
                const nextItem = session.itemQueue[1]
                if (nextItem) {
                    if (nextItem.intro) {
                        const pushUUID = nextItem.sentences.length > 0 ? nextItem.intro.uuid : null
                        dispatch(setSection({ section: nextItem.intro.uuid, push: pushUUID }))
                    }
                    if (nextItem.startingIndex) {
                        dispatch(setEntry({ entry: nextItem.startingIndex }))
                    } else if (nextItem.kind === ManifestKind.randomWalk) {
                        dispatch(setEntry({ entry: 0 }))
                    }
                } else {
                    console.log('trying to PAUSE')
                    dispatch(setIsPlaying(false))
                    return
                }
            } else {
                const doPush = currentItem.intro // only push to server if this is a real section (not a random walk)
                dispatch(setEntry({ entry: learner.entry + 1, push: doPush }))
            }
            dispatch(resetPlayingState())
        }

        if (needsNext()) {
            dispatch(requestNext())
        }

        resumeCurrentState(url, store)
    };

    return {
        play: play,
        resumeCurrentState: resumeCurrentState,
        transitionToNextState: transitionToNextState,
        needsNext: needsNext
    };
}
