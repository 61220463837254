import SVGIcon from './SVGIcon'

export default function GoForwardIcon({className, color, onClick})
{
  return <SVGIcon
          name='goforward'
          className={className}
          size='79.36 86.79'
          color={color}
          onClick={onClick}
          paths={[
            'm.25,47.13c0,21.53,17.92,39.4,39.4,39.4s39.45-17.87,39.45-39.4c0-12.7-6.3-24.17-15.87-31.45-1.9-1.46-4.35-1.03-5.42.78-1.12,1.81-.54,3.91,1.22,5.22,7.67,5.81,12.55,14.99,12.6,25.44.05,17.77-14.21,31.98-31.98,31.98s-31.88-14.21-31.88-31.98c0-15.14,10.25-27.69,24.32-31.05v4.98c0,3.03,2.05,3.81,4.35,2.2l12.4-8.69c1.9-1.32,1.95-3.37,0-4.74L36.48,1.14c-2.34-1.71-4.39-.93-4.39,2.15v5.18C14.07,12.08.25,28.19.25,47.13Z',
            'm21.73,47.13c0,2.15,1.51,3.61,3.76,3.61h10.5v10.6c0,2.2,1.46,3.71,3.61,3.71s3.71-1.51,3.71-3.71v-10.6h10.6c2.2,0,3.71-1.46,3.71-3.61s-1.51-3.71-3.71-3.71h-10.6v-10.55c0-2.25-1.46-3.76-3.71-3.76s-3.61,1.51-3.61,3.76v10.55h-10.5c-2.25,0-3.76,1.51-3.76,3.71Z'
            ]}
          />
}
