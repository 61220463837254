import {
    resetSections,
    loadItems,
    coHostDidEndURL,
    presenterDidEndURL,
    requestNext,
    role,
    setIsPlaying,
    setPresenterStatus,
    setText
} from "../slices/session/sessionSlice"
import { loginHappened } from '../slices/autoMagicSlice'
import { setEntry, incrSessionTime, completeIntro, completeSentence, completeSection, resetProgress, setLastInterjection, resetAtSection } from '../slices/learnerSlice'
import { sessionSelector, learnerSelector } from '../store'

import PuppetEngine from '../PuppetEngine/PuppetEngine'

function presenterURL(store, role) {
    return sessionSelector(store.getState())[role].url;
}

export default
function PuppetMiddleware(api) {
    return store => {

        const puppetEngine = PuppetEngine(store)
        let timer = null

        function updateSections(updater)
        {
            const session = sessionSelector(store.getState())
            const wasEmpty = session.itemQueue.length < 1
            updater()
            if (wasEmpty && session.isPlaying) {
                puppetEngine.resumeCurrentState("", store)
            }
        }

        return next => action => {

            switch (action.type) {

                case setIsPlaying.type:
                    const shouldPlay = action.payload
                    puppetEngine.play(shouldPlay, store)
                    if (shouldPlay) {
                        if (timer) {
                            console.log('timer already running!')
                        } else {
                            timer = setInterval(() => {
                                store.dispatch(incrSessionTime(1))
                            }, 1000)
                        }
                    } else {
                        const otimer = timer
                        timer = null
                        clearInterval(otimer)
                        // TODO: they don't get credit until they complete something ..
                        // TODO: so if this is due to pausing, do nothing, and they'll restart when they hit play again
                        // TODO: which lines up with how the play / pause button works currently .. I think
                    }
                    break

                case setPresenterStatus.type:
                    const status = action.payload
                    // console.log('status', status)
                    const url = presenterURL(store, role.presenter)
                    if (status === 'canplay' && url !== null) {
                        const txt = sessionSelector(store.getState()).textToDisplay
                        if (txt) store.dispatch(setText(txt))
                    }
                    break

                case presenterDidEndURL.type:
                    const state = store.getState()
                    const settings = learnerSelector(state)
                    if (settings.introToComplete != null) {
                        store.dispatch(completeIntro(settings.introToComplete))
                    }
                    if (settings.sentenceToComplete != null) {
                        store.dispatch(completeSentence(settings.sentenceToComplete))
                    }
                    if (settings.sectionToComplete != null) {
                        store.dispatch(completeSection(settings.sectionToComplete))
                    }
                    puppetEngine.transitionToNextState(presenterURL(store, role.presenter), store)
                    break

                case coHostDidEndURL.type:
                    puppetEngine.transitionToNextState(presenterURL(store, role.coHost), store)
                    break

                case requestNext.type:
                    (async () => {
                        const manifest = await api.next(store)
                        if (!manifest) return;
                        updateSections(() => {
                            store.dispatch(loadItems(manifest.items))
                        })
                    })()
                    break

                case loginHappened.type:
                    api.setup(store, action.payload)
                    break

                case resetProgress.type:
                    (async () => {
                        const manifest = await api.resetProgress(store)
                        updateSections(() => {
                            store.dispatch(resetSections());
                            store.dispatch(loadItems(manifest.items))
                            store.dispatch(setEntry({ entry: -1, push: true }))
                        })
                    })()
                    break

                case setLastInterjection.type:
                    (async () => {
                        await api.setLastInterjection(store, action.payload)
                    })()
                    break

                case resetAtSection.type:
                    (async () => {
                        const manifest = await api.resetAtSection(store, action.payload);
                        updateSections(() => {
                            store.dispatch(resetSections());
                            store.dispatch(loadItems(manifest.items))
                            store.dispatch(setEntry({ entry: -1, push: true }))
                        })
                    })()
                    break

                default:
                    break

            }

            return next(action)
        }
    }
}
