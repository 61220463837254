import { useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import './Modal.css'

export default function Modal({display, onDismiss, children})
{
  const nodeRef = useRef(null)
  function onClick(e) {
    if (!e.target.classList.contains('modal2-backdrop')) return;
    if (onDismiss) onDismiss();
  }

  const modal =
    <CSSTransition nodeRef={nodeRef} in={display} classNames='modal2' timeout={300} unmountOnExit>
      <div ref={nodeRef} className={'modal2-backdrop'} onClick={onClick}>
        <div className='modal2-content'>
          {children}
        </div>
      </div>
    </CSSTransition>
  return modal
}
