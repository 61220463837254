import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslate } from '../Translation'
import { useAlertDialog } from '../Hooks/AlertDialog'
import { useConfirmDialog } from '../Hooks/ConfirmDialog'
import { useGroupTreeInfo } from '../Hooks/GroupTreeInfo'
import { PersonType } from '../Core/PersonType'
import AddDialogComponent from './AddDialogComponent'
import AdminContainer from './AdminContainer'
import ClassStats from './ClassStats'
import CopyEmailAction from '../Actions/CopyEmailAction'
import LabelledBox from 'ssi-react-lib/LabelledBox'
import ManagerOverview from './ManagerOverview'
import PersonList from './PersonList'
import NavPanel from '../Widgets/NavPanel'
import Overlay from 'ssi-react-lib/Overlay'
import TrashEntityAction from '../Actions/TrashEntityAction'
import './Class.css'

export default function AdminClass(props)
{
  const params = useParams()
  const translate = useTranslate()
  const [spinning, setSpinning] = useState(false)
  const [showAlert, alertDialog] = useAlertDialog(translate('ok'))
  const [showConfirm, confirmDialog] = useConfirmDialog(translate('yes'), translate('cancel'))

  const groupId = params.groupId
  const classId = params.classId

  // eslint-disable-next-line no-unused-vars -- managersDict
  const [path, groupTree, managersDict, invited, refreshGroups] = useGroupTreeInfo(groupId, classId, setSpinning)

  function findGroupInTree(uuid, tree)
  {
    if (tree.uuid === uuid) return tree;
    for (const grp of tree.groups)
    {
      const rslt = findGroupInTree(uuid, grp)
      if (rslt) return rslt;
    }
    return null
  }
  const myTree = groupTree ? findGroupInTree(classId, groupTree) : null

  const emailTitle = 'Paste your learners’ email addresses here and we’ll send them an invitation for you:'
  const [showAddLearners, addLearnersDialog] = AddDialogComponent(classId, translate('addLearners'), [PersonType.learner], emailTitle, null, translate('thisClass'), translate('learners'), translate('send'), refreshGroups, refreshGroups, translate, showAlert)
  const addLearners = () =>
  {
    showAddLearners()
  }

  const nameWithContext = groupTree ? [...path, groupTree].map(grp => grp.name).join(' ⟩ ') : null
  const nameOfClass = myTree?.name ?? ''
  const getLearnerEmail = uuid => myTree.users.find(e => e.uuid === uuid)?.email
  const personDropLayout = (learner, deleteLearner, popNFade) =>
  {
    const layout =
      <div className='student-dropdown'>
        <div className='student-email'>{learner.email}</div>
        <div className='student-icon-box'>
          <CopyEmailAction getEmail={getLearnerEmail} popNFade={popNFade}/>
          <TrashEntityAction deleteEntity={deleteLearner}/>
        </div>
      </div>
    return layout
  }
  const classStats = myTree ? <ClassStats groupTree={myTree} /> : null
  const page =
    <>
      <NavPanel className='class-content' backLabel={translate('back')} backUrl={-1}>
        <AdminContainer className='class-container'>
          <PersonList className='students'
                      label={translate('learners')}
                      roles={[PersonType.learner]}
                      people={myTree?.users ?? []}
                      invited={invited}
                      addPeople={addLearners}
                      group={myTree}
                      relnDescription='as a student in this class'
                      personLayout={pers => <div className='person-name'>{pers.name}</div>}
                      personDropLayout={personDropLayout}
                      refreshGroup={refreshGroups}
                      refreshInvites={refreshGroups}
                      showAlert={showAlert}
                      showConfirm={showConfirm}
                      emptyMessage={translate('learnersEmpty')} />
          <ManagerOverview className='class-overview' banner={nameWithContext}>
            <div className='manager-overview-banner'>{nameOfClass}</div>
            <div>
              {translate('class-overview-body')}
            </div>
          </ManagerOverview>
          <LabelledBox className='class-stats' label={translate('statistics')}>
            {classStats}
          </LabelledBox>
        </AdminContainer>
      </NavPanel>
      <Overlay display={spinning} msg={translate('loading')} />
      {addLearnersDialog}
      {alertDialog}
      {confirmDialog}
    </>
  return page
}
