import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAPI } from '../Core/app/API'
import { useTranslate } from '../Translation'
import Button from 'ssi-react-lib/Button'
import CourseChooser from '../Widgets/CourseChooser'
import HorizontalLine from 'ssi-react-lib/HorizontalLine'
import NavPanel from '../Widgets/NavPanel'
import Overlay from 'ssi-react-lib/Overlay'
import './GroupReport.css'

export default function GroupReport(props) {
  const params = useParams()
  const groupId = params.groupId
  const translate = useTranslate()
  const api = useAPI()

  const [spinning, setSpinning] = useState(false)

  const [courses, setCourses] = useState([])
  useEffect(() => {
    (async () => {
      const cinfo = await api.groupCoursesOffered(groupId)
      cinfo.push({ uuid: '00000000-0000-0000-0000-000000000000', identifier: 'any', name: 'Any' })
      setCourses(cinfo)
    })()
  }, [api, groupId])

  const [courseId, setCourseId] = useState(null)
  const onCourseSelected = (meta) =>
  {
    setCourseId(meta.course_id)
  }

  const [report, setReport] = useState(null)
  useEffect(() =>
  {
    setSpinning(true);
    (async () =>
    {
      if (!courseId) return;
      const report = await api.groupReport(groupId, courseId)
      if (!report) return;
      setReport(report)
      setSpinning(false)
    })()
  }, [courseId]) // eslint-disable-line react-hooks/exhaustive-deps

  const breadcrumb = report ? report.context.map(grp => grp.name).join(' ⟩ ') : ''

  let reportLines = []
  if (report)
  {
    reportLines.push(breadcrumb + ',Learner,Email,App Mins')
    for (const subreport of report.subreports)
    {
      const sbreadcrumb = subreport.context.map(grp => grp.name).join(' ⟩ ')
      reportLines.push(sbreadcrumb)
      for (const row of subreport.learnerStats)
      {
        const elts = ['', row.learner.name, row.learner.owner.email, row.statistics.minutesInApp]
        const line = elts.join(',')
        reportLines.push(line)
      }
      const selts = ['Group Averages', '', '', subreport.summary.minutesInApp]
      reportLines.push(selts.join(','))
      reportLines.push('')
    }
    const oelts = ['Overall Averages', '', '', report.summary.minutesInApp]
    reportLines.push(oelts.join(','))
  }
  const reportCSV = reportLines.join('\n')
  const blob = new Blob([reportCSV], {type: 'text/plain'})
  const blobUrl = URL.createObjectURL(blob)
  const downloadLink = <a className='download-button-label' href={blobUrl} download='report.csv'>Download as CSV</a>

  let reportBody
  if (!report)
  {
    reportBody = null
  }
  else if (report.subreports.length > 1)
  {
    let subbodies = []
    for (const subreport of report.subreports)
    {
      const sbreadcrumb = subreport.context.map(grp => grp.name).join(' ⟩ ')
      const rows = subreport.learnerStats.map(row =>
      {
        const email = row.learner.owner.email
        // const addtl = email ? <span className='learner-email'>({email})</span> : null
        const rrow =
          <div>
            <div className='group-report-list grl-column1'>{row.learner.name}</div>
            <div className='group-report-list grl-column2'>{email}</div>
            <div className='group-report-list grl-column3'>{Math.ceil(row.statistics.minutesInApp)}</div>
          </div>
        return rrow
      })
      const bod =
        <>
          <div className='group-report-list-container body'>
            <div className='group-report-list-contents'>
              {sbreadcrumb}
              <div>
                <div className='group-report-list grl-header grl-column1'>
                  Learner
                </div>
                <div className='group-report-list grl-header grl-column2'>
                  Email
                </div>
                <div className='group-report-list grl-header grl-column3'>
                  App Mins
                </div>
              </div>
              <HorizontalLine />
              {rows}
              <HorizontalLine />
              <div>
                <div className='group-report-list grl-header grl-column1'>
                  Group Averages
                </div>
                <div className='group-report-list grl-header grl-column2'>
                </div>
                <div className='group-report-list grl-column3'>
                  {Math.round(subreport.summary.minutesInApp)}
                </div>
              </div>
            </div>
          </div>
        </>
      subbodies.push(bod)
    }
    const osummary =
      <div className='group-report-list-container summary'>
        <div className='group-report-list-contents'>
          <div>
            <div className='group-report-list grl-header grl-column1'>
              Overall Averages
            </div>
            <div className='group-report-list grl-header grl-column2'>
            </div>
            <div className='group-report-list grl-column3'>
              {Math.round(report.summary.minutesInApp)}
            </div>
          </div>
        </div>
      </div>
    subbodies.push(osummary)
    reportBody = subbodies
  }
  else
  {
    const report1 = report.subreports[0]
    const rows = report1 ? report1.learnerStats.map(row =>
    {
      const email = row.learner.owner.email
      // const addtl = email ? <span className='learner-email'>({email})</span> : null
      const rrow =
        <div>
          <div className='group-report-list grl-column1'>{row.learner.name}</div>
          <div className='group-report-list grl-column2'>{email}</div>
          <div className='group-report-list grl-column3'>{Math.ceil(row.statistics.minutesInApp)}</div>
        </div>
      return rrow
    }) : []

    reportBody = report1 ?
      <>
        <div className='group-report-list-container body'>
          <div className='group-report-list-contents'>
            <div>
              <div className='group-report-list grl-header grl-column1'>
                Learner
              </div>
              <div className='group-report-list grl-header grl-column2'>
                Email
              </div>
              <div className='group-report-list grl-header grl-column3'>
                App Mins
              </div>
            </div>
            <HorizontalLine />
            {rows}
          </div>
        </div>
        <div className='group-report-list-container summary'>
          <div className='group-report-list-contents'>
            <div>
              <div className='group-report-list grl-header grl-column1'>
                Group Averages
              </div>
              <div className='group-report-list grl-header grl-column2'>
              </div>
              <div className='group-report-list grl-column3'>
                {Math.round(report1.summary.minutesInApp)}
              </div>
            </div>
          </div>
        </div>
      </> : null
  }

  const downloadButton = <Button class='download-button' label={downloadLink} />
  const layout =
    <>
      <NavPanel className='groups-content' backLabel={translate('back')} backUrl={-1} extraButtons={downloadButton}>
        <div className='report-container'>
          <span className='group-report-header'>{breadcrumb}</span>
          <div className='report-chooser-container'>
            <CourseChooser courses={courses} onError={props.onError} onInitialSelection={onCourseSelected} onCourseSelected={onCourseSelected} />
          </div>
          {reportBody}
        </div>
      </NavPanel>
      <Overlay display={spinning} msg={translate('generatingReport')} />
    </>
  return layout
}
