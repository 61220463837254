import TrashIcon from 'ssi-react-lib/Icons/Trash'

export default function TrashEntityAction({deleteEntity})
{
  const onTrash = async (e) =>
  {
    e.preventDefault()
    e.stopPropagation()
    const row = e.target.closest('.list-elem')
    const guid = row.dataset.meta
    await deleteEntity(guid)
  }

  const icon = <TrashIcon className='person-trash' onClick={onTrash}/>
  return icon
}
