import { logoutHappened, preloadItem } from '../slices/autoMagicSlice'
import { initCursor, resetSection, setSection, setEntry } from '../slices/learnerSlice'
import { ManifestKind } from '../slices/session/sessionSlice'
import { sessionSelector, learnerSelector } from '../store'
import { automagickEndpoint } from '../API'
import uuidToUrl from '../PuppetEngine/uuid-to-url'

export default
function AutoMagicMiddleware(api, loader)
{
    const baseMediaUrl = automagickEndpoint('media')
    const urlFromDescriptor = (state, descriptor) =>
    {
        return uuidToUrl(descriptor.uuid, descriptor.isVideo || !learnerSelector(state).videoHidden, baseMediaUrl)
    }

    const preLoader = async (state, section, entry) =>
    {
        const itemQueue = sessionSelector(state).itemQueue
        let item = itemQueue.find(s => s.intro?.uuid === section)
        if (!item)
        {
            item = itemQueue.find(it => it.kind === ManifestKind.randomWalk)
            if (item)
            {
                entry = 0
            }
        }
        if (!item) return;
        let queue = []
        const loadSentence = (sentence) =>
        {
            queue.push(sentence.sourceDescriptor)
            queue.push(sentence.target1Descriptor)
            queue.push(sentence.target2Descriptor)
        }
        if (entry < 0) {
            queue.push(item.intro.descriptor)
            const snt0 = item.sentences[0]
            if (snt0) loadSentence(snt0)
        } else {
            // Just in case "entry" has become confused, make sure it's in bounds...
            let idx = Math.min(entry, item.sentences.length - 1)
            const snt = item.sentences[idx]
            if (snt) loadSentence(snt);
            if (idx + 1 < item.sentences.length) {
                const snt2 = item.sentences[idx + 1]
                loadSentence(snt2)
            } else {
                // look ahead until next section
                const idx = itemQueue.findIndex(s => s.intro?.uuid === section)
                if (idx >= 0) {
                    outer:
                    for (let i = idx + 1; i < itemQueue.length; i++) {
                        const item = itemQueue[i]
                        if (!item) break;
                        switch (item.kind)
                        {
                            case ManifestKind.infoVideo:
                                const descr = { isVideo: true, ...item.intro.descriptor }
                                queue.push(descr)
                                break
                            case ManifestKind.encouragement:
                                queue.push(item.intro.descriptor)
                                break
                            case ManifestKind.section:
                                queue.push(item.intro.descriptor)
                                break outer
                                // ideally should do something here ...
                                /*
                                 case ManifestKind.randomWalk:
                                 api */
                            default:
                                break outer
                        }
                    }
                }
            }
        }
        for (const descriptor of queue) {
            const url = urlFromDescriptor(state, descriptor)
            const rslt = await loader.load(url, true)
            if (!rslt) break; // not sure if the right choice, but bail if things look bad..
        }
    }

    return store => next => action =>
    {
        const state = store.getState()
        const { section, entry, mode } = learnerSelector(state)
        switch (action.type)
        {
        case logoutHappened.type:
            api.logout()
            break
        case preloadItem.type:
            const url = uuidToUrl(action.payload.uuid, action.payload.isVideo, baseMediaUrl)
            loader.load(url, true)
            break
        case initCursor.type:
            if (action.payload.section === section && action.payload.entry === entry && action.payload.mode === mode) {
                break
            }
            preLoader(state, action.payload.section, action.payload.entry)
            break
        case resetSection.type:
            break;
        case setSection.type:
            if (action.payload.section === section) {
                break
            }
            preLoader(state, action.payload.section, -1)
            break
        case setEntry.type:
            if (action.payload.entry === entry && loader.cache.length > 0) {
                break
            }
            preLoader(state, section, action.payload.entry)
            break
        default:
            break
        }
        let result = next(action)
        return result
    }
}
