import SVGIcon from './SVGIcon'

export default function ClockIcon({className, color, onClick})
{
  return <SVGIcon
          name='clock'
          className={className}
          size='79.36 79.31'
          color={color}
          onClick={onClick}
          paths={[
            'm39.65,79.06c21.53,0,39.45-17.87,39.45-39.4S61.14.25,39.61.25.25,18.12.25,39.65s17.92,39.4,39.4,39.4Zm0-7.42c-17.72,0-31.88-14.21-31.88-31.98S21.88,7.67,39.61,7.67s32.03,14.21,32.03,31.98-14.21,31.98-31.98,31.98Z',
            'm20.12,44h19.48c1.71,0,3.03-1.32,3.03-2.98V15.88c0-1.71-1.32-3.03-3.03-3.03s-2.98,1.32-2.98,3.03v22.12h-16.5c-1.71,0-2.98,1.32-2.98,3.03s1.27,2.98,2.98,2.98Z'
            ]}
          />
}
