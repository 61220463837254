import { useSelector } from 'react-redux'
import { useLocation, useNavigate, Routes, Route } from 'react-router-dom'
import { autoMagicSelector } from '../Core/app/store'
import { useGroups } from '../Hooks/Groups'
import Feedback from '../Feedback'
import FeedbackIcon from 'ssi-react-lib/Icons/Feedback'
import GroupIcon from 'ssi-react-lib/Icons/Group'
import Learn from '../Pages/Learn'
import LearnerSettingsIcon from 'ssi-react-lib/Icons/LearnerSettingsIcon'
import LearningIcon from 'ssi-react-lib/Icons/Learning'
import GroupsRoute from '../Widgets/GroupsRoute'
import TabBarController from '../Widgets/TabBarController'
import UserSettings from '../Pages/User'

export default function AppSwitch()
{
  const location = useLocation()
  const navigate = useNavigate()
  const autoMagicSettings = useSelector(autoMagicSelector)
  const hasLearning = autoMagicSettings.courses && autoMagicSettings.courses.length > 0
  const groups = useGroups()
  const hasGroups = groups?.length > 0

  if (autoMagicSettings.courses)
  {
    if (location.pathname === '/app/learn' && !hasLearning) {
      if (hasGroups) {
        navigate('/app/groups')
      } else {
        window.location.href = process.env.REACT_APP_RAILS_ROOT
      }
    }
    if (location.pathname === '/app/groups' && !hasGroups) {
      if (hasLearning) {
        navigate('/app/learn')
      } else {
        window.location.href = process.env.REACT_APP_RAILS_ROOT
      }
    }
    if (location.pathname === '/app')
    {
      if (hasLearning)
      {
        navigate('/app/learn')
      } else if (hasGroups)
      {
        navigate('/app/groups')
      } else {
        window.location.href = process.env.REACT_APP_RAILS_ROOT
      }
      return
    }
  }

  const tabs =
  [
   hasLearning ? { name: 'Learning', identifier: 'learn', icon: <LearningIcon />, body: <Learn /> } : null,
   { name: 'Settings', identifier: 'settings', icon: <LearnerSettingsIcon />, body: <UserSettings hasLearning={hasLearning} /> },
   groups.length > 0 ? { name: 'My Groups', identifier: 'groups', icon: <GroupIcon />, body: <GroupsRoute /> } : null,
   { name: 'Feedback', identifier: 'feedback', icon: <FeedbackIcon />, body: <Feedback /> }
  ].filter(tb => tb !== null)
  // return <TabBarController tabs={tabs} />

  const swtch =
    <Routes location={location}>
      <Route path=':selected/*' element={<SwitchLayout tabs={tabs} />}>
      </Route>
    </Routes>
  return swtch
}

function SwitchLayout({tabs})
{
  const layout =
    <TabBarController tabs={tabs} />
  return layout
}
