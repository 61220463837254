import { useEffect, useRef, useState } from "react";
import { Assets } from '../Assets'

import Renderer from "./Renderer";
import useAnimationFrame from "../VideoPlayer/useAnimationFrame";
import "./CompositeVideoPlayer.css";
// import styles from "./CompositeVideoPlayer.module.css";

import resizeCanvasToDisplaySize from "../VideoPlayer/chroma-renderer/resizeCanvasToDisplaySize";
import useWindowSize from "../VideoPlayer/useWindowSize";
import { useMedia } from '../Loader'

function CompositeVideoPlayer(props) {

    const loader = useMedia()

    const presenterVideoElement = useRef();
    const coHostVideoElement = useRef();
    const canvas = useRef();
    const backgroundImage = new Image();

    const renderer = useRef();

    const preparationFrames = 5;//frames played before poster is taken.
    const framesPrepared = useRef(0);

    const prevReadyStatePresenter = useRef(0);
    const prevReadyStateCohost = useRef(0);

    const {
        setReady,
        presenterState,
        coHostState,
        backgroundSource,
        onPresenterDuration,
        onCoHostDuration,
        onPresenterEnded,
        onCoHostEnded,
        onError,
        onStalled,
        onInterupted,
        onPresenterStatusChange,
        onCoHostStatusChange,
        presenterScale,
        onCanvasClick,
        setAutoPlayback,
        onChangeOrientation,
        onImageReady,

        disableRender,
    } = props;

    const onPresenterEnd = () => {
        onVideoEnd(onPresenterEnded);
    }

    const onCoHostEnd = () => {
        onVideoEnd(onCoHostEnded);
    }

    const onVideoEnd = (endCallback) => {
        loader.prune();
        endCallback();
    }

    const onPresenterDurationChange = (e) => {
        const { src, duration } = e.target;
        onPresenterDuration(src, duration);
    };

    const onCoHostDurationChange = (e) => {
        const { src, duration } = e.target;
        onCoHostDuration(src, duration);
    };

    const onPresenterStatusChng = (e) => {
        onPresenterStatusChange(e.type)
    }
    const onCoHostStatusChng = (e) => {
        onCoHostStatusChange(e.type)
    }

    const playVideo = (element) => {
        element.play().catch((error) => {
            // Error 20 is "play() was interrupted by a new load request" - which seems to be a harmless indication
            // that we didn't need to trigger play again.  We can perhaps avoid this by carefully not calling play,
            // but ignoring the error may well be the simpler approach
            if (error.code !== 20) {
                console.error('error', error.code, error);
                onInterupted(error)
            }
        });
    }
    
    const onLoad = (element) => {
        if (element) {
            if (element.readyState && element.paused && presenterState.isPlaying && element.currentTime < 0.1) {
                playVideo(element);
            }
            if (framesPrepared.current >= preparationFrames) {
                if (element === presenterVideoElement.current) {
                    renderer.current.resetPresenterXFTimer();
                } else {
                    renderer.current.resetCoHostXFTimer();
                }
            }
        }
    }

    const startAutomaticPlayback = () => {
        try {
            setAutoPlayback(true);
        } catch {
            setTimeout(startAutomaticPlayback, 50);
        }
    }

    useEffect(() => {
        if (canvas.current) {
            const gl = canvas.current.getContext("webgl2");
            if (!gl) {
                console.error("webgl2 context not available")
                return
            }
            renderer.current = new Renderer(gl);

            backgroundImage.src = backgroundSource;
            backgroundImage.onload = function() {
                renderer.current.setBackgroundImage(backgroundImage);
            }
            startAutomaticPlayback();
        } else {
            console.error("canvas not loaded in startup useEffect");
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (renderer.current) {
            renderer.current.setPresenterScale(presenterScale);
        }
    }, [presenterScale])

    useEffect(() => {
        if (!presenterVideoElement.current) return;
        console.log('attaching error to presenter')
        presenterVideoElement.current.addEventListener('error', (e) => {
            const err = presenterVideoElement.current.error
            console.log('presenter video error', err)
            console.log('src', presenterVideoElement.current.src)
            onError(err)
        }, true)
    }, [presenterVideoElement.current])
    useEffect(() => {
        if (!coHostVideoElement.current) return;
        console.log('attaching error to cohost')
        coHostVideoElement.current.addEventListener('error', (e) => {
            const err = coHostVideoElement.current.error
            console.log('cohost video error', err)
            console.log('src', coHostVideoElement.current.src)
            onError(err)
        }, true)
    }, [coHostVideoElement.current])

    useEffect(() => {
        if (presenterState.isPlaying) {
            playVideo(presenterVideoElement.current);
        } else {
            presenterVideoElement.current.pause();
        }
    }, [presenterState.isPlaying, presenterState.timestamp]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (coHostState.isPlaying) {
            playVideo(coHostVideoElement.current);
        } else {
            coHostVideoElement.current.pause();
        }
    }, [coHostState.isPlaying, coHostState.timestamp]); // eslint-disable-line react-hooks/exhaustive-deps

    const canvasSizeChanged = function() {
        if (!canvas.current || !renderer.current) return;
        resizeCanvasToDisplaySize(canvas.current, devicePixelRatio);
        if (renderer.current && canvas.current) {
            renderer.current.landscape = canvas.current.width / canvas.current.height > 1.2;
            if (props.isLandscape !== renderer.current.landscape) {
                // TODO: what's with this?
                // props.isLandscape = renderer.current.landscape;
                onChangeOrientation(props.isLandscape);
            }
        }
    }

    useWindowSize(() => {
        canvasSizeChanged();
    })

    useEffect(() => {
        canvasSizeChanged();
    }, [canvas]) // eslint-disable-line react-hooks/exhaustive-deps

    useAnimationFrame(() => {
        if (canvas.current && renderer.current && presenterVideoElement.current && coHostVideoElement.current) {
            if (!disableRender || framesPrepared.current < preparationFrames) {
                if (prevReadyStatePresenter.current !== presenterVideoElement.current.readyState) {
                    prevReadyStatePresenter.current = presenterVideoElement.current.readyState;
                }
                if (presenterVideoElement.current.readyState > 2) {
                    renderer.current.setPresenterImage(presenterVideoElement.current);
                    if (!renderer.current.isPresenterMidXF()) {
                        renderer.current.setPresenterXFImage(presenterVideoElement.current);
                    }
                }
                if (prevReadyStateCohost.current !== coHostVideoElement.current.readyState) {
                    prevReadyStateCohost.current = coHostVideoElement.current.readyState;
                }
                if (coHostVideoElement.current.readyState > 2) {
                    renderer.current.setCoHostImage(coHostVideoElement.current);
                    if (!renderer.current.isCohostMidXF()) {
                        renderer.current.setCoHostXFImage(coHostVideoElement.current);
                    }
                }
                if (presenterVideoElement.current) {
                    renderer.current.setViewport(canvas.current, presenterVideoElement.current);
                }
            }
            if (framesPrepared.current < preparationFrames) {
                if (renderer.current.presenterTexture && renderer.current.coHostTexture) {
                    framesPrepared.current += 1;
                    if (framesPrepared.current === preparationFrames) {
                        setAutoPlayback(false);
                        onImageReady();
                        if (!disableRender) {
                            renderer.current.draw();
                        }
                    }
                }
            } else if (!disableRender) {
                renderer.current.draw();
            }
        }
    })

    const presenterSrc = presenterState.url ?? Assets.presenterIdleURL
    const [presenterSrcUrl, setPresenterSrcUrl] = useState(null)
    useEffect(() =>
    {
      if (!presenterSrc) return;
      setReady(false);
      (async () =>
      {
        const url = await loader.load(presenterSrc)
        if (!url)
        {
            onStalled()
            return
        }
        setPresenterSrcUrl(url)
        setReady(true)
      })()
    }, [presenterSrc]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() =>
    {
      if (presenterState.isPlaying) {
        playVideo(presenterVideoElement.current) // TODO: verify this...
      }
    }, [presenterSrcUrl]) // eslint-disable-line react-hooks/exhaustive-deps

    const coHostSrc = coHostState.url ?? Assets.coHostIdleURL
    const [coHostSrcUrl, setCoHostSrcUrl] = useState(null)
    useEffect(() =>
    {
      if (!coHostSrc) return;
      setReady(false);
      (async () =>
      {
        const url = await loader.load(coHostSrc)
        if (!url)
        {
            onStalled()
            return
        }
        setCoHostSrcUrl(url)
        setReady(true)
      })()
    }, [coHostSrc]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() =>
    {
      if (coHostState.isPlaying) {
        playVideo(coHostVideoElement.current) // TODO: verify this...
      }
    }, [coHostSrcUrl]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <canvas className='canvasPresenter' ref={canvas} onClick={onCanvasClick} />

            <video className='canvasPresenter displayNone'
                crossOrigin='use-credentials'
                src={presenterSrcUrl}
                ref={presenterVideoElement}
                playsInline
                disablePictureInPicture
                onEnded={onPresenterEnd}
                onDurationChange={onPresenterDurationChange}
                onLoadedData={() => void onLoad(presenterVideoElement.current)}
                onStalled={onPresenterStatusChng}
                onAbort={onPresenterStatusChng}
                onCanPlay={onPresenterStatusChng}
                onCanPlayThrough={onPresenterStatusChng}
                onSuspend={onPresenterStatusChng}
                onWaiting={onPresenterStatusChng}
                onPlaying={onPresenterStatusChng}
                onPlay={onPresenterStatusChng}
                onPause={onPresenterStatusChng} />
            <video className='canvasPresenter displayNone'
                crossOrigin='use-credentials'
                src={coHostSrcUrl}
                ref={coHostVideoElement}
                playsInline
                disablePictureInPicture
                onEnded={onCoHostEnd}
                onDurationChange={onCoHostDurationChange}
                onLoadedData={() => void onLoad(coHostVideoElement.current)}
                onStalled={onCoHostStatusChng}
                onAbort={onCoHostStatusChng}
                onCanPlay={onCoHostStatusChng}
                onCanPlayThrough={onCoHostStatusChng}
                onSuspend={onCoHostStatusChng}
                onWaiting={onCoHostStatusChng}
                onPlaying={onCoHostStatusChng}
                onPlay={onCoHostStatusChng}
                onPause={onCoHostStatusChng} />
        </>
    );
}

export default CompositeVideoPlayer;
