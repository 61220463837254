import { isUUID } from "../../RE";

export default function uuid(url) {
    
    const matches = url.match(isUUID);

    return matches
        ? matches[0]
        : undefined;
        
}