import { useDispatch, useSelector, useStore } from 'react-redux'
import { useAPI } from '../Core/app/API'
import { setDisplayHowAreYouFeeling } from '../Core/app/slices/settings/settingsSlice'
import { setIsPlaying } from '../Core/app/slices/session/sessionSlice'
import { learnerSelector, autoMagicSelector } from '../Core/app/store'
import LeftRightChooser from './LeftRightChooser'
import TranslatedText from './TranslatedText'
import './HowAreYouFeeling.css'

export default
function HowAreYouFeeling(props) {
  const dispatch = useDispatch()
  const store = useStore()
  const api = useAPI()
  const setDisplayChooser = (setting) => dispatch(setDisplayHowAreYouFeeling(setting))
  const learnerSettings = useSelector(learnerSelector)
  const autoMagicSettings = useSelector(autoMagicSelector)
  const pacing = learnerSettings.pacing ?? 0 // TODO: sloppy defaults!
  const setPacing = (pacing) => api.changePacing(store, pacing)

  const velocities = autoMagicSettings.velocities
  const lrchoices = velocities.map(vel =>
  {
    const choice = { phrase: vel.uiParameters.name,
                     details: <TranslatedText class='howfeeling-details' translationKey={vel.uiParameters.phrase} />
                   }
    return choice
  })

  const onUpdateSelection = (seld) =>
  {
    dispatch(setIsPlaying(false))
    setPacing(seld)
  }

  if (velocities.length === 0) return null;

  const onDismiss = () =>
  {
    setDisplayChooser(false)
  }

  const panel =
    <div className='howfeeling-container'>
      <div className='howfeeling-box'>
        <LeftRightChooser description='howAreYouFeeling'
                          choices={lrchoices}
                          selected={pacing}
                          onUpdate={onUpdateSelection}
                          fgStyles='controls-fg-color'
                          onClickPhrase={onDismiss}
                          onClickDetails={onDismiss} />
      </div>
    </div>
  return panel
}
