import { useLocation, useNavigate, useParams, Routes, Route, Navigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslate } from '../Translation'
import { logoutHappened } from '../Core/app/slices/autoMagicSlice'
import Button from 'ssi-react-lib/Button'
import CenteredThings from 'ssi-react-lib/CenteredThings'
// import NavPanel from '../Widgets/NavPanel'
import TabButtonGroup from 'ssi-react-lib/TabButtonGroup'
import UserGeneral from './User/General'
import UserLearning from './User/Learning'
import UserPassword from './User/Password'
import './User.scss'

export default function UserSettings({hasLearning})
{
  const location = useLocation()

  if (location.pathname === '/app/settings')
  {
    // TODO: this feels lame...
    if (hasLearning)
    {
      return <Navigate to='learning' />
    } else
    {
      return <Navigate to='general' />
    }
  }
  const swtch =
    <Routes location={location}>
      <Route path=':tab' element={<SettingsLayout hasLearning={hasLearning} />}>
      </Route>
    </Routes>
  return swtch
}

function SettingsLayout({hasLearning})
{
  const params = useParams()
  const navigate = useNavigate()
  const translate = useTranslate()
  const dispatch = useDispatch()

  const tabs =
  [
    hasLearning ? { label: 'Learning', identifier: 'learning', body: <UserLearning/> } : null,
    { label: 'General', identifier: 'general', body: <UserGeneral/> },
    { label: 'Password', identifier: 'password', body: <UserPassword/> }
  ].filter(t => t !== null)
  const selected = tabs.find(tb => tb.identifier === params.tab)

  const onSelect = (tab) =>
  {
    navigate('../' + tab.identifier)
  }

  const onLogout = async () =>
  {
    dispatch(logoutHappened())
    navigate('/login')
  }

  const layout =
    <div className='user-settings'>
      <CenteredThings justify='center'>
        <div className='logout-row user-container'>
          <Button name='logout' class='logout' action={onLogout} label={translate('logout')} />
        </div>
      </CenteredThings>
      <TabButtonGroup tabs={tabs} selected={selected?.identifier} onClick={onSelect} />
      <CenteredThings justify='center'>
        <div className='user-container'>
          {selected?.body}
        </div>
      </CenteredThings>
    </div>
  return layout
}
