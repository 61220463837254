import { useAPI } from '../Core/app/API'
import AddDialog from './AddDialog'

export default function AddDialogComponent(groupId, actionDescription, roles, emailTitle, linkTitle, groupDeterminer, pluralName, sendLabel, refreshInvites, refreshGroup, translate, showAlert)
{
    const api = useAPI()

    const onEmailComplete = (errors, already, success, dismiss) =>
    {
      refreshInvites()
      if (already.length > 0) refreshGroup();
      const successfulMsg = success.length > 0 ? (errors.length > 0 || already.length > 0 ? 'All other emails successfully delivered!' : 'All emails successfully delivered!') : null
      const reportLines = errors.map(err => { return <p>{`${err.email}: ${translate(err.reason)}`}</p> })
      const alreadyLines = already.map(email => { return <p>{email}</p> })
      const errorReport = errors.length > 0 ?
        <div>
          <p>The following email addresses could not be delivered:</p>
          <p></p>
          {reportLines}
        </div> : null
      const alreadyReport = already.length > 0 ?
        <div>
          <p>The following email addresses were already in the system and have been added to {groupDeterminer}:</p>
          <p></p>
          {alreadyLines}
        </div> : null
      const mesg =
        <>
          {errorReport}
          {alreadyReport}
          {successfulMsg}
        </>
      showAlert(mesg, dismiss)
    }
    const onSend = async (emails, onDismiss) =>
    {
      const language = 'en'
      let errorEmails = []
      let alreadyEmails = []
      let successEmails = []
      for (const email of emails)
      {
        const resp = await api.sendInvitation(groupId, roles, email, language)
        if (resp.alreadyExists)
        {
          const error = await api.addToGroup(resp.namedEntity.uuid, roles, groupId)
          if (!error)
          {
            // await updateLearners(selectedClass)
            // showAlert(translate(selected === 1 ? 'managerExistsGroup' : 'learnerExistsGroup'))
            alreadyEmails.push(email)
          } else {
            errorEmails.push({email: email, reason: error.reason})
            // showAlert(translate(error.reason))
          }
        } else {
          const error = resp.error
          if (!error && !resp.mailError)
          {
            successEmails.push(email)
          } else {
            errorEmails.push({email: email, reason: resp.mailError ?? resp.reason})
            // showAlert(translate(resp.reason))
          }
        }
      }
      onEmailComplete(errorEmails, alreadyEmails, successEmails, onDismiss)
    }
    const emailPlaceholder = translate('emailPlaceholder')
    return AddDialog(actionDescription, roles, linkTitle, emailTitle, emailPlaceholder, pluralName, sendLabel, groupId, onSend)
}
