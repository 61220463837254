import SVGIcon from './SVGIcon'

export default function PlusIcon({className, color, onClick})
{
  return <SVGIcon
          name='plus'
          className={className}
          size='63.82 63.87'
          color={color}
          onClick={onClick}
          paths={[
            'M0,31.93c0,2.15,1.81,3.96,3.96,3.96H27.98v24.02c0,2.1,1.81,3.96,3.96,3.96s3.91-1.86,3.91-3.96v-24.02h24.07c2.1,0,3.91-1.81,3.91-3.96s-1.81-3.96-3.91-3.96h-24.07V3.96c0-2.1-1.76-3.96-3.91-3.96s-3.96,1.86-3.96,3.96V27.98H3.96c-2.15,0-3.96,1.81-3.96,3.96Z'
            ]}
          />
}
