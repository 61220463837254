import { useCallback, useState } from 'react'
import Overlay from 'ssi-react-lib/Overlay'
import './Spinner.css';

export function useSpinner(defaultMessage)
{
  const [spinning, setSpinning] = useState(false)
  const [message, setMessage] = useState(defaultMessage)

  const startSpinning = useCallback(mesg =>
  {
    if (mesg) setMessage(mesg);
    setSpinning(true)
  }, [])
  const stopSpinning = useCallback(() =>
  {
    setSpinning(false)
    // we could setMessage(defaultMessage) here if that behavior is desirable
  }, [])

  const spinner =
    <Overlay className='spinny' display={spinning} msg={message} />

  return [startSpinning, stopSpinning, spinner]
}
