import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslate } from '../Translation'
import { useAPI } from '../Core/app/API'
import { useAlertDialog } from '../Hooks/AlertDialog'
import { useConfirmDialog } from '../Hooks/ConfirmDialog'
import { useFormField, useEmailFormField, usePasswordFormField } from '../Hooks/FormFields'
import { useSpinner } from '../Hooks/Spinner'
import Button from 'ssi-react-lib/Button'
import HTML from '../HTML'
import LandingStandout from '../Widgets/LandingStandout'
import Stack from 'ssi-react-lib/Stack'
import './SignupTrials.css'

export default function TrialsSignup(props)
{
  const navigate = useNavigate()
  const translate = useTranslate()
  const api = useAPI()

  const [name, nameField] = useFormField({label: translate('name')})
  const [email, emailField] = useEmailFormField({label: translate('email')})
  const [passwd1, passwdField1] = usePasswordFormField({label: translate('newPassword')})
  const [passwd2, passwdField2] = usePasswordFormField({label: translate('newPasswordAgain')})

  const [buttonEnabled, setButtonEnabled] = useState(false)
  const [startSpinning, stopSpinning, spinOverlay] = useSpinner(translate('settingup'))
  const [explanation, setExplanation] = useState('')
  const [showAlert, alertDialog] = useAlertDialog(translate('ok'))
  const [showConfirm, confirmDialog] = useConfirmDialog(translate('gotoLogin'), translate('tryAnotherEmail'))

  const community = props.community ?? 'welsh'
  const direction = 'ltr'

  useEffect(() => {
    function emailValid(email)
    {
      return /^\w+([.+-]\w+)*@\w+([.-]\w+)*(\.\w{2,6})+$/.test(email);
    }
    const nv1 = name.length > 0
    const nv2 = email.length > 0
    const ev = emailValid(email)
    const pv1 = passwd1.length > 4
    const pv2 = passwd1 === passwd2
    // console.log('nv', nv, 'ev', ev, 'pv', pv, 'valid', nv && ev && pv)
    setButtonEnabled(ev && nv1 && nv2 && pv1 && pv2)
    if (!nv1) {
      setExplanation('Please enter a name')
    } else if (!nv2) {
      setExplanation('Please enter an email address')
    } else if (!ev) {
      setExplanation('The email doesn\'t appear to be valid')
    } else if (!pv2) {
      setExplanation('The passwords don\'t match')
    } else if (!pv1) {
      setExplanation('The password is not long enough')
    }
  }, [name, email, passwd1, passwd2])

  async function onSignup(e)
  {
    if (!buttonEnabled) {
      showAlert(explanation)
      return
    }
    startSpinning()
    // TODO: is hard-coded 'en' the best we can do?
    const result = await api.signupTrial(community, name, email, 'en', passwd1)
    stopSpinning()
    if (result.ok)
    {
      navigate('/app/learn')
    } else {
      if (result.reason === 'accountExistsFor')
      {
        const mesg1 = translate('accountExistsFor').replace(/%EMAIL%/g, email)
        const mesg2 = translate('loginOrTryAgain')
        const mesg = `${mesg1}. ${mesg2}`
        showConfirm(mesg, cancelled =>
        {
          if (!cancelled)
          {
            navigate('/login')
          } else
          {
            // nothing?
          }
        })
      } else
      {
        showAlert(translate(result.reason))
      }
    }
  }

  const disabled = buttonEnabled ? '' : ' disabled'
  const buttonPos = direction === 'rtl' ? ' right' : ''
  const introHTML = translate('trialsSignupIntro').replace(/%COMMUNITY%/g, community)
  const intro = <HTML body={introHTML} />
  const signup =
    <>
      <LandingStandout>
        <Stack className='signup-section'>
          <div>
            {intro}
          </div>
          {nameField}
          {emailField}
          {passwdField1}
          {passwdField2}
          <Button label={translate('trialsSignup')} labelClass='signup-button-label' class={`submit-button signup${disabled}${buttonPos}`} action={onSignup} />
        </Stack>
      </LandingStandout>
      {spinOverlay}
      {alertDialog}
      {confirmDialog}
    </>
  return signup
}
