import { useEffect, useState } from 'react'
import Button from 'ssi-react-lib/Button'
import './OneLineTextEntryField.css'

export default function OneLineTextEntryField({description, decoration, value, placeholder, className, icon, onSubmit})
{
  const [field, setField] = useState(value)
  const [buttonEnabled, setButtonEnabled] = useState(false)

  const onChange = event => {
    setField(event.target.value)
  }

  useEffect(() => {
    setField(value)
  }, [value])
  useEffect(() => {
      if (!field) return;
    setButtonEnabled(field.length > 0)
  }, [field])

  const formSubmit = (e) => {
    e.preventDefault()
    localSubmit()
  }
  const localSubmit = () => {
    if (!buttonEnabled) return;
    if (onSubmit) onSubmit(field)
  }

  const deco = decoration ? <div className='input-decoration'>{decoration}</div> : null
  const disabled = buttonEnabled ? '' : ' disabled'
  const button = icon ? <Button icon={icon} class={`color-button submit-button inline-button${disabled}`} action={localSubmit} /> : null
  const form =
    <div className={className}>
      <div className='one-line-description'>{description}</div>
      <div className='one-line-form-box'>
        <form className='one-line-field-form' onSubmit={formSubmit}>
          {deco}
          <input autoFocus id='field' name='field' type='text' value={field} placeholder={placeholder} onChange={onChange} />
        </form>
        {button}
      </div>
    </div>
  return form
}

