import { useState } from 'react'
import CenteredThings from 'ssi-react-lib/CenteredThings'
import Modal from '../Widgets/Modal'
import OneLineTextEntryField from '../Widgets/OneLineTextEntryField'
import './EditFieldDialog.css'

export function useEditFieldDialog({title, description, placeholder, icon, onSubmit})
{
  const [display, setDisplay] = useState(false)
  const [context, setContext] = useState(null)
  const [value, setValue] = useState(false)

  const onDisplay = (val, context) =>
  {
    setValue(val)
    setContext(context)
    setDisplay(true)
  }

  const onDismiss = () =>
  {
    setDisplay(false)
  }

  const localSubmit = (email) =>
  {
    onSubmit(email, context)
    onDismiss()
  }

  const dialog =
    <Modal display={display} onDismiss={onDismiss}>
      <CenteredThings><div className='edit-field-dialog-title'>{title}</div></CenteredThings>
      <OneLineTextEntryField className='edit-field-entry' description={description} value={value} placeholder={placeholder} icon={icon} onSubmit={localSubmit} />
    </Modal>
  return [onDisplay, dialog]
}
