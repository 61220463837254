import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslate } from './Translation'
import { useAPI } from './Core/app/API'
import { autoMagicSelector } from './Core/app/store'
import { setSessionError } from './Core/app/slices/autoMagicSlice'
import { setIsPlaying } from './Core/app/slices/session/sessionSlice'
import Button from 'ssi-react-lib/Button'
import CenteredThings from 'ssi-react-lib/CenteredThings'
import Modal from './Widgets/Modal'

export default function Session(props)
{
  const api = useAPI()
  const dispatch = useDispatch()
  // bootstrap API & Media - because API fetch / Media needs to be able to dispatch on session exipry or errors,
  // but state isn't setup at the point that API is provided
  api.provideDispatch(dispatch)

  const navigate = useNavigate()
  const translate = useTranslate()
  const [displayAlert, setDisplayAlert] = useState(false)

  const autoMagicSettings = useSelector(autoMagicSelector)
  useEffect(() => {
    if (autoMagicSettings.sessionExpired || autoMagicSettings.sessionError)
    {
      setDisplayAlert(true)
      setIsPlaying(false)
    }
    // we don't clear the alert display.. that's done by the user
  }, [autoMagicSettings.sessionExpired, autoMagicSettings.sessionError])

  const onDismiss = () =>
  {
    setDisplayAlert(false)
    if (autoMagicSettings.sessionExpired) {
      navigate('/login')
    } else {
      dispatch(setSessionError(null)) // they've been notified .. clear so they can try again
    }
  }

  const mesg = autoMagicSettings.sessionExpired ? 'Your session has expired.  You will be re-directed to the login window'
             : autoMagicSettings.sessionError
  const session =
    <>
      {props.children}
      <Modal display={displayAlert} onDismiss={onDismiss}>
        {mesg}
        <CenteredThings topSpacing={12}>
          <Button class='submit-button' label={translate('ok')} action={onDismiss} />
        </CenteredThings>
      </Modal>
    </>
  return session
}
