import { useRef } from 'react'
import Button from 'ssi-react-lib/Button'

export default function EmailBox({title, placeholder, done, onDone})
{
  const inputRef = useRef()

  const onLocalDone = async () =>
  {
    const emailstr = inputRef.current?.value ?? ''
    const emails = emailstr.trim().split(/[,\s]+/).filter(e => e !== '')
    const emails2 = emails.map(e => e.replace(/^mailto:/, '').replace(/[.!#=;:]+$/, ''))
    onDone(emails2)
  }

  const emailBox =
    <>
      <div className='field-label'>{title}</div>
      <textarea autoFocus ref={inputRef} id='suggestion' className='suggestion' rows='10' placeholder={placeholder} maxLength='5000' name='field'>
      </textarea>
      <Button class='submit-button' label={done} action={onLocalDone} />
    </>
  return emailBox
}
