// eslint-disable-next-line import/no-anonymous-default-export
export default `#version 300 es
  precision mediump float;

  in vec2 vTexCoord;
  out vec4 pixel;

  uniform sampler2D presenter;
  uniform sampler2D presenterCross;

  uniform float crossCoeff;

  vec3 rgbToHsv(vec3 rgb) {
    float r = rgb.r;
    float g = rgb.g;
    float b = rgb.b;

    float v = max(r, max(g, b));
    float delta = v - min(r, min(g, b));
    float s;
    float h;

    if (v < 0.001) {
      s = 0.0;
    } else {
      s = delta / v;
    }

    if (r > g && r > b) {
      //greatest component is red
      h = mod((g - b) / delta, 6.0);
    } else if (g > b) {
      //greatest component is green
      h = (b - r) / delta + 2.0;
    } else {
      //greatest component is blue
      h = (r - g) / delta + 4.0;
    }
    h = h * 60.0;
    return vec3(h, s, v);
  }

  vec4 convertColor(vec4 prev) {

    // Parameters
    vec2 hueRange = vec2(60.0, 180.0);
    float satCullThresh = 0.4;
    float valCullThresh = 0.4;
    float satRecolorThresh = 0.15;
    float valRecolorThresh = 0.2;
    float recolorCoefficient = 1.3;

    // Components
    vec3 hsv = rgbToHsv(prev.rgb);
    float h = hsv.x;
    float s = hsv.y;
    float v = hsv.z;

    float r = prev.r;
    float g = prev.g;
    float b = prev.b;


    bool isGreen = h > hueRange[0] && h < hueRange[1];
    //set opacity of some pixels clearly on the greenscreen to 0.0
    if (isGreen && s > satCullThresh && v > valCullThresh) {
      return vec4(0.0, 0.0, 0.0, 0.0);
    }

    //blend boundary pixels
    if (isGreen && s > satRecolorThresh && v > valRecolorThresh) {

      float valEdgeFactor = 1.0 - (valRecolorThresh - v) / (valRecolorThresh - valCullThresh);
      float satEdgeFactor = 1.0 - (satRecolorThresh - s) / (satRecolorThresh - satCullThresh);
      float maxFactor = (1.0 - satRecolorThresh);
      float minFactor = (1.0 - satCullThresh);
      float normalisedEdgeFactor = clamp(max(valEdgeFactor, satEdgeFactor), 0.0, 1.0);

      normalisedEdgeFactor = normalisedEdgeFactor * normalisedEdgeFactor;

      return vec4(prev.r, max(prev.r, prev.b), prev.b, normalisedEdgeFactor);
    }
    return prev;
  }

  vec4 lerp(vec4 from, vec4 to, float t) {
    return from * (1.0 - t) + to * t;
  }

  void main(void) {
    vec4 presenterColor = texture(presenter, vTexCoord);
    vec4 presenterCrossColor = texture(presenterCross, vTexCoord);

    presenterColor = convertColor(presenterColor);
    presenterCrossColor = convertColor(presenterCrossColor);

    presenterColor = lerp(presenterCrossColor, presenterColor, crossCoeff);
    pixel = presenterColor;
  }
`;
