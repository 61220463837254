import './PasswordFormField.css';
import FormField from './FormField';

function PasswordFormField(props) {
  const extraAttrs = {
    type: 'password'
  }
  function onChange(passwd) {
    props.onChange(passwd)
  }
  const field =
    <FormField id='session_passwd' class={props.class} name='password' direction={props.direction} extraAttrs={extraAttrs} onChange={onChange} />
  return field
}

export default PasswordFormField;

