import { useAPI } from '../Core/app/API'
import { useDispatch, useStore } from 'react-redux'
// import { useTranslate } from '../Translation'
import { useListOfLearners } from '../Hooks/ListOfLearners'
import { useShowAsModal } from '../Hooks/ShowAsModal'
import { setDisplaySelectLearner } from '../Core/app/slices/settings/settingsSlice'
import LearnerIcon from 'ssi-react-lib/Icons/Learner'
import List from 'ssi-react-lib/List'
import SchoolIcon from 'ssi-react-lib/Icons/School'
import './BigLearnerChooser.css'

// Rewrite this to use useShowAsModal (rather than the other way around)

export function useBigLearnerChooser()
{
  const dispatch = useDispatch()
  const store = useStore()
  const api = useAPI()
  // const translate = useTranslate()
  const learners = useListOfLearners()

  let dismiss = () => { }
  const learnerClick = async (uuid) =>
  {
    const lnr = learners.find(lnr => lnr.uuid === uuid)
    if (lnr)
    {
      dispatch(setDisplaySelectLearner(false))
      await api.switchToLearner(store, lnr)
      dismiss()
    }
  }
  const learnerItems = learners.map(lnr =>
  {
    const icon = lnr.isPerson ? <LearnerIcon /> : <SchoolIcon />
    const contents =
      <div className='learner-box outlined'>
        {icon} <span className='learner-name'>{lnr.name}</span>
      </div>
    return { label: lnr.name, contents: contents, key: lnr.uuid, meta: lnr.uuid }
  })
  const listOfLearners = <List className='list-of-learners' onClick={learnerClick}>{learnerItems}</List>
  const chooseWhich = 'Please choose which profile to play as'
  // const chooseWhich = translate('chooseLearnerProfile')
  const body =
    <div className='big-learner-chooser'>
      <div className='big-learner-chooser-title'>
        {chooseWhich}
      </div>
      {listOfLearners}
    </div>
  const [showModal, dismissModal, chooser] = useShowAsModal(body)
  dismiss = dismissModal
  return [learners.length > 1, showModal, chooser]
}
