import AddIcon from 'ssi-react-lib/Icons/AddIcon'
import CenteredThings from 'ssi-react-lib/CenteredThings'
import Checkbox from '../Widgets/Checkbox'
import Dropdown from '../Widgets/Dropdown'
import Modal from '../Widgets/Modal'
import OneLineTextEntryField from '../Widgets/OneLineTextEntryField'
import './SchoolsAddClassDialog.css'

export default
function SchoolsAddClassDialog(title, warning, classDescription, classPlaceholder, chooserDescription, groupItems, [display, setDisplay], [selected, setSelected], [playAsClass, setPlayAsClass], onAddLevel, onAddClass)
{
  const onDisplay = () => {
    setDisplay(true)
  }

  const onClassSubmit = (name) =>
  {
    const selectedParam = selected ? selected : (groupItems ? groupItems[0].meta : null)
    if (onAddClass) onAddClass(name, selectedParam, playAsClass)
    setDisplay(false)
  }

  const onDismiss = () =>
  {
    setDisplay(false)
  }

  const onSelect = (uuid) =>
  {
    setSelected(uuid)
  }

  const onPlayAsClassChanged = (e, state) =>
  {
    setPlayAsClass(state)
  }

  const addIcon = <AddIcon fgColor={"white"}/>

  const warningDiv = !warning ? null :
    <div className='warning-notice'>
      {warning}
    </div>
  const playAsClassOption = setPlayAsClass ?
    <>
      <Checkbox className='play-as-class-setting' checked={playAsClass} label='Enable “Play as class”?' onChange={onPlayAsClassChanged} />
      <div className='play-as-class-setting description'>Check the above if you plan to use AutoMagic in the classroom as a group learning activity.</div>
    </> : null
  let dialog =
    <Modal display={display} onDismiss={onDismiss}>
      <CenteredThings><div className='add-schools-dialog-title'>{title}</div></CenteredThings>
      {warningDiv}
      <div className='add-class-group-chooser'>
        <div className='description'>{chooserDescription}</div>
        <Dropdown menuItems={groupItems} selected='' onSelect={onSelect}/>
      </div>
      <OneLineTextEntryField className='add-class-classname' description={classDescription} placeholder={classPlaceholder} icon={addIcon} onSubmit={onClassSubmit} />
      {playAsClassOption}
    </Modal>
  return [onDisplay, dialog]
}
