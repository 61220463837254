import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslate } from '../Translation'
import { useConfirmDialog } from '../Hooks/ConfirmDialog'
import { usePopAndFade } from '../Widgets/PopAndFade'
import joinedClassName from 'ssi-react-lib/joinedClassName'
import CenterNotice from 'ssi-react-lib/CenterNotice'
import CopyUUIDAction from '../Actions/CopyUUIDAction'
import DropDownIconBox from '../Widgets/DropDownIconBox'
import EditGroupDetailsAction from '../Actions/EditGroupDetailsAction'
import GearIcon from 'ssi-react-lib/Icons/Gear'
import GroupSettingsDialog from './GroupSettingsDialog'
import LabelledBoxWithPlus from 'ssi-react-lib/LabelledBoxWithPlus'
import List from 'ssi-react-lib/List'
import TrashEntityAction from '../Actions/TrashEntityAction'
import './GroupsList.css'

export default function GroupsList({className, label, groups, emptyMessage, addGroup, deleteGroup, renameGroup, setPlayAsClass, moveGroup})
{
  const navigate = useNavigate()
  const translate = useTranslate()
  const [selected, setSelected] = useState(null)
  const [showConfirm, confirmDialog] = useConfirmDialog(translate('yes'), translate('cancel'))
  const [popNFade, popNFadeDialog] = usePopAndFade(2000)

  const lastEntered = useRef(null)
  const stateIdx = useRef(0)
  // TODO: abstract and generalize .. I wrote this version out by hand to understand the
  // TODO: state machine, because it's complex due to handling enter/leaves on internal nodes,
  // TODO: but I think it can be distilled (in particular I think all internal nodes can be
  // TODO: generalized to one state (state 3) regardless of nesting level
  const draggy =
  {
    'onDragEnter': e =>
    {
      const elt = e.target.closest('.list-elem')
      if (elt.classList.contains('dragging')) return;

      if (stateIdx.current === 0) // outside draggable
      {
        elt.classList.add('dragndrop-hilight')
        lastEntered.current = elt
        stateIdx.current = 1
      } else if (stateIdx.current === 1) // inside draggable node, but not in any nested nodes
      {
        if (elt === lastEntered.current)
        {
          stateIdx.current = 2
        } else {
          // note: very similar to state 0 action
          elt.classList.add('dragndrop-hilight')
          lastEntered.current = elt
          stateIdx.current = 5
        }
      } else if (stateIdx.current === 3) // inside nested node
      {
        if (elt === lastEntered.current)
        {
          stateIdx.current = 4
        } else {
          // note: very similar to state 0 action
          elt.classList.add('dragndrop-hilight')
          lastEntered.current = elt
          stateIdx.current = 6
        }
      }
    },
    'onDragLeave': e =>
    {
      const elt = e.target.closest('.list-elem')
      if (stateIdx.current === 1) // inside draggable node, but not in any nested nodes
      {
        if (elt === lastEntered.current)
        {
          elt.classList.remove('dragndrop-hilight')
          lastEntered.current = null
          stateIdx.current = 0
        }
      } else if (stateIdx.current === 2) // transitioning to nested node
      {
        stateIdx.current = 3
      } else if (stateIdx.current === 3) // inside nested node
      {
        // can only be a leave of B
        elt.classList.remove('dragndrop-hilight')
        lastEntered.current = null
        stateIdx.current = 0
      } else if (stateIdx.current === 4) // transitioning to draggable outer node
      {
        stateIdx.current = 1
      } else if (stateIdx.current === 5) // transitioning from one draggable directly to another
      {
        elt.classList.remove('dragndrop-hilight')
        stateIdx.current = 1
      } else if (stateIdx.current === 6) // transitioning from nested node directly to another draggable
      {
        // same as 5 ... collapse 6 to 5?
        elt.classList.remove('dragndrop-hilight')
        stateIdx.current = 1
      }

    },
    'onDragOver': e =>
    {
      e.preventDefault()
    },
    'onDrop': e =>
    {
      const draggedItem = e.dataTransfer.getData('text')
      const elt = e.target.closest('.list-elem')
      elt.classList.remove('dragndrop-hilight')
      lastEntered.current = null
      stateIdx.current = 0

      if (draggedItem === elt.dataset.meta) return;

      // get names ...
      const draggedGroup = groups.find(grp => grp.uuid === draggedItem)
      const targetGroup = groups.find(grp => grp.uuid === elt.dataset.meta)
      showConfirm(`Move "${draggedGroup.name}" to be a subfolder of "${targetGroup.name}"?`, (cancelled) =>
      {
        if (cancelled) return;
        moveGroup(draggedItem, elt.dataset.meta)
      })
    }
  }

  const onClick = (uuid) =>
  {
    const link = `/app/groups/group/${uuid}`
    navigate(link)
  }

  const onClickGear = (e) =>
  {
    e.preventDefault()
    e.stopPropagation()
    const row = e.target.closest('.list-elem')
    const uuid = row.dataset.meta
    console.log('UUID', uuid)
    if (uuid === selected)
    {
      setSelected(null)
    } else {
      setSelected(uuid)
    }
  }

  const onRename = async (name, guid) =>
  {
    if (!renameGroup) return;
    await renameGroup(guid, name)
  }
  const onChecked = async (uuid, checked, undo, success) =>
  {
    setPlayAsClass(uuid, checked, undo, success)
  }
  const [showRenameGroup, renameGroupDialog] = GroupSettingsDialog(onRename, onChecked)

  const groupItems = groups.map(grp =>
  {
    const isSelected = selected && grp.uuid === selected
    const controls =
      <DropDownIconBox active={isSelected} onClick={onClickGear}>
        <CopyUUIDAction popNFade={popNFade} />
        <EditGroupDetailsAction editGroupDetails={showRenameGroup} />
        <TrashEntityAction deleteEntity={deleteGroup} />
      </DropDownIconBox>
    const contents =
      <>
        <div className='group-list-row'>
          <div className='group-list-name'>{grp.name}</div>
          <div className='group-list-controls'>
            <GearIcon onClick={onClickGear}/>
          </div>
        </div>
        {controls}
      </>
    return { label: grp.name, key: grp.uuid, meta: grp.uuid, draggable: true, contents: contents }
  })
  const groupItemsSorted = groupItems.sort((a, b) =>
  {
    return a.label.localeCompare(b.label, undefined, { numeric: true })
  })
  const list = groupItems.length > 0 ?
    <List className='icon-decorated entity' addtlEltAttrs={draggy} onClick={onClick}>
      {groupItemsSorted}
    </List> : null
  const emptyList = emptyMessage ?
    <CenterNotice>
      {emptyMessage}
    </CenterNotice> : null
  const groupsList = list ?? emptyList

  const result =
    <>
      <LabelledBoxWithPlus className={joinedClassName('groups', className)} label={label} onAdd={addGroup}>
        {groupsList}
      </LabelledBoxWithPlus>
      {renameGroupDialog}
      {confirmDialog}
      {popNFadeDialog}
    </>
  return result
}
