import joinedClassName from './joinedClassName'
import './Row.css'

export default function Row({className, children})
{
  const row =
    <div className={joinedClassName('row', className)}>
      {children}
    </div>
  return row
}
