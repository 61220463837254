import { createSlice } from '@reduxjs/toolkit'
import { logoutHappened } from '../autoMagicSlice'
import { defaultDescriptor } from './speed'

export const initialState = {
    speed: defaultDescriptor,
    displayHowAreYouFeeling: true,
    displaySelectLearner: false
}

export const settingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        setMode: (state, action) => {
            state.mode = action.payload
        },
        setDisplayHowAreYouFeeling: (state, action) => {
            state.displayHowAreYouFeeling = action.payload
        },
        setDisplaySelectLearner: (state, action) => {
            state.displaySelectLearner = action.payload
        }
    },
    extraReducers: builder =>
    {
        builder.addCase(logoutHappened, (state, action) =>
        {
            return initialState
        })
    }
})

export const { 
    setMode,
    setDisplayHowAreYouFeeling,
    setDisplaySelectLearner
} = settingsSlice.actions

export default settingsSlice.reducer
