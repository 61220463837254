import './LabelWithNumber.css'

export default
function LabelWithNumber({label, number, singular, plural})
{
  const classifier = number === 1 ? singular : plural
  const lwn =
    <>
      <span className='lwn-label'>{label}</span>
      <span className='lwn-number'>({number} {classifier})</span>
    </>
  return lwn
}
