import { useEffect, useState } from 'react'
import { useAuth } from '../../Auth'
import { useAPI } from '../../Core/app/API'
import { useTranslate } from '../../Translation'
import { useAlertDialog } from '../../Hooks/AlertDialog'
import { usePopAndFade } from '../../Widgets/PopAndFade'
import { useSpinner } from '../../Hooks/Spinner'
import Button from 'ssi-react-lib/Button'
import TranslatedText from '../../Widgets/TranslatedText'

export default function UserGeneral()
{
  const auth = useAuth()
  const user = auth.user()
  const api = useAPI()
  const direction = user?.language?.direction ?? 'ltr'
  const translate = useTranslate()
  const [showAlert, alertDialog] = useAlertDialog(translate('ok'))
  const [startSpinning, stopSpinning, spinOverlay] = useSpinner(translate('loading'))
  const [popNFade, popNFadeDialog] = usePopAndFade(2000)
  const [name, setName] = useState(user.name)
  const [email, setEmail] = useState(user.emails[0])
  const [buttonEnabled, setButtonEnabled] = useState(false)

  const onName = event =>
  {
    setName(event.target.value)
  }
  const onEmail = event =>
  {
    if (user.hasPassword)
    {
      setEmail(event.target.value)
    } else
    {
      showAlert('Your account is linked to an account on www.saysomethingin.com. Please login at this site to update your email')
    }
  }
  useEffect(() =>
  {
    const nameDiff = name !== user.name
    const emailDiff = !user.emails.includes(email)
    setButtonEnabled(nameDiff || emailDiff)
  }, [user, name, email])
  const onUpdate = async () =>
  {
    if (!buttonEnabled)
    {
      popNFade('No update necessary since you haven’t changed either name or email')
      return
    }
    startSpinning()
    const error = await api.updateUser({ name: name, email: email })
    stopSpinning()
    if (error) {
      showAlert(error)
    } else {
      popNFade(translate('updateSuccess'))
    }
  }

  const disabled = buttonEnabled ? '' : ' disabled'
  const general =
    <>
      <TranslatedText class='user-label' translationKey='name' />
      <input className='form-field' id='user_name' name='name' type='text' dir={direction} value={name} onChange={onName} />
      <TranslatedText class='user-label' translationKey='email' />
      <input className='form-field' id='user_email' name='email' type='text' dir={direction} value={email} onChange={onEmail} />
      <Button name='update' class={`submit-button below${disabled}`} action={onUpdate} label={translate('update')} />
      {spinOverlay}
      {alertDialog}
      {popNFadeDialog}
    </>
  return general
}
