import { useAuth } from '../Auth'
import { useGroups } from '../Hooks/Groups'

export function useListOfLearners(startSpinning, stopSpinning)
{
  const auth = useAuth()
  const groups = useGroups(startSpinning, stopSpinning)

  const user = auth.user()
  const userLearner = { isPerson: true, ...user }
  const playingAsPotentials = [userLearner].concat(groups)
  let playingAsChoices = []
  for (let pot of playingAsPotentials) {
    if (pot.learners) playingAsChoices = playingAsChoices.concat(pot.isPerson ? pot.learners.map(lnr => { return { isPerson: true, ...lnr } }) : pot.learners)
  }
  return playingAsChoices
}
