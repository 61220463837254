import { useEffect } from "react";

export default function useWindowSize(callback) {
    
    useEffect(() => {

        function handleResize() {

            callback({
                width: window.innerWidth,
                height: window.innerHeight,
            });
            
        }

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
        
    }, [callback])
    
}
