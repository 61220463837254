import './EmailFormField.css';
import FormField from './FormField';

function EmailFormField(props) {
  const extraAttrs = {
    type: 'email',
    autoComplete: 'off',
    autoCorrect: 'off',
    autoCapitalize: 'off'
  }
  function onChange(email) {
    props.onChange(email)
  }
  const field =
    <FormField id='session_email' class={props.class} name='email' value={props.value} direction={props.direction} extraAttrs={extraAttrs} onChange={onChange} />
  return field
}

export default EmailFormField;
