import SVGIcon from './SVGIcon'

export default function ExclamBackwardIcon({className, color, onClick})
{
  return <SVGIcon
          name='exclam-backward'
          className={className}
          size='86.82 79.31'
          color={color}
          onClick={onClick}
          paths={[
            'm47.11,79.06c21.53,0,39.45-17.87,39.45-39.4S68.65.25,47.11.25,7.71,18.07,7.71,39.61h7.47c0-17.77,14.16-31.88,31.93-31.88s31.98,14.16,31.98,31.93-14.21,32.08-31.98,31.98c-10.45-.05-19.68-4.93-25.44-12.6-1.32-1.81-3.42-2.34-5.22-1.22-1.81,1.07-2.25,3.52-.78,5.42,7.37,9.52,18.75,15.82,31.45,15.82ZM3.26,32.04c-3.08,0-3.81,2.1-2.15,4.44l8.69,12.35c1.37,1.9,3.42,1.9,4.74,0l8.69-12.4c1.61-2.29.83-4.39-2.2-4.39H3.26Z',
            'm47.11,46.59c2.05,0,3.27-1.17,3.32-3.37l.59-20.02c.05-2.2-1.71-3.81-3.96-3.81s-3.96,1.56-3.91,3.76l.59,20.07c0,2.2,1.22,3.37,3.37,3.37Zm0,12.79c2.39,0,4.49-1.9,4.49-4.3s-2.05-4.3-4.49-4.3-4.49,1.9-4.49,4.3,2.1,4.3,4.49,4.3Z'
            ]}
          />
}
