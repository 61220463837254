import ExclamBackwardIcon from 'ssi-react-lib/Icons/ExclamBackward'

export default function ExpireTrialAction({expireTrial})
{
  const onExpire = async (e) =>
  {
    e.preventDefault()
    e.stopPropagation()
    const row = e.target.closest('.list-elem')
    const guid = row.dataset.meta
    console.log('EXPIRE!')
    await expireTrial(guid)
  }

  const icon = <ExclamBackwardIcon className='admin-expire' onClick={onExpire}/>
  return icon
}
