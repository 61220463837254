import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from 'ssi-react-lib/Button'
import CenteredThings from 'ssi-react-lib/CenteredThings'
import PlayIcon from 'ssi-react-lib/Icons/Play'
import './OnBoardLanding.css'

export default function OnBoardLanding(props)
{
  const navigate = useNavigate()
  const video = useRef()
  const [faded, setFaded] = useState(false)

  const play = () =>
  {
    setFaded(true)
    video.current?.play()
  }

  const letsgo = () =>
  {
    navigate('/app')
  }

  const overlayFaded = faded ? ' faded' : ''

  const page =
    <div className='onboard-video-container'>
      <div className='onboard-video-box'>
        <div className='onboard-banner'>
          Welcome to AutoMagic!
        </div>
        <div className='onboard-instructions'>
          Watch the video below to get started…
        </div>
        <div className='video-overlay-rel'>
          <div className={`video-overlay${overlayFaded}`}>
            <PlayIcon color='white' onClick={play} />
          </div>
        <video ref={video} className='onboard-video' src='assets/onboarding-video.mp4' />
        </div>
        <div className='onboard-letsgo'>
          <CenteredThings>
            <Button class='submit-button' label="Got it - let's go!" action={letsgo} />
          </CenteredThings>
        </div>
      </div>
    </div>
  return page
}
