export default function SendIcon(props) {
  const color = props.color != null ? props.color : "currentColor"
  let icon =
    <svg className="icon send-icon" viewBox="0 0 71.14 71.19">
      <path fill={color}
        d="M12.45,71.19H58.69c8.35,0,12.45-4.1,12.45-12.3V12.3c0-8.2-4.1-12.3-12.45-12.3H12.45C4.15,0,0,4.1,0,12.3V58.89c0,8.2,4.15,12.3,12.45,12.3Z"/>
      <path fill={props.fgColor}
        d="M35.64,55.52c-2,0-3.42-1.42-3.42-3.42v-17.82l.39-9.08-3.71,4.69-4.1,4.15c-.63,.63-1.46,.98-2.39,.98-1.9,0-3.32-1.42-3.32-3.27,0-.93,.29-1.76,.93-2.34l12.99-12.89c.93-.93,1.61-1.32,2.64-1.32s1.76,.39,2.64,1.32l12.99,12.89c.59,.59,.93,1.42,.93,2.34,0,1.86-1.46,3.27-3.32,3.27-.98,0-1.81-.29-2.44-.98l-4-4.15-3.81-4.74,.39,9.13v17.82c0,2-1.42,3.42-3.37,3.42Z"/>
    </svg>
  return icon
}
