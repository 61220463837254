import { useEffect, useState } from 'react'
import { useAPI } from '../Core/app/API'
import CenteredThings from 'ssi-react-lib/CenteredThings'
import Checkbox from '../Widgets/Checkbox'
import Modal from '../Widgets/Modal'
import OneLineTextEntryField from '../Widgets/OneLineTextEntryField'
import SendIcon from 'ssi-react-lib/Icons/SendIcon'
import './GroupSettingsDialog.css'

export default
function GroupSettingsDialog(onAdd, onChecked)
{
  const api = useAPI()
  const [display, setDisplay] = useState(false)
  const [context, setContext] = useState(null)
  const [group, setGroup] = useState(null)
  const [groupStale, setGroupStale] = useState(true)

  const onDisplay = (context) => {
    setDisplay(true)
    setContext(context)
  }

  const updateGroupInfo = async () =>
  {
    const info = await api.group(context)
    setGroup(info)
    setGroupStale(false)
  }
  useEffect(() => {
    if (!context) return;
    updateGroupInfo()
  }, [context, groupStale]) // eslint-disable-line react-hooks/exhaustive-deps -- updateGroupInfo

  const onSubmit = (name) =>
  {
    if (onAdd)
    {
      onAdd(name, context)
      setGroupStale(true)
    }
    setDisplay(false)
  }

  const onDismiss = () =>
  {
    setDisplay(false)
  }

  const onPlayAsClassChanged = (e, checked, undo) =>
  {
    onChecked(context, checked, undo, () =>
    {
      setGroupStale(true)
    })
  }

  const name = group?.name ?? ''
  const playAsClassChecked = group ? group.learners.length > 0 : false

  const title = 'Edit SubGroup'
  const description = 'Edit Name:'
  const placeholder = 'subgroup name'
  const icon = <SendIcon fgColor={"white"}/>
  const dialog =
    <Modal display={display} onDismiss={onDismiss}>
      <CenteredThings><div className='group-settings-dialog-title'>{title}</div></CenteredThings>
      <OneLineTextEntryField className='edit-group-dialog' description={description} value={name} placeholder={placeholder} icon={icon} onSubmit={onSubmit} />
      <Checkbox className='play-as-class-setting' checked={playAsClassChecked} label='Enable “Play as class”?' onChange={onPlayAsClassChanged} />
    </Modal>
  return [onDisplay, dialog]
}
