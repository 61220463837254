import React from 'react'
import joinedClassName from './joinedClassName'
import './CenteredThings.css'

export default
function CenteredThings(props) {
  const justify = props.justify ?? 'around'
  const ctClasses = `centered-things centered-things-${justify}`
  let style = { };
  if (props.topSpacing !== null) style.marginTop = props.topSpacing + 'px';
  const container =
    <div className={joinedClassName(ctClasses, props.className)} style={style}>
      {props.children}
    </div>
  return container
}
