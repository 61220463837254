import { useRef } from 'react'
import { useSelector } from 'react-redux'
import { useMedia } from '../Core/Loader'
import { CSSTransition } from 'react-transition-group'
import Stringify from '../Core/Stringify'
import { learnerSelector, sessionSelector, autoMagicSelector } from '../Core/app/store'
import { ManifestKind } from '../Core/app/slices/session/sessionSlice'
import { playingStates } from '../Core/app/PuppetEngine/PuppetEngine'
import uuidToUrl from '../Core/app/PuppetEngine/uuid-to-url'
import Button from 'ssi-react-lib/Button'
import PlayPauseIcon from 'ssi-react-lib/Icons/PlayPause'
import './Debug.css'

export default
function DebugView({display, onPlay})
{
  const nodeRef = useRef(null)
  const loader = useMedia()
  const settings = useSelector(learnerSelector)
  const session = useSelector(sessionSelector)
  const amSettings = useSelector(autoMagicSelector)
  const { itemQueue, ...session2 } = session
  const { velocities, ...amSettings2 } = amSettings
  // delete session2.itemQueue
  session2.playingState = playingStates[session2.playingStateIndex]
  const audio = document.querySelector('audio')
  if (audio)
  {
    session2.playbackRate = audio.playbackRate
  }
  // delete session2.playingStateIndex
  const mode = '\n' + settings.mode
  const queue = itemQueue.map(item =>
  {
    const snts = item.sentences.map((s,i) => `    ${i}. ${s.sourceText} / ${s.targetText}`).join('\n')
    let descr
    switch (item.kind) {
        case ManifestKind.infoVideo: descr = `InfoVideo: ${item.title}`; break;
        case ManifestKind.section: descr = `Section: ${item.title}`; break;
        case ManifestKind.summary: descr = `Summary for ${item.uuid}`; break;
        case ManifestKind.encouragement: descr = 'Encouragement'; break;
        case ManifestKind.randomWalk: descr = 'RandomWalk'; break;
        case ManifestKind.announcement: descr = 'Announcement'; break;
        case ManifestKind.expiration: descr = 'Expiration'; break;
    }
    const tem = descr + '\n' + snts
    return tem
  }).join('\n')

  const { isPlaying } = useSelector(sessionSelector)
  const playClass = 'transport play debug' + (isPlaying ? ' active' : '')
  const playButton = <Button name='play' action={onPlay} icon=<PlayPauseIcon /> class={playClass} />
  const contents = Stringify({session: session2, settings: settings, amSettings: amSettings2, index: settings.entry })
  const view =
    <CSSTransition nodeRef={nodeRef} in={display} classNames='modal2' timeout={300} unmountOnExit>
      <div ref={nodeRef} className='debugView'>
        {playButton}
        <div className='code'>
          {contents}
          <div className='debug-learningMode'>{mode}</div>
          {queue}
        </div>
      </div>
    </CSSTransition>
  return view
}
