import { Provider } from "react-redux"
import { makeStore } from "./Core/app/store"
import { useAPI } from "./Core/app/API"
import { useMedia } from './Core/Loader'

export default function AutoMagicProvider(props) {
  const api = useAPI()
  const loader = useMedia()
  const store = makeStore(api, loader)

  /*
  const unsubscribe = store.subscribe(() =>
    console.log('State after dispatch: ', store.getState())
  ) */

  const prov =
    <Provider store={store} loader={loader} >
      {props.children}
    </Provider>

  return prov
}
