import joinedClassName from './joinedClassName'
import './Stack.css'

export default function Stack({className, children})
{
  const row =
    <div className={joinedClassName('stack', className)}>
      {children}
    </div>
  return row
}
