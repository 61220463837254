import { useEffect, useState } from 'react'
import { useSelector, useStore } from 'react-redux'
import { useAuth } from '../Auth'
import { useAPI } from '../Core/app/API'
import { autoMagicSelector } from '../Core/app/store'
import CourseChooser from './CourseChooser'
import Dropdown from './Dropdown'
import TranslatedText from './TranslatedText'

export default function LearnerChooser(props)
{
  const store = useStore()
  const api = useAPI()
  const learner = useSelector(autoMagicSelector).learner
  const [groups, setGroups] = useState([])
  // comment this out for now .. until we fix inheritance/narrowing of learner courses
  // const [courses, setCourses] = useState(null)
  useEffect(() =>
  {
    (async () =>
    {
      const grps = await api.groupsManages()
      if (!grps) return
      setGroups(grps)
    })()
  }, [api])
  /*
  useEffect(() => {
    for (const grp of groups) {
      for (const lnr of grp.learners) {
        if (lnr.uuid === learner.uuid) {
          setCourses(lnr.courses)
          return
        }
      }
    }
    setCourses(null) // the chooser will use the person's set of courses
  }, [learner, groups]) */
  const auth = useAuth()
  const playingAsPotentials = [auth.user()].concat(groups)
  let playingAsChoices = []
  for (let pot of playingAsPotentials) {
    if (pot.learners) playingAsChoices = playingAsChoices.concat(pot.learners)
  }
  const menuItems = playingAsChoices.map(grp => { return { label: grp.name, key: grp.uuid, meta: { uuid: grp.uuid, name: grp.name } } }).sort((a, b) => a.label.localeCompare(b.label))
  const onSelectLearner = async (learner) =>
  {
    if (props.onSelect) props.onSelect(learner);
    await api.switchToLearner(store, learner)
  }
  const learnerChooser = (menuItems.length > 1 && learner) ?
    <>
      <TranslatedText class='playing-as' translationKey='playingAs' />
      <Dropdown menuItems={menuItems} selected={learner.name} onSelect={onSelectLearner}/>
    </> : null
  const courseChooser = props.withCourseChooser ?
    <div className='settings-box below'>
      <TranslatedText class='playing-as' translationKey='selectedCourse' />
      <CourseChooser onError={props.onError} />
    </div> : null
  const chooser =
  learnerChooser || courseChooser ?
    <>
      {learnerChooser}
      {courseChooser}
    </> : null
  return chooser
}
