import { useState } from 'react'
import { useAPI } from '../Core/app/API'
import { usePopAndFade } from '../Widgets/PopAndFade'
import Button from 'ssi-react-lib/Button'
import CenteredThings from 'ssi-react-lib/CenteredThings'
import CloseButton from 'ssi-react-lib/CloseButton'
import EmailBox from '../Widgets/EmailBox'
import Modal from '../Widgets/Modal'
import './AddDialog.css'

export default
function AddDialog(title, linkTypes, linkTitle, emailTitle, emailPlaceholder, pluralName, sendLabel, guuid, onSend)
{
  const [display, setDisplay] = useState(false)
  const [popNFade, popNFadeDialog] = usePopAndFade(3000)

  const onDisplay = () => {
    // setEntry('')
    setDisplay(true)
  }

  const onDismiss = () =>
  {
    setDisplay(false)
  }

  const localSend = (emails) =>
  {
    onSend(emails, onDismiss)
  }

  const linkChooser = linkTitle ? LinkChooser(linkTitle, pluralName, linkTypes, guuid, popNFade) : null
  let dialog =
    <Modal display={display}>
      <CloseButton onClick={onDismiss} />
      <CenteredThings><div className='add-dialog-title'>{title}</div></CenteredThings>
      <EmailBox title={emailTitle} placeholder={emailPlaceholder} done={sendLabel} onDone={localSend} />
      {linkChooser}
      {popNFadeDialog}
    </Modal>
  return [onDisplay, dialog]
}

function LinkChooser(title, pluralName, linkTypes, guuid, popNFade)
{
  const api = useAPI()

  const onSubmit = () =>
  {
    const linkText = new ClipboardItem({ 'text/plain' :
      api.generateLink(guuid, linkTypes, 1000)
      .then(link => new Blob([link], { type: 'text/plain' }))
    })
    navigator.clipboard.write([linkText])
    .then(_ => popNFade('Link copied to clipboard!'))
  }

  const chooser =
    <>
      <div className='field-label canolfan'>{title}</div>
      <div className='chooser-box canolfan'>
        <Button label='Copy Link' class='inset-button submit-button' action={onSubmit} />
      </div>
    </>
  return chooser
}
