import { useEffect, useState } from 'react'
import { useNavigate, useLocation, useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useAuth } from '../Auth'
import { useAPI } from '../Core/app/API'
import { useTranslate } from '../Translation'
import { automagickEndpoint } from '../Core/app/API'
import { loginHappened } from '../Core/app/slices/autoMagicSlice'
import { setDisplaySelectLearner } from '../Core/app/slices/settings/settingsSlice'
import { useAlertDialog } from '../Hooks/AlertDialog'
import { useEmailFormField, usePasswordFormField } from '../Hooks/FormFields'
import { useSpinner } from '../Hooks/Spinner'
import Button from 'ssi-react-lib/Button'
import LandingStandout from '../Widgets/LandingStandout'
import Stack from 'ssi-react-lib/Stack'
import PasswordFormField from 'ssi-react-lib/PasswordFormField'
import TranslatedText from '../Widgets/TranslatedText'
import './Login.scss'

export function LoginPage() {
  const params = useParams()
  const page =
    <LandingStandout className='login-standout'>
      {params.context ? <Login context={params.context} /> : <Login />}
    </LandingStandout>
  return page
}

export function LoginWithPage() {
  const params = useParams()
  const page =
    <LandingStandout className='login-standout'>
      {params.guid ? <Login guid={params.guid} /> : <Login />}
    </LandingStandout>
  return page
}

export function Login({context, guid, extraItems})
{
  const navigate = useNavigate()
  const translate = useTranslate()
  const api = useAPI()
  const location = useLocation()
  const auth = useAuth()
  const dispatch = useDispatch()

  const from = location.state?.from?.pathname || "/app"
  const [email, emailField] = useEmailFormField({})
  const [passwd, passwdField] = usePasswordFormField({})
  const [startSpinning, stopSpinning, spinOverlay] = useSpinner(translate('loggingin'))
  const [showAlert, alertDialog] = useAlertDialog(translate('ok'))

  async function onLogin(e)
  {
    startSpinning()
    auth.login(email, passwd, async (user) => {
      let message
      if (guid) {
        const error = await api.addOnLogin(guid)
        console.log('ERROR', error)
        if (error) {
          console.log('FO1')
          message = translate('problemAddingInvitation')
        } else {
          console.log('FO2')
          message = translate('successAddingInvitation')
        }
      }
      stopSpinning()
      const finishUp = () => {
        dispatch(loginHappened(user))
        dispatch(setDisplaySelectLearner(true))
        navigate(from, { replace: true })
      }
      if (message) {
        showAlert(message, () => {
          finishUp()
        })
      } else {
        finishUp()
      }
      /*
      dispatch(loginHappened(user))
      dispatch(setDisplaySelectLearner(true))
      navigate(from, { replace: true }) */
    }, () => {
      stopSpinning()
      showAlert(translate('passwordWrong'))
    })
  }

  function onForgot(e) {
    const suffix = email.length > 0 ? '/' + encodeURIComponent(email) : ''
    const uri = '/forgot' + suffix
    navigate(uri)
  }

  const prelude = context ? <div><TranslatedText class='login-prelude' translationKey={`loginPrelude${context}`} /></div> : null
  const loginButtonLabel = translate(context ? `login${context}` : 'login')
  const login =
    <>
      <Stack>
        {prelude}
        <div>
          <TranslatedText class='login-field-label' translationKey='email' />
          {emailField}
        </div>
        <div>
          <TranslatedText class='login-field-label' translationKey='password' />
          {passwdField}
        </div>
        <Button label={translate('forgotPassword')} class='forgot login' noBorder action={onForgot} />
        <Button label={loginButtonLabel} labelClass='login-button-label' class='submit-button login wide' action={onLogin} />
        {extraItems}
      </Stack>
      {spinOverlay}
      {alertDialog}
    </>
  return login
}

export function Forgot()
{
  const params = useParams()
  const navigate = useNavigate()
  const translate = useTranslate()
  const api = useAPI()
  const [startSpinning, stopSpinning, spinOverlay] = useSpinner(translate('sending'))
  const [showAlert, alertDialog] = useAlertDialog(translate('ok'))

  const initialEmail = params.email ?? ''
  const [email, emailField] = useEmailFormField({initialValue: initialEmail})

  const onCancel = () =>
  {
    navigate('/login')
  }

  const onSubmit = async () =>
  {
    startSpinning()
    const result = await api.forgot(email)
    stopSpinning()
    if (result.error) {
      showAlert(result.reason)
    }
    else
    {
      showAlert(translate('forgotEmailSent'))
    }
  }
  const formSubmit = (e) => {
    e.preventDefault()
    onSubmit()
  }

  const forgot =
    <>
      <LandingStandout>
        <Stack>
          <div className='login-field-label'>{translate('forgotEmailHeader')}</div>
          <form onSubmit={formSubmit}>
            {emailField}
          </form>
          <div className='forgot-buttons'>
            <Button label={translate('cancel')} class='button login' action={onCancel} />
            <Button label={translate('submit')} class='submit-button login' action={onSubmit} />
          </div>
        </Stack>
      </LandingStandout>
      {spinOverlay}
      {alertDialog}
    </>
  return forgot
}

export function ResetPassword()
{
  const params = useParams()
  const dispatch = useDispatch()
  const translate = useTranslate()
  const navigate = useNavigate()

  const [showAlert, alertDialog] = useAlertDialog(translate('ok'))

  const [passwd1, setPasswd1] = useState('')
  const [passwd2, setPasswd2] = useState('')
  const [buttonEnabled, setButtonEnabled] = useState(false)
  const [explanation, setExplanation] = useState('')
  const [startSpinning, stopSpinning, spinOverlay] = useSpinner(translate('resetting'))

  function passwd1Change(newPasswd) {
    setPasswd1(newPasswd)
  }
  function passwd2Change(newPasswd) {
    setPasswd2(newPasswd)
  }
  useEffect(() => {
    const pv1 = passwd1.length > 4
    const pv2 = passwd1 === passwd2
    // console.log('nv', nv, 'ev', ev, 'pv', pv, 'valid', nv && ev && pv)
    setButtonEnabled(pv1 && pv2)
    if (!pv2) {
      setExplanation('The passwords don\'t match')
    } else if (!pv1) {
      setExplanation('The password is not long enough')
    }
  }, [passwd1, passwd2])

  const onSubmit = async () =>
  {
    if (!buttonEnabled) {
      showAlert(explanation)
      return
    }
    let json = { guid: params.guid, password: passwd1 }
    const endpoint = 'reset-password'
    startSpinning()
    const response = await fetch(automagickEndpoint(endpoint),
                                 { method: 'post',
                                   credentials: 'include',
                                   body: JSON.stringify(json),
                                   headers: { 'Content-Type': 'application/json' } })
    stopSpinning()
    if (response.ok) {
      // setLoggedIn(true)
      const user = await response.json()
      dispatch(loginHappened(user))
      const onOk = () =>
      {
        navigate('/')
      }
      showAlert(translate('pwResetSuccess'), onOk)
    } else {
      const json = await response.json()
      showAlert(json.reason)
    }
  }

  const disabled = buttonEnabled ? '' : ' disabled'
  const reset =
    <>
      <LandingStandout>
        <Stack>
          <div className='login-field-label'>{translate('newPassword')}</div>
          <PasswordFormField onChange={passwd1Change}/>
          <div className='login-field-label'>{translate('newPasswordAgain')}</div>
          <PasswordFormField onChange={passwd2Change}/>
          <Button label={translate('submit')} class={`submit-button login wide${disabled}`} action={onSubmit} />
        </Stack>
      </LandingStandout>
      {spinOverlay}
      {alertDialog}
    </>
  return reset
}
