import React from 'react'
import { autoMagicSelector, learnerSelector, sessionSelector } from './store'
import { setUser, setLearner, setCourses, setVelocities, setSessionExpired, setSessionError, setAnnouncement, setExpired, setOnboardingUser, settingUp, loginHappened, preloadItem } from './slices/autoMagicSlice'
import { LearningMode, initCursor, setCourse, setSection, setEntry, setPacing, setTotalTime, setTextHidden, setVideoHidden } from './slices/learnerSlice'
import { ManifestKind, loadItems, resetSessionState, resetSections, reloadQueue, setIsPlaying, setText } from './slices/session/sessionSlice'
import SerialQueue from '../SerialQueue'

export function automagickEndpoint(leaf) {
  const location = window.location;
  return `${location.origin}/api/v1/${leaf}`;
}

let APIContext = React.createContext(null)

export default function APIProvider(props)
{
    let myDispatch
    const serialQueue = SerialQueue()
    let prevAbortController // used by change pacing only, at this point

    async function fetcher(endpoint, options)
    {
        if (!endpoint) {
            console.log('undef endpoint!  WHo is calling?')
        }
        try {
            const result = await fetch(endpoint, options)
            if (result.ok) return result;
            if (result.status === 401 || result.status === 403)
            {
                if (myDispatch) myDispatch(setSessionExpired(true));
            }
            return result
        } catch (error) {
            if (error.name === "AbortError")
            {
                // console.log('ABORT!')
                throw(error)
            }
            // There's no useful information in the error object .. typical javascript!
            // But it seems that there's few reasons to throw an exception, and it's fairly
            // catastrophic, so just flag up a general "there's a problem with the network"
            // message.
            const mesg = "There appears to be a problem with the network—possibly you are not connected.  Please check your network settings and try again.  If this persists, or you know you have a good network connection, please contact support@saysomethingin.com"
            if (myDispatch) myDispatch(setSessionError(mesg));
            return null
        }
    }

    async function fetchAndDecode(endpoint, options, errorValue)
    {
        const result = await fetcher(automagickEndpoint(endpoint), { credentials: 'include', ...options })
        if (!result) return null; // usually due to a network error
        const body = await result.json()
        if (result.ok) return body;

        if (body.reason)
        {
            if (myDispatch) myDispatch(setSessionError(body.reason));
        }
        // throw new Error('error in fetch')
        return errorValue
    }

    async function fetchAndDecodeIgnoreError(endpoint, options, errorValue)
    {
        const result = await fetcher(automagickEndpoint(endpoint), { credentials: 'include', ...options })
        // console.log('result', result)
        const body = await result.json()
        if (result.ok) return body;
        return null;
    }

    async function fetchAndDecodePropogateError(endpoint, options, errorValue)
    {
        const result = await fetcher(automagickEndpoint(endpoint), { credentials: 'include', ...options })
        const body = await result.json()
        if (result.ok) return { error: false, result: body };
        return body
    }

    // preload until a section - other other thing with substantial content
    async function preLoadLeadingEdge(items)
    {
        if (!myDispatch) return;
        for (const item of items)
        {
            switch (item.kind)
            {
                case ManifestKind.infoVideo:
                    myDispatch(preloadItem({uuid: item.intro.uuid, isVideo: true}))
                    break
                case ManifestKind.encouragement:
                    myDispatch(preloadItem({uuid: item.intro.descriptor.uuid, isVideo: false}))
                    break
                case ManifestKind.section:
                    myDispatch(preloadItem({uuid: item.intro.descriptor.uuid, isVideo: false}))
                    return
                // ideally should do something here ...
                    /*
                case ManifestKind.randomWalk:
                    api */
                default:
                    return
            }
        }
    }

    function firstUUID(items)
    {
        for (const item of items)
        {
            if (item.uuid) return item.uuid;
        }
        console.log('firstUUID - none found!')
        return null
    }

    const api = {
        provideDispatch: function(suppliedDispatch)
        {
            myDispatch = suppliedDispatch
            this.dispatch = suppliedDispatch
        },
        dispatch: myDispatch, // doesn't actually get initialized at this point... see above
        fetcher: fetcher,

        userAttributes: async function()
        {
            const response = await fetch(automagickEndpoint('user/attributes'), { credentials: 'include' })
            if (!response.ok)
            {
                // TODO: no doubt a better response is warranted
                return []
            }
            const attrs = await response.json()
            return attrs
        },

        forgot: async function(email)
        {
            const emailURI = encodeURIComponent(email)
            const result = await fetcher(automagickEndpoint(`forgot/${emailURI}`), { credentials: 'include' });
            if (result.ok) return { error: false };
            // const string = await result.text()
            // console.log('string', string)
            const body = await result.json()
            return body;
        },

        logout: async function()
        {
            await fetcher(automagickEndpoint('logout'), { credentials: 'include' });
        },

        feedback: async function(course_uuid, comment)
        {
            const endpoint = `learning/${course_uuid}/feedback`
            const result = await fetcher(automagickEndpoint(endpoint), {
                credentials: 'include',
                method: 'post',
                body: comment,
                headers: { 'Content-Type': 'text/plain' }
            })
            if (!result.ok) {
                console.log('error submitting feedback', result)
            }
        },

        users : async function(search)
        {
            const ss = { searchString: search }
            const result = await fetchAndDecode('users', {
              method: 'POST',
              body: JSON.stringify(ss),
              headers: { 'Content-Type': 'application/json' }
            }, [])
            return result
        },

        forgots : async function()
        {
            const result = await fetchAndDecode('users/forgots', { }, [])
            return result
        },

        updateUser : async function(update)
        {
            const response = await fetcher(automagickEndpoint('user'), {
              method: 'PATCH',
              credentials: 'include',
              body: JSON.stringify(update),
              headers: { 'Content-Type': 'application/json' }
            })
            const result = await response.json()
            if (response.ok) {
                this.dispatch(setUser(result))
                return null
            }
            return result.reason
        },

        userOnboard: async function()
        {
            await fetcher(automagickEndpoint(`user/onboard/true`), { method: 'post', credentials: 'include' })
        },

        resetOnboard: async function()
        {
            await fetcher(automagickEndpoint(`user/onboard/false`), { method: 'post', credentials: 'include' })
        },

        learnerState: async function(learner)
        {
            const result = await fetchAndDecode(`learner/${learner.uuid}`, { }, [])
            return result
        },


        courses: async function()
        {
            const result = await fetchAndDecode('courses', { }, [])
            return result
        },

        velocities: async function(courseId)
        {
            const result = await fetchAndDecode(`learning/${courseId}/velocities`, { }, [])
            return result
        },

        group: async function(uuid)
        {
            const result = await fetchAndDecode(`groups/${uuid}`)
            return result
        },

        groupTree: async function(uuid)
        {
            const result = await fetchAndDecode(`groups/${uuid}/tree`)
            return result
        },

        groupCourses: async function(uuid)
        {
            const result = await fetchAndDecode(`groups/${uuid}/courses`)
            return result
        },

        groupCoursesOffered: async function(uuid)
        {
            const result = await fetchAndDecode(`groups/${uuid}/courses/offered`)
            return result
        },

        groupReport: async function(uuid, course)
        {
            const result = await fetchAndDecode(`groups/${uuid}/stats/${course}`)
            return result
        },

        createGroup: async function(parentUUID, name)
        {
            const result = await fetchAndDecodePropogateError(`groups/${parentUUID}`, {
              method: 'post',
              body: JSON.stringify({name: name}),
              headers: { 'Content-Type': 'application/json' }
            })
            return result
        },

        createOwnGroup: async function(parentUUID, name)
        {
            const result = await fetchAndDecode(`groups/${parentUUID}/own`, {
              method: 'post',
              body: JSON.stringify({name: name}),
              headers: { 'Content-Type': 'application/json' }
            })
            return result
        },

        groupsManages: async function()
        {
            const result = await fetchAndDecode('groups/manages')
            return result
        },

        groupsManagesTree: async function()
        {
            const result = await fetchAndDecode('groups/manages/tree')
            return result
        },

        groupStats: async function(groupId, interval, count)
        {
            const result = await fetchAndDecode(`groups/${groupId}/signup-stats/${interval}/${count}`)
            return result
        },

        updateGroup: async function(uuid, name)
        {
            const result = await fetcher(automagickEndpoint(`groups/${uuid}`), {
              method: 'PATCH',
              credentials: 'include',
              body: JSON.stringify({name: name}),
              headers: { 'Content-Type': 'application/json' }
            })
            if (result.ok) return null;
            const error = await result.json()
            if (!error) return true; // something non-null!
            return error
        },

        deleteGroup: async function(uuid)
        {
            const result = await fetcher(automagickEndpoint(`groups/${uuid}`), {
              method: 'DELETE',
              credentials: 'include'
            })
            if (result.ok) return null;
            const error = await result.json()
            if (!error) return true; // something non-null!
            return error
        },

        addToGroup: async function(uuid, roles, guuid, again)
        {
            const role = roles.join("+")
            const againStr = (again && again === true) ? '/again' : ''
            const result = await fetcher(automagickEndpoint(`groups/${guuid}/${uuid}/${role}${againStr}`), { method: 'PUT', credentials: 'include' })
            if (result.ok) return null
            const error = await result.json()
            if (!error) return true; // something non-null!
            return error
        },

        removeFromGroup: async function(uuid, roles, guuid)
        {
            const roleStr = roles.join("+")
            const result = await fetcher(automagickEndpoint(`groups/${guuid}/${uuid}/${roleStr}`), { method: 'DELETE', credentials: 'include' })
            if (result.ok) return null
            const error = await result.json()
            if (!error) return true; // something non-null!
            return error
        },

        addAttribute: async function(attr, guuid)
        {
            const result = await fetcher(automagickEndpoint(`groups/${guuid}/add-attribute/${attr}`), { credentials: 'include' })
            if (result.ok) return null
            const error = await result.json()
            if (!error) return true; // something non-null!
            return error
        },

        // to avoid confusion, this endpoint is for associating a learner entity directly to a group (i.e. not for membership)
        addLearnerToGroup: async function(uuid)
        {
            const result = await fetcher(automagickEndpoint(`groups/${uuid}/add-learner`), { credentials: 'include' })
            const body = await result.json()
            if (!result.ok) return body;
            return { error: false, uuid: body }
        },

        // to avoid confusion, this endpoint is for removing a learner entity directly from a group (i.e. not for membership)
        removeLearnerFromGroup: async function(uuid)
        {
            const result = await fetcher(automagickEndpoint(`groups/${uuid}/remove-learner`), { credentials: 'include' })
            if (result.ok) return { error: false };
            const body = await result.json()
            return body;
        },

        moveGroup: async function(fromId, groupId, toId)
        {
            const result = await fetcher(automagickEndpoint(`groups/${fromId}/move/${groupId}/to/${toId}`), { credentials: 'include' })
            if (result.ok) return { error: false };
            const body = await result.json()
            return body
        },

        sendInvitation: async function(uuid, linkTypes, email, language)
        {
            const userRole = linkTypes.join("+")
            let body = {email: email}
            if (language) body.language = language;
            const result = await fetcher(automagickEndpoint(`groups/${uuid}/invite/${userRole}/again`), {
              method: 'POST',
              credentials: 'include',
              body: JSON.stringify(body),
              headers: { 'Content-Type': 'application/json' }
            })
            // if (result.ok) return null;
            const resp = await result.json()
            return resp
        },

        sendPublicInvitation: async function(uuid, email, language)
        {
            let body = {email: email}
            if (language) body.language = language;
            const result = await fetcher(automagickEndpoint(`public_signup/${uuid}`), {
              method: 'POST',
              credentials: 'include',
              body: JSON.stringify(body),
              headers: { 'Content-Type': 'application/json' }
            })
            // if (result.ok) return null;
            const resp = await result.json()
            return resp
        },

        generateLink: async function(uuid, linkTypes, count)
        {
            const userRole = linkTypes.join("+")
            // const userRole = userRoles[linkType]
            const endpoint = count ? `groups/${uuid}/generate-link/${userRole}/${count}` : `groups/${uuid}/generate-link/${userRole}`
            const result = await fetcher(automagickEndpoint(endpoint), { credentials: 'include' })
            if (!result.ok) return null
            const groupsInfo = await result.json()
            return groupsInfo
        },

        getInvites: async function(uuid)
        {
            const result = await fetchAndDecode(`groups/${uuid}/invites`)
            return result
        },

        deleteInvite: async function(groupId, uuid)
        {
            const result = await fetcher(automagickEndpoint(`groups/${groupId}/invite/${uuid}`), { method: 'DELETE', credentials: 'include' })
            if (result.ok) return null
            const error = await result.json()
            if (!error) return true; // something non-null!
            return error
        },

        deleteForgot: async function(uuid)
        {
            const result = await fetcher(automagickEndpoint(`users/forgot/${uuid}`), { method: 'DELETE', credentials: 'include' })
            if (result.ok) return null
            const error = await result.json()
            if (!error) return true; // something non-null!
            return error
        },

        moveInvite: async function(groupId, guid, groupId2)
        {
            const result = await fetcher(automagickEndpoint(`groups/${groupId}/invite/${guid}/move/${groupId2}`), { method: 'PATCH', credentials: 'include' })
            if (result.ok) return null
            const error = await result.json()
            if (!error) return true; // something non-null!
            return error
        },

        amendInvite: async function(groupId, guid, roles)
        {
            const roleStr = roles.join("+")
            const result = await fetcher(automagickEndpoint(`groups/${groupId}/invite/${guid}/roles/${roleStr}`), { method: 'PATCH', credentials: 'include' })
            if (result.ok) return null
            const error = await result.json()
            if (!error) return true; // something non-null!
            return error
        },

        editInvite: async function(groupId, guid, email)
        {
            const json = { email: email }
            const result = await fetcher(automagickEndpoint(`groups/${groupId}/invite/${guid}`),
                                         { method: 'PATCH',
                                           credentials: 'include',
                                           body: JSON.stringify(json),
                                           headers: { 'Content-Type': 'application/json' } })
            if (result.ok) return null
            const error = await result.json()
            if (!error) return true; // something non-null!
            return error
        },

        signup: async function(name, email, locale, password, guid)
        {
            let json = { name: name, email: email, locale: locale, password: password, guid: guid }
            const response = await fetcher(automagickEndpoint('signup'),
                                           { method: 'post',
                                             credentials: 'include',
                                             body: JSON.stringify(json),
                                             headers: { 'Content-Type': 'application/json' } })
            if (response.ok) {
                const user = await response.json()
                this.dispatch(setUser(user))
                this.dispatch(loginHappened(user))
                return null // null == success
            } else {
                const json = await response.json()
                return json.reason
            }
        },

        addOnLogin: async function(guid)
        {
            let json = { guid: guid }
            const response = await fetcher(automagickEndpoint('add-on-login'),
                                           { method: 'post',
                                             credentials: 'include',
                                             body: JSON.stringify(json),
                                             headers: { 'Content-Type': 'application/json' } })
            if (response.ok) {
                // const user = await response.json()
                return null // null == success
            } else {
                const json = await response.json()
                return json.reason
            }
        },

        signupTrial: async function(community, name, email, locale, password)
        {
            let json = { name: name, email: email, locale: locale, password: password }
            const response = await fetcher(automagickEndpoint(`signup-trial/${community}`),
                                           { method: 'post',
                                             credentials: 'include',
                                             body: JSON.stringify(json),
                                             headers: { 'Content-Type': 'application/json' } })
            if (response.ok) {
                const user = await response.json()
                this.dispatch(setUser(user))
                this.dispatch(loginHappened(user))
                return { ok : true }
            } else {
                const json = await response.json()
                return { reason : json.reason, ok : false }
            }
        },

        advanceProgress: async function(uuid)
        {
            const userId = uuid ? `/${uuid}` : ''
            const result = await fetcher(automagickEndpoint(`user${userId}/advance`), { method: 'post', credentials: 'include' })
            if (result.ok) return null
            const error = await result.json()
            if (!error) return true; // something non-null!
            return error
        },

        expireTrial: async function(uuid)
        {
            const userId = uuid ? `/${uuid}` : ''
            const result = await fetcher(automagickEndpoint(`user${userId}/expire`), { method: 'post', credentials: 'include' })
            const body = await result.json()
            if (result.ok) return body;
            if (!body.error) return { error: "Unknown error" };
            return { error: body.error }
        },

        clearTrialExpiry: async function()
        {
            const result = await fetcher(automagickEndpoint('user/clear-expiry'), { method: 'post', credentials: 'include' })
            if (result.ok)
            {
                console.log('CLEARING EXPIRED')
                this.dispatch(setExpired(false)) // even if they are trying to game it by calling the post subscribe
                                                 // callback manually, this only clears the local state, but won't
                                                 // let them access more content
                return null
            }
            const error = await result.json()
            if (!error) return true; // something non-null!
            return error
        },

        addTrial: async function(community)
        {
            const response = await fetcher(automagickEndpoint(`user/add-trial/${community}`), { method: 'post', credentials: 'include' })
            if (response.ok) {
                return { ok: true }
            } else {
                const json = await response.json()
                return { reason : json.reason, ok : false }
            }
        },

        signupWBL: async function(name, email, orgName, locale, password)
        {
            let json = { name: name, email: email, orgName: orgName, locale: locale, password: password }
            const response = await fetcher(automagickEndpoint('signup-wbl'),
                                           { method: 'post',
                                             credentials: 'include',
                                             body: JSON.stringify(json),
                                             headers: { 'Content-Type': 'application/json' } })
            if (response.ok) {
                // setLoggedIn(true)
                const info = await response.json()
                this.dispatch(setUser(info.manager))
                this.dispatch(loginHappened(info.manager))
                return { manager : info.manager, groupId : info.groupId, ok : true } // null == success
            } else {
                const json = await response.json()
                return { reason : json.reason, ok : false }
            }
        },

        preinviteWBL2: async function(groupId, emails, proceed)
        {
            const json = { ident: groupId, emails: emails, proceed: false }
            const response = await fetcher(automagickEndpoint('wbl/prepreinvite'),
                                           { method: 'post',
                                             credentials: 'include',
                                             body: JSON.stringify(json),
                                             headers: { 'Content-Type': 'application/json' } })
            if (!response.ok) {
                const json = await response.json()
                return { reason : json.reason, ok : false }
            }
            const info = await response.json()
            if (info.emails.length === 0)
            {
                const info0 =
                {
                no_account: [],
                unaccepted_same: [],
                unaccepted_other: [],
                no_subscription: [],
                already_member: [],
                has_sub: [],
                duplicates: info.duplicates,
                invalids: info.invalids,
                cost: 0,
                success: true
                }
                return { result: info0, ok : true }
            } else
            {
                const json2 = { emails: info.emails, proceed: proceed, success_url: `${process.env.REACT_APP_AM_ROOT}/app/groups/wbl/${groupId}/wait` }
                const endpoint2 = `${process.env.REACT_APP_RAILS_ROOT}/api/2/workplaces/${groupId.toLowerCase()}/preinvite`
                const response2 = await fetcher(endpoint2,
                                                { method: 'post',
                credentials: 'include',
                body: JSON.stringify(json2),
                headers: { 'Content-Type': 'application/json',
                    'authorization': 'Basic c2F5c29tZXRoaW5naW46ZGFueWRkYWVhcg=='
                } })
                if (response2.ok) {
                    // setLoggedIn(true)
                    let info2 = await response2.json()
                    const info2a =
                    {
                    no_account: info2.no_account ?? [],
                    unaccepted_same: info2.unaccepted_same ?? [],
                    unaccepted_other: info2.unaccepted_other ?? [],
                    no_subscription: info2.no_subscription ?? [],
                    already_member: info2.already_member ?? [],
                    has_sub: info2.has_sub ?? [],
                    duplicates: info.duplicates,
                    invalids: info.invalids,
                    cost: info2.cost ?? 0,
                    checkout_url: info2.checkout_url,
                    am_changeset_id: info2.am_changeset_id,
                    success: true
                    }
                    return { result: info2a, ok : true }
                } else {
                    const json3 = await response2.json()
                    return { reason : json3.reason, ok : false }
                }
            }
        },

        preinviteWBL: async function(groupId, emails, proceed)
        {
            console.log('DIR', process.env.REACT_APP_DIRECT_WBL_INVITE)
            if (process.env.REACT_APP_DIRECT_WBL_INVITE === 'true')
            {
                return this.preinviteWBL2(groupId, emails, proceed)
            }
            const json = { ident: groupId, emails: emails, proceed: proceed }
            const response = await fetcher(automagickEndpoint('wbl/preinvite'),
                                           { method: 'post',
                                             credentials: 'include',
                                             body: JSON.stringify(json),
                                             headers: { 'Content-Type': 'application/json' } })
            if (response.ok) {
                // setLoggedIn(true)
                const info = await response.json()
                return { result: info, ok : true }
            } else {
                const json = await response.json()
                return { reason : json.reason, ok : false }
            }
        },

        preinviteWBLDirect: async function(groupId, emails, proceed)
        {
            const json = { emails: emails, proceed: proceed, success_url: `${process.env.REACT_APP_AM_ROOT}/app/groups/wbl/${groupId}/wait` }
            const endpoint = `${process.env.REACT_APP_RAILS_ROOT}/api/2/workplaces/${groupId.toLowerCase()}/preinvite`
            const response = await fetcher(endpoint,
                                           { method: 'post',
                                             credentials: 'include',
                                             body: JSON.stringify(json),
                                             headers: { 'Content-Type': 'application/json',
                                                 'authorization': 'Basic c2F5c29tZXRoaW5naW46ZGFueWRkYWVhcg=='
                                             } })
            if (response.ok) {
                // setLoggedIn(true)
                const info = await response.json()
                return { result: info, ok : true }
            } else {
                const json = await response.json()
                return { reason : json.reason, ok : false }
            }
        },

        checkPayment: async function(groupId, changeSetId)
        {
            const response = await fetcher(automagickEndpoint(`wbl/${groupId}/check_payment/${changeSetId}`),
                                           { credentials: 'include' })
            const result = await response.json()
            return result
        },

        _state: async function(store, uuid)
        {
            const autoMagicSettings = autoMagicSelector(store.getState())
            const learnerSettings = learnerSelector(store.getState())
            const learner_uuid = autoMagicSettings.learner?.uuid
            const course_uuid = uuid ?? learnerSettings.course
            const learning = learner_uuid ? `/learner/${learner_uuid}` : ''
            const result = await fetcher(automagickEndpoint(`learning/${course_uuid}${learning}/state`), { credentials: 'include' })
            const state = await result.json();
            return state
        },

        state: async function(store, uuid)
        {
            return await serialQueue.execute('ST', async () => {
                return this._state(store, uuid)
            })
        },

        revisit: async function(store, isPlaying, complete)
        {
            serialQueue.execute('RV', async () =>
            {
                await this._revisitManifest(store, isPlaying)
                if (complete) complete();
            })
        },

        skip: async function(store, isPlaying, complete)
        {
            serialQueue.execute('SK', async () =>
            {
                await this._skipManifest(store, isPlaying)
                if (complete) complete();
            })
        },

        // Change pacing is the user-initiated action, set pacing is initiated by the state middleware .. possibly we can just combine?
        changePacing: async function(store, pacing)
        {
            if (prevAbortController) { /* console.log('CALL ABORT'); */ prevAbortController.abort(); } // else { console.log('NO ABORT!') }
            const abortController = new AbortController()
            prevAbortController = abortController
            serialQueue.execute('CP', async () => {
                const state = store.getState()
                const session = sessionSelector(state)
                const queueCopy = session.itemQueue // make a copy that can be used to restore the queue if aborted
                try
                {
                    const learner = learnerSelector(state)
                    const currentSection = session.itemQueue.find(s => s.kind === ManifestKind.section)
                    const currentSectionId = currentSection.intro.uuid
                    if (!currentSection || currentSection.sentences.length < 1) {
                        // TODO: do something to prevent hanging...
                        console.log('problem!!')
                        return
                    }
                    let factor = 1
                    const sectionLength = currentSection.sentences.length
                    if (learner.entry >= 0) {
                        factor = learner.entry / sectionLength
                    }
                    store.dispatch(setPacing({pacing}))
                    // don't signal the middleware to update the pacing on the server, do it here to assure that it's done before getting manifest
                    await this._setPacing(store, pacing)
                    if (!session.isPlaying) {
                        store.dispatch(resetSessionState())
                    }
                    console.log('LEARNER.MODE', learner.mode)
                    // await api._updateManifest(store, currentSectionId, Math.round(factor * learner.entry) + 1, learner.mode, { signal: abortController.signal })
                    if (learner.mode === LearningMode.skip)
                    {
                        await api._skipManifest(store, false, { signal: abortController.signal })
                    } else
                    {
                        await api._updateManifest(store, currentSectionId, Math.round(factor * learner.entry) + 1, learner.mode, { signal: abortController.signal })
                    }
                } catch(err)
                {
                    // abortController = null
                    store.dispatch(reloadQueue(queueCopy))
                    // console.log('CATCH!', err)
                }
            })
        },

        _setPacing: async function(store, pacing)
        {
            const autoMagicSettings = autoMagicSelector(store.getState())
            const learnerSettings = learnerSelector(store.getState())
            const course_uuid = learnerSettings.course
            if (!course_uuid) return null;
            const learner_uuid = autoMagicSettings.learner?.uuid
            const learning = learner_uuid ? `/learner/${learner_uuid}` : ''
            const response = await fetcher(automagickEndpoint(`learning/${course_uuid}${learning}/set-pacing/${pacing}`), { credentials: 'include' })
            if (!response.ok) {
                const info = await response.json()
                console.log('error marking set pacing', info)
            }
        },

        setPacing: async function(store, pacing)
        {
            serialQueue.execute('SP', async () => {
                this._setPacing(store, pacing)
            })
        },

        changeToCourse: async function(store, learnerId, courseId)
        {
            serialQueue.execute('CC', async () => {
                store.dispatch(resetSessionState())
                const error = this._setCourse(store, courseId, learnerId)
                return error
            })
        },

        switchToLearner: async function(store, learner)
        {
            serialQueue.execute('SL', async () => {
                store.dispatch(resetSessionState())
                store.dispatch(setLearner(learner))
                await api._setupLearner(store, learner)
            })
        },

        setVideoHidden: async function(store, videoHidden)
        {
            serialQueue.execute('SVH', async () => {
                const autoMagicSettings = autoMagicSelector(store.getState())
                const learnerSettings = learnerSelector(store.getState())
                const course_uuid = learnerSettings.course
                if (!course_uuid) return null;
                const learner_uuid = autoMagicSettings.learner?.uuid
                const learning = learner_uuid ? `/learner/${learner_uuid}` : ''
                const response = await fetcher(automagickEndpoint(`learning/${course_uuid}${learning}/set-video-hidden/${videoHidden}`), { credentials: 'include' });
                if (!response.ok) {
                    const info = await response.json()
                    console.log('error marking set video hidden', info)
                }
            })
        },

        setTextHidden: async function(store, textHidden)
        {
            serialQueue.execute('STH', async () => {
                const autoMagicSettings = autoMagicSelector(store.getState())
                const learnerSettings = learnerSelector(store.getState())
                const course_uuid = learnerSettings.course
                if (!course_uuid) return null;
                const learner_uuid = autoMagicSettings.learner?.uuid
                const learning = learner_uuid ? `/learner/${learner_uuid}` : ''
                const response = await fetcher(automagickEndpoint(`learning/${course_uuid}${learning}/set-text-hidden/${textHidden}`), { credentials: 'include' });
                if (!response.ok) {
                    const info = await response.json()
                    console.log('error marking set text hidden', info)
                }
            })
        },

        startPlayback: async function(stopSpinning)
        {
            // doesn't actually use the API per se, but it's handy to synchronize with it
            serialQueue.execute('PL', async () => {
                this.dispatch(setIsPlaying(true))
                stopSpinning()
            })
        },

        _resetSection: async function(store)
        {
            const autoMagicSettings = autoMagicSelector(store.getState())
            const learnerSettings = learnerSelector(store.getState())
            const learner_uuid = autoMagicSettings.learner?.uuid
            const learning = learner_uuid ? `/learner/${learner_uuid}` : ''
            const response = await fetcher(automagickEndpoint(`learning/${learnerSettings.course}${learning}/reset-section`),
                                           { method: 'get', credentials: 'include' })
            if (!response.ok) {
                const info = await response.json()
                console.log('error marking new section', info)
            }
        },

        resetSection: async function(store)
        {
            serialQueue.execute('RS', async () => {
                this._resetSection(store)
            })
        },

        newSection: async function(store, uuid)
        {
            serialQueue.execute('NS', async () => {
                const autoMagicSettings = autoMagicSelector(store.getState())
                const learnerSettings = learnerSelector(store.getState())
                const learner_uuid = autoMagicSettings.learner?.uuid
                const learning = learner_uuid ? `/learner/${learner_uuid}` : ''
                const response = await fetcher(automagickEndpoint(`learning/${learnerSettings.course}${learning}/new-section/${uuid}`),
                                               { method: 'get', credentials: 'include' })
                if (!response.ok) {
                    const info = await response.json()
                    console.log('error marking new section', info)
                }
            })
        },

        updateEntry: async function(store, idx)
        {
            serialQueue.execute('UE', async () => {
                const autoMagicSettings = autoMagicSelector(store.getState())
                const learnerSettings = learnerSelector(store.getState())
                const learner_uuid = autoMagicSettings.learner?.uuid
                const learning = learner_uuid ? `/learner/${learner_uuid}` : ''
                const response = await fetcher(automagickEndpoint(`learning/${learnerSettings.course}${learning}/update-entry/${idx}`),
                                               { method: 'get', credentials: 'include' })
                if (!response.ok) {
                    const info = await response.json()
                    console.log('error marking update entry', info)
                }
            })
        },

        updateMode: async function(store, mode)
        {
            serialQueue.execute('UM', async () => {
                const autoMagicSettings = autoMagicSelector(store.getState())
                const learnerSettings = learnerSelector(store.getState())
                const learner_uuid = autoMagicSettings.learner?.uuid
                const learning = learner_uuid ? `/learner/${learner_uuid}` : ''
                const response = await fetcher(automagickEndpoint(`learning/${learnerSettings.course}${learning}/update-mode/${mode}`),
                                               { method: 'get', credentials: 'include' })
                if (!response.ok) {
                    const info = await response.json()
                    console.log('error marking update mode', info)
                }
            })
        },

        setLastInterjection: async function(store, idx)
        {
            serialQueue.execute('SLI', async () => {
                const autoMagicSettings = autoMagicSelector(store.getState())
                const learnerSettings = learnerSelector(store.getState())
                const learner_uuid = autoMagicSettings.learner?.uuid
                const learning = learner_uuid ? `/learner/${learner_uuid}` : ''
                const response = await fetcher(automagickEndpoint(`learning/${learnerSettings.course}${learning}/set-last-interjection/${idx}`),
                                               { method: 'put', credentials: 'include' })
                if (!response.ok) {
                    const info = await response.json()
                    console.log('error setting last interjection', info)
                }
            })
        },

        completeIntro: async function(store, uuid)
        {
            serialQueue.execute('CI', async () => {
                const autoMagicSettings = autoMagicSelector(store.getState())
                const learnerSettings = learnerSelector(store.getState())
                const learner_uuid = autoMagicSettings.learner?.uuid
                const learning = learner_uuid ? `/learner/${learner_uuid}` : ''
                const result = await fetchAndDecodeIgnoreError(`learning/${learnerSettings.course}${learning}/complete-intro/${uuid}`,
                                                               { method: 'get', credentials: 'include' })
                if (!result) {
                    return
                }
                if (result.totalTime) {
                    store.dispatch(setTotalTime(result.totalTime))
                }
            })
        },

        completeSentence: async function(store, uuid)
        {
            serialQueue.execute('CS', async () => {
                const state = store.getState()
                const autoMagicSettings = autoMagicSelector(state)
                const learnerSettings = learnerSelector(state)
                const learner_uuid = autoMagicSettings.learner?.uuid
                const learning = learner_uuid ? `/learner/${learner_uuid}` : ''
                const result = await fetchAndDecodeIgnoreError(`learning/${learnerSettings.course}${learning}/complete-sentence/${uuid}`,
                                                               { method: 'get', credentials: 'include' })
                if (!result) {
                    return
                }
                if (result.totalTime) {
                    store.dispatch(setTotalTime(result.totalTime))
                }
            })
        },

        completeSection: async function(store, uuid)
        {
            serialQueue.execute('CX', async () => {
                const state = store.getState()
                const autoMagicSettings = autoMagicSelector(state)
                const learnerSettings = learnerSelector(state)
                const learner_uuid = autoMagicSettings.learner?.uuid
                const learning = learner_uuid ? `/learner/${learner_uuid}` : ''
                const result = await fetchAndDecodeIgnoreError(`learning/${learnerSettings.course}${learning}/complete-section/${uuid}`,
                                                               { method: 'get', credentials: 'include' })
                if (!result) {
                    return
                }
                if (result.totalTime) {
                    store.dispatch(setTotalTime(result.totalTime))
                }
            })
        },

        getBlocks: async function(courseId)
        {
            const result = await fetchAndDecodeIgnoreError(`course/${courseId}/num-blocks`)
            console.log('result', result)
            return result
        },

        getIUs: async function(courseId, blockIdx)
        {
            const result = await fetchAndDecode(`course/${courseId}/ius/${blockIdx}`)
            return result
        },

        _next: async function(store)
        {
            const state = store.getState()
            const autoMagicSettings = autoMagicSelector(state)
            const learner_uuid = autoMagicSettings.learner?.uuid
            const learnerSettings = learnerSelector(state)
            const session = sessionSelector(state)
            let nextUUID = null
            // TODO: we should sensibly just look at the last item in the queue...
            for (const item of session.itemQueue)
            {
                const uuid = item?.intro?.uuid
                if (uuid) nextUUID = uuid;
            }
            if (nextUUID)
            {
                const result = await fetchAndDecode(`learning/${learnerSettings.course}/learner/${learner_uuid}/next/${nextUUID}`)
                return result?.uuid
            } else
            {
                const result = await fetchAndDecode(`learning/${learnerSettings.course}/learner/${learner_uuid}/next`)
                return result?.uuid
            }
        },

        _entries: async function(store, section, options)
        {
            const state = store.getState()
            const autoMagicSettings = autoMagicSelector(state)
            const learnerSettings = learnerSelector(state)
            let learner = autoMagicSettings.learner
            if (!learner) return null;
            const result = await fetchAndDecode(`learning/${learnerSettings.course}/learner/${learner.uuid}/entries/${section}`, options)
            return result
        },

        _revisit: async function(store, options)
        {
            const state = store.getState()
            const autoMagicSettings = autoMagicSelector(state)
            const learnerSettings = learnerSelector(state)
            let learner = autoMagicSettings.learner
            if (!learner) return null;
            const result = await fetchAndDecode(`learning/${learnerSettings.course}/learner/${learner.uuid}/revisit/${learnerSettings.section}/${learnerSettings.mode}`, options)
            return result
        },

        _skip: async function(store, options)
        {
            const state = store.getState()
            const autoMagicSettings = autoMagicSelector(state)
            const learnerSettings = learnerSelector(state)
            let learner = autoMagicSettings.learner
            if (!learner) return null;
            const result = await fetchAndDecode(`learning/${learnerSettings.course}/learner/${learner.uuid}/skip/${learnerSettings.section}/${learnerSettings.mode}`, options)
            return result
        },

        next: async function(store)
        {
            return await serialQueue.execute('NX', async () => {
                const nextId = await this._next(store)
                if (!nextId) return null;
                const manifest = await this._entries(store, nextId)
                return manifest
            })
        },

        polyLogin: async function(email, password)
        {
            const creds = { 'email' : email, 'passwd' : password }
            const response = await fetcher(automagickEndpoint('login-poly'),
                                           { method: 'post', body: JSON.stringify(creds), headers: { 'Content-Type': 'application/json' }, credentials: 'include' })
            if (!response.ok) {
              return null
            }
            const usr = await response.json()
            return usr
        },

        _revisitManifest: async function(store, isPlaying, options)
        {
            const manifest = await this._revisit(store, options)
            if (!manifest) {
                const learnerSettings = learnerSelector(store.getState())
                await this._updateManifest(store, learnerSettings.section, learnerSettings.entry, LearningMode.normal)
                return
            };
            const uuid = firstUUID(manifest.items)
            await this._updateManifestAfter(store, manifest, uuid, 0, LearningMode.revisit)
            if (isPlaying)
            {
                this.dispatch(setIsPlaying(true))
            }
        },

        _skipManifest: async function(store, isPlaying, options)
        {
            const manifest = await this._skip(store, options)
            if (!manifest || manifest.length === 0) return;
            const seed = manifest.items[0]
            console.log('manifest', manifest)
            console.log('seed.uuid', seed.uuid)
            console.log('LearningMode.skip', LearningMode.skip)
            await this._updateManifestAfter(store, manifest, seed.uuid, 0, LearningMode.skip)
            if (isPlaying)
            {
                this.dispatch(setIsPlaying(true))
            }
        },

        _updateManifest: async function(store, section, entry, mode, options)
        {
            const manifest = await this._entries(store, section, options)
            if (!manifest) return;
            await this._updateManifestAfter(store, manifest, section, entry, mode)
        },

        _updateManifestAfter: async function(store, manifest, section, entry, mode)
        {
            let entryLocal = entry
            store.dispatch(resetSections())
            let items = manifest.items
            if (items) {
                await preLoadLeadingEdge(items)
                let shiftCnt = 0
                for (const item of items)
                {
                    if (item) {
                        // eslint-disable-next-line default-case
                        switch (item.kind)
                        {
                        case ManifestKind.infoVideo:
                            entryLocal = -1
                            break
                        case ManifestKind.section:
                            if (entryLocal >= item.sentences.length && item.sentences.length > 0) {
                                console.log('adjusting entry', item.sentences.length - 1)
                                entryLocal = item.sentences.length - 1
                            }
                            break
                        case ManifestKind.encouragement:
                            entryLocal = -1
                            break
                        case ManifestKind.randomWalk:
                            entryLocal = 0
                            break
                        case ManifestKind.announcement:
                            console.log('Announcement at the front!')
                            if (myDispatch) myDispatch(setAnnouncement({title: item.title, body: item.body}));
                            shiftCnt++
                            continue
                        case ManifestKind.expiration:
                            console.log('Expiration at the front!')
                            if (myDispatch) myDispatch(setExpired(true));
                            shiftCnt++
                            continue
                        }
                    }
                    break // normally, we'll only look at the first one
                }
                for (let i = 0; i < shiftCnt; i++) items.shift();
            }
            store.dispatch(initCursor({section, entry: entryLocal, mode}))

            store.dispatch(loadItems(items))
            store.dispatch(setEntry({ entry: entryLocal }))
        },

        fetchMedia: async function(url)
        {
            try {
                const response = await fetcher(url, { credentials: 'include' })
                if (!response.ok) {
                    try {
                        const info = await response.json()
                        console.log('not ok info', info)
                        return { response, info }
                    } catch (error) {
                        return { response }
                    }
                }
                const blob = await response.blob()
                // console.log('blob type', blob.type)
                return { blob }
            } catch(error) {
                console.log('caught error', error)
                return { error }
            }
        },

        // This is called after login... and after, say, refresh
        setup: async function(store, user)
        {
            serialQueue.execute('SU', async () => {
                // NOTE: this is a clasic case of the reverse arrow function gotcha .. this needs be defined using function, not arrow
                // in order to get the "this" right
                const autoMagicSettings = autoMagicSelector(store.getState())
                store.dispatch(settingUp(true))

                let learner = autoMagicSettings.learner
                // if they've already chosen a learner, stick to it
                if (!learner || !user.learners.find(lnr => lnr.uuid === learner.uuid))
                {
                    // choose the first as a reasonable default
                    learner = user.learners[0]
                    store.dispatch(setLearner(learner))
                }

                if (learner)
                {
                    await this._setupLearner(store, learner, user);
                    
                    // finally, handle any onboarding necessary
                    if (!user.onboarded)
                    {
                        // const attr = user.attributes ? user.attributes.find(attr => attr.attribute === 'role:schools_teacher') : null
                        store.dispatch(setOnboardingUser(user))
                    }
                    store.dispatch(settingUp(false))
                }
            })
        },

        _setCourse: async function(store, courseId, learnerId)
        {
            // const uuid = action.payload.course
            const amSettings = autoMagicSelector(store.getState())
            const state = await this._state(store, courseId)

            // TODO: it's not cool that we're hardcoding default settings here ..
            store.dispatch(setPacing({ pacing: state.pacing ?? 2}));
            store.dispatch(setVideoHidden({ hidden: state.videoHidden ?? true }));
            store.dispatch(setTextHidden({ hidden: state.textHidden ?? false }));
            store.dispatch(setTotalTime(state.totalTime))

            let section, entry, mode
            if (!state.section) {
                const courseInfo = amSettings.courses?.find(course => course.uuid === courseId)
                if (!courseInfo) return; // TODO: probably needs a better response
                section = courseInfo.startingUnit
                entry = -1
                mode = LearningMode.normal
            } else {
                section = state.section
                entry = state.entry
                mode = state.mode
            }
            store.dispatch(setCourse({course: courseId}))
            if (learnerId) {
                const result = await fetcher(automagickEndpoint(`learner/${learnerId}/course/${courseId}`), { method: 'put', credentials: 'include' })
                const state = await result.json()
                if ('reason' in state)
                {
                    return state.reason
                }
            }
            await this._updateManifest(store, section, entry, mode)
            return null
        },

        _setupLearner: async function(store, learner, user)
        {
            const linfo = await this.learnerState(learner)
            if (!linfo.course)
            {
                // this user has no courses available
                store.dispatch(setCourses([]))
                // nothing more to do here ...
                return
            }
            if (!linfo)
            {
                // shouldn't actually happen... unless network error?
                const courses = await this.courses()
                store.dispatch(setCourses(courses))
                if (courses.length > 0)
                {
                    // there was no currrently selected course, so we're now selecting one...
                    await this._setCourse(store, courses[0].uuid, learner.uuid)
                    // TODO: handle error by signaling session error?
                }
                return
            }
            const cinfo = linfo.course
            const state = store.getState()
            const autoMagicSettings = autoMagicSelector(state)
            const settingsCourses = autoMagicSettings.courses ?? []
            if (settingsCourses.length < 1) {
                // stash this info temporarily as "all courses" info
                store.dispatch(setCourses([cinfo]))
            }
            // When we set the course, this also sets other state like How Are You Feeling..
            // TODO: is this the best way to handle this?
            // there was a selected course on the server, so we're just syncing with local state
            // TODO: the onboarded flag on learner is actually not used/updated anymore..
            // but the greeting is nice, so maybe we end ditching the flag and just setting the greeting at startup
            if (linfo.onboarded) {
                // do nothing
            } else {
                const sessionSettings = sessionSelector(state)
                if (!sessionSettings.isPlaying) {
                    store.dispatch(setText('#onboardGreeting'))
                }
            }
            // take this opportunity to grab velocity info ...
            const vinfo = await this.velocities(cinfo.uuid)
            if (vinfo.length >= 0)
            {
                store.dispatch(setVelocities(vinfo))
            }
            await this._setCourse(store, cinfo.uuid)
        },

        resetProgress: async function(store)
        {
            return serialQueue.execute('RP', async () =>
            {
                const learnerSettings = learnerSelector(store.getState())
                const courses = await this.courses()
                const courseInfo = courses.find(course => course.uuid === learnerSettings.course)
                const section = courseInfo.startingUnit
                store.dispatch(resetSessionState())
                await this._resetSection(store)
                const manifest = await this._entries(store, section)
                console.log('MANIFEST', manifest)
                return manifest
            })
        },

        resetAtSection: async function(store, section)
        {
            return serialQueue.execute('RA', async () =>
            {
                store.dispatch(resetSessionState())
                store.dispatch(setSection({ section: section, push: section }))
                const manifest = await this._entries(store, section)
                return manifest
            })
        }
    }

    const prov =
      <APIContext.Provider value={api}>
        {props.children}
      </APIContext.Provider>

    return prov
}

export function useAPI() {
  return React.useContext(APIContext)
}
