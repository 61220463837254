import { useCallback, useState } from "react"
import Button from 'ssi-react-lib/Button'
import CenteredThings from 'ssi-react-lib/CenteredThings'
import Modal from '../Widgets/Modal'

export function useConfirmDialog(icon, icon2, onDismiss)
{
  const [display, setDisplay] = useState(false)
  const [message, setMessage] = useState('')
  const [callback, setCallback] = useState(null)

  const onDisplay = useCallback((message, callback) =>
  {
    setMessage(message)
    setDisplay(true)
    setCallback(() => callback)
  }, [])

  const localDismiss = () => {
    setDisplay(false)
    if (callback)
    {
      callback(false)
      setCallback(null)
    }
  }

  const localDismiss2 = () => {
    setDisplay(false)
    if (callback)
    {
      callback(true)
      setCallback(null)
    }
  }

  const buttons2 =
    <>
      <Button class='button' label={icon2} action={localDismiss2} />
      <Button class='submit-button' label={icon} action={localDismiss} />
    </>
  const buttons1 =
    <Button class='submit-button' label={icon} action={localDismiss} />
  const buttons = icon2 ? buttons2 : buttons1

  let dialog =
    <Modal display={display} onDismiss={onDismiss}>
      {message}
      <CenteredThings topSpacing={12}>
        {buttons}
      </CenteredThings>
    </Modal>
  return [onDisplay, dialog]
}
