import SVGIcon from './SVGIcon'

export default function GearIcon({className, color, onClick})
{
  return <SVGIcon
          name='gear'
          className={className}
          size='82.67 82.52'
          color={color}
          onClick={onClick}
          paths={[
            'm37.45,82.52h7.71c3.12,0,5.57-1.9,6.3-4.83l1.61-7.03.88-.29,6.05,3.76c2.64,1.61,5.71,1.22,7.91-1.03l5.37-5.32c2.2-2.2,2.64-5.32.98-7.86l-3.81-6.1.29-.73,7.03-1.61c2.93-.73,4.88-3.22,4.88-6.3v-7.52c0-3.08-1.9-5.57-4.88-6.3l-6.93-1.71-.34-.78,3.81-6.1c1.66-2.59,1.22-5.62-.98-7.91l-5.37-5.32c-2.15-2.2-5.22-2.59-7.86-1.03l-6.1,3.71-.93-.34-1.61-7.03c-.73-2.93-3.17-4.83-6.3-4.83h-7.71c-3.12,0-5.52,1.9-6.25,4.83l-1.61,7.03-.98.34-6.1-3.71c-2.59-1.56-5.66-1.17-7.86,1.03l-5.32,5.32c-2.2,2.29-2.64,5.32-.98,7.91l3.76,6.1-.34.78-6.93,1.71c-2.93.73-4.83,3.22-4.83,6.3v7.52c0,3.08,1.95,5.57,4.83,6.3l7.03,1.61.29.73-3.76,6.1c-1.66,2.54-1.22,5.66.98,7.86l5.32,5.32c2.2,2.25,5.32,2.64,7.91,1.03l6.1-3.76.88.29,1.61,7.03c.73,2.93,3.12,4.83,6.25,4.83Zm.68-6.79c-.49,0-.73-.2-.78-.63l-2.39-9.57c-2.49-.63-4.74-1.56-6.45-2.73l-8.45,5.22c-.34.2-.68.15-1.03-.15l-4.39-4.44c-.34-.29-.34-.63-.1-1.03l5.13-8.4c-.93-1.66-2-3.91-2.64-6.4l-9.62-2.34c-.44-.05-.63-.29-.63-.78v-6.25c0-.54.15-.68.63-.83l9.57-2.29c.63-2.69,1.9-5.03,2.59-6.49l-5.08-8.35c-.29-.44-.29-.78.05-1.12l4.44-4.35c.34-.29.59-.39,1.07-.15l8.35,5.08c1.71-1.03,4.15-2.05,6.59-2.73l2.34-9.57c.05-.44.29-.63.78-.63h6.4c.49,0,.73.2.78.63l2.34,9.67c2.54.63,4.74,1.66,6.49,2.69l8.45-5.13c.44-.24.68-.15,1.07.15l4.44,4.35c.29.34.34.68.05,1.12l-5.13,8.35c.73,1.46,1.95,3.81,2.59,6.49l9.62,2.29c.49.15.59.29.59.83v6.25c0,.49-.15.73-.59.78l-9.67,2.34c-.59,2.49-1.66,4.74-2.64,6.4l5.18,8.4c.24.39.24.73-.1,1.03l-4.39,4.44c-.39.29-.73.34-1.03.15l-8.5-5.22c-1.66,1.17-3.96,2.1-6.45,2.73l-2.34,9.57c-.05.44-.29.63-.78.63h-6.4Zm3.17-19.87c8.01,0,14.6-6.54,14.6-14.6s-6.59-14.5-14.6-14.5-14.6,6.54-14.6,14.5,6.59,14.6,14.6,14.6Zm0-6.74c-4.3,0-7.76-3.52-7.76-7.86s3.47-7.76,7.76-7.76,7.71,3.52,7.71,7.76-3.52,7.86-7.71,7.86Z'
            ]}
          />
}
