import SVGIcon from './SVGIcon'

export default function TrashIcon({className, color, onClick})
{
  return <SVGIcon
          name='trash'
          className={className}
          size='74.61 85.69'
          color={color}
          onClick={onClick}
          paths={[
            'M19.68,85.69H54.98c5.81,0,9.57-3.61,9.86-9.47l2.49-54.74h4.05c1.81,0,3.22-1.46,3.22-3.27s-1.46-3.27-3.22-3.27H3.22c-1.71,0-3.22,1.46-3.22,3.27s1.51,3.27,3.22,3.27H7.28l2.54,54.79c.29,5.86,3.96,9.42,9.86,9.42Zm.63-6.54c-2.05,0-3.56-1.51-3.66-3.66l-2.54-54H60.35l-2.39,54c-.1,2.2-1.56,3.66-3.71,3.66H20.31Zm-.05-61.13h6.93V9.81c0-2,1.42-3.32,3.47-3.32h13.23c2,0,3.42,1.32,3.42,3.32v8.2h6.98V9.33c0-5.76-3.76-9.33-9.91-9.33h-14.26c-6.1,0-9.86,3.56-9.86,9.33v8.69Zm17.04,55.08c1.66,0,2.78-1.03,2.78-2.54V30.52c0-1.51-1.12-2.54-2.78-2.54s-2.78,1.03-2.78,2.54v40.04c0,1.51,1.12,2.54,2.78,2.54Zm11.33,0c1.51,0,2.64-.98,2.69-2.54l1.07-40.04c.05-1.51-1.03-2.54-2.69-2.54-1.56,0-2.64,1.03-2.69,2.54l-1.07,40.04c-.05,1.51,1.03,2.54,2.69,2.54Zm-22.61,0c1.66,0,2.73-1.03,2.69-2.54l-1.12-40.04c-.05-1.56-1.12-2.54-2.69-2.54s-2.73,1.03-2.69,2.54l1.12,40.04c.05,1.56,1.12,2.54,2.69,2.54Z'
            ]}
          />
}
