import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useAPI } from '../Core/app/API'
import { useAuth } from '../Auth'
import { useAlertDialog } from '../Hooks/AlertDialog'
import { useTranslate } from '../Translation'
import { useSpinner } from '../Hooks/Spinner'
import { loginHappened } from '../Core/app/slices/autoMagicSlice'
import { automagickEndpoint } from '../Core/app/API'

export default function AddTrial({community})
{
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const api = useAPI()
  const auth = useAuth()
  const translate = useTranslate()
  const [startSpinning, stopSpinning, spinOverlay] = useSpinner(translate('settingup'))
  const [showAlert, alertDialog] = useAlertDialog(translate('ok'))

  useEffect(() => {
    (async () =>
    {
      startSpinning()
      const rsult = await api.addTrial(community)
      stopSpinning()
      if (!rsult.ok)
      {
        const reason = rsult.reason
        if (reason === 'alreadyHasAccess' || reason === 'alreadyInATrial')
        {
          const tresponse = await fetch(automagickEndpoint(`translations/en`))
          const tdict = await tresponse.json()
          showAlert(translate(tdict[reason] ?? reason), () =>
          {
            navigate('/learn', { replace: true })
          })
        }
        return
      }
      const response = await fetch(automagickEndpoint('user'), { credentials: 'include' })
      if (response.ok)
      {
        const usr = await response.json()
        dispatch(loginHappened(usr))
      }
      navigate('/learn', { replace: true })
    })()
  }, [])

  const components =
    <>
      {spinOverlay}
      {alertDialog}
    </>
  return components
}
