import { useRef } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAPI } from './Core/app/API'
import { useTranslate } from './Translation'
import { learnerSelector } from './Core/app/store'
import { useAlertDialog } from './Hooks/AlertDialog'
import Button from 'ssi-react-lib/Button'
import './Feedback.scss'

export default function Feedback(props)
{
  const inputRef = useRef()
  const navigate = useNavigate()
  const translate = useTranslate()
  const api = useAPI()
  const learningSettings = useSelector(learnerSelector)

  const goBack = () =>
  {
    const url = '/app'
    navigate(url)
  }

  const [showAlert, alertDialog] = useAlertDialog(translate('ok'), () =>
  {
    goBack()
  })

  const submit = () =>
  {
    api.feedback(learningSettings.course, inputRef.current.value)
    showAlert(translate('feedbackThanks'))
  }

  const dialog =
    <>
      <textarea ref={inputRef} id='suggestion' className='suggestion' rows='10' placeholder='Your feedback here …' maxLength='5000' name='field'></textarea>
      <div className='user-settings-box below'>
        <Button name='reset' class='submit-button' action={submit} label={translate('submit')} />
      </div>
      {alertDialog}
    </>
  return dialog
}
