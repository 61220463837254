import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { setInGroups } from '../Core/app/slices/autoMagicSlice'
import { useAPI } from '../Core/app/API'
import { useTranslate } from '../Translation'
import { useAlertDialog } from '../Hooks/AlertDialog'
import { useConfirmDialog } from '../Hooks/ConfirmDialog'
import { usePopAndFade } from '../Widgets/PopAndFade'
import { useSpinner } from '../Hooks/Spinner'
import { mkGroupAction } from './GroupAction'
import { PersonType } from '../Core/PersonType'
import AddDialogComponent from './AddDialogComponent'
import AddIcon from 'ssi-react-lib/Icons/AddIcon'
import AddOneDialog from './AddOneDialog'
import AdminContainer from './AdminContainer'
import GroupManagerOverview from './GroupManagerOverview'
import GroupsList from './GroupsList'
import NavPanel from '../Widgets/NavPanel'
import PersonList from './PersonList'
import ReportIcon from 'ssi-react-lib/Icons/Report'
import SchoolIcon from 'ssi-react-lib/Icons/School'
import SendIcon from 'ssi-react-lib/Icons/SendIcon'
import './GroupManager.css'

export default function GroupManager(props)
{
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const api = useAPI()
  const translate = useTranslate()
  const [group, setGroup] = useState(null)
  const [groupStale, setGroupStale] = useState(true)
  const [invites, setInvites] = useState(null)
  const [invitesStale, setInvitesStale] = useState(true)
  const [startSpinning, stopSpinning, spinOverlay] = useSpinner()
  const [showAlert, alertDialog] = useAlertDialog(translate('ok'))
  const [showConfirm, confirmDialog] = useConfirmDialog(translate('yes'), translate('cancel'))
  const [popNFade, popNFadeDialog] = usePopAndFade(2000)

  useEffect(() =>
  {
    dispatch(setInGroups(true))
    return () => {
      dispatch(setInGroups(false))
    };
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const groupId = params.groupId

  useEffect(() =>
  {
    setGroupStale(true)
  }, [groupId])

  const updateGroup = async () =>
  {
    if (!groupStale) return;
    startSpinning(translate('loading'))
    const info = await api.group(groupId)
    setGroup(info)
    setGroupStale(false)
    stopSpinning()
  }
  useEffect(() =>
  {
    updateGroup()
  }, [groupStale]) // eslint-disable-line react-hooks/exhaustive-deps -- updateGroup
  const refreshGroup = () =>
  {
    setGroupStale(true)
  }
  const updateInvites = async () =>
  {
    if (!invitesStale) return;
    startSpinning(translate('loading'))
    const invited = await api.getInvites(groupId)
    setInvites(invited)
    setInvitesStale(false)
    stopSpinning()
  }
  useEffect(() =>
  {
    updateInvites()
  }, [invitesStale]) // eslint-disable-line react-hooks/exhaustive-deps -- updateInvites
  const refreshInvites = () =>
  {
    setInvitesStale(true)
  }

  const onAdd = async (name) =>
  {
    const new_guuid = await api.createGroup(groupId, name)
    if (new_guuid)
    {
      refreshGroup()
    }
  }
  const description = 'Enter the name of your new subgroup here:'
  const [showAddGroup, addGroupDialog] = AddOneDialog('Add SubGroup', description, 'subgroup name', <AddIcon fgColor={"white"}/>, onAdd)

  const deleteGroup = async (uuid) =>
  {
    const grp = group.groups.find(g => g.uuid === uuid)
    const confirmMessage = `Are you sure you want to permanently delete “${grp.name}”?`
    const onConfirm = async (cancelled) =>
    {
      if (cancelled) return;
      const error = await api.deleteGroup(uuid)
      if (error)
      {
        showAlert(error.reason)
        return
      }
      refreshGroup()
    }
    showConfirm(confirmMessage, onConfirm)
  }

  const renameGroup = async (uuid, name) =>
  {
    const error = await api.updateGroup(uuid, name)
    if (error)
    {
      showAlert(error.reason)
      return
    }
    refreshGroup()
  }

  const setPlayAsClass = async (uuid, checked, undo, success) =>
  {
    if (checked)
    {
      const result = await api.addLearnerToGroup(uuid)
      if (result.error)
      {
        showAlert(result.reason)
        undo()
      } else
      {
        popNFade('Successfully added')
        success()
      }
    } else {
      const onConfirm = async (cancelled) =>
      {
        if (cancelled)
        {
          undo()
          return
        }
        const result = await api.removeLearnerFromGroup(uuid)
        if (result.error)
        {
          showAlert(result.reason)
          undo()
        } else {
          popNFade('Successfully removed')
          success()
        }
      }
      showConfirm(translate('cfmDelGrpProgress'), onConfirm)
    }
  }

  const moveGroup = async (group, dest) =>
  {
    const result = await api.moveGroup(groupId, group, dest)
    if (result.error)
    {
      showAlert(result.reason)
      return
    }
    refreshGroup()
  }

  const emailTitle1 = 'Paste your managers’ email addresses here and we’ll send them an invitation for you:'
  const linkTitle1 = translate('copyLinkDescription').replace(/%ROLE%/g, 'a manager of your group')
  const [showAddManager, addManagerDialog] = AddDialogComponent(groupId, translate('addManagers'), [PersonType.manager], emailTitle1, linkTitle1, translate('thisGroup'), translate('managers'), translate('send'), refreshInvites, refreshGroup, translate, showAlert)
  const addManagers = () =>
  {
    showAddManager()
  }
  const emailTitle2 = 'Paste your learners’ email addresses here and we’ll send them an invitation for you:'
  const linkTitle2 = translate('copyLinkDescription').replace(/%ROLE%/g, 'a learner in your group')
  const [showAddLearner, addLearnerDialog] = AddDialogComponent(groupId, translate('addLearners'), [PersonType.learner], emailTitle2, linkTitle2, translate('thisGroup'), translate('learners'), translate('send'), refreshInvites, refreshGroup, translate, showAlert)
  const addLearners = () =>
  {
    showAddLearner()
  }

  const onReport = () =>
  {
    navigate('report')
  }
  const statsAction = mkGroupAction('View statistics report', <ReportIcon class={'group-stats-report visible'} />, onReport)

  const schoolAttr = group ? group.attributes.find(attr => attr.attribute === 'role:school') : null
  const wblAttr = group ? group.attributes.find(attr => attr.attribute === 'role:wbl') : null
  const headOfWelshAttr = group ? group.attributes.find(attr => attr.attribute === 'role:schools_headOfWelsh') : null
  const onInviteHeadOfWelsh = async (email) =>
  {
    const roles = [PersonType.manager, PersonType.headOfWelsh]
    const resp = await api.sendInvitation(groupId, roles, email, 'en')
    if (resp.alreadyExists)
    {
      const error = await api.addToGroup(resp.namedEntity.uuid, roles, groupId)
      if (!error)
      {
        // await updateLearners(selectedClass)
        refreshGroup()
        showAlert(translate('managerExistsGroup'))
      } else {
        showAlert(translate(error.reason))
      }
    } else {
      const error = resp.error
      if (!error)
      {
        refreshInvites()
        const msg =
          <div>
            {translate('emailSent')}<br/><br/>
            {translate('orUseLink')}<br/><br/>
            {resp.inviteLink}
          </div>
        showAlert(msg)
      } else {
        showAlert(translate(resp.reason))
      }
    }
  }
  const description2 = 'Enter the email address of the Head of Welsh here:'
  const [showAddHeadOfWelsh, addHeadOfWelshDialog] = AddOneDialog('Add Head of Welsh', description2, 'email address', <SendIcon fgColor={"white"}/>, onInviteHeadOfWelsh)
  const inviteHeadOfWelshAction = schoolAttr && !headOfWelshAttr ? mkGroupAction('Invite Head of Welsh', <SendIcon fgColor={"white"}/>, showAddHeadOfWelsh) : null
  const onViewAsHeadOfWelsh = async () =>
  {
    navigate(`../headOfWelsh/${groupId}`)
  }
  const viewAsHeadOfWelshAction = schoolAttr ? mkGroupAction('View page as Head of Welsh', <ReportIcon />, onViewAsHeadOfWelsh) : null

  const onViewAsWBL = async () =>
  {
    navigate(`../wbl/${groupId}`)
  }
  const viewAsWBLAction = wblAttr ? mkGroupAction('View page as WBL', <ReportIcon />, onViewAsWBL) : null

  const onConvertToSchool = async () =>
  {
    showConfirm(translate('cfmConvertToSchool').replace(/%SCHOOL%/g, group.name), async (cancelled) =>
    {
      if (cancelled) return;
      startSpinning(translate('converting'))
      const groupTreeWithAncestors = await api.groupTree(groupId)
      const groupTree = groupTreeWithAncestors.groupInfo
      const error = await convertToSchool(api, groupId, groupTree)
      stopSpinning()
      if (error)
      {
        showAlert(error, () =>
        {
          // even partial results might have observable changes
          navigate('', { replace: true })
        })
      } else
      {
        navigate('', { replace: true })
      }
    })
  }
  const convertToSchoolAction = (!schoolAttr && !wblAttr) ? mkGroupAction('Convert to School', <SchoolIcon />, onConvertToSchool) : null

  const actionsList = [statsAction, inviteHeadOfWelshAction, viewAsHeadOfWelshAction, viewAsWBLAction, convertToSchoolAction]
  const actions = actionsList.map((f,i) => f ? f(i) : null).filter(x => x) // we assign the key first to assure that key will remain invariant

  const groups = group ? group.groups : []
  const managers = group ? group.managers : []
  const manager_invites = invites ? invites.filter(inv => inv.roles.some(rl => rl === PersonType.manager)) : []
  const learners = group ? group.users : []
  const learner_invites = invites ? invites.filter(inv => inv.roles.some(rl => rl === PersonType.learner)) : []

  const getManagerEmail = uuid => group.managers.find(e => e.uuid === uuid)?.email
  const getUserEmail = uuid => group.users.find(e => e.uuid === uuid)?.email

  const page =
    <>
      <NavPanel className='group-manager-content' backLabel={translate('back')} backUrl={-1}>
        <AdminContainer className='group-container'>
          <GroupsList className='groups-column' label={translate('subgroups')} groups={groups} addGroup={showAddGroup} deleteGroup={deleteGroup} renameGroup={renameGroup} setPlayAsClass={setPlayAsClass} moveGroup={moveGroup} />
          <GroupManagerOverview group={group} groups={groups} actions={actions} moveGroup={moveGroup} showConfirm={showConfirm} />
          <PersonList className='managers-column' label={translate('managers')} roles={[PersonType.manager]} people={managers} invited={manager_invites} getEmail={getManagerEmail} addPeople={addManagers}
                        group={group} relnDescription='as a manager of this group' refreshGroup={refreshGroup} refreshInvites={refreshInvites} showAlert={showAlert} showConfirm={showConfirm} />
          <PersonList className='learners-column' label={translate('learners')} roles={[PersonType.learner]} people={learners} invited={learner_invites} getEmail={getUserEmail} addPeople={addLearners}
                      group={group} relnDescription='as a learner from this group' refreshGroup={refreshGroup} refreshInvites={refreshInvites} showAlert={showAlert} showConfirm={showConfirm} />
        </AdminContainer>
      </NavPanel>
      {spinOverlay}
      {addGroupDialog}
      {addManagerDialog}
      {addLearnerDialog}
      {addHeadOfWelshDialog}
      {alertDialog}
      {confirmDialog}
      {popNFadeDialog}
    </>
  return page
}

async function convertToSchool(api, groupId, groupTree, showAlert)
{
  if (groupTree.groups.length < 1)
  {
    return 'There are no subgroups for year levels'
  }
  for (const grp of groupTree.groups)
  {
    if (grp.users.length > 0)
    {
      return `"${grp.name}" appears to be a class at the year level, please re-org first`
    }
    for (const sgrp of grp.groups)
    {
      if (sgrp.groups.length > 0)
      {
        return 'Schools can currently only be organized on two-level (year / class)'
      }
    }
  }

  // good to go!

  for (const mgr of groupTree.managers)
  {
    const error = await api.addToGroup(mgr.uuid, [PersonType.schoolsTeacher], groupId, true)
    if (error)
    {
      return `Unable to add ${mgr.name} as schools teacher`
    }
  }
  for (const grp of groupTree.groups)
  {
    for (const mgr of grp.managers)
    {
      // these are managers at the mid-level .. move 'em up to the top level
      const error = await api.addToGroup(mgr.uuid, [PersonType.manager], groupId, true)
      if (error)
      {
        return `Unable to add ${mgr.name} as top-level manager`
      }
      const error2 = await api.addToGroup(mgr.uuid, [PersonType.schoolsTeacher], groupId, true)
      if (error2)
      {
        return `Unable to add ${mgr.name} as schools teacher`
      }
      const error3 = await api.removeFromGroup(mgr.uuid, [PersonType.manager], grp.uuid, true)
      if (error3)
      {
        return `Unable to remove ${mgr.name} as mid-level manager`
      }
    }
    // move invites
    const invites = await api.getInvites(grp.uuid)
    const mgrInvites = invites.filter(inv => inv.roles.some(rl => rl === PersonType.manager))
    for (const invite of mgrInvites)
    {
      const error = await api.moveInvite(grp.uuid, invite.uuid, groupId)
      if (error)
      {
        return `Unable to move ${invite.uuid}`
      }
      const error2 = await api.amendInvite(groupId, invite.uuid, [PersonType.manager,PersonType.schoolsTeacher])
      if (error2)
      {
        return `Unable to amend ${invite.uuid}`
      }
    }
    for (const sgrp of grp.groups)
    {
      for (const mgr of sgrp.managers)
      {
        const error = await api.addToGroup(mgr.uuid, [PersonType.manager], groupId, true)
        if (error)
        {
          return `Unable to add ${mgr.name} as top-level manager`
        }
        const error2 = await api.addToGroup(mgr.uuid, [PersonType.schoolsTeacher], groupId, true)
        if (error2)
        {
          return `Unable to add ${mgr.name} as schools teacher`
        }
      }
      // move invites
      const invites = await api.getInvites(sgrp.uuid)
      const mgrInvites = invites.filter(inv => inv.roles.some(rl => rl === PersonType.manager))
      for (const invite of mgrInvites)
      {
        const error = await api.moveInvite(sgrp.uuid, invite.uuid, groupId)
        if (error)
        {
          return `Unable to move ${invite.uuid}`
        }
        const error2 = await api.amendInvite(groupId, invite.uuid, [PersonType.manager,PersonType.schoolsTeacher])
        if (error2)
        {
          return `Unable to amend ${invite.uuid}`
        }
      }
    }

    // wait until the end to tag the school so that it's easy to replay until all errors are fixed
    const err = await api.addAttribute('role:school', groupId)
    if (err)
    {
      return err
    }
    return null
  }
}
