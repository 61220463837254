import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isPuppetable: false,
    isWebGL2: false,
    reasons: []
};

export const capabilitySlice = createSlice({
    name: "capabilty",
    initialState: initialState,
    reducers: {
        setCapability: (state, action) => {
            const features = action.payload;
            state.isPuppetable = features.isPuppetable;
            state.isWebGL2 = features.isWebGL2;
            state.reasons = [...features.reasons];
        }
    }
});

export const { setCapability } = capabilitySlice.actions;

export default capabilitySlice.reducer;