import { BrowserRouter, Routes, Route, Outlet, Navigate } from 'react-router-dom'
import { AuthProvider, Guarded } from './Auth'
import APIProvider from "./Core/app/API"
import MediaProvider from './Core/Loader'
import AutoMagicProvider from "./AutoMagic"
import { TranslationProvider } from './Translation'
import AddTrial from './Pages/AddTrial'
import Admin from './Pages/Admin'
import AppSwitch from './Widgets/AppSwitch'
import Content from './Content'
import Demo from './Pages/Demo'
import ErrorBoundary from './ErrorBoundary'
import ErrorPage from './Pages/ErrorPage'
import InviteCode from './Pages/InviteCode'
import LandingPage from './Pages/LandingPage'
import LandingPageASR from './Pages/LandingPageASR'
import { LoginPage, LoginWithPage, Forgot, ResetPassword } from './Pages/Login'
import OnBoardLanding from './OnBoardLanding'
import PostExpiryAndSubscribe from './Pages/PostExpiryAndSubscribe'
import Session from './Session'
import SignupASR from './Pages/SignupASR'
import SignupPassword from './Pages/SignupPassword'
import Subscribe from './Pages/Subscribe'
import TrialsSignup from './Pages/SignupTrials'
import WBLSignup from './Pages/WBLSignup'
import { WBLPage } from './Admin/WBL'
import './App.css'

export default function App() {
  const app =
    <ErrorBoundary>
      <APIProvider>
        <MediaProvider>
          <AutoMagicProvider>
            <AuthProvider>
              <BrowserRouter>
                <TranslationProvider>
                  <Session>
                    <Routes>
                      <Route path='/' element={<Layout />}>
                        <Route index element={<LandingPage />} />
                        <Route path='login' element={<LoginPage />} />
                        <Route path='login/:context' element={<LoginPage />} />
                        <Route path='login/with/:guid' element={<LoginWithPage />} />
                        <Route path='forgot/:email' element={<Forgot />} />
                        <Route path='forgot' element={<Forgot />} />
                        <Route path='reset-password/:guid' element={<ResetPassword />} />
                        <Route path='invite-code' element={<InviteCode />} />
                        <Route path='signup' element={<TrialsSignup />} />
                        <Route path='signup/welsh' element={<TrialsSignup community='welsh' />} />
                        <Route path='signup/welsh/:code' element={<Guarded context='Trials'><AddTrial community='welsh' /></Guarded>} />
                        <Route path='signup/spanish' element={<TrialsSignup community='spanish' />} />
                        <Route path='asr_signup' element={<LandingPageASR />} />
                        <Route path='asr_signup/:locale' element={<SignupASR />} />
                        <Route path='signup-password' element={<SignupPassword />} />
                        <Route path='trials-signup' element={<TrialsSignup />} />
                        <Route path='wbl-signup' element={<WBLSignup />} />
                        <Route path='subscribe' element={<Guarded><Subscribe /></Guarded>} />
                        <Route path='error/:key' element={<ErrorPage />} />
                        <Route path='onboard' element={<OnBoardLanding />} />
                        <Route path='admin/*' element={<Guarded requiredAttrs={[{attribute: 'manager'}]}><Admin /></Guarded>} />
                        <Route path='wbl/:groupId' element={<Guarded requiredAttrs={['manager']}><WBLPage /></Guarded>} />
                        <Route path='demo' element={<Guarded requiredAttrs={[{attribute: 'manager'}]}><Demo /></Guarded>} />
                        <Route path='app/*' element={<Guarded><AppSwitch /></Guarded>} />
                        <Route path='learn' element={<Navigate to='/app/learn' replace />} />
                        <Route path='post-subscribe' element={<PostExpiryAndSubscribe />} />
                        <Route path='feedback' element={<Navigate to='/app/settings/feedback' replace />} />
                      </Route>
                    </Routes>
                  </Session>
                </TranslationProvider>
              </BrowserRouter>
            </AuthProvider>
          </AutoMagicProvider>
        </MediaProvider>
      </APIProvider>
    </ErrorBoundary>
  return app
}

function Layout() {
  let layout =
    <>
      <Content>
        <Outlet />
      </Content>
    </>
  return layout
}
