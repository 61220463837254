import SVGIcon from './SVGIcon'

export default function GoBackwardIcon({className, color, onClick})
{
  return <SVGIcon
          name='gobackward'
          className={className}
          size='79.36 86.8'
          color={color}
          onClick={onClick}
          paths={[
            'm.25,47.15c0,21.53,17.92,39.4,39.4,39.4s39.45-17.87,39.45-39.4c0-18.95-13.87-35.06-31.84-38.67V3.25c0-3.03-2.1-3.81-4.35-2.15l-12.4,8.69c-1.9,1.32-1.95,3.37,0,4.74l12.35,8.69c2.29,1.66,4.39.88,4.39-2.2v-4.93c14.06,3.37,24.32,15.92,24.32,31.05,0,17.77-14.16,31.98-31.93,31.98s-31.98-14.21-31.93-31.98c.05-10.45,4.93-19.63,12.6-25.44,1.76-1.32,2.34-3.42,1.22-5.22-1.07-1.81-3.52-2.25-5.47-.78C6.5,22.98.25,34.45.25,47.15Z',
            'm25.4,50.86h28.71c2.44,0,4.15-1.27,4.15-3.61s-1.61-3.71-4.15-3.71h-28.71c-2.59,0-4.2,1.32-4.2,3.71s1.71,3.61,4.2,3.61Z'
            ]}
          />
}
