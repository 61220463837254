import { useEffect, useState } from 'react'
import { useAPI } from '../Core/app/API'
import { useTranslate } from '../Translation'

export function useAttributes(startSpinning, stopSpinning)
{
  const api = useAPI()
  const translate = useTranslate()

  const [attributes, setAttributes] = useState([])
  const updateAttributes = async () =>
  {
    if (startSpinning) startSpinning(translate('loading'));
    const attrs = await api.userAttributes()
    if (stopSpinning) stopSpinning();
    if (!attrs) return;
    setAttributes(attrs)
  }
  useEffect(() =>
  {
      updateAttributes()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return attributes
}

