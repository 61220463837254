import SVGIcon from './SVGIcon'

export default function InfoIcon({className, color, onClick})
{
  return <SVGIcon
          name='info'
          className={className}
          size='78.86 78.81'
          color={color}
          onClick={onClick}
          paths={[
            'm39.4,78.81c21.53,0,39.45-17.87,39.45-39.4S60.89,0,39.36,0,0,17.87,0,39.4s17.92,39.4,39.4,39.4Zm0-7.42c-17.72,0-31.88-14.21-31.88-31.98S21.63,7.42,39.36,7.42s32.03,14.21,32.03,31.98-14.21,31.98-31.98,31.98Z',
            'm32.81,61.04h15.53c1.76,0,3.17-1.27,3.17-3.08,0-1.71-1.42-3.03-3.17-3.03h-4.3v-18.26c0-2.39-1.17-3.91-3.37-3.91h-7.13c-1.76,0-3.12,1.32-3.12,2.98,0,1.81,1.37,3.08,3.12,3.08h3.56v16.11h-4.3c-1.76,0-3.17,1.32-3.17,3.03,0,1.81,1.42,3.08,3.17,3.08Zm6.2-34.28c3.08,0,5.47-2.44,5.47-5.52s-2.39-5.47-5.47-5.47-5.47,2.39-5.47,5.47,2.44,5.52,5.47,5.52Z'
            ]}
          />
}
